import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Box,Collapse,IconButton,Paper,Table,TableBody,TableCell,LinearProgress,
        TableContainer,TableHead,TableRow,} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import EditarLectura from './EditarMtto'
import EditarOrdenDummy from './EditarMttoDummy'
import CreateIcon from '@material-ui/icons/Create';
import {llamaApiCargaToken,llamadaApiToken,ErrorGeneral } from '../funciones/ClienteAxios';
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {    
    maxHeight: window.innerHeight-170,
    minHeight: 280,
    minWidth:'100%',
  },
  container2: {
    maxHeight: 550,    
    //minHeight: 280,
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: process.env.REACT_APP_COLOR
    }
  },
  tableCell: {
    "$selected &": {
      color: "white"
    }
  },
  hover: {},
  selected: {}
});

const columnas=[      
    {
      Id:1,
      titulo:"Mtto",
      alineacion:'center'
    }, 
    {
      Id:4,
      titulo:"Estatus",
      alineacion:'center'
    },
    { Id:3,
      titulo:"Fecha",
      alineacion:'center'
    },    
    {
      Id:2,
      titulo:"Tipo",
      alineacion:'center'
    }, 
  ]

export default function TablaOrden({setOrdenSelect,orden,fechasSeleccionadas,buscar,setBuscar,
    turno,usuarioContxt,setArrCabinas,setEsperCab,terminal,estatus,setAvTotal,setAuxCabSelecc ,auxPilDet}) {
  const classes = useStyles();
  let history = useHistory();
  const [selectedID, setSelectedID] = useState(null);
  const [open, setOpen] = React.useState(false);     
  const [espera,setEspera]=useState(false);
  const [ordenSelecc, setOrdenSelecc] = React.useState(0);
  const [seleccionadoEdit, setSeleccionadoEdit] = React.useState([]);
  const [modalAbierto, setModalAbierto] = React.useState(false);

  const [datos, guardarDatos] = React.useState([])  
  const[sinDatos,guardarSinDatos]= React.useState(true)
  const[auxListCabs,setAuxListCabs]= React.useState([])  
  let nvlSNPermiso ='5'
  const source = axios.CancelToken.source();         
  let idusu= localStorage.getItem('UsuId');
  React.useEffect(()=>{ 
     
    let fInicio=moment(fechasSeleccionadas.inicio).format("YYYY-MM-DD")
    let fFinal=moment(fechasSeleccionadas.final).format("YYYY-MM-DD")        
      
    const llenaListCabinas = () => {      
      setOrdenSelect([])
      setOrdenSelecc(0)
      setSelectedID(null)
      let data = qs.stringify({
        Terminal:terminal,
        FechaIni:fInicio+'T00:00:00' ,
        FechaFin:fFinal+'T23:59:59',    
        Estatus:estatus,
        idDispositivo:'89y7gttvjhj98h'
      });
      let url = "/pilona-mtto-intervalo";
      function respuestaListAct(auxiliar) {
        if (auxiliar[0].Id!=-1) 
        { guardarSinDatos(true)                                                           
          guardarDatos(auxiliar)
        }
        else
        { guardarSinDatos(false)  
          guardarDatos([])
        }      
      }

      llamaApiCargaToken(data, url,respuestaListAct,ErrorGeneral,setEspera,history,source);
    };   
    llenaListCabinas();      
    return ()=> {
      source.cancel();
    }                

  },[buscar]);
  React.useEffect(() => {
    if (auxPilDet!==0) {
     
      const llenaDetalleCab = () => {      
        //setOrdenSelect([])
        //setAuxCabSelecc(0)
        let data = qs.stringify({
          MttoId:  auxPilDet,
          Usuario:idusu,
          idDispositivo:'89y7gttvjhj98h'
        });
        let url = "/pilona-mtto-det-list";
        function respuestaDetaCab(auxiliar) {
          if (auxiliar[0]?.Id!==-1 &&parseInt(auxiliar[0]?.Id)!==-1  ) 
          { setArrCabinas(auxiliar)
            //console.log(auxiliar);
           // setOrdenSelect(mtto) 
            //console.log({avance:`${mtto.avance}/${mtto.total}`,original:auxiliar});
            let auxAvance=0 ,auxTotal=0
            auxiliar.forEach(element => {
              auxAvance= auxAvance + element.Avance
              auxTotal= auxTotal + element.Total
            });
            setAvTotal({avance:`${auxAvance}/${auxTotal}`,original:auxiliar})
            setAuxListCabs(auxiliar)
          }             
        }
        llamaApiCargaToken(data, url,respuestaDetaCab,ErrorGeneral,setEsperCab,history,source);
      };     
      llenaDetalleCab()
    }      
  }, [auxPilDet])
  
  const llamadaMtto=(mtto)=>{    
    const llenaDetalleCab = () => {      
      setOrdenSelect([])
      setAuxCabSelecc(0)
      let data = qs.stringify({
        MttoId:  mtto.Id,
        Usuario:idusu,
        idDispositivo:'89y7gttvjhj98h'
      });
      let url = "/pilona-mtto-det-list";
      function respuestaDetaCab(auxiliar) {
        if (auxiliar[0]?.Id!==-1 &&parseInt(auxiliar[0]?.Id)!==-1  ) 
        { setArrCabinas(auxiliar)
          //console.log(auxiliar);
          setOrdenSelect(mtto) 
          //console.log({avance:`${mtto.avance}/${mtto.total}`,original:auxiliar});
          let auxAvance=0 ,auxTotal=0
          auxiliar.forEach(element => {
            auxAvance= auxAvance + element.Avance
            auxTotal= auxTotal + element.Total
          });
          setAvTotal({avance:`${auxAvance}/${auxTotal}`,original:auxiliar})
          setAuxListCabs(auxiliar)
        }             
      }
      llamaApiCargaToken(data, url,respuestaDetaCab,ErrorGeneral,setEsperCab,history,source);
    };     
    /* const llamaAvance = () => {      
      setAvTotal({avance:"",original:[]})
      let data = qs.stringify({
          MttoId:mtto.Id,
          Cabina:0,
          Status:3,
          idDispositivo:'89y7gttvjhj98h'
      });
      let url = "/cabina-mtto-avance";
      function respuestaAvan(auxiliar) {
        if (auxiliar ) 
        { //console.log(auxiliar);
          //setAvTotal({avance:`${auxiliar.avance}/${auxiliar.total}`,original:auxiliar})
        }               
      }      
      llamadaApiToken(data, url,respuestaAvan,ErrorGeneral,history,source);
    }; */
    llenaDetalleCab()    
    //llamaAvance()          
  }

  const seleccionado=(auxId,auxOrden)=>{
    setArrCabinas([])   
    setAuxListCabs([])
    //console.log(auxOrden);
    llamadaMtto(auxOrden)              
    setSelectedID(auxId)    
  }

  function editarFall(auxFalla) {
    setSeleccionadoEdit(auxFalla) 
    setModalAbierto(true)
  }
   
  function verMas(auxId) 
  { if (auxId === ordenSelecc) 
    { setOpen(!open)
    }
    else
    { setOpen(true)
    }        
    setOrdenSelecc(auxId)
  }

  function tipoEstado(estado,idSelecc,auxEtiq)
  { let auxEtiqueta=''
    let auxColor=''
    if (estado===1) 
    { auxEtiqueta='Pendiente'
      auxColor='#D83906'
    }
    else
    { if (estado===3) 
      { auxEtiqueta='Completo'
        auxColor='#107014'
      }
      else
      { if (estado == 2) 
        { auxEtiqueta='Avance'
          auxColor='#E1AA06'
        } 
      }
    }
    let renglon = <div></div>
    let colorCell = selectedID ===idSelecc? 'white':auxColor
  
    renglon = <TableCell style={{ color:colorCell,fontWeight:700,width:100+'px'}} 
                padding={'none'} align="center" >
                {auxEtiq}
              </TableCell>
    return renglon
  }

function Row(props) {
  const { row } = props;  
  return (
    <React.Fragment>
      <TableRow onClick={ () => { seleccionado(row.Id,row); } }
        className={classes.tableRow} selected={ selectedID === row.Id}
        classes={{ hover: classes.hover, selected: classes.selected }}        
      >
        <TableCell align="center" padding={'none'} style={{ width:30+'px'}} >
          <IconButton color="primary"  aria-label="expand row" size="small" onClick={() => verMas(row.Id)}>
            { open && ordenSelecc=== row.Id ?( selectedID === row.Id?<KeyboardArrowUpIcon style={{color:'white'}} />:
                                                  <KeyboardArrowUpIcon  />) : 
              selectedID === row.Id ? <KeyboardArrowDownIcon style={{color:'white'}}  />:<KeyboardArrowDownIcon  />}            
          </IconButton>
        </TableCell>
        <TableCell align="center" padding={'none'} style={{ width:30+'px'}} >
          <IconButton   aria-label="add circle" size="small" onClick={() => editarFall(row)} component="span">
          {selectedID === row.Id ? <CreateIcon style={{color:'white'}} />:<CreateIcon color="primary"/>}
          </IconButton>                                                                                                                                                                          
        </TableCell>      
        <TableCell  className={classes.tableCell} style={{ fontWeight:700,width:50+'px'}}
           align="center" padding={'none'} 
        >
          {row.Id}
        </TableCell>     
        {tipoEstado(row.stId,row.Id,row.stDescr)}   
        <TableCell  className={classes.tableCell} align="center" 
          padding={'none'} style={{ width:120+'px'}}
        >
          {moment.utc(row.Fecha).local(true).format("DD/MM/YYYY HH:mm")}
        </TableCell>        
        <TableCell  className={classes.tableCell} style={{ fontWeight:700,width:110+'px'}}
           align="center" padding={'none'} 
        >
          {row.tpDescr}
        </TableCell>                                  
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6} >
          <Collapse in={open && ordenSelecc=== row.Id} timeout="auto" unmountOnExit>
          <Box >                
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow color="primary">
                    <TableCell style={{ color:'#FF6439'}} padding={'none'} >
                      Descripción
                    </TableCell>                       
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={row.Id}>
                    <TableCell align="left" padding={'none'} >
                      {row.descr }
                    </TableCell>                    
                  </TableRow>
                </TableBody>
              </Table>
            </Box>            
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
    );
  }
  function tablaCompleta(auxlista){
    //console.log("entrando sin preguntar");
    return(
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>            
              <TableCell align="center" padding={'none'}></TableCell>  
              <TableCell align="center" padding={'none'}></TableCell>                             
              {columnas.map((col)=>{
               return <TableCell  key={col.Id} align={col.alineacion} 
                  padding={'none'}
                >
                  {col.titulo}
                </TableCell> 
              })}                   
            </TableRow>
          </TableHead>
          <TableBody>
            {auxlista.length!=0 ? (auxlista.map((row) => (
                <Row key={row.Id} row={row} />
            ))):null}
          </TableBody>
        </Table>
      </TableContainer>
   )
  }
  const tablaSinDatos=()=>{
   return(<TableContainer className={classes.container2}>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>            
        {columnas.map((col)=>{
       return   <TableCell  key={col.Id} align={col.alineacion} 
            padding={'none'}
          >
            {col.titulo}
          </TableCell> 
        })}                                            
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow  className={classes.tableRow} >          
          <TableCell  className={classes.tableCell} align="center" padding={'none'}>
            Dia
          </TableCell>          
          <TableCell  className={classes.tableCell} align="left" padding={'none'}>
            Sin
          </TableCell>             
          <TableCell  className={classes.tableCell} align="left" padding={'none'}>
            Reportes
          </TableCell>                      
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
  ) 
  }

  const tablaSinResultados=()=>{
    return(
      <TableContainer className={classes.container2}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>            
              {columnas.map((col)=>{
               return <TableCell  key={col.Id} align={col.alineacion} 
                  padding={'none'}
                >
                  {col.titulo}
                </TableCell> 
              })}                     
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={classes.tableRow} >          
              <TableCell  className={classes.tableCell} align="center">Orden</TableCell>          
              <TableCell  className={classes.tableCell} align="left">Sin</TableCell>             
              <TableCell  className={classes.tableCell} align="left">coincidencias</TableCell>                  
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    ) 
   }

  function filtro()
  {        
    let info=[]      
    if (turno.length!=0) 
    { let auxCadena=turno.trim();       
      info=datos.filter((dat)=>dat.turno.toString().search(auxCadena.toUpperCase() ) !== -1)   
     // console.log(info);
    } 
    else 
    {
      if (orden.length!=0) 
      { let auxCadena=orden.trim();
        info=datos.filter((dat)=>dat.Id.toString().search(auxCadena) !== -1)           
        
      }
    }
    //let info=datos.filter((dat)=>dat.Id.toString().search(auxCadena.toUpperCase() ) !== -1)         
    let metodos=null
    if (info.length>0) {
      //console.log("mandandando la lista");
      metodos= tablaCompleta(info)
    } else {
      metodos=tablaSinResultados()
    }
    return metodos ;
  }
  const contenido2= turno!=''||  orden!=''  ? filtro() :tablaCompleta(datos) 
  const contenido = sinDatos&&datos.length!=0 ?contenido2:tablaSinDatos()
  const tabla = espera ?  (<Box mt={2}><LinearProgress/> </Box>) :( contenido )
  const modal =seleccionadoEdit.length!=0 && modalAbierto !='' ? 
              (  usuarioContxt?.nivel !=undefined && usuarioContxt.nivel!== nvlSNPermiso ?
                  (<EditarLectura titulo={'Editar Mantenimiento '+seleccionadoEdit.Id} modalAbierto={modalAbierto} 
                    setModalAbierto={setModalAbierto} lecturaSelect={seleccionadoEdit} 
                    setBuscar={setBuscar} buscar={buscar} auxListCabs={auxListCabs} setOrdenSelect={setOrdenSelect}
                    datos={datos}  setDatos={guardarDatos}
                  />)
              
                : (<EditarOrdenDummy titulo={'Mantenimiento '+seleccionadoEdit.Id} modalAbierto={modalAbierto} setModalAbierto={setModalAbierto}
                lecturaSelect={seleccionadoEdit} setBuscar={setBuscar} buscar={buscar} auxListCabs={auxListCabs}
                  />)              
              )        
              :null
  return (
    <Paper className={classes.root}>
      {tabla}
      {modal}
    </Paper>
  );
}