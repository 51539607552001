import Modal from "../Generales/ModalTam";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    Input,
    InputLabel,
    ListItemText,
    MenuItem,
    Radio,
    RadioGroup,
    Select as MaterialSelect,
    TextField
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {ErrorGeneral, llamaApiCargaToken, llamadaApiToken} from "../funciones/ClienteAxios";
import moment from "moment/moment";
import {useHistory} from "react-router-dom";
import axios from "axios";
import qs from 'qs';
import {makeStyles} from "@material-ui/core/styles";
import {toast} from "react-toastify";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const AgregarOT = ({modalAbierto, setModalAbierto, titulo, paroSelect, recargaTabla}) => {

    const classes = useStyles();
    const source = axios.CancelToken.source();

    let idusu = localStorage.getItem('UsuId');
    let history = useHistory();
    let numUbiOtro = 234;
    let hoy = paroSelect.fecha;
    let fecha = moment(hoy).format("YYYY-MM-DD");
    let fecha2 = moment(hoy).format("YYYY-MM-DDTHH:mm:ss")

    const [espera, setEspera] = useState(false);
    const [aviso, setAviso] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [tipoAdvertencia, setTipoAdvertencia] = useState('success');
    const [listParoTipo, setListParoTipo] = useState([])
    const [listLugar, setListLugar] = useState([])
    const [listCategoria, setListCategoria] = useState([])
    const [listLugarTerminal, setListLugarTerminal] = useState([])
    const [listUbicacion, guardarListUbicacion] = useState([])
    const [listPeriodo, setListPeriodo] = useState([])
    const [auxUbicacionTipo, guardarAuxUbicacionTipo] = useState([])
    const [auxUbicacionSelecc, guardarAuxUbicacionSelecc] = useState([])
    const [ubicacionSeleccion, guardarUbicacionSeleccion] = useState([])
    const [nuevaFalla, guardarNuevaFalla] = useState({
        ParoId: 0,
        Estado: "",
        Ala: 0,
        Turno: 'N',
        cabina: 0,
        Categoria: 0,
        CategoriaNom: "",
        CodError: "",
        conducId: 0,
        conducNom: "",
        descr: "",
        Fecha: new Date(),
        Horario: new Date(),
        Informacion: "",
        Lugar: 0,
        SolucionId: 0,
        Solucion: 0,
        Terminal: 0,
        Tiempo: new Date(),
        Tipo: 0,
        TipoDescr: "",
        Ubicacion: 0,
        UbicDescr: "",
        Periodo: '',
        refManual: '',
        Multiple:false,
    })
    const {
        Terminal,
        Tipo,
        Ala,
        Lugar,
        Turno,
        TipoDescr,
        refManual,
        Ubicacion,
        UbicDescr,
        ParoId,
        Periodo,Multiple

    } = nuevaFalla;

    function llenarUbicaciones() {
        const auxUbi = JSON.parse(localStorage.getItem('LisUbicOrd'));

        let auxArre = [];
        let auxArre2 = [];

        auxArre2.push('TODO')
        auxUbi.forEach((ubica) => {
            if (ubica.LugarId.toString() === Lugar && (ubica.Terminal.search(Terminal) !== -1)) {
                auxArre.push({value: ubica.Id, label: ubica.Tipo})
                auxArre2.push(ubica.Tipo)
            }
        })

        if (Lugar === 5 ) {
            guardarNuevaFalla({
                ...nuevaFalla,
                Ubicacion: numUbiOtro,
                UbicDescr: (UbicDescr.length !== 0 ? UbicDescr : ''),
                Multiple:false,
            })
        } else {
            guardarNuevaFalla({
                ...nuevaFalla,
                Ubicacion: 0,
                UbicDescr: '',
                Multiple:false,
            })
        }
        guardarUbicacionSeleccion([])
        guardarAuxUbicacionSelecc([]);
        guardarListUbicacion(auxArre)
        guardarAuxUbicacionTipo(auxArre2)
    }

    useEffect(() => {

        guardarNuevaFalla({
            ...nuevaFalla, ParoId: paroSelect.Id,
            Estado: paroSelect.Status.toString(),
            Ala: paroSelect.alaId,
            Turno: 'N',
            cabina: paroSelect.cabina,
            Categoria: paroSelect.categId,
            CategoriaNom: paroSelect.categNom,
            CodError: paroSelect.codError,
            conducId: paroSelect.conducId,
            conducNom: paroSelect.conducNom,
            descr: paroSelect.descr,
            Fecha: fecha2,
            Horario: fecha + "T" + paroSelect.hora,
            Informacion: paroSelect.info,
            SolucionId: paroSelect.solucId,
            Solucion: paroSelect.solucion,
            Terminal: paroSelect.termiId,
            Tiempo: fecha + "T" + paroSelect.horaCierre,
            Tipo: 0,//paroSelect.tipoId,
            TipoDescr: paroSelect.descr,
            Ubicacion: paroSelect.ubicId,
            UbicDescr: paroSelect.ubicDescr,
            Periodo: '',
            refManual: '',
        })
        const llenarListTerminal = () => {
            let data = qs.stringify({
                usuario: idusu, idDispositivo: '89y7gttvjhj98h'
            });
            let url = "/lugar-terminal-list";

            function respuesta(aux) {
                if (aux[0].Id !== -1) {
                    let auxArre = [];
                    aux.map((tipo) => {
                        if (tipo.Id != 0) {
                            auxArre.push({value: tipo.Id, label: tipo.Descr})
                        }
                    })
                    setListLugarTerminal(auxArre);
                }
            }

            llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source)
        };

        if (listLugarTerminal.length === 0) {
            llenarListTerminal();
        }

        const llenarListParo = () => {
            let data = qs.stringify({
                idDispositivo: '89y7gttvjhj98h'
            });
            let url = "/orden-actividad-tipo";

            function respuesta(aux) {
                if (aux[0].Id !== -1) {
                    let auxArre = [];
                    aux.map((tipo) => {
                        auxArre.push({value: tipo.Id, label: tipo.Tipo})
                    })
                    setListParoTipo(auxArre);
                }
            }

            llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source)
        };

        if (listParoTipo.length === 0) {
            llenarListParo();
        }

        const llamaListPeriodo = () => {
            let data = qs.stringify({
                tipo: "ORD",
                idDispositivo: '987gyubjhkkjh'
            });
            let url = "/mtto-periodo";

            function respuesta(aux) {
                if (aux[0].length !== 0) {
                    setListPeriodo(aux);
                }
            }

            llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source)
        };

        if (listPeriodo.length === 0) {
            llamaListPeriodo();
        }

        const llenarListLugar = () => {
            let data = qs.stringify({
                tipo:'ORD',
                idDispositivo: '89y7gttvjhj98h'
            });
            let url = "/lugar-list-1_1";

            function respuesta(aux) {
                if (aux[0].Id !== -1) {
                    let auxArre = [];
                    aux.map((tipo) => {
                        if (tipo.Id != 0) {
                            auxArre.push({value: tipo.Id, label: tipo.Tipo})
                        }
                    })
                    setListLugar(auxArre);
                }
            }

            llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source)
        };

        if (listLugar.length === 0) {
            llenarListLugar()
        }

        const llenarListCategoria = () => {
            let data = qs.stringify({
                idDispositivo: '89y7gttvjhj98h'
            });
            let url = "/parofalla-categoria";

            function respuesta(aux) {
                if (aux[0].Id !== -1) {
                    let auxArre = [];
                    aux.map((tipo) => {
                        if (tipo.Id != 0) {
                            auxArre.push({value: tipo.Id, label: tipo.Tipo})
                        }
                    })
                    setListCategoria(auxArre);
                }
            }

            llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source)
        };

        if (listCategoria.length === 0) {
            llenarListCategoria()
        }

    }, [])

    useEffect(() => {

        if (Lugar !== '' && Terminal !== 0) {
            llenarUbicaciones()
        }

    }, [Terminal, Lugar])

    const seleccionarUbicacion = (e) => {
        let resultados = [];
        let auxArreglo = [];

        if (e.target.value.length > 1) {
            let ubicNueva = filterArray(auxUbicacionSelecc, e.target.value)

            if (ubicNueva.length !== 0) {
                if (ubicNueva == 'TODO') {
                    auxArreglo = []
                } else {
                    resultados = listUbicacion.filter((registro) => registro.label == ubicNueva)
                    if (ubicacionSeleccion.length > 1) {
                        let arrFiltrado = ubicacionSeleccion.filter((registro) => {

                            return (registro.value !== resultados[0].value)
                        })
                        auxArreglo = arrFiltrado;
                    } else {
                        auxArreglo.push({value: resultados[0].value, label: resultados[0].label})
                    }
                }
            } else {
                if (e.target.value[e.target.value.length - 1] === 'TODO') {
                    auxArreglo = listUbicacion
                } else {
                    resultados = listUbicacion.filter((registro) => registro.label === e.target.value[e.target.value.length - 1])
                    auxArreglo = ubicacionSeleccion
                    auxArreglo.push({value: resultados[0].value, label: resultados[0].label})
                }
            }
        } else {
            if (e.target.value[0] === 'TODO') {
                auxArreglo = listUbicacion

            } else {
                resultados = listUbicacion.filter((registro) => registro.label === e.target.value[0])
                if (resultados.length !== 0) {
                    auxArreglo.push({value: resultados[0].value, label: resultados[0].label})
                } else {
                    auxArreglo = []
                }
            }
        }

        guardarUbicacionSeleccion(auxArreglo)

        if (auxArreglo.length !== 0) {
            if (auxArreglo[0].length != 0) {
                let nomUbiID = '';
                auxArreglo.forEach((ubicacion) => {
                    nomUbiID = nomUbiID + ubicacion.value + ','
                })
                let nomUbi = '';
                if (e.target.value[e.target.value.length - 1] === 'TODO') {
                    auxArreglo = listUbicacion
                    auxArreglo.forEach((ubicacion) => {
                        nomUbi = `${nomUbi}${ubicacion.label}, `
                    })
                } else {
                    e.target.value.forEach((ubicacion) => {
                        if (ubicacion !== 'TODO') {
                            nomUbi = `${nomUbi} ${ubicacion}, `
                        }

                    })
                }
                guardarNuevaFalla({
                    ...nuevaFalla,
                    Ubicacion: 0,
                    UbicDescr: nomUbi,
                    Multiple:auxArreglo.length===1?false:Multiple,
                })
            } else {
                guardarNuevaFalla({
                    ...nuevaFalla,
                    Ubicacion: '',
                    UbicDescr: '',
                    Multiple:false,
                })
            }
        } else {
            guardarNuevaFalla({
                ...nuevaFalla,
                Ubicacion: '',
                UbicDescr: '',
                Multiple:false,
            })
        }

        if (e.target.value[e.target.value.length - 1] === 'TODO') { //console.log(e.target.value[e.target.value.length-1]);
            if (auxArreglo.length != 0) {
                guardarAuxUbicacionSelecc(auxUbicacionTipo)
            } else {
                guardarAuxUbicacionSelecc([])
            }

        } else {
            let ubicNueva = filterArray(auxUbicacionSelecc, e.target.value)
            if (ubicNueva[0] == 'TODO') {
                guardarAuxUbicacionSelecc([])
            } else {
                guardarAuxUbicacionSelecc(e.target.value);
            }

        }
    };

    const filterArray = (arr1, arr2) => {
        const filtered = arr1.filter(el => {
            return arr2.indexOf(el) === -1;
        });
        return filtered;
    };

    const onChange = e => {
        e.preventDefault()
        guardarNuevaFalla({
            ...nuevaFalla, [e.target.name]: e.target.value
        })
    };

    const handleDescripcion = e => {
        e.preventDefault()
        guardarNuevaFalla({
            ...nuevaFalla, descr: e.target.value
        })
    };

    const handleReferencia = e => {
        e.preventDefault()
        guardarNuevaFalla({
            ...nuevaFalla, refManual: e.target.value
        })
    };

    const agregarNuevaOT = () => {
        const llenarNvoParo = () => {

            let data = qs.stringify({
                Usuario: idusu,
                Periodo: Periodo,
                Lugar: Lugar,
                Terminal: Terminal,
                Turno: Turno,
                Finaliza: '',
                Descripcion: TipoDescr,
                Solucion: '',
                Causa: '',
                Fchcausa: '',
                Usrfinaliza: '',
                UbicDescr: UbicDescr,
                UbicId: Ubicacion,
                SeccId: 0,
                TipoId: Tipo,
                RefManual: refManual,
                ParoId: ParoId,
                Multiple:Multiple ? 1 : 0,                                
                idDispositivo: '987guftyhvgjjhu'
            });
            let url = "/orden-nuevo";

            function respuesta(aux) {
                if (aux.respuesta === 1) {
                    let mensaje = "Se agrego correctamente la OT " + aux.mensaje;
                    setTipoAdvertencia("success")
                    setMensaje(mensaje);
                    setAviso(true)
                    toast.success(mensaje, {
                        pauseOnFocusLoss: false, toastId: `nva-Orden-${aux.mensaje}`
                    })
                    recargaTabla()
                    setModalAbierto(false)
                }
            }

            llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
        }
        llenarNvoParo();
    }

    const mostrarAlertas = (auxMensaje) => {
        setTipoAdvertencia("warning")
        let mensaje = auxMensaje
        setMensaje(mensaje);
        setAviso(true)
    }

    function valNulos(auxMensaje, auxCampo) {
        let valida = false
        if (auxCampo !== '' && auxCampo !== '0' && auxCampo !== 0 && auxCampo !== null && auxCampo !== " ") {
            valida = true
        }
        if (!valida) {
            mostrarAlertas(auxMensaje)
        }
        return valida
    }

    const guardaUbicacionInput = e => {
        guardarNuevaFalla({
            ...nuevaFalla,
            Ubicacion: numUbiOtro,
            UbicDescr: e.target.value,
            Multiple:false,
        })
    }
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const guardarParo = () => {
        if (valNulos("Seleccionar Terminal", Terminal) &&
            valNulos("Seleccionar Tipo", Tipo) &&
            valNulos("Seleccionar Periodo", Periodo) &&
            valNulos("Seleccionar Lugar", Lugar) &&
            valNulos("Seleccionar Ubicación", UbicDescr) &&            
            valNulos("Ingrese una Descripcion", TipoDescr) &&
            valNulos("Ingrese una Referencia Manual", refManual)
        ) 
        {
           agregarNuevaOT()
        }
    };

    const guardaTurno = e => {
        guardarNuevaFalla({
            ...nuevaFalla,
            Turno: e.target.value
        })
    };
    const onChangeCheck = (event) => {
        guardarNuevaFalla({
          ...nuevaFalla,
            [event.target.name]: event.target.checked 
          });
    };
    const onChangeUnaUbic=(e) =>{                   
        const resultados= listUbicacion.filter(  (registro) => registro.value == e.target.value  )
        //console.log(resultados);
        if (resultados.length!=0) 
        {
            guardarNuevaFalla({
                ...nuevaFalla,
                Ubicacion :e.target.value  ,
                UbicDescr :resultados[0].label  , 
                Multiple:false,       
            })  
        } 
        else 
        {   guardarNuevaFalla({
                ...nuevaFalla,
                Ubicacion :e.target.value  ,
                UbicDescr :''  ,  
                Multiple:false,      
            })  
        }
    }
    return (<Modal
        titulo={titulo} modalAbierto={modalAbierto}
        setModalAbierto={setModalAbierto} guardarDatos={guardarParo}
        mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
        aviso={aviso} setAviso={setAviso} espera={espera}
        setEspera={setEspera} tamanio={"sm"}
    >
        <form className={classes.root} noValidate autoComplete="off" fullWidth>
            <FormControl component="fieldset" >
                <FormLabel component="legend" >Turno</FormLabel>
                <RadioGroup row aria-label="position" 
                     name="Turno" value={Turno} onChange={guardaTurno}
                >
                    <FormControlLabel value="N" control={<Radio size="small" color="primary"/>} label="T.Nocturno"/>
                    <FormControlLabel value="D"  control={<Radio size="small" color="primary"/>} label="T.Diurno"/>
                </RadioGroup>
            </FormControl>
            <br/>
            {listLugarTerminal.length !== 0 ? <FormControl size="small">
                    <InputLabel htmlFor="age-native-simple" size="small">Terminal</InputLabel>
                    <MaterialSelect native value={Terminal}
                        onChange={onChange} size="small"
                        inputProps={{name: 'Terminal', id: 'Terminal',}}
                    >
                        <option aria-label="None" value=""/>
                        {listLugarTerminal.map((row, index) => (
                            <option key={index} value={row.value}>{row.label}</option>))}
                    </MaterialSelect>
                </FormControl> :
                null}
            {listParoTipo.length !== 0 ? <FormControl size="small">
                <InputLabel htmlFor="Tipo">Tipo</InputLabel>
                <MaterialSelect native value={Tipo}
                    onChange={onChange}
                    inputProps={{name: 'Tipo', id: 'Tipo',}}
                >                    
                    {listParoTipo.map((row, index) => (
                        <option key={index} value={row.value}>{row.label}</option>))}
                </MaterialSelect>
            </FormControl> : null}      
            {listPeriodo.length !== 0 ? <FormControl size="small" >
                <InputLabel htmlFor="age-native-simple">Periodo</InputLabel>
                <MaterialSelect native value={Periodo}
                    onChange={onChange}
                    inputProps={{name: 'Periodo', id: 'Periodo',}}
                >
                    <option aria-label="None" value=""/>
                    {listPeriodo.map((row, index) => (
                        <option key={index} value={row.Id}>{row.Tipo}</option>))}
                </MaterialSelect>
            </FormControl> : null}
            <br/>
            {listLugar.length !== 0 ? <FormControl size="small" style={{marginBottom:1+'rem'}}>
                <InputLabel htmlFor="age-native-simple"> Lugar </InputLabel>
                <MaterialSelect native value={Lugar}
                    onChange={onChange}
                    inputProps={{name: 'Lugar', id: 'Lugar',}}
                >
                    <option aria-label="None" value=""/>
                    {listLugar.map((row, index) => (
                        <option key={index} value={row.value}>{row.label}</option>))}
                </MaterialSelect>
                </FormControl> 
            : null}

            {listUbicacion.length !== 0 ?
                (Lugar !== 5&&Lugar !== "5"  && Lugar !== 3 && Lugar !== "3") ?
                
                    (<FormControl className={classes.formControl2} style={{width: 8 + 'rem'}} size="small">
                        <InputLabel id="input-label-ubic">Ubicación</InputLabel>
                        <MaterialSelect
                            labelId="mutiple-checkbox-label-ubic"
                            id="mutiple-checkbox-ubic"
                            multiple
                            value={auxUbicacionSelecc}
                            onChange={seleccionarUbicacion}
                            input={<Input/>}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {auxUbicacionTipo.map((ubic) => {
                                //console.log(Lugar)
                                return (
                                <MenuItem key={ubic} value={ubic}>
                                    <Checkbox checked={auxUbicacionSelecc.indexOf(ubic) > -1}/>
                                    <ListItemText primary={ubic}/>
                                </MenuItem>
                                )
                            })}
                        </MaterialSelect>
                    </FormControl> )
                    :
                    ( Lugar === 5 && Lugar === "5" ?
                        <TextField id="outlinedUbicacion"
                            label="Ubicación" size="small"
                            value={UbicDescr} onChange={guardaUbicacionInput}
                            name="UbicDescr" style={{width: 10 + 'rem'}}
                        />
                        : <FormControl style={{width:6.5+'rem'}} size="small">
                        <InputLabel htmlFor="age-native-simple">Ubicación</InputLabel>
                        <MaterialSelect native value={Ubicacion}
                            onChange={onChangeUnaUbic}
                            inputProps={{ name: 'Ubicacion', id: 'Ubicacion', }}
                        >
                            <option aria-label="None" value="" />        
                            {listUbicacion.map((row,index) => (
                                <option key={index} value={row.value}>{row.label}</option>
                            ))}   
                        </MaterialSelect>
                    </FormControl>
                    )
            : null}   
            {auxUbicacionSelecc.length>1?                     
                <FormControlLabel style={{marginLeft:.1+'rem'}}
                    control={<Checkbox checked={Multiple} onChange={onChangeCheck} name="Multiple" />}
                    label="Generar actividades por cada Ubicación"
                />     
            :null}               
            <TextField size="small"
                id="Descripción"
                label="Descripción"
                multiline rows={4}
                fullWidth variant="outlined"
                value={TipoDescr}
                name="TipoDescr"
                onChange={onChange}
            />
            <TextField size="small"
                id="Referencia Manual"
                label="Referencia Manual"
                fullWidth variant="outlined"
                value={refManual}
                onChange={handleReferencia}
            />
        </form>
    </Modal>);
};

export default AgregarOT;