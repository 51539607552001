import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {InputLabel,FormControl,Select as MaterialSelect  ,Box ,TextField
  ,FormControlLabel,LinearProgress,Checkbox,Typography} from '@material-ui/core';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import {toast} from "react-toastify";
import {ErrorGeneral, llamaApiCargaToken, llamadaApiToken} from "../funciones/ClienteAxios";
import Modal from '../Generales/ModalTam';
import Rating from '@material-ui/lab/Rating';
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '20ch',
      }, 
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      Textnumero: {
        width: 5+"px",
      },
      formControlFull: {
        //margin: theme.spacing(1),
        //minWidth: 120,
        width:5+"rem"
      },
      margin: {
        margin: theme.spacing(1),
      },
    },
  }));

const NuevaActividad = ({modalAbierto,setModalAbierto,titulo,auxOrdenId,funcionActuliza}) => {
  let history = useHistory();
  const classes = useStyles();
  const [espera,setEspera]=useState(false);
  const [mensaje, setMensaje] = useState('');
  const [tipoAdvertencia, setTipoAdvertencia] = useState('success');
  const [aviso, setAviso] = useState(false); 
  const [btnDesact, setBtnDesact] = useState(false); 
  const [nvActividad,guardarNvActividad]= useState({Orden:0,Tipo:0,Estado:1,Finaliza:0,
                                                          Prioridad:1,Descripcion:'',Solucion:'',RefManual:''});  
  const {Orden,Tipo,Estado,Finaliza,Prioridad,Descripcion,Solucion,RefManual}=nvActividad  
  const [listTipo, guardarListTipo] = useState([])  
  const source = axios.CancelToken.source(); 
  React.useEffect(()=>{           
    guardarNvActividad({
      ...nvActividad, Orden:auxOrdenId
    })                     
   const llenarListTipo = () => {
    let data = qs.stringify({
        idDispositivo: '987gyubjhkkjh'
    });
    let url = "/orden-actividad-tipo";

    function respuesta(aux) {
      if (aux[0].Id!==-1) 
      { 
        let auxArre=[];   
        aux.forEach((tipo) => {
          if (tipo.Id!==0) 
          {
            auxArre.push({ value : tipo.Id, label :tipo.Tipo })  
          }            
        })                    
        guardarListTipo(auxArre);                                      
      }         
    }
    llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);
  };  

  if (listTipo.length===0) 
  {
    llenarListTipo()
  }  
  return ()=> {
      source.cancel();
  }     
    // eslint-disable-next-line react-hooks/exhaustive-deps	
},[]);

  const registrarNActividad=()=>{               
      const llenarNvActi = () => {
        let idusu= localStorage.getItem('UsuId'); 
        //let NomUsu= localStorage.getItem('UsuarioNom');  
        let data = qs.stringify({
          Orden:Orden,
          Usuario:idusu,
          Tipo:Tipo,
          Status:1,
          Prioridad:Prioridad,
          Descripcion:Descripcion,
          Solucion:"",
          Usrfinaliza:"",
          ReferManual: RefManual,
          idDispositivo:'8978yuhjnfrsdsd'
        });
        let url = "/orden-actividad-nuevo_1_2";
      
        function respuesta(aux) {
          if (aux.respuesta==1) 
          { console.log(aux);
            let mensaje='Se registro correctamente la Nueva Actividad con Folio "'+aux.folio +'"'
            setTipoAdvertencia("success")      
            setMensaje(mensaje);
            setAviso(true)     
            toast.success(mensaje, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });       
            funcionActuliza()
            setModalAbierto(false)            
          }   
        }
        llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);
      };     
    llenarNvActi();    
  }

  const onChange = e =>
  { let expNopermitida = new RegExp('[#_:$!%-({})/*=?|]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[A-Za-z0-9]');
    if ((expCadena.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&
        !expMas.test(e.target.value) )||e.target.value===' '||e.target.value==='') 
    {
    
      guardarNvActividad({
        ...nvActividad,
        [e.target.name] : e.target.value  
      })
      if (aviso) 
      {
        setMensaje('');
        setAviso(false);
      }
    }
  };
  const onChangeDesc = e =>
  {      
    guardarNvActividad({
      ...nvActividad,
      [e.target.name] : e.target.value  
    })
    if (aviso) 
    {
      setMensaje('');
      setAviso(false);
    }    
  };
/*   const onChange2 = e =>
  { let expNopermitida = new RegExp('[#_:$!¡%-({})/*=?|]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[A-Za-z0-9]');
    if (expCadena.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&
        !expMas.test(e.target.value)||e.target.value===' '||e.target.value==='') 
    {
      guardarNvActividad({
        ...nvActividad,
        [e.target.name] : e.target.value  
      })
      if (aviso) 
      {
        setMensaje('');
        setAviso(false);
      }
    }
  };
   */
  
  const guardTerminal = e =>
  {
    guardarNvActividad({
      ...nvActividad,
      [e.target.name] : e.target.value  
    })
  };
  const validaDatos=(valor, mensaje)=>{
    let respuesta=false    
    if (valor!= null && valor!= '' && valor!= '0' && valor!== 0 ) 
    { respuesta=true     
    }
    else
    { setTipoAdvertencia("warning")    
      setMensaje(mensaje);
      setAviso(true)     
    }
    return respuesta
  }
  const guardarDatos = () => 
  {
    if (validaDatos(Tipo,"Seleccionar Tipo")) 
    { if ( validaDatos(Prioridad,"Seleccionar Prioridad")&& validaDatos(RefManual,"Llenar campo de Referencia Manual ")) 
      { if ( ((Descripcion.length>=5 && validaDatos(Descripcion, "Llenar campo de Descripción (mayor a 5 caracteres)") ))
            || (Descripcion.length<5 && validaDatos(0, "Llenar campo de Descripción (mayor a 5 caracteres)"))
            ) 
        { if (Estado != ''&&Estado != 0) 
          { if ((Finaliza && Solucion != '')|| !Finaliza) 
            { 
              registrarNActividad()                             
            } 
            else 
            { setTipoAdvertencia("warning")
              let mensaje="Llenar campo de Solución"            
              setMensaje(mensaje);
              setAviso(true)                                         
            }
          }   
          else
          { setTipoAdvertencia("warning")
            let mensaje="Seleccionar Estatus"            
            setMensaje(mensaje);
            setAviso(true)   
          }
        }   
      }         
    }                            
  };

  const onChangeRef = e =>
  { let expNopermitida = new RegExp('[$!?]');
    //let expMenos = new RegExp('-');
    //let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[A-Za-z0-9#_:%-({})/*=|]');
    if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) ) 
        || e.target.value===' ' || e.target.value==='') 
    {    
      guardarNvActividad({
        ...nvActividad,
        [e.target.name] : e.target.value  
      })
      if (aviso) 
      {
        setMensaje('');
        setAviso(false);
      }
    }
  };

  const cambioActualiza = (event) => {
    guardarNvActividad({
      ...nvActividad,
        [event.target.name]: event.target.checked 
      });
  };

  const guardarPrioridad = (event) => {
    //console.log(event);
    if (event) {      
      guardarNvActividad({
        ...nvActividad,
        Prioridad : event  
      })
    }
    else
    {
      guardarNvActividad({
        ...nvActividad,
        Prioridad : 0
      })
      //console.log('sin guardar vacio');
    }
    
  };
  return ( 
    <Modal
      titulo={titulo} modalAbierto={modalAbierto}
      setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
      mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
      aviso={aviso} setAviso={setAviso} espera={espera} 
      setEspera={setEspera}  tamanio={"sm"} btnDesact={btnDesact}
    >
      <form className={classes.root} noValidate autoComplete="off" fullWidth style={{width:30+'rem'}} >                               
       {listTipo.length!==0 ?  
       <Box display="flex" flexDirection="row">
        <Box mr={5}>  
        <FormControl className={classes.formControl} size="small" style={{width:12+'rem'}}>
          <InputLabel htmlFor="age-native-simple">Tipo</InputLabel>
          <MaterialSelect
            native value={Tipo}
            onChange={guardTerminal}
            inputProps={{ name: 'Tipo', id: 'Tipo', }}            
          >
            <option aria-label="None" value="" />      
            {listTipo.map((row) => (
                <option value={row.value}>{row.label}</option>
            ))}   
          </MaterialSelect>
        </FormControl>     
        </Box>  
        <Box  borderColor="transparent">
        <span style={{color:process.env.REACT_APP_COLOR}}  >Prioridad</span>
        <Rating
          name="simple-controlled" value={Prioridad} max={3}
          onChange={(event, newValue) => {
            guardarPrioridad(newValue);
          }}
          style={{color:process.env.REACT_APP_COLOR}}   
        />
      </Box>
      </Box> 
      :<LinearProgress color="primary" />}    
      <TextField id="outlinedReferen"           
        label="Referencia Manual"  size="small"
        value={RefManual} onChange={onChangeRef}  
        name="RefManual" style={{width:9+'rem'}}                                               
      /> 
      
      <br/>  
      <Box>
      <Typography component="p" variant="body2" color='primary' 
          style={{margin:0,marginLeft:.5+'rem'}} >
          Descripción
      </Typography>   
      <FormControl component="fieldset" style={{width:30+'rem'}}>    
        <textarea id="Descripcion-TextArea" name="Descripcion" rows="5"
          cols="100"placeholder="Descripción" style={{width: 30+"rem",borderRadius:3+'px'}} 
          value={Descripcion} onChange={onChangeDesc} 
        />
      </FormControl>
      </Box>
     {/*  <br/>
        <FormControlLabel
          control={<Checkbox checked={Finaliza} onChange={cambioActualiza} name="Finaliza" />}
          label="Finaliza"
        />
      
      <br/>
      {Finaliza?
      <textarea id="Solucion-TextArea" name="Solucion" rows="5"
          cols="100"placeholder="Solución" style={{width: 33.2+"rem",borderRadius:3+'px'}} 
          value={Solucion} onChange={onChange} 
        />:null} */}
      </form>
  </Modal>
  );
}
 
export default NuevaActividad;