import React from 'react';
import {Button,Dialog,DialogActions,DialogContent
        ,DialogTitle,CircularProgress} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Alert from '@material-ui/lab/Alert';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {    
    marginTop: 0,
    paddingTop:0,
    position: 'relative',
  },
 
  buttonGuardar:{color: green[500],},
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function Modal({titulo,children,modalAbierto,setModalAbierto,guardarDatos,mensaje,tipoAdvertencia,aviso, setAviso,espera,btnDesact,tamanio,cerrarBorrar}) {
  const classes = useStyles();
  //const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const descriptionElementRef = React.useRef(null);
  
  React.useEffect(() => {
    if (modalAbierto) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }    
  }, [modalAbierto]);
  
  /* const cerrarBorrar = () => {        
    setAviso(false);
    setModalAbierto(false)
  }; */

  /* const handleClose = () => {
    setOpen(false);
    setModalAbierto(false)
  }; */

  const handleCloseSN = () => {
    setAviso(false);    
  };
          

  return (
  <div>         
    <Dialog
      open={modalAbierto} //onClose={handleClose}
      scroll={scroll} aria-labelledby="modal2"
      aria-describedby="modal2"
      fullWidth={true} maxWidth={tamanio}
    >                
      <DialogTitle style={{color:'#51ad32'}} id="mod2-titulo" align="center" >{titulo}</DialogTitle>            
      <DialogContent dividers={scroll === 'paper'} >        
        {aviso&&tipoAdvertencia &&mensaje.length!=0 ? 
          <Alert severity={tipoAdvertencia}  onClose={handleCloseSN}>
            {mensaje}
          </Alert>:<span></span>
        }
        {children}        
      </DialogContent>    
      <DialogActions>
        <Button onClick={cerrarBorrar} disabled={espera} color="secondary" variant="outlined">
          Cerrar
        </Button>
        <div className={classes.wrapper}>
          <Button
            variant="outlined" className={classes.buttonGuardar}
            disabled={btnDesact ? true : espera} onClick={guardarDatos}
          >
            Guardar
          </Button>
          {espera && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>                  
      </DialogActions>      
    </Dialog>    
  </div>
  );
}
