import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {IconButton,TableRow,Table,TableBody,TableCell,TableContainer,TableHead,
  LinearProgress,Box,//TablePagination,Typography,Box,Collapse,Paper,
    } from '@material-ui/core';
//import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
//import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import axios from 'axios';
//import jwt from 'jsonwebtoken';
import qs from 'qs';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import CreateIcon from '@material-ui/icons/Create';

import AddIcon from '@material-ui/icons/Add';
import NuevaDrive from './NuevaDrive';
import EditarDrive from './EditarDrive';
import EditarDriveDummy from './EditarDriveDummy';
import {findNumber} from "../funciones/FindNumber";
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  /*container: {    
   maxHeight: 650,
   maxWidth: 680,
  },*/
  container: {
    //maxHeight: '100%',
    maxHeight: window.innerHeight-170,
    minHeight: 280,
    maxWidth: 680,
    
    //height: '100%',
  },
  container2: {
    //maxHeight: '100%',
    //maxHeight: window.innerHeight-280,
    //minHeight: 280,
    //height: '100%',
  },
  //tableCell:{width:3+'rem'}
});


export default function TablaActividades({buscar,folioSelecc,usuarioContxt,fechasSeleccionadas,Bucle,listaDirectDV, guardarListaDirectDV}) {
  const classes = useStyles();
  let history = useHistory();
  const auxColor="#51ad32"
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] =  useState(10);
  const [open, setOpen] =  useState(false);
  const [seleccionado, setSeleccionado] =  useState(0);
  const [seleccionadoEdit, setSeleccionadoEdit] =  useState([]);
  const [modalAbierto, setModalAbierto] =  useState(false);
  const [modalAbierto2, setModalAbierto2] =  useState(false);
  const [espera, setEspera] =  useState(false);
  const[diaDif,setDiaDif]= useState(false);
  const[fechDiaSel,setFechDiaSel]= useState(new Date());
  const[actualizar,setActualizar]= useState(false);
  const[sinDatos,guardarSinDatos]= useState(true)
  const [datos, guardarDatos] = useState([]) 
  const [nvLectura,setNvLectura]=React.useState({ Horario:'00:00:00',TemAmb:0.0,VelSistem:0.0,
                                                  TempRoda:0.0,TempInv1:0.0,TempInv2:0.0,
                                                  TempInv3:0.0,TempInv4:0.0,DifPreP1P3:0.0,
                                                  DifPreP2P3:0.0 ,TempMaxDev:0.0,TempMinDev:0.0});
  const{  Horario,TemAmb,VelSistem,TempRoda,TempInv1,TempInv2,
          TempInv3,TempInv4,DifPreP1P3,DifPreP2P3,TempMaxDev,TempMinDev}=nvLectura      
                                                                                                                              
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  
  //const [lectuSeleccionada, guardarLectuSeleccionada] = React.useState([]) 
  //const [listaDirectDV, guardarListaDirectDV] = React.useState([])   
  
  const transpose = (matrix) => {
    let [row] = matrix
    ///console.log(row);
    return row.map((value, column) => matrix.map(row1 => row1[column]))
  }

  let nvlSNPermiso ='5'
  React.useEffect(()=>{ 
    const source = axios.CancelToken.source();    
    const fInicio1=moment(fechasSeleccionadas.inicio).format("YYYY-MM-DD")
    const fFinal1=moment(fechasSeleccionadas.final).format("YYYY-MM-DD")
    setFechDiaSel(fInicio1)
    async function conectarJSon()  { 
      guardarListaDirectDV([])
      setEspera(true)           
      const auxU=process.env.REACT_APP_LINK +`/drive/v2/list`;               
      const token =localStorage.getItem('token20') ;               
      const dataInfo = qs.stringify({                               
          'fechaIni':fInicio1+' 00:00:00',
          'fechaFin':fFinal1+' 23:59:59',                            
          'idDispositivo':'8978yuhjnfrsdsd'

          });
      const config = {
          url:auxU ,
          method: 'POST',
          headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
          data: dataInfo,
          cancelToken: source.token
      };
      let aux ={};           
      await axios(config)
      .then(function (response) {          
          aux=  response.data;        
          let auxArreglo= [
                            [0], [1], [2], [3],
                            [4], [5], [6], [7],
                            [8], [9], [10], [10]
                            ,[12],[12],[17],[14]
                            ,[15],[16]
                          ];
          //console.log(aux);                   
          if (aux[0].Id != -1) 
          { guardarSinDatos(true)         
            guardarListaDirectDV(aux)
            let cont1=0   
          
            
            for (let index = 0; index < aux.length; index++) {                                           
              auxArreglo[11][index] =aux[index].Horario
              auxArreglo[12][index] =aux[index].Tipo
              auxArreglo[13][index] =aux[index].tpLectNom
              auxArreglo[0][index] =aux[index].ValTemAmb+' '+aux[index].UniTemAmb
              auxArreglo[1][index] =aux[index].ValVelSistem+' '+aux[index].UniVelSistem
              auxArreglo[2][index] =aux[index].ValTempRoda+' '+aux[index].UniTempRoda
              auxArreglo[3][index] =aux[index].ValTempInv1+' '+aux[index].UniTempInv1
              auxArreglo[4][index] =aux[index].ValTempInv2+' '+aux[index].UniTempInv2
              auxArreglo[5][index] =aux[index].ValTempInv3+' '+aux[index].UniTempInv3
              auxArreglo[6][index] =aux[index].ValTempInv4+' '+aux[index].UniTempInv4
              auxArreglo[7][index] =aux[index].ValDifPreP1P3+' '+aux[index].UniDifPreP1P3
              auxArreglo[8][index] =aux[index].ValDifPreP2P3+' '+aux[index].UniDifPreP2P3
              auxArreglo[9][index] =aux[index].ValTempMaxDev+' '+aux[index].UniTempMaxDev
              auxArreglo[10][index] =aux[index].ValTempMinDev+' '+aux[index].UniTempMinDev
              auxArreglo[14][index] =aux[index].ValCorriente1+' '+aux[index].UniCorriente1
              auxArreglo[15][index] =aux[index].ValCorriente2+' '+aux[index].UniCorriente2
              auxArreglo[16][index] =aux[index].ValCorriente3+' '+aux[index].UniCorriente3
              auxArreglo[17][index] =aux[index].ValCorriente4+' '+aux[index].UniCorriente4
            }
  
           guardarDatos(auxArreglo)
          }else{
            guardarListaDirectDV([])
            guardarSinDatos(false)
          }                                            
      })
      .catch(function (error) {          
        console.log(`Error: ${error}`);        
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        {
          console.log('datos incorrectos' );  
        }else
        {
          if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
              ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
          {
            console.log('loguearse de nuevo');
            localStorage.clear();                      
            history.push("/");                              
          } 
        }
      }); 
      setEspera(false)                          
    };
      
    conectarJSon();
    if (fInicio1!=fFinal1) 
    {
      //console.log(fInicio1+" "+fFinal1);  
      setDiaDif(true);        
    }else
    {//console.log(fInicio1+" "+fFinal1);  
      setDiaDif(false);        
    }
    return ()=> {
      source.cancel();
    }                
},[buscar,actualizar]);

const obtenPromedios=(aux,promedios)=>{
  let auxpromed=promedios  
  auxpromed.ValTemAmb += aux.ValTemAmb 
  auxpromed.ValVelSistem += aux.ValVelSistem
  auxpromed.ValTempRoda +=  aux.ValTempRoda
  auxpromed.ValTempInv1 += aux.ValTempInv1
  auxpromed.ValTempInv2 += aux.ValTempInv2
  auxpromed.ValTempInv3 += aux.ValTempInv3
  auxpromed.ValTempInv4 +=  aux.ValTempInv4
  auxpromed.ValDifPreP1P3 +=parseFloat(aux.ValDifPreP1P3)
  auxpromed.ValDifPreP2P3 += parseFloat(aux.ValDifPreP2P3)
  auxpromed.ValTempMaxDev += parseFloat(aux.ValTempMaxDev)
  auxpromed.ValTempMinDev += parseFloat(aux.ValTempMinDev)
  auxpromed.ValCorriente1 += parseFloat(aux.ValCorriente1)
  auxpromed.ValCorriente2 += parseFloat(aux.ValCorriente2)
  auxpromed.ValCorriente3 += parseFloat(aux.ValCorriente3)
  auxpromed.ValCorriente4 += parseFloat(aux.ValCorriente4)
  return(auxpromed)
 
}

const obtenDif=(valor, promedio)=>{
  let bandera=false
  const contador=valor.indexOf("C")
  const rango=10
  if (contador!==-1) {
    const auxValor=parseFloat(valor.substring(0,valor.length-2))
    const auxProm=parseFloat(promedio.substring(0,promedio.length-2))    
    
    if (auxValor >=(auxProm - rango) &&auxValor <=  (auxProm  + rango) ) {
      bandera=true
    }  
  }
  
  return bandera
}

const obtenDifA=(valor, promedio)=>{
  let bandera=false
  const contador=valor.indexOf("A")
  const rango=10
  if (contador!==-1) {
    const auxValor=parseFloat(valor.substring(0,valor.length-2))
    const auxProm=parseFloat(promedio.substring(0,promedio.length-2))    
    
    if (auxValor >=(auxProm - rango) &&auxValor <=  (auxProm  + rango) ) {
      bandera=true
    }  
  }
  
  return bandera
}

const obtenPromediosFinal=(promedios, total, aux)=>{
  let auxpromed=promedios  
  auxpromed.ValTemAmb = `${(auxpromed.ValTemAmb /  total).toFixed(2)} ${aux.UniTemAmb}`
  auxpromed.ValVelSistem = `${(auxpromed.ValVelSistem / total).toFixed(2)}  ${aux.UniVelSistem}`
  auxpromed.ValTempRoda =  `${(auxpromed.ValTempRoda / total).toFixed(2)} ${aux.UniTempRoda}`
  auxpromed.ValTempInv1 = `${(auxpromed.ValTempInv1 / total).toFixed(2)} ${aux.UniTempInv1}`
  auxpromed.ValTempInv2 = `${(auxpromed.ValTempInv2 / total).toFixed(2)} ${aux.UniTempInv2}`
  auxpromed.ValTempInv3 = `${(auxpromed.ValTempInv3 / total).toFixed(2)} ${aux.UniTempInv3}`
  auxpromed.ValTempInv4 =  `${(auxpromed.ValTempInv4 / total).toFixed(2)} ${aux.UniTempInv4}`
  auxpromed.ValDifPreP1P3 = `${(auxpromed.ValDifPreP1P3 / total).toFixed(2)} ${aux.UniDifPreP1P3}`
  auxpromed.ValDifPreP2P3 = `${(auxpromed.ValDifPreP2P3 / total).toFixed(2)} ${aux.UniDifPreP2P3}`
  auxpromed.ValTempMaxDev = `${(auxpromed.ValTempMaxDev / total).toFixed(2)} ${aux.UniTempMaxDev}`
  auxpromed.ValTempMinDev = `${(auxpromed.ValTempMinDev / total).toFixed(2)} ${aux.UniTempMinDev}`
  auxpromed.ValCorriente1 = `${(auxpromed.ValCorriente1 / total).toFixed(2)} ${aux.UniCorriente1}`
  auxpromed.ValCorriente2 = `${(auxpromed.ValCorriente2 / total).toFixed(2)} ${aux.UniCorriente2}`
  auxpromed.ValCorriente3 = `${(auxpromed.ValCorriente3 / total).toFixed(2)} ${aux.UniCorriente3}`
  auxpromed.ValCorriente4 = `${(auxpromed.ValCorriente4 / total).toFixed(2)} ${aux.UniCorriente4}`
  return(auxpromed)
}

const funcionActualiza=()=>{
  setActualizar(!actualizar)
}

function verMas(auxId) {
  setOpen(!open)
// console.log(auxId);
  setSeleccionado(auxId)
}

function editarLectura(auxFalla) {  
  listaDirectDV.map((lectura,index)=>{
    if (auxFalla==lectura.Id) 
    {    
      setSeleccionadoEdit(lectura)
    }
  })   
  setModalAbierto2(true)
}

function nuevaLect() {    
  setModalAbierto(true)
}

const ordenFecha=(fecha)=>{
  let ordenada=""
  ordenada=fecha.substring(8,10)+'/'+fecha.substring(5,7)+'/'+fecha.substring(0,4)
  return ordenada;
}


  const CeldaHead =({width,titulo,estilo})=>{
    return<TableHead padding={'none'}>
      <TableCell style={{...estilo,color:auxColor,width: width,fontWeight:600,fontSize:12.5+'px',}} padding={'none'}>
        {titulo}
      </TableCell>   
    </TableHead>
  }

  const CeldaProm=({auxtext,estilo})=>{
    return<TableCell align="center" padding={'none'}  style={{...estilo,fontSize:12.5+'px' ,fontWeight:450,width:"100px"}}>{auxtext}</TableCell> 
  }
  
  const CeldaVacia=()=>{
    return<TableCell align="center" padding={'none'}  style={{}}></TableCell> 
  }

function tablaCompleta(auxlista,listPromed){
  //console.log("entrando sin preguntar");
  //fconsole.log(auxlista);
  let valor=0;
  return(
  <TableContainer className={classes.container} >
    <Table stickyHeader aria-label="sticky table" >                                  
      <TableBody >          
        <TableRow  >
          <TableHead padding={'none'}>
            { usuarioContxt?.nivel !=undefined && usuarioContxt.nivel!== nvlSNPermiso ? 
              <TableCell style={{ color:auxColor,height:  55+'px',width:  200+'px',fontWeight:600,fontSize:12.5+'px',}} padding={'none'} >
                {  findNumber('|13|') ?  <>
                  Nueva Lectura  
                  <IconButton color="primary"  aria-label="add circle" size="small" onClick={nuevaLect} component="span">
                      <AddIcon/>
                  </IconButton> 
                  </>:null}
              </TableCell>
            : <TableCell style={{ color:auxColor,height:  55+'px',width:  200+'px',fontWeight:600,fontSize:12.5+'px',}} padding={'none'} >               
              </TableCell>                  
            }
          </TableHead>
          {listPromed && listPromed.length!==0 ? 
            <CeldaProm auxtext={"Promedio"} estilo={{color:auxColor,fontSize:12.5+'px'}} />               
          :null}
          {listaDirectDV.length!=0? ( listaDirectDV.map((lista,index) => 
            {
              let auxtext=diaDif? ordenFecha(lista.HorarioFecha)+' '+lista.Horario:lista.Horario                  
              if(lista.TermId==Bucle)
              {
                return(
                  <TableCell align="center"  padding={'none'} style={{ width: 200+'px',paddingLeft:.5+'rem',fontSize:12.5+'px',}} >
                    {auxtext}
                    { findNumber('|14|') ?
                      <IconButton color="primary"  aria-label="add circle" size="small" 
                        onClick={()=>editarLectura(lista.Id)}  component="span">
                        <CreateIcon/>
                      </IconButton> 
                    : null
                    }
                  </TableCell> 
                )
              }
            }      
            ) ):null 
          }   
        </TableRow>
        <TableRow  >
          <CeldaHead width={'200px'} titulo={"Tipo"} estilo={{}} />      
          {listPromed && listPromed.length!==0 ? 
            <CeldaVacia/>            
          :null}    
          {auxlista.length!=0? ( auxlista[13].map((index) => 
            { let auxtext=index                             
              return(<TableCell align="center" padding={'none'}  style={{fontSize:12.5+'px' ,fontWeight:450,}}>{auxtext}</TableCell> )
            }      
            ) ):null 
          }   
        </TableRow>
        <TableRow  style={{height: 2+'rem'}}>
          <CeldaHead width={'200px'} titulo={"Temperatura Ambiente"}  estilo={{paddingRight:'60px' }} />      
          {listPromed && listPromed.length!==0 ? 
            <CeldaProm auxtext={listPromed.ValTemAmb} estilo={{fontSize:12.5+'px'}} />            
          :null}
          {auxlista.length!=0? ( auxlista[0].map((index) => 
            { let auxtext=index
              const auxEstilo = obtenDif(auxtext, listPromed.ValTemAmb) ? {} :{backgroundColor: "#fc0f03",fontWeight:900}            
              return(<TableCell align="center" padding={'none'} 
                style={{...auxEstilo,  fontSize:12.5+'px', } } >{auxtext}</TableCell> )
            }      
            ) ):null 
          }   
        </TableRow>
        <TableRow style={{height: 2+'rem'}} >
          <CeldaHead width={'300px'} titulo={"Valor Referencia de Velocidad del Sistema"}  estilo={{}} />   
          {listPromed && listPromed.length!==0 ? 
            <CeldaProm auxtext={""} estilo={{fontSize:12.5+'px'}} />            
          :null}           
          { auxlista.length!=0? ( auxlista[1].map((index) => 
            { let auxtext=index                  
              //console.log(index);
              return(<TableCell align="center" padding={'none'} style={{fontSize:12.5+'px'}} >{auxtext}</TableCell> )
            }      
            ) ):null 
          }   
        </TableRow>            
        <TableRow style={{height: 2+'rem'}} >
          <CeldaHead width={'200px'} titulo={"Temperatura de Rodamiento del Motor "}  estilo={{}} />              
          {listPromed && listPromed.length!==0 ? 
            <CeldaProm auxtext={listPromed.ValTempRoda} estilo={{fontSize:12.5+'px'}} />            
          :null}  
          { auxlista.length!=0? ( auxlista[2].map((index) => 
            { let auxtext=index                  
              
              const auxEstilo = obtenDif(auxtext, listPromed.ValTempRoda) ? {} :{backgroundColor: "#fc0f03",fontWeight:900}
              return(<TableCell align="center" padding={'none'} 
                style={{...auxEstilo,fontSize:12.5+'px',}} >{auxtext}</TableCell> )
            }      
            ) ):null 
          }
        </TableRow>
        <TableRow style={{height: 2+'rem'}} >
          <CeldaHead width={'200px'} titulo={"Temperatura Inverter LeitDrive 1"}  estilo={{}} />                        
          {listPromed && listPromed.length!==0 ? 
            <CeldaProm auxtext={listPromed.ValTempInv1} estilo={{fontSize:12.5+'px'}} />            
          :null}
          { auxlista.length!=0? ( auxlista[3].map((index) => 
            { let auxtext=index                  
              const auxEstilo = obtenDif(auxtext, listPromed.ValTempInv1) ? {} :{backgroundColor: "#fc0f03",fontWeight:900}            
              return(<TableCell align="center" padding={'none'} style={{...auxEstilo, fontSize:12.5+'px'}} >{auxtext}</TableCell> )
            }      
            ) ):null 
          }
        </TableRow>
        <TableRow style={{height: 2+'rem'}} >
          <CeldaHead width={'200px'} titulo={"Temperatura Inverter LeitDrive 2"}  estilo={{}} />                                  
          {listPromed && listPromed.length!==0 ? 
            <CeldaProm auxtext={listPromed.ValTempInv2} estilo={{fontSize:12.5+'px'}} />            
          :null}
          { auxlista.length!=0? ( auxlista[4].map((index) => 
            { let auxtext=index                  
              const auxEstilo = obtenDif(auxtext, listPromed.ValTempInv2) ? {} :{backgroundColor: "#fc0f03",fontWeight:900}       
              return(<TableCell align="center" padding={'none'} style={{...auxEstilo, fontSize:12.5+'px'}} >{auxtext}</TableCell> )
            }      
            ) ):null 
          }
        </TableRow>
        <TableRow  style={{height: 2+'rem'}}>
          <CeldaHead width={'200px'} titulo={"Temperatura Inverter LeitDrive 3"}  estilo={{}} />               
          {listPromed && listPromed.length!==0 ? 
            <CeldaProm auxtext={listPromed.ValTempInv3} estilo={{fontSize:12.5+'px'}} />            
          :null}
          { auxlista.length!=0? ( auxlista[5].map((index) => 
            { let auxtext=index                  
              const auxEstilo = obtenDif(auxtext, listPromed.ValTempInv3) ? {} :{backgroundColor: "#fc0f03",fontWeight:900}   
              return(<TableCell align="center" padding={'none'} style={{...auxEstilo, fontSize:12.5+'px'}} >{auxtext}</TableCell> )
            }      
            ) ):null 
          }
        </TableRow>
        <TableRow  style={{height: 2+'rem'}}>
          <CeldaHead width={'200px'} titulo={"Temperatura Inverter LeitDrive 4"}  estilo={{}} />                         
          {listPromed && listPromed.length!==0 ? 
            <CeldaProm auxtext={listPromed.ValTempInv4} estilo={{fontSize:12.5+'px'}} />            
          :null}
          { auxlista.length!=0? ( auxlista[6].map((index) => 
            { let auxtext=index                  
              const auxEstilo = obtenDif(auxtext, listPromed.ValTempInv4) ? {} :{backgroundColor: "#fc0f03",fontWeight:900} 
              return(<TableCell align="center" padding={'none'} style={{...auxEstilo, fontSize:12.5+'px'}}>{auxtext}</TableCell> )
            }      
            ) ):null 
          }
        </TableRow>
        <TableRow style={{height: 2+'rem'}} >
          <CeldaHead width={'200px'} titulo={"Estado Afe:Par OK/Master Activo"}  estilo={{}} />               
          {listPromed && listPromed.length!==0 ? 
            <CeldaVacia/>            
          :null}
          { auxlista.length!=0? ( auxlista[12].map((index) => 
            { let auxtext=index                  
              //console.log(index);
              return(<TableCell align="center" padding={'none'} style={{fontSize:12.5+'px'}}>{auxtext}</TableCell> )
            }      
            ) ):null 
          }
        </TableRow>
        <TableRow  style={{maxHeight: 1+'rem',padding:0,margin:0}}>
          <CeldaHead width={'200px'} titulo={"Corriente 1"}  estilo={{}} />               
          {listPromed && listPromed.length!==0 ? 
            <CeldaProm auxtext={listPromed.ValCorriente1} estilo={{fontSize:12.5+'px'}} />            
          :null}
          { auxlista.length!=0? ( auxlista[14].map((index) => 
            { let auxtext=index      
              const auxEstilo = obtenDifA(auxtext, listPromed.ValCorriente1) ? {} :{backgroundColor: "#fc0f03",fontWeight:900} 
              return(<TableCell align="center" style={{...auxEstilo, fontSize:12.5+'px',}} padding={'none'}>{auxtext}</TableCell> )
            }      
            ) ):null 
          }
        </TableRow>
        <TableRow  style={{maxHeight: 1+'rem',padding:0,margin:0}}>
          <CeldaHead width={'200px'} titulo={"Corriente 2"}  estilo={{}} />               
          {listPromed && listPromed.length!==0 ? 
            <CeldaProm auxtext={listPromed.ValCorriente2} estilo={{fontSize:12.5+'px'}} />            
          :null}
          { auxlista.length!=0? ( auxlista[15].map((index) => 
            { let auxtext=index               
              const auxEstilo = obtenDifA(auxtext, listPromed.ValCorriente2) ? {} :{backgroundColor: "#fc0f03",fontWeight:900}                  
              return(<TableCell align="center" style={{...auxEstilo, fontSize:12.5+'px',}} padding={'none'}>{auxtext}</TableCell> )
            }      
            ) ):null 
          }
        </TableRow>
        <TableRow  style={{maxHeight: 1+'rem',padding:0,margin:0}}>
          <CeldaHead width={'200px'} titulo={"Corriente 3"}  estilo={{}} />               
          {listPromed && listPromed.length!==0 ? 
            <CeldaProm auxtext={listPromed.ValCorriente3} estilo={{fontSize:12.5+'px'}} />            
          :null}
          { auxlista.length!=0? ( auxlista[16].map((index) => 
            { let auxtext=index      
              const auxEstilo = obtenDifA(auxtext, listPromed.ValCorriente3) ? {} :{backgroundColor: "#fc0f03",fontWeight:900}                            
              return(<TableCell align="center" style={{...auxEstilo, fontSize:12.5+'px',}} padding={'none'}>{auxtext}</TableCell> )
            }      
            ) ):null 
          }
        </TableRow>
        <TableRow  style={{maxHeight: 1+'rem',padding:0,margin:0}}>
          <CeldaHead width={'200px'} titulo={"Corriente 4"}  estilo={{}} />               
          {listPromed && listPromed.length!==0 ? 
            <CeldaProm auxtext={listPromed.ValCorriente4} estilo={{fontSize:12.5+'px'}} />            
          :null}
          { auxlista.length!=0? ( auxlista[17].map((index) => 
            { let auxtext=index                                
              const auxEstilo = obtenDifA(auxtext, listPromed.ValCorriente4) ? {} :{backgroundColor: "#fc0f03",fontWeight:900}                            
              return(<TableCell align="center" style={{...auxEstilo, fontSize:12.5+'px',}} padding={'none'}>{auxtext}</TableCell> )
            }      
            ) ):null 
          }
        </TableRow>
        <TableRow  style={{maxHeight: 1+'rem',padding:0,margin:0}}>
          <CeldaHead width={'200px'} titulo={"Dif. Presión P1-P3"}  estilo={{}} />               
          {listPromed && listPromed.length!==0 ? 
            <CeldaProm auxtext={""} estilo={{fontSize:12.5+'px'}} />            
          :null}
          { auxlista.length!=0? ( auxlista[7].map((index) => 
            { let auxtext=index                                
              return(<TableCell align="center" style={{  fontSize:12.5+'px',}} padding={'none'}>{auxtext}</TableCell> )
            }      
            ) ):null 
          }
        </TableRow>
        <TableRow  style={{maxHeight: 1+'rem',padding:0,margin:0}}>
          <CeldaHead width={'200px'} titulo={"Dif. Presión P2-P3"}  estilo={{}} />      
          {listPromed && listPromed.length!==0 ? 
            <CeldaProm auxtext={""} estilo={{fontSize:12.5+'px'}} />            
          :null}         
          { auxlista.length!=0? ( auxlista[8].map((index) => 
            { let auxtext=index                                
              return(<TableCell align="center" padding={'none'} style={{ fontSize:12.5+'px'}} >{auxtext}</TableCell> )
            }      
            ) ):null 
          }
        </TableRow>
        <TableRow style={{height: 2+'rem'}} >
          <CeldaHead width={'200px'} titulo={"Temperatura Máxima Instantánea Devanados Motor"}  estilo={{}} />               
          {listPromed && listPromed.length!==0 ? 
            <CeldaProm auxtext={listPromed.ValTempMaxDev} estilo={{fontSize:12.5+'px'}} />            
          :null} 
          { auxlista.length!=0? ( auxlista[9].map((index) => 
            { let auxtext=index                                
              const auxEstilo = obtenDif(auxtext, listPromed.ValTempMaxDev) ? {} :{backgroundColor: "#fc0f03",fontWeight:900} 
              return(<TableCell align="center" padding={'none'} style={{...auxEstilo, fontSize:12.5+'px'}} >{auxtext}</TableCell> )
            }      
            ) ):null 
          }
        </TableRow>
            
        <TableRow  style={{padding:0,margin:0}}>
          <CeldaHead width={'200px'} titulo={"Temperatura Mínima Instantánea Devanados Motor"}  estilo={{}} />                  
          {listPromed && listPromed.length!==0 ? 
            <CeldaProm auxtext={listPromed.ValTempMinDev} estilo={{fontSize:12.5+'px'}} />            
          :null} 
          { auxlista.length!=0? ( auxlista[10].map((index) => 
            { let auxtext=index                  
              const auxEstilo = obtenDif(auxtext, listPromed.ValTempMinDev) ? {} :{backgroundColor: "#fc0f03",fontWeight:900} 
              return(<TableCell align="center" padding={'none'} style={{...auxEstilo, fontSize:12.5+'px'}} >{auxtext}</TableCell> )
            }      
            ) ):null 
          }
        </TableRow>       
      </TableBody>
    </Table>
  </TableContainer>
 )
}

/* const tablaSinDatos=()=>{
 return(<TableContainer className={classes.container} style={{width: 50+'rem'}}>
 <Table stickyHeader aria-label="sticky table" >                                  
   <TableBody>
     <TableRow  >
       <TableCell style={{ color:auxColor,width: 10+'rem'}}>Nueva Lectura            
          <IconButton color="primary"  aria-label="add circle" size="small" onClick={nuevaLect} component="span">
            <AddIcon/>
          </IconButton>           
       </TableCell>                  
       <TableCell align="center"> </TableCell> 
     </TableRow>
     <TableRow  >
       <TableCell style={{ color:auxColor,width: 10+'rem'}}>Temperatura Ambiente</TableCell>                  
       <TableCell align="center"> </TableCell> 
     </TableRow>
     <TableRow  >
       <TableCell style={{ color:auxColor,width: 10+'rem'}}>Valor Referencia de Velocidad del Sistema</TableCell>   
       <TableCell align="center">Fecha</TableCell> 
     </TableRow>            
     <TableRow  >
       <TableCell style={{ color:auxColor,width: 10+'rem'}}>Temperatura de Rodamiento del Motor </TableCell>            
       <TableCell align="center"> </TableCell> 
     </TableRow>
     <TableRow  >
       <TableCell style={{ color:auxColor,width: 10+'rem'}} >Temperatura Inverter LeitDrive 1</TableCell>
       <TableCell align="center">Sin </TableCell> 
     </TableRow>
     <TableRow  >
       <TableCell style={{ color:auxColor,width: 10+'rem'}} >Temperatura Inverter LeitDrive 2</TableCell>
       <TableCell align="center"> </TableCell> 
     </TableRow>
     <TableRow  >
       <TableCell style={{ color:auxColor,width: 10+'rem'}} >Temperatura Inverter LeitDrive 3</TableCell>
       <TableCell align="center">Lecturas </TableCell> 
     </TableRow>
     <TableRow  >
       <TableCell style={{ color:auxColor,width: 10+'rem'}} >Temperatura Inverter LeitDrive 4</TableCell>
       <TableCell align="center"> </TableCell> 
     </TableRow>
     <TableRow  >
       <TableCell style={{ color:auxColor,width: 10+'rem'}} >Dif. Presión P1-P3</TableCell>
       <TableCell align="center"> </TableCell> 
     </TableRow>
     <TableRow  >
       <TableCell style={{ color:auxColor,width: 10+'rem'}} >Dif. Presión P2-P3</TableCell>
       <TableCell align="center"> </TableCell> 
     </TableRow>
     <TableRow  >
       <TableCell style={{ color:auxColor,width: 10+'rem'}} >Temperatura Máxima Instantánea Devanados Motor</TableCell>       
       <TableCell align="center"> </TableCell> 
     </TableRow>     
     <TableRow  >
       <TableCell style={{ color:auxColor,width: 10+'rem'}} >Temperatura Mínima Instantánea Devanados Motor</TableCell>                   
       <TableCell align="center"> </TableCell> 
     </TableRow>       
   </TableBody>
 </Table>
</TableContainer>
) 
} */

const tablaSinResultados=()=>{
  return(<TableContainer className={classes.container} style={{width: 50+'rem'}}>
  <Table stickyHeader aria-label="sticky table" >                                  
    <TableBody>
      <TableRow  >
        { usuarioContxt?.nivel !=undefined && usuarioContxt.nivel!== nvlSNPermiso ? 
          <TableCell style={{ color:auxColor,width: 10+'rem',fontWeight:600}}>Nueva Lectura  
            <IconButton color="primary"  aria-label="add circle" size="small" onClick={nuevaLect} component="span">
                <AddIcon/>
            </IconButton> 
          </TableCell> 
          :
          <TableCell style={{ color:auxColor,width: 10+'rem',fontWeight:600}}>
            Sin Lecturas       
          </TableCell>
        }                
        <TableCell align="center"> </TableCell> 
      </TableRow>
            
    </TableBody>
  </Table>
 </TableContainer>
 ) 
 }

 function filtro(){        
  //let auxCadena=folioSelecc.trim();         
  //let info=datos.filter((dat)=>dat.Id.toUpperCase().search(auxCadena.toUpperCase() ) !== -1)         
  let info=listaDirectDV.filter((dat)=>dat.TermId.toString().search(Bucle) !== -1)         
  let metodos=null  , promedios={ValTemAmb:0, ValVelSistem:0, ValTempRoda:0, ValTempInv1:0, ValTempInv2:0, 
    ValTempInv3:0,ValTempInv4:0, ValDifPreP1P3:0, ValDifPreP1P3:0, ValDifPreP2P3:0,
    ValTempMaxDev:0, ValTempMinDev:0, ValCorriente1:0, ValCorriente2:0, ValCorriente3:0, ValCorriente4:0}          
  if (info.length>0) {
    let auxArreglo= [
      [0], [1], [2], [3],
      [4], [5], [6], [7],
      [8], [9], [10], [10]
      ,[12],[12],[12],[12]
      ,[12],[12]
    ];
    //console.log("mandandando la lista");
    for (let index = 0; index < info.length; index++) {                             
      auxArreglo[11][index] =info[index].Horario
      auxArreglo[12][index] =info[index].Tipo
      auxArreglo[13][index] =info[index].tpLectNom
      auxArreglo[0][index] =info[index].ValTemAmb+' '+info[index].UniTemAmb
      auxArreglo[1][index] =info[index].ValVelSistem+' '+info[index].UniVelSistem
      auxArreglo[2][index] =info[index].ValTempRoda+' '+info[index].UniTempRoda
      auxArreglo[3][index] =info[index].ValTempInv1+' '+info[index].UniTempInv1
      auxArreglo[4][index] =info[index].ValTempInv2+' '+info[index].UniTempInv2
      auxArreglo[5][index] =info[index].ValTempInv3+' '+info[index].UniTempInv3
      auxArreglo[6][index] =info[index].ValTempInv4+' '+info[index].UniTempInv4
      auxArreglo[7][index] =info[index].ValDifPreP1P3+' '+info[index].UniDifPreP1P3
      auxArreglo[8][index] =info[index].ValDifPreP2P3+' '+info[index].UniDifPreP2P3
      auxArreglo[9][index] =info[index].ValTempMaxDev+' '+info[index].UniTempMaxDev
      auxArreglo[10][index] =info[index].ValTempMinDev+' '+info[index].UniTempMinDev
      auxArreglo[14][index] =info[index].ValCorriente1+' '+info[index].UniCorriente1
      auxArreglo[15][index] =info[index].ValCorriente2+' '+info[index].UniCorriente2
      auxArreglo[16][index] =info[index].ValCorriente3+' '+info[index].UniCorriente3
      auxArreglo[17][index] =info[index].ValCorriente4+' '+info[index].UniCorriente4
      promedios=obtenPromedios(info[index],promedios)
      
    }
    
    //console.log(promedios);
    promedios=obtenPromediosFinal(promedios, info.length, info[0])
    //console.log(promedios);
    metodos= tablaCompleta(auxArreglo,promedios)
    
  } else {
    metodos=tablaSinResultados()
  }
  return metodos ;
}

const modal =modalAbierto   ? 
             ( usuarioContxt?.nivel !=undefined && usuarioContxt.nivel!== nvlSNPermiso ?
              (<NuevaDrive titulo={'Nueva Lectura '+ordenFecha(fechDiaSel)} modalAbierto={modalAbierto}
                fechDiaSel={fechDiaSel} setModalAbierto={setModalAbierto}  nvLectura={nvLectura} setNvLectura={setNvLectura}      
                funcionActualiza={funcionActualiza}
              />)
              : null
              )
              :null
const modal2 =modalAbierto2  ? 
              ( usuarioContxt?.nivel !=undefined && usuarioContxt.nivel!== nvlSNPermiso ?
              ( <EditarDrive titulo={'Editar Lectura '+ordenFecha(seleccionadoEdit.HorarioFecha)} modalAbierto={modalAbierto2}
                  fechDiaSel={fechDiaSel}   seleccionadoEdit={seleccionadoEdit}
                  setModalAbierto={setModalAbierto2}  nvLectura={nvLectura} setNvLectura={setNvLectura}      
                  funcionActualiza={funcionActualiza}
              />)
              :(<EditarDriveDummy titulo={'Lectura '+ordenFecha(seleccionadoEdit.HorarioFecha)} modalAbierto={modalAbierto2}
                  fechDiaSel={fechDiaSel}   seleccionadoEdit={seleccionadoEdit}
                  setModalAbierto={setModalAbierto2}  nvLectura={nvLectura} setNvLectura={setNvLectura}      
                />)
              )
              :null   
 
const contenido2=Bucle!=0 &&Bucle!='' ?filtro() :tablaCompleta(datos,[]) 
const contenido = sinDatos&&datos.length!=0 ?contenido2:tablaSinResultados()
const tabla = espera ?  (<Box width={"100%"} mt={1}><LinearProgress/> </Box>) :( contenido )
  return (
    <React.Fragment >
      {tabla}
     {modal /*  */}
     {modal2}
    </React.Fragment>
  );
}
