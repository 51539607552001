import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Box,Paper,Table,TableBody,TableCell,LinearProgress,
  TableContainer,TableHead,TableRow,IconButton} from '@material-ui/core';
import {AuthContext} from '../context/AuthContext'
import axios from 'axios';
import qs from 'qs';
//import moment from 'moment';
import { useHistory } from "react-router-dom";
import AddIcon from '@material-ui/icons/Add';
import NvRefaccion from './NuevaRefaccion'
import {ErrorGeneral, llamaApiCargaToken} from '../funciones/ClienteAxios';
import {findNumber} from "../funciones/FindNumber";
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 420,
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: process.env.REACT_APP_COLOR
    }
  },
  tableCell: {
    "$selected &": {
      color: "white"
    }
  },
  hover: {},
  selected: {}
});

export default function TablaRefacciones({orden}) {
  const classes = useStyles();
  let history = useHistory();
  const [selectedID, setSelectedID] = useState(null);
  const {usuario}=React.useContext(AuthContext);
  const[sinDatos,guardarSinDatos]= useState(false)
  const [datos, guardarDatos] = useState([])
  const[espera,setEspera]= useState(false)
  const [modalAbierto, setModalAbierto] = useState(false);
  const[actualiza,setActualiza]= useState(false)
  let nvlSNPermiso ='5' 
  React.useEffect(()=>{ 
    const source = axios.CancelToken.source();    
    const llenaListRef = () => {
      let data = qs.stringify({
          orden: orden.Id,
          idDispositivo: '89y7gttvjhj98h'
      });
      let url = "/orden-refaccion-lista";

      function respuestaListAct(aux) {
        if ( aux[0].Id !== -1 ) 
        {   guardarSinDatos(false)                 
            guardarDatos(aux)
        }
        else
        {
          guardarSinDatos(true)
        }      
      }

      llamaApiCargaToken(data, url, respuestaListAct, ErrorGeneral, setEspera, history, source);
    };
 
    llenaListRef();
      
    return ()=> {
        source.cancel();
    }                
  // eslint-disable-next-line react-hooks/exhaustive-deps	
},[orden,actualiza]);
const funcionActual=()=> {  
  setActualiza(!actualiza)
}
function nuevaActiv() {  
  setModalAbierto(true)
}

const rengNvReg= usuario?.nivel !== undefined && usuario.nivel !== nvlSNPermiso ?
  (<TableRow className={classes.tableRow}>           
    <TableCell  className={classes.tableCell} align="left" padding={'none'} 
      style={{  paddingLeft:10+'px'}} 
    >{findNumber('|3|') ?
        <IconButton   aria-label="agregar-actividad" size="small" 
          onClick={() => nuevaActiv()} component="span" 
        >
          <AddIcon color="primary"/>        
        </IconButton>   
      : null}      
    </TableCell>                
    <TableCell  className={classes.tableCell} align="center" padding={'none'} 
      style={{  paddingLeft:5+'px',fontWeight:700,color:process.env.REACT_APP_COLOR}}  >    
        <span > {findNumber('|3|') ? 'Nueva' : null}</span>
    </TableCell>            
    <TableCell  className={classes.tableCell} padding={'none'}
      style={{ paddingLeft:5+'px',fontWeight:500,color:process.env.REACT_APP_COLOR}}
    >   
           {findNumber('|3|') ? 'Refacción' : null}      
    </TableCell>              
  </TableRow>)
  : (null)

  function Row(props) {
    const { row } = props;    
    return (
      <React.Fragment>
        <TableRow //className={classes.root}
          onClick={() => { setSelectedID(row.Id); }}
          selected={selectedID === row.Id} className={classes.tableRow}
          classes={{ hover: classes.hover, selected: classes.selected }}    
        >
            
          <TableCell  className={classes.tableCell} align="center" padding={'none'}
            style={{width:50+'px',  fontWeight:650,marginRight:5+'px'}} 
          >
            {row.refId}
          </TableCell>
          <TableCell  className={classes.tableCell} align="center" padding={'none'}
            style={{width:70+'px',marginRight:5+'px'}} 
          >
            {row.cantidad}
          </TableCell>          
          <TableCell  className={classes.tableCell} align="left" padding={'none'}>
            {row.refNom}
          </TableCell>          
        </TableRow>
      
      </React.Fragment>
    );
  }
  const tablaCompleta=()=>{
    return(
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>            
                <TableCell align="left" padding={'none'} style={{marginLeft:5+'px'}} >
                  #Parte 
                  </TableCell>    
                <TableCell align="center" padding={'none'}>Cantidad</TableCell>           
                <TableCell align="left" padding={'none'}>Descripción</TableCell>                                 
            </TableRow>
          </TableHead>
          <TableBody>
            {rengNvReg}
            {datos.length !== 0? (datos.map((row) => (
                <Row key={row.Id} row={row} />
            ))):null}
          </TableBody>
        </Table>
      </TableContainer>
   )
  }
  const tablaSinDatos=()=>{
   return(<TableContainer className={classes.container}>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>            
          <TableCell align="left" padding={'none'} style={{marginLeft:5+'px'}} >#Parte </TableCell>    
          <TableCell align="center" padding={'none'}>Cantidad</TableCell>                   
          <TableCell align="left" padding={'none'}>Descripción</TableCell>                 
        </TableRow>
      </TableHead>
      <TableBody>
        {rengNvReg}
        <TableRow  className={classes.tableRow} >          
          <TableCell  className={classes.tableCell} align="left" padding={'none'}
            style={{width:50+'px',marginRight:5+'px'}} 
          >
            Orden 
          </TableCell>          
          <TableCell  className={classes.tableCell} align="center" padding={'none'}
            style={{width:70+'px',marginRight:5+'px'}} 
          >
            Sin
          </TableCell>             
          <TableCell  className={classes.tableCell} align="left"padding={'none'}>
            Datos
          </TableCell>                 
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
 ) 
  }

  const modal =modalAbierto  ? 
                                ( usuario?.nivel !== undefined && usuario.nivel!== nvlSNPermiso ?
                                  (<NvRefaccion titulo={'Nueva Refacción'} modalAbierto={modalAbierto} 
                                    setModalAbierto={setModalAbierto} auxOrdenId={orden.Id} 
                                    funcionActual={funcionActual}
                                  />)
                                  :null
                                )
                                :null

  const contenido = !sinDatos ?tablaCompleta() :tablaSinDatos()
  const cargando= !espera ?  contenido : <Box my={2}><LinearProgress /></Box>
  return (
    <Paper className={classes.root}>      
      {cargando}
      {modal}
    </Paper>
  );
}
