import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormControlLabel, TextField, MenuItem, FormControl, Select, 
         InputLabel, Radio, RadioGroup, FormLabel ,Grid} from "@material-ui/core";
import ModalDummy from "./ModalDummy";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {
      margin: {
        margin: theme.spacing(1),
      },
    },
    medidor:{
      width:'100px',
      marginBottom:'1em'
    },
    campos:{
      width: '200px',
      marginBottom: '1em'
    },
    camposLectura:{
      width: '150px',
      marginBottom: '1em'
    },
    fecha:{
      width: '200px',
      marginTop:'3px',
      marginBottom: '1em'
    }
  
  }));

const CarroTensorDummy = ({ modalAbierto, setModalAbierto, titulo, energia,terminales}) => {
    const classes = useStyles();
    const [aviso, setAviso] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [registro, setRegistro] = useState({
                                                  bitacora: energia.Id,
                                                  usuario: energia.UsrId,
                                                  LecturaE1: energia.tensorE1,
                                                  LecturaE4A: energia.tensorE4A,
                                                  LecturaE7: energia.tensorE7,
                                                  LecturaE4B: energia.tensorE4B,
                                                  Fecha: moment().format(energia.FBitacora),
                                                  FechaAlta:  moment().format(energia.FAlta),
                                                  Activo: 1,
                                                  Terminal : energia.termId
                                                  });
    const [cargando, setCargando] = useState(false);
    const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);
    const { bitacora, usuario, LecturaE1,LecturaE4A,LecturaE7,
            LecturaE4B, Fecha,FechaAlta,Activo,Terminal} = registro;

    return (
        <ModalDummy
        titulo={titulo}
        modalAbierto={modalAbierto}
        setModalAbierto={setModalAbierto}      
        mensaje={mensaje}
        tipoAdvertencia={tipoAdvertencia}
        aviso={aviso}
        setAviso={setAviso}
        espera={cargando}
        setEspera={setCargando}
        >
            <form className={classes.root} autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl className={classes.campos}>
                    <InputLabel id="Terminal">Terminal</InputLabel>
                    <Select
                      label="Terminal" id="Terminal" name="Terminal" value={Terminal} disabled
                    >
                      {terminales.map((terminal, index) => (
                        <MenuItem value={terminal.Id} key={terminal.Id}>
                          <em>{terminal.Descr}</em>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={1}>
                  <FormControl className={classes.fecha}>
                    <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                      <DatePicker format="dd/MM/yyyy"
                        label="Fecha" size="small" name ="Fecha"
                        value={Fecha} disabled cancelLabel={"Cancelar"}                  
                        okLabel="Seleccionar" 
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
              </Grid>
              {Terminal===1 ? 
              <Grid container spacing={2}>
                <Grid item xs={6}>
                <FormControl className={classes.campos}>
                    <TextField id="LecturaE1"
                      label="Estación 1" variant="outlined"
                      size="small" value={LecturaE1} disabled
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={1}>
                  <FormControl className={classes.campos}>
                    <TextField id="LecturaE4A"
                      label="Estación 4A" variant="outlined"
                      size="small" value={LecturaE4A} disabled
                    />
                  </FormControl>
                </Grid>
              </Grid>
               :<Grid container spacing={2}>
               <Grid item xs={6}>
               <FormControl className={classes.campos}>
                   <TextField id="LecturaE7"
                     label="Estación 7" variant="outlined"
                     size="small" value={LecturaE7} disabled
                   />
                 </FormControl>
               </Grid>
               <Grid item xs={1}>
                 <FormControl className={classes.campos}>
                   <TextField id="LecturaE4B"
                     label="Estación 4B" variant="outlined"
                     size="small" value={LecturaE4B} disabled
                   />
                 </FormControl>
               </Grid>
             </Grid>}
              <Grid container spacing={2}>
                <Grid item>
                  <FormControl className={classes.campos}>
                    <FormLabel>Activo</FormLabel>
                    <RadioGroup aria-label="activo"
                      name="Activo"
                      value={Activo}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <FormControlLabel value="activo" control={<Radio />} label="Activo" disabled/>
                        </Grid>
                        <Grid item xs={1}>
                          <FormControlLabel value="inactivo" control={<Radio />} label="Inactivo" disabled />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </form>
        </ModalDummy>
      );
};
    
export default CarroTensorDummy;