import React, {useEffect, useState,useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import { Box,  Paper, Table,//Tooltip,Collapse, IconButton,
    TableBody, TableCell, TableContainer,
    TableHead, TableRow,  LinearProgress } from '@material-ui/core';
import axios from 'axios';
import qs from 'qs';
import {useHistory} from "react-router-dom";
import EditarUsuario from "./EditUsuario";
import {ErrorGeneral,llamaApiCargaToken ,llamadaApiToken} from '../funciones/ClienteAxios';
import TablaPerfilTipo from './TablaPerfTipo';
import TablaPerfilOperacion from './TablaPerflOpera';
import {AuthContext} from '../context/AuthContext'
import { PermisosContext } from './ContextPermiso';
const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: window.innerHeight - 170,
        minHeight: 280,
        width: '15rem',
    },
    container2: {
        maxHeight: 550,
    },
    tableRow: {
        "&$selected, &$selected:hover": {
            backgroundColor: process.env.REACT_APP_COLOR
        }
    },
    tableCell: {
        "$selected &": {
            color: "white"
        }
    },
    hover: {},
    selected: {}
});

export default function TablaPerfil({ usuario,}) {
    const classes = useStyles();
    let history = useHistory();
    let nvlSNPermiso = '5'
    const source = axios.CancelToken.source();
    const [selectedID, setSelectedID] = useState(null);    
    const [espera, setEspera] = useState(false);    
    const [seleccionadoEdit, setSeleccionadoEdit] = useState([]);
    const [modalAbierto, setModalAbierto] = useState(false);
    const [datos, setDatos] = useState([])    
    const [buscar, setBuscar] = useState(true)    
    const [tipoSelect, setTipoSelect] = useState([]);
    const [perfil, setPerfil] = useState([])    
    //const {permisos,setPermisos}= useContext(PermisosContext);
    let idusu= localStorage.getItem('UsuId');
    useEffect(() => {
        
        
        const llenarListPerfil = () => {      
            let data = qs.stringify({
              usuario:idusu,
              idDispositivo:'89y7gttvjhj98h'
            });
            let url = "/usuario-perfil-list";
            function respuesta(aux) {
              if (aux[0].Id !== -1) 
              {                          
                setDatos(aux);                             
              }
            }     
            llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera,history,source)                   
        };
        llenarListPerfil()
        return () => {
            source.cancel();
        }
        
    }, [buscar]);

    const celda=(alinear,estilo,contenido,key,classes)=>{
        return (
        <TableCell align={alinear} key={key} className={classes} 
            style={estilo} 
        >
          {contenido}
        </TableCell>
        )
    }

    const llenarListPerfil2 = () => {    
        console.log("segunda llamada");  
        let data = qs.stringify({
          usuario:idusu,
          idDispositivo:'89y7gttvjhj98h'
        });
        let url = "/usuario-perfil-list";
        function respuesta(aux) {
          if (aux[0].Id !== -1) 
          {                          
            setDatos(aux);                             
          }
        }     
        llamadaApiToken(data, url, respuesta,ErrorGeneral,history,source)                   
    };

    const actualizar=()=>{
        //setBuscar(!buscar)
        llenarListPerfil2()
    }


    const seleccionado = (auxId, auxOrden) => {
        setSelectedID(auxId)
        setPerfil(auxOrden)
        setTipoSelect([])
    }

    function editarFall(auxFalla) {
        setSeleccionadoEdit(auxFalla)
        //setPerfil(auxFalla)
        setModalAbierto(true)
    } 

    function Row(props) {
        const {row} = props;
        //console.log(row);
        return (    
        <TableRow onClick={() => { seleccionado(row.Id, row) }}
            className={classes.tableRow} selected={selectedID === row.Id}
            classes={{hover: classes.hover, selected: classes.selected}}
        >
            {celda("left", { }, row.Nom, 5, classes.tableCell)}                    
        </TableRow>               
        );
    }

    function tablaCompleta(auxlista) {
        return (
        <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>                    
                  {/*   <TableCell align="center" padding='none'>{auxlista.length}</TableCell> */}
                    <TableCell align="center" padding='none'>{`${auxlista.length} Perfil`} </TableCell>    
                </TableRow>
            </TableHead>
            <TableBody>
                {auxlista.length !== 0 ? (auxlista.map((row) => (
                    <Row key={row.Id} row={row}/>
                ))) : null}
            </TableBody>
        </Table>
        </TableContainer>
        )
    }

    const tablaSinDatos = () => {
        return (
        <TableContainer className={classes.container2}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>                                        
                    <TableCell align="left">Perfil</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow className={classes.tableRow}>
                    <TableCell className={classes.tableCell} align="center">Sin Perfiles</TableCell>                                                                                   
                </TableRow>
            </TableBody>
        </Table>
        </TableContainer>
        )
    }

    const modalEditar = modalAbierto ? 
        (usuario?.nivel != undefined && usuario.nivel !== nvlSNPermiso ?
            <EditarUsuario modalAbierto={modalAbierto} setModalAbierto={setModalAbierto} titulo={'Editar usuario ' + seleccionadoEdit.Nombre}
                seleccionadoEdit={seleccionadoEdit} user={usuario}  /> : null) 
        : null;

    const contenido =  datos.length !== 0 ? tablaCompleta(datos) :  tablaSinDatos()
    const carga=espera?<Box style={{width:"15rem"}} mt={2}> <LinearProgress  color="primary"   /> </Box>: <Paper>{contenido}</Paper>
    const tabTipo = perfil.length !==0 && perfil.tipo ? 
    <TablaPerfilTipo datos={perfil.tipo}  setTipoSelect={setTipoSelect} tipoSelect={tipoSelect} tipo={2}/> :null
    const tabOpera= tipoSelect.length !==0 && tipoSelect.operaciones ? 
                                    <TablaPerfilOperacion datos={tipoSelect.operaciones}  tipoNom={`${tipoSelect.Nom}`}
                                        original={datos} usrId={0} llamaApi={actualizar} perfilId={selectedID} listaOriginal={datos}
                                        tipoSelect={tipoSelect} setPerfil={setPerfil}
                                        setListOriginal={setDatos} setTipoSelect={setTipoSelect}
                                    /> :null
    return (
    <Box className={classes.root}>
        
        <Box display="flex">                        
            {carga}            
            <Paper style={{ marginLeft:1+'rem'}}>
                {tabTipo}
            </Paper>                
            <Paper style={{ marginLeft:1+'rem'}}>
                {tabOpera}
            </Paper>                
        </Box>        
        {modalEditar}
    </Box>
    );
}