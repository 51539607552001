import React from 'react';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles, IconButton, Button } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu'; 
import { useHistory } from "react-router-dom";
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import MenuAndroid from '../AppAndroid';
const drawerWidth = 240;

const useStyles =makeStyles(theme=>({    
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('lg')]: {
            display: 'none',
          },
      },
    mititulo:{
        flexGrow:1
    },
    appBar: {
        [theme.breakpoints.up('lg')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
          },
      },
      appBarSinloguear: {
        
            width: `100% `,
            //marginLeft: drawerWidth,          
      },
}))

const Navbar = (propiedades) => {
    const {guardarUsuario}=React.useContext(AuthContext);
    const {generales,guardarGenerales}=React.useContext(GeneralesContext);
    let history = useHistory();
    //let auxTitulo1='Pruebas - '+generales.Titulo
    //let auxTitulo2='Pruebas - Mexicable'
    let auxTitulo1=generales.Titulo    
    let auxTitulo2='Mexicable'
    const misclases=useStyles()
    const CerrarSesion = e=>{
        e.preventDefault();
        localStorage.clear();
        guardarGenerales([])       	
        guardarUsuario([])
        history.push("/")			
    }
    return (
    
        <AppBar className={propiedades.usuario.length!==0?misclases.appBar:misclases.appBarSinloguear} position="fixed" color="primary">
            <Toolbar>
            <IconButton color="inherit"  aria-label="menu" className={misclases.menuButton} onClick={()=>propiedades.funcionAbrir()} >
                <MenuIcon/>
            </IconButton>
            
            <Typography variant="h6" className={misclases.mititulo}>
                {generales.length!==0 ? auxTitulo1 : auxTitulo2}
            </Typography>
            <Typography variant="h6" style={{marginRight:"1rem"}}>
                PRUEBAS
            </Typography>
            <MenuAndroid/>
            
            {propiedades.usuario.length!==0?<Button variant="text" color="inherit" onClick={CerrarSesion}>
                Cerrar Sesion
            </Button>:null}
            {/* */}
            </Toolbar>
        </AppBar>
        
    );
}
 
export default Navbar;