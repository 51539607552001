import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { GeneralesContext } from "../context/GeneralesContext";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Select as MaterialSelect,
  InputLabel,
  LinearProgress,
  Paper,
  Typography,
} from "@material-ui/core";
import TablaOrden from "./TablaOrden";
import Relojes from "./Relojes";
import axios from "axios";
import qs from "qs";
import {
  llamadaApiToken,
  ErrorGeneral,
  llamaApiCargaToken,
} from "../funciones/ClienteAxios";
import Galeria from "./Galeria";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    width: "100%",
    textAlign: "center",
    color: "#51ad32",
  },
  title: {
    fontSize: 28,
  },
  title2: {
    fontSize: 18,
  },
  subTitle2: {
    fontSize: 12,
  },
  pos: {
    marginBottom: 12,
  },
  addButton: {
    marginRight: theme.spacing(2),
  },
  altura2: {
    minHeight: 70,
    marginTop: 0,
    paddingTop: 0,
  },
  altura3: {
    minHeight: 145,
    marginTop: 0,
    paddingTop: 0,
  },
  altura1: {
    height: 90,
  },
}));

const ConsultaEliminacionEdificio = () => {
  const classes = useStyles();

  const arrEstatus = [
    { valor: -2, nombre: "Todo" },
    { valor: 0, nombre: "Registrada" },
    { valor: 1, nombre: "Finalizada" },
    { valor: -1, nombre: "Cancelada" },
  ];

  let idusu = localStorage.getItem("UsuId");
  let history = useHistory();
  const source = axios.CancelToken.source();

  const { usuario, guardarUsuario } = useContext(AuthContext);
  const { guardarGenerales } = useContext(GeneralesContext);

  const [ordenSeleccionada, guardarOrdenSeleccionada] = useState([]);
  const [ordenBusq, setOrdenBusq] = useState({ orden: "", turno: "" });
  const { orden, turno } = ordenBusq;
  const [fechasSeleccionadas, setFechasSeleccionadas] = useState({
    inicio: new Date(),
    final: new Date(),
  });
  const [estatus, setEstatus] = useState(-2);
  const [buscar, setBuscar] = useState(true);
  const [terminal, setTerminal] = useState(1);
  const [listLugarTerminal, setListLugarTerminal] = useState([]);
  const [selectedID, setSelectedID] = useState(null);

  const [galeria, setGaleria] = useState([]);
  const [cargando, setCargando] = useState(false);
  const [abrirModal, setAbrirModal] = useState(false);
  const [mensaje, setMensaje] = useState("");

  useEffect(() => {
    const llenarListTerminal = () => {
      let data = qs.stringify({
        usuario: idusu,
        idDispositivo: "89y7gttvjhj98h",
      });
      let url = "/lugar-terminal-list";

      function respuestaListTermin(aux) {
        if (aux[0].Id !== -1) {
          let auxArre = [];
          aux.map((tipo) => {
            if (tipo.Id != 0) {
              auxArre.push({ value: tipo.Id, label: tipo.Descr });
            }
          });
          let auxTerm = localStorage.getItem("UsuTerminal");
          setListLugarTerminal(auxArre);
          setTerminal(parseInt(auxTerm));
        }
      }

      llamadaApiToken(
        data,
        url,
        respuestaListTermin,
        ErrorGeneral,
        history,
        source
      );
    };

    const llamarUbicacionOrd = () => {
      let data = qs.stringify({
        tipo: "ORD",
        idDispositivo: "89y7gttvjhj98h",
      });
      let url = "/lugar-ubicacion-list-1_1";

      function respuestaListInterv(auxiliar) {
        if (auxiliar[0].Id !== -1) {
          localStorage.setItem("LisUbicOrd", JSON.stringify(auxiliar));
        }
      }

      llamadaApiToken(
        data,
        url,
        respuestaListInterv,
        ErrorGeneral,
        history,
        source
      );
    };

    const veriSesion = () => {
      const usu = localStorage.getItem("UsuarioNom");
      const nvl = localStorage.getItem("Tipo");
      if (usu === null || (nvl && parseInt(nvl) !== 1 )  ) {
        if ( parseInt(nvl) !== 1) 
        {
          history.push("/vacio");  
        }
        else
        {
          guardarUsuario([]);
          guardarGenerales([]);
          localStorage.clear();
          history.push("/");
        }
        
      } 
      else 
      {
        if (usuario.length === 0) 
        {
          guardarUsuario({ Nombre: usu, nivel: nvl });
        }

        guardarGenerales({
          Titulo: "Consulta y Eliminación de Evidencias Edificios",
        });

        const auxUbi = JSON.parse(localStorage.getItem("LisUbicOrd"));
        if (auxUbi === null || auxUbi === undefined) {
          llamarUbicacionOrd();
        }
        if (listLugarTerminal.length === 0) {
          llenarListTerminal();
        }
      }
    };
    veriSesion();
    return () => {
      source.cancel();
    };
  }, []);

  useEffect(() => {
    setGaleria([]);
    //setCargando(true)
    const consultarImagenes = () => {
      let data = qs.stringify({
        llave: selectedID,
        usuario: idusu,
        idDispositivo: "987gyubjhkkjh",
      });
      let url = "/mtto-evidencia-desactiva-list";

      function respuesta(response) {
        if (response[0].Id === -1) {
          setGaleria([]);
          setMensaje("Sin fotografías.");
        } else {
          //setCargando(false)
          setGaleria(response);
        }
      }

      llamaApiCargaToken(
        data,
        url,
        respuesta,
        ErrorGeneral,
        setCargando,
        history,
        source
      );
    };
    if (selectedID) {
      consultarImagenes();
    }
  }, [selectedID]);

  const onChange = (e) => {
    let expNopermitida = new RegExp("[#._:$!%-({})/*=?|]");
    let expMenos = new RegExp("-");
    let expMas = new RegExp("[+]");
    let expCadena = new RegExp("[A-Za-z0-9]");
    if (
      (expCadena.test(e.target.value) &&
        !expNopermitida.test(e.target.value) &&
        !expMenos.test(e.target.value) &&
        !expMas.test(e.target.value)) ||
      e.target.value === " " ||
      e.target.value === ""
    ) {
      setOrdenBusq({
        ...ordenBusq,
        [e.target.name]: e.target.value.trim(),
      });
    }
  };

  const guardaTurno = (e) => {
    setOrdenBusq({
      ...ordenBusq,
      turno: e.target.value,
    });
  };

  const incrementarBuscar = (e) => {
    setBuscar(!buscar);
  };

  const onChangeTerminal = (event) => {
    setTerminal(event.target.value);
  };

  const onChangeEstatus = (event) => {
    setEstatus(event.target.value);
  };

  const listarGaleria =
    galeria.length > 0 ? (
      <Galeria
        galeria={galeria}
        idusu={idusu}
        history={history}
        source={source}
        cargando={cargando}
        setCargando={setCargando}
        abrirModal={abrirModal}
        setAbrirModal={setAbrirModal}
      />
    ) : (
      <Paper className={classes.root}>
        <Typography variant={"h5"}>{mensaje}</Typography>
      </Paper>
    );

  const contenido2 = (
    <Box pl={1} mt={0.5} display={"flex"} flexDirection="column">
      <div display="flex" flexDirection="column">
        {cargando ? (
          <Box pl={1} pt={5}>
            <LinearProgress />
          </Box>
        ) : (
          listarGaleria
        )}
      </div>
    </Box>
  );

  const principal = () => {
    return (
      <Box display="flex" flexDirection="column">
        <Box>
          <Card className={classes.altura2}>
            <CardContent
              style={{
                padding: 0,
                marginTop: 0.6 + "rem",
                marginBottom: 0.5 + "rem",
              }}
            >
              <Box display="flex" flexDirection="row">
                <Box mr={1.5}>
                  <IconButton
                    color="primary"
                    aria-label="add circle"
                    size={"small"}
                    component="span"
                    onClick={incrementarBuscar}
                    style={{ marginTop: 0.6 + "rem" }}
                  >
                    <SearchIcon />
                  </IconButton>
                </Box>
                <Box>                  
                  {listLugarTerminal.length != 0 ? (
                    <FormControl
                      className={classes.formControl}
                      size="small"
                      style={{ width: 8 + "rem", padding: 0 }}
                    >
                      <InputLabel htmlFor="terminal1-native-simple">
                        Terminal
                      </InputLabel>
                      <MaterialSelect
                        native
                        value={terminal}
                        onChange={onChangeTerminal}
                        inputProps={{ name: "terminal", id: "terminal" }}
                      >
                        <option aria-label="None" value="" />
                        {listLugarTerminal.map((row) => (
                          <option value={row.value}>{row.label}</option>
                        ))}
                      </MaterialSelect>
                    </FormControl>
                  ) : null}
                  <FormControl
                    component="fieldset"
                    style={{ width: 8 + "rem", marginLeft: 0.5 + "rem" }}
                    size="small"
                  >
                    <InputLabel htmlFor="estatus1-native">Estatus</InputLabel>
                    <MaterialSelect
                      native
                      value={estatus}
                      onChange={onChangeEstatus}
                      inputProps={{ name: "estatus", id: "estatus" }}
                    >
                      {arrEstatus.map((status) => {
                        return (
                          <option value={status.valor} id={status.valor}>
                            {status.nombre}
                          </option>
                        );
                      })}
                    </MaterialSelect>
                  </FormControl>
                  <Box component="span">
                    <Relojes
                      fechasSeleccionadas={fechasSeleccionadas}
                      setFechasSeleccionadas={setFechasSeleccionadas}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Folio"
                      variant="outlined"
                      size="small"
                      style={{
                        width: 8 + "rem",
                        marginTop: 0.3 + "rem",
                        marginLeft: 0.4 + "rem",
                      }}
                      name="orden"
                      value={orden}
                      onChange={onChange}
                    />
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box display="flex" flexDirection="row">
          <Box width="30%">
            <Box mt={1}>
              <TablaOrden
                history={history}
                source={source}
                guardarOrdenSeleccionada={guardarOrdenSeleccionada}
                orden={orden}
                fechasSeleccionadas={fechasSeleccionadas}
                buscar={buscar}
                turno={turno}
                terminal={terminal}
                estatus={estatus}
                setSelectedID={setSelectedID}
                selectedID={selectedID}
              />
            </Box>
          </Box>
          <Box width="60%">{contenido2}</Box>
        </Box>
      </Box>
    );
  };

  return <div>{principal()}</div>;
};

export default ConsultaEliminacionEdificio;
