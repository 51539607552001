import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {InputLabel,FormControl,Select as MaterialSelect  ,Box 
  ,LinearProgress ,TextField,RadioGroup,FormControlLabel,Radio,FormLabel,//,Typography
  Typography
  } from '@material-ui/core';
import axios from 'axios';
import qs from 'qs';
import CabinaContainerBlock from './CabinaContainerBlock';
import { useHistory } from "react-router-dom";
import Modal from '../Generales/Modal';
import {toast} from "react-toastify";
//import moment from 'moment';
import {llamaApiCargaToken,ErrorGeneral,llamadaApiToken } from '../funciones/ClienteAxios';
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '20ch',
      }, 
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      Textnumero: {
        width: 5+"px",
      },
      formControlFull: {
        //margin: theme.spacing(1),
        //minWidth: 120,
        width:5+"rem"
      },
      margin: {
        margin: theme.spacing(1),
      },
    },
  }));

const EditarMtto = ({modalAbierto,setModalAbierto,titulo,lecturaSelect,auxListCabs,setOrdenSelect,datos, setDatos}) => {
  let history = useHistory();
  const classes = useStyles();
  const [espera,setEspera]=React.useState(false);
  const [mensaje, setMensaje] = React.useState('');
  const [tipoAdvertencia, setTipoAdvertencia] = React.useState('success');
  const [arregloFiltrado, setArregloFiltrado] = useState([])
  const [aviso, setAviso] = React.useState(false);
  const [cabinaSelec, setCabinaSelec] = React.useState([]);  
  const[nvoMtto,setNvoMtto]=React.useState({Terminal:0,Tipo:0,Auxiliar:"",Tecnico:"",Estado:'0' , Descripcion:"",
                                            Horometro:0,Observ:"",Cabinas:"",RefManual:"",MttoID:0})

  const{Terminal ,Tipo ,Descripcion ,Horometro,Observ ,Estado,Cabinas,RefManual,Auxiliar,Tecnico,MttoID}=nvoMtto                                                                
  const [listLugarTerminal, setListLugarTerminal] = React.useState([])  
  const [listTipo, setListTipo] = React.useState([])
  const[listStatus,setListStatus]= React.useState([])
  const[listCabs,setListCabs]= React.useState([])
  const[arrCabSelect,setArrCabSelect]= React.useState([])
  //const [cabinaObjLista, setCabinaObjLista] = useState([])
  //const[arrFiltrado,setArrFiltrado]= React.useState([])
  let idusu= localStorage.getItem('UsuId'); 
  const source = axios.CancelToken.source();      

  React.useEffect(()=>{ 
   //console.log(lecturaSelect,datos.findIndex((mtto)=>mtto.Id===lecturaSelect.Id));
   //console.log(datos);
    setNvoMtto({...nvoMtto,Descripcion:lecturaSelect.Descr ,Horometro:lecturaSelect.Horometro ,
      MttoID:lecturaSelect.Id ,Observ:lecturaSelect.Observ ,Estado:lecturaSelect.stId.toString(),
      Terminal:lecturaSelect.termId,Tipo:lecturaSelect.tpId,RefManual:lecturaSelect.RefManual,
    })    
    let auxTerminal= localStorage.getItem('UsuTerminal');  
    const llenarListStatus = () => {      
      let data = qs.stringify({      
        idDispositivo:'89y7gttvjhj98h'
      });
      let url = "/cabina-mtto-status";
      function respuestaListStat(auxiliar) {
        if (auxiliar[0].Id !== -1) 
        { //guardarSinDatos(true) 
          //console.log(auxiliar);                                           
          setListStatus(auxiliar)
        }else{
        // guardarSinDatos(false)
        }      
      }
  
      llamaApiCargaToken(data, url,respuestaListStat,ErrorGeneral,setEspera,history,source);
    };
    const llenarListTerminal = () => {      
      let data = qs.stringify({
        usuario:idusu,
        idDispositivo:'89y7gttvjhj98h'
      });
      let url = "/lugar-terminal-list";
      function respuestaListTermin(aux) {
        if (aux[0].Id !== -1) 
        { let auxArre=[];   
          //console.log(aux);
          aux.map((tipo) => {
            if (tipo.Id!=0) 
            { auxArre.push({ value : tipo.Id, label :tipo.Descr })  
            }            
          })                    
          setListLugarTerminal(auxArre);  
        }
      }
      
      llamadaApiToken(data, url, respuestaListTermin,ErrorGeneral,history,source)
    };    

    const llenarListCabs = () => {      
      let data = qs.stringify({
        usuario:idusu,
        idDispositivo:'89y7gttvjhj98h'
      });
      let url = "/cabina-list";
      function respuestaListCabs(aux) {
        if (aux[0].Id !== -1) 
        { setListCabs(aux);     
          //console.log(aux)                    
          var au1x = aux.filter(function (val) {
            return val.TermId === parseInt(auxTerminal);
          });        
          au1x.sort(function (a, b) { return a.Id - b.Id; });              
          setArregloFiltrado(au1x) 
        }
      }
      
      llamadaApiToken(data, url, respuestaListCabs,ErrorGeneral,history,source)
    };    
    
    const llenarListTipo = () => {      
      let data = qs.stringify({        
        idDispositivo:'89y7gttvjhj98h'
      });
      let url = "/cabina-mtto-tipo";
      function respuestaListCabs(aux) {
        if (aux[0].Id !== -1) 
        {  let auxArre=[];  
          //console.log(aux); 
          aux.map((tipo) => {
            if (tipo.Id!==0) 
            {
              auxArre.push({ value : tipo.Id, label :tipo.Descr })  
            }            
          })                    
          setListTipo(auxArre); 
        }
      }
      
      llamadaApiToken(data, url, respuestaListCabs,ErrorGeneral,history,source)
    };    
   
    if (listTipo.length === 0) 
    {
      llenarListTipo()
    }
    if (listLugarTerminal.length === 0) 
    {
      llenarListTerminal();  
    }
    if (listCabs.length === 0) 
    {
      llenarListCabs();  
    }
    if (listStatus.length === 0) 
    {
      llenarListStatus();    
    }  

   /*  if (Terminal===0) {    
      setNvoMtto({
        ...nvoMtto, Terminal : auxTerminal  })  
    }     */  

    return ()=> {
      source.cancel();
    }     
      
  },[]);

  const registrarNVLectura=( )=>{    
    const llenarEditarCabs = () => {      
      let data = qs.stringify({
        MttoId:MttoID,
        Status:parseInt(Estado),
        Usuario:idusu,                
        Descr:Descripcion,//'Mantenimiento programado',
        Observ:Observ,//'Pendiente',        
        Horometro:Horometro,
        RefManual:RefManual,
        idDispositivo:'987gyubjhkkjh'
      });
      let url = "/cabina-mtto-edit_1_2";
      function respuestaEditCab(auxiliar) {
        if (auxiliar.respuesta==1) 
        { // console.log(auxiliar);
          let mensaje="Se edito correctamente el Mantenimiento"
          setTipoAdvertencia("success")      
          setMensaje(mensaje);
          setAviso(true)     
          let auxStd=listStatus.filter((std)=>std.Id===parseInt(Estado))
          let arrEdit={...lecturaSelect,stId:parseInt(Estado),
            Descr:Descripcion,Horometro:parseFloat(Horometro),RefManual:RefManual,
            Observ:Observ,stDescr:auxStd[0].Descr}
          //  console.log(datos );
          setOrdenSelect(arrEdit)   
          let auxlistArray=datos 
          let pos =auxlistArray.findIndex((mtto)=>mtto.Id===arrEdit.Id)
          auxlistArray.splice(pos, 1,arrEdit )        
          toast.success(mensaje, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setModalAbierto(false)
          //console.log(auxlistArray);
          //actualizar()//llamada de funcionamiento   
        }       
      }  
      llamaApiCargaToken(data, url,respuestaEditCab,ErrorGeneral,setEspera,history,source);
    }
    const llenarValida = () => {      
      let data = qs.stringify({
        Mtto:MttoID,        
        idDispositivo:'987gyubjhkkjh'
      });
      let url = "/cabina-mtto-verifica-total-avance";
      function respuesta(auxiliar) {
        if (auxiliar.length!==0) 
        {  //console.log(auxiliar);
          if (auxiliar.total===auxiliar.avance) {
            llenarEditarCabs()
          }
          else{
            let mensaje=`Debe finalizar los mantenimientos de la cabina ${MttoID}  su avance es ${auxiliar.avance}/${auxiliar.total}`
            setTipoAdvertencia("error")      
            setMensaje(mensaje);
            setAviso(true)
          }
                          
          //actualizar()//llamada de funcionamiento   
        }       
      }  
      llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);
    }
    //console.log(Estado,parseInt(Estado));
    if (parseInt(Estado)===3) 
    {   llenarValida() 
    }
    else
    {  llenarEditarCabs()
    }
    
  }

  const onChangeRef = e =>
  { let expNopermitida = new RegExp('[$!?]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[A-Za-z0-9#_:%-({})/*=|]');
    if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) ) 
        || e.target.value===' ' || e.target.value==='') 
    {    
      setNvoMtto({
        ...nvoMtto, [e.target.name] : e.target.value  })
      if (aviso) 
      { setMensaje('');
        setAviso(false);
      }
    }
  };

  const onChange = e =>
  { let expNopermitida = new RegExp('[#_:$!%-({})/*=?|]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[A-Za-z0-9]');
    if ( ( expCadena.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&
        !expMas.test(e.target.value) )||e.target.value===' '||e.target.value==='') 
    { setNvoMtto({
        ...nvoMtto, [e.target.name] : e.target.value })
      if (aviso) 
      { setMensaje('');
        setAviso(false);
      }
    }
  };
  
  const onChangeNum = e =>
  { let expNopermitida = new RegExp('[#A-Za-z_:,$!%-({})/*=?|]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[0-9.]');
    if ( ( expCadena.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&
        !expMas.test(e.target.value)) ||e.target.value===' '||e.target.value==='') 
    {
      setNvoMtto({
        ...nvoMtto, [e.target.name] : e.target.value })
      if (aviso) 
      {
        setMensaje('');
        setAviso(false);
      }
    }
  };
  
  const guardTerminal = e =>
  {
    if (parseInt(e.target.value) > 0) 
    { setArrCabSelect([])  
      var aux = listCabs.filter(function (val) 
      {
        return val.TermId === parseInt(e.target.value);
      });
      aux.sort(function (a, b) { return a.Id - b.Id; });    
                    
      setArregloFiltrado(aux)
    }
    else
    { setArrCabSelect([]) 
      setArregloFiltrado([])
    }
    setNvoMtto({
      ...nvoMtto, [e.target.name] : e.target.value  })
    if (aviso) 
    { setMensaje('');
      setAviso(false);
    }
  };

  const mostrarAlertas =(auxMensaje)=>{
    setTipoAdvertencia("warning")
    let mensaje=auxMensaje            
    setMensaje(mensaje);
    setAviso(true)   
  }

  function valNumero(auxNumero,auxCampo) 
  { let valida=false    
    if (typeof parseInt(auxNumero) === 'number') 
    {
      if (parseInt(auxNumero) !== 0) 
      { valida=true
      }  
      else{
        if (parseInt(auxNumero) > 0 ) 
        { valida=true
        } 
      }
    }
    if (!valida) 
    { 
      mostrarAlertas("Debe escribir un valor de "+auxCampo)
    }
    return valida
  }  

  function valNulos(auxMensaje,auxCampo) 
  { let valida=false    
    if (auxCampo !== '' && auxCampo !== '0'&& auxCampo !==0 && auxCampo !== null && auxCampo!==" " ) 
    {      
      valida=true              
    }
    if (!valida) 
    { 
      mostrarAlertas(auxMensaje)
    }
    return valida
  }
  function valCabinas() 
  { let valida=false    
    if (arrCabSelect.length>0 ) 
    {      
      valida=true              
    }
    if (!valida) 
    { 
      mostrarAlertas("Seleccionar Cabinas")
    }
    return valida
  }
  
  const guardarDatos = () => 
  {
    if ( valNulos("Seleccionar Estatus",Estado) && valNulos("Seleccionar Terminal",Terminal)  && valNulos("Seleccionar Tipo",Tipo) 
      && valNumero(Horometro,"Horómetro") && valNulos("Debe escribir Referencia de Manual",RefManual) ) 
    {       //
      if ( (Descripcion.length>=5 && valNulos("Llenar campo de Descripción (mayor a 5 caracteres)",Descripcion) )
          || (Descripcion.length<5 && valNulos("Llenar campo de Descripción (mayor a 5 caracteres)",0))
      ) 
      {            
        registrarNVLectura()                                                                                                                                                     
      }                 
    }       
  };

  const guardaEstado = e =>
  {  
    setNvoMtto({
      ...nvoMtto, Estado : e.target.value  })  
    if (aviso) 
    { setMensaje('');
      setAviso(false);
    }      
  };

  const onChangeSelect = e =>
  {  
    setNvoMtto({
      ...nvoMtto,   [e.target.name] : e.target.value })

    if (aviso) 
    { setMensaje('');
      setAviso(false);
    }   
  };

  return ( 
    <Modal
      titulo={titulo} modalAbierto={modalAbierto}
      setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
      mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
      aviso={aviso} setAviso={setAviso} espera={espera}
      setEspera={setEspera} tamanio={"md"}
    >
    <form  noValidate autoComplete="off" fullWidth>       
      <Box  display="flex" flexDirection="row">                
        <Box style={{width:35+'rem'}} className={classes.root}>  
          {listStatus.length!=0? 
            <FormControl component="fieldset" style={{width:40+'rem'}}>              
              <FormLabel component="legend" style={{color:'#FF6439',fontWeight:500}}>
                Estatus
              </FormLabel>      
              <RadioGroup row aria-label="position" defaultValue="top"   
                name="Estado" value={Estado} onChange={guardaEstado}
              >            
                {listStatus.map((nvStatus)=>{
                  if (nvStatus.Id!== -1&&nvStatus.Id!== 0) 
                  { return(  
                    <span> 
                      <FormControlLabel value={nvStatus.Id.toString()} style={{color:nvStatus.Color}} 
                        control={<Radio color="primary" />} label={nvStatus.Descr} />    
                      <br/>
                    </span>)
                  }
                })}                        
              </RadioGroup>                     
            </FormControl>     
          :null}      
          <br/>                   
          {listLugarTerminal.length!=0 ?  
            <FormControl className={classes.formControl} size="small" style={{width:8+'rem'}}>
              <InputLabel htmlFor="age-native-simple">Terminal</InputLabel>
              <MaterialSelect
                native value={Terminal} onChange={guardTerminal}
                inputProps={{ name: 'Terminal', id: 'Terminal'}}
                disabled
              >
                <option aria-label="None" value="" />      
                {listLugarTerminal.map((row) => (
                    <option value={row.value}>{row.label}</option>
                ))}   
              </MaterialSelect>
            </FormControl>     
          :null}  
          {listTipo.length!=0 ?  
            <FormControl className={classes.formControl} size="small" style={{width:10+'rem'}}>
              <InputLabel htmlFor="age-native-simple">Tipo</InputLabel>
              <MaterialSelect
                native value={Tipo} onChange={onChangeSelect}
                inputProps={{ name: 'Tipo', id: 'Tipo'}}
                disabled
              >
                <option aria-label="None" value="" />      
                {listTipo.map((row) => (
                    <option value={row.value}>{row.label}</option>
                ))}   
              </MaterialSelect>
            </FormControl>     
          :null}            
                  
          <TextField id="outlinedReferen"           
            label="Horómetro"  size="small" value={Horometro} 
            onChange={onChangeNum} name="Horometro" style={{width:9+'rem'}}                                               
          /> 
          <TextField id="outlinedReferen"           
            label="Referencia Manual"  size="small" value={RefManual} 
            onChange={onChangeRef} name="RefManual" style={{width:31+'rem'}}                                               
          /> 
          <br/>        
          
          <Box  display="flex" flexDirection="row" style={{width:32+'rem'}}  >                
            <Box >        
              <Box display="flex" flexDirection="column"  style={{width:31+'rem'}}>  
                <Typography component="p" variant="body2" color='primary'>Descripción</Typography>                           
                <textarea id="Descr-TextArea" name="Descripcion" rows="3"
                  cols="100"placeholder="Descripción" style={{width: 31+"rem",borderRadius:3+'px'}} 
                  value={Descripcion} onChange={onChange} 
                />
              </Box>
              <Box display="flex" flexDirection="column"  style={{width:31+'rem',paddingTop:.4+'rem'}}>                          
                <Typography component="p" variant="body2" color='primary'>Observación</Typography>                          
                <textarea id="Descr-TextArea" name="Observ" rows="3"
                  cols="100"placeholder="Observación" style={{width: 31+"rem",borderRadius:3+'px'}} 
                  value={Observ} onChange={onChange} 
                />
              </Box>                                            
            </Box>
          </Box> 
        </Box>
        <Box style={{width:25+'em'}} display={"flex"} flexDirection="column">  
        <FormLabel component="legend" style={{color:'#FF6439',fontWeight:500}}>
          Selección de Cabina
        </FormLabel>      
        {Terminal!==0&& auxListCabs.length>0  ?
          <Box style={{marginTop:'1rem'}} >
            <CabinaContainerBlock cabinasTotales={auxListCabs.length} setCabinaSelec={setCabinaSelec} 
              cabSelecc={cabinaSelec} arrCabinas={auxListCabs} arrCabSelect={arrCabSelect}
              setArrCabSelect={setArrCabSelect} //auxListCabs={auxListCabs}
            />
          </Box>
          : <Box pl={1} pt={5}><LinearProgress/></Box>
        }
        </Box>
      </Box>
    </form>
  </Modal>
  );
}
 
export default EditarMtto;