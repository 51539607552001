import React,{useState, useEffect} from "react";
import { Line } from "react-chartjs-2";

export default function GraficaEnergia({dataGrafica}) {
  const [titulos,setTitulos]= useState([])
  const [arrReverse,setArrReverse]= useState([])
  useEffect(()=>{
    let auxTxt=""
    if (dataGrafica.estacion === "Bucle A" ) {
      auxTxt="E1"
    }else{
      if ( dataGrafica.estacion === "Bucle B") {
        auxTxt="5C"
      }
      else{
        auxTxt="5B"
      }
    }
    let mod1 = dataGrafica.labels.reverse()
    let modIni = dataGrafica.data.reverse()
    let modFin = dataGrafica.datFin.reverse()
    let modCarro = dataGrafica.datCArro.reverse()
    let modElog = dataGrafica.datEnlog.reverse()
    setTitulos([`${auxTxt} Inicial `,` ${auxTxt} Final`,`Carro`,"Elogación"])
    //console.log(dataGrafica.labels,mod);
    //console.log(mod1.toReversed());
    setArrReverse({nvoLabels:mod1, inicial:modIni, final:modFin, elogac:modElog,carro:modCarro})
  },[dataGrafica])

  const data = {
    labels:arrReverse.nvoLabels,
    datasets: [
      {
        label:titulos[0],
        backgroundColor: "#51ad32",
        borderColor: "#51ad32",
        data: arrReverse.inicial,
        tension: 0.4,
      },
      {
        label:titulos[1],
        backgroundColor: "#FFC400",
        borderColor: "#FFC400",
        data: arrReverse.final,
        tension: 0.4,
      },
      {
        label:titulos[2],
        backgroundColor: "#0358A3",
        borderColor: "#0358A3",
        data: arrReverse.carro,
        tension: 0.4,
      },
      {
        label:titulos[3],
        backgroundColor: "#E91E63",
        borderColor: "#E91E63",
        data: arrReverse.elogac,
        tension: 0.4,
      },
    ],
  };

  return (
  arrReverse.length!==0?
   <Line width={700} height={550}
    data={data}       
    options={{
      animation: false,
       /* plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                const index = context.dataIndex;
                const valor = context.dataset.data[index];
                const bucle = !graficaFiltrada ? labelGrafica : "";
                const label =
                  bucle +
                  " " +
                  arrayMedidores[index] +
                  " " +
                  valor.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
                return label;
              },
            }, 
          },
        },*/

        scales: {
          y: {
            ticks: {
              callback: function (value) {
                return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
              },
            },
          },
          x: {},
        },
      }}
    />
    :null
  );
}
