import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  IconButton,Tooltip
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import NumberFormat from "react-number-format";
import CarroTensorDummy from "./CarroTensorDummy";
import moment from "moment";
import EditarCarroTensor from "./EditarCarroTensor";
import {findNumber} from "../funciones/FindNumber";
import {exportarArch} from '../funciones/ListaExcel'
import SaveIcon from '@material-ui/icons/Save';
const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    width: "29rem",
  },
  tableRow: {
    backgroundColor: "#51ad32",
    maxWidth: "430px",
  },
  alturaTabla: {
    minHeight: 80,
  },

  tableCellHeader: {
    witdh: "auto",
    backgroundColor: "#51ad32",
    color: "white",
  },

  tableCell: {
    "$selected &": {
      color: "white"
    }
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: "#51ad32"
    }
  },
  hover: {},
  selected: {}
}));

const columnasTerm1 = [
  {  id: 1, titulo: "Fecha",  },
  {  id: 2, titulo: "Terminal",  },
  {  id: 3, titulo: "E1 Inicial ", },
  {  id: 4, titulo: "E1 Final ", },
  {  id: 5, titulo: "Carro ", },
  {  id: 6, titulo: "Elogación", },
];
const columnasTerm2 = [
  { id: 1, titulo: "Fecha", },
  { id: 2, titulo: "Terminal",  },
  { id: 3, titulo: "5C Inicial ",  },
  { id: 4, titulo: "5C Final ", },
  { id: 5, titulo: "Carro ", },
  {  id: 6, titulo: "Elogación", },
]

const columnasTerm3 = [
  { id: 1, titulo: "Fecha",  },
  { id: 2, titulo: "Terminal", },
  { id: 3, titulo: "5B Inicial ",  },   
  { id: 4, titulo: "5B Final ", },
  { id: 5, titulo: "Carro ", },
  {  id: 6, titulo: "Elogación", },
];

export default function TablaCarroTensor({listaCTensor, listaTerminales,
  termSelect, leyendaWarning, usuarioContext,setListaCTensor
}) {
  let nvlSNPermiso = "5";
  const classes = useStyles();
  const [idBotonEditar, setIdBotonEditar] = useState(0);
  const [modalEditarAbierto, setModalEditarAbierto] = useState(false);
  const [modalTitulo, setModalTitulo] = useState("");
  const [energia, setEnergia] = useState(null);
  const [listaExcel, setListaExcel] = useState([]);
  const [alturaTabla, setAlturaTabla] = useState(
    window.innerHeight < 1300
      ? window.innerHeight - 450
      : window.innerHeight - 300
  );

  useEffect(() => {
    let listExc=[]
    if (termSelect===1) {
      listExc.push(["Fecha","Terminal","E1 Inicial","E1 Final","Carro","Elogación"])  
      const filtro=listaCTensor.filter((objeto)=> objeto.termId===termSelect)
      if (filtro.length!==0) {
        filtro.forEach(row => {
          listExc.push([ moment.utc(row.fBitacora).format("DD/MM/YYYY"), row.termNom, row.tensorE1, row.tensorE1Fin, row.tensorE1Motriz, row.tensorE1Elonga])
        });
      }
    }
    else{
      if (termSelect===2) {
        listExc.push(["Fecha","Terminal","5C Inicial","5C Final","Carro","Elogación"])  
        const filtro=listaCTensor.filter((objeto)=> objeto.termId===termSelect)
        if (filtro.length!==0) {
          filtro.forEach(row => {
            listExc.push([ moment.utc(row.fBitacora).format("DD/MM/YYYY"), row.termNom, row.tensorE5C, row.tensorE5CFin, row.tensorE5CMotriz, row.tensorE5CElonga ])
          });
        }
      }
      else{
        if (termSelect===3) {
          listExc.push(["Fecha","Terminal","5B Inicial","5B Final","Carro","Elogación"])  
          const filtro=listaCTensor.filter((objeto)=> objeto.termId===termSelect)
          if (filtro.length!==0) {
            filtro.forEach(row => {
              listExc.push([ moment.utc(row.fBitacora).format("DD/MM/YYYY"), row.termNom, row.tensorE5B, row.tensorE5BFin, row.tensorE5BMotriz, row.tensorE5BElonga ])
            });
          }
        }
      }
    }
    //console.log(termSelect,listExc);
    setListaExcel(listExc)
    
  }, [listaCTensor,termSelect])
  
  const modEditar = modalEditarAbierto ? (
    usuarioContext?.nivel != undefined &&
    usuarioContext.nivel !== nvlSNPermiso ? (
      <EditarCarroTensor
        modalAbierto={modalEditarAbierto}
        setModalAbierto={setModalEditarAbierto}
        titulo={modalTitulo}
        energia={energia}
        terminales={listaTerminales}
        listaCTensor={listaCTensor}       
        setListaCTensor={setListaCTensor} 
      />
    ) : (
      <CarroTensorDummy
        modalAbierto={modalEditarAbierto}
        setModalAbierto={setModalEditarAbierto}
        titulo={modalTitulo}
        energia={energia}
        terminales={listaTerminales}
      />
    )
  ) : null;
  

  useEffect(() => {
    window.addEventListener("resize", function () {
      if (window.innerHeight < 1300) {
        if (listaCTensor.length > 22) {
          setAlturaTabla(window.innerHeight - 450);
        } else {
          let dimTotal = 21 * listaCTensor.length;
          setAlturaTabla(dimTotal);
        }
      } else {
        if (listaCTensor.length > 22) {
          setAlturaTabla(window.innerHeight - 280);
        } else {
          let dimTotal = 21 * listaCTensor.length;
          setAlturaTabla(dimTotal);
        }
      }
    });
  }, []);

  function abrirModalEdit(row) {
    setModalEditarAbierto(!modalEditarAbierto);    
    setModalTitulo("Editar Registro " + moment.utc(row.fBitacora).format("DD/MM/YYYY"));
    setEnergia(row);
  }

  function handleClickEditar(row) {
    if (row.Id !== idBotonEditar) {
      setIdBotonEditar(row.Id);
    }
  }

  const tablaSinResultados = () => {
    return (
    <TableRow>
      <TableCell
        align="center" padding={"none"} className={classes.TableCell}
        style={{ color: "#51ad32", width: 600 + "px", fontWeight: 600 }}          
      >
        Sin Resultados
      </TableCell>
    </TableRow>
    );
  };

  const celda = (alinear, estilo, contenido) => {
    return (
    <TableCell
      align={alinear} className={classes.tableCell}
      padding={"none"} style={estilo} 
    >
      {contenido}
    </TableCell>
    );
  };

  const valLeyenda=(elogacion)=>{
    let nvo=""
    if (elogacion>1000 && elogacion <1100) {
      nvo=leyendaWarning.minimo
    }
    else
    {
      if (elogacion>1100) {
        nvo=leyendaWarning.maximo
      }
    }
    //console.log("");
    
    return nvo
  }

  function generarTablaTerm1() {
    let auxFilas = [];
    if (listaCTensor.length > 0) {
      for (let i = 0; i < listaCTensor.length; i++) {
        let row = listaCTensor[i];
        const nvoLeyenda=valLeyenda(row.tensorE1Elonga)
        auxFilas.push(
          < >
          <TableRow selected={row.Id === idBotonEditar }
            key={row.Id} onClick={() => handleClickEditar(row)}
            className={ classes.tableRow }
            classes={{ hover: classes.hover, selected: classes.selected }} 
          >
            {celda("center",{width: 30 + "px" },
             row.Id === idBotonEditar && findNumber('|67|')?
              <Tooltip title={'Editar'}>
                <IconButton size="small" component={"span"}
                  onClick={
                    row.Id === idBotonEditar ? () => abrirModalEdit(row) : null
                  }
                >                 
                  <EditIcon style={{ color: "white" }} />                
                </IconButton>
              </Tooltip>
              : null
            )}                             
            {celda("center",{width: 80 + "px" }, moment.utc(row.fBitacora).format("DD/MM/YYYY") )}             
            {celda("center",{width: 90 + "px"},row.termNom)}                 
            {celda("center",{width: 120 + "px"},
              <NumberFormat thousandSeparator={true}
                displayType="text" value={row.tensorE1} 
              />
            )}  
            {celda("center",{width: 120 + "px"},
              <NumberFormat thousandSeparator={true}
                displayType="text" value={row.tensorE1Fin} 
              />
            )}   
            {celda("center",{width: 120 + "px"},
              <NumberFormat thousandSeparator={true}
                displayType="text" value={row.tensorE1Motriz} 
              />
            )}      
            {celda("center",{width: 120 + "px"},
              <NumberFormat thousandSeparator={true}
                displayType="text" value={row.tensorE1Elonga} 
              />
            )}                            
          </TableRow>
          {nvoLeyenda!==""?
          <TableRow>
            <TableCell colSpan={7}>
            <TableCell></TableCell>
            <TableCell align="left" padding={'none'} style={{color:'red'}}>
                {nvoLeyenda} 
            </TableCell>
            </TableCell>

          </TableRow>
          :null}
          </ >
        );
      }
      return auxFilas;
    } else {
      tablaSinResultados();
    }
  }

  function generarTablaTerm2() {
    let auxFilas = [];
    if (listaCTensor.length > 0) {
      for (let i = 0; i < listaCTensor.length; i++) {
        let row = listaCTensor[i];
        const nvoLeyenda=valLeyenda(row.tensorE5CElonga)
        auxFilas.push(
          <>
          <TableRow selected={row.Id === idBotonEditar }
            key={row.Id} onClick={() => handleClickEditar(row)}
            className={classes.tableRow}
            classes={{ hover: classes.hover, selected: classes.selected }} 
          >
            {celda("center",{width: 30 + "px" },
              <IconButton
                size="small" component={"span"}
                onClick={
                  row.Id === idBotonEditar  && findNumber('|67|') ? 
                  () => abrirModalEdit(row) : null
                }
              >
                {row.Id === idBotonEditar  && findNumber('|67|') ?
                  (<EditIcon style={{ color: "white" }} />) 
                  : null}
              </IconButton>
            )}              
            {celda("center",{width: 50 + "px"},
              moment.utc(row.fBitacora).format("DD/MM/YYYY")
            )}              
            {celda("center",{width: 90 + "px"},row.termNom)}    
            {celda("center",{width: 120 + "px" },
              <NumberFormat
                thousandSeparator={true} displayType="text"
                value={row.tensorE5C} 
              />
            )} 
            {celda("center",{width: 120 + "px"},
              <NumberFormat thousandSeparator={true}
                displayType="text" value={row.tensorE5CFin} 
              />
            )}   
            {celda("center",{width: 120 + "px"},
              <NumberFormat thousandSeparator={true}
                displayType="text" value={row.tensorE5CMotriz} 
              />
            )}    
              {celda("center",{width: 120 + "px"},
              <NumberFormat thousandSeparator={true}
                displayType="text" value={row.tensorE5CElonga} 
              />
            )} 
          </TableRow>
          {nvoLeyenda!==""?
          <TableRow>
            <TableCell colSpan={7}>
            <TableCell></TableCell>
            <TableCell align="left" padding={'none'} style={{color:'red'}}>
                {nvoLeyenda} 
            </TableCell>
            </TableCell>

          </TableRow>
          :null}
          </>
        );
      }
      return auxFilas;
    } else {
      tablaSinResultados();
    }
  }

  function generarTablaTerm3() {
    let auxFilas = [];
    if (listaCTensor.length > 0) {
      for (let i = 0; i < listaCTensor.length; i++) {
        let row = listaCTensor[i];
        const nvoLeyenda=valLeyenda(row.tensorE5BElonga)
        auxFilas.push(
        <>
          <TableRow selected={row.Id === idBotonEditar }
            key={row.Id} onClick={() => handleClickEditar(row)}
            className={classes.tableRow}
            classes={{ hover: classes.hover, selected: classes.selected }} 
          >
            {celda("center",{width: 30 + "px" },
              <IconButton
                size="small" component={"span"}
                onClick={
                  row.Id === idBotonEditar  && findNumber('|67|') ? 
                  () => abrirModalEdit(row) : null
                }
              >
                {row.Id === idBotonEditar  && findNumber('|67|') ?
                  (<EditIcon style={{ color: "white" }} />) 
                  : null}
              </IconButton>
            )}              
            {celda("center",{width: 50 + "px"},
              moment.utc(row.fBitacora).format("DD/MM/YYYY")
            )}              
            {celda("center",{width: 90 + "px"},row.termNom)}    
            {celda("center",{width: 120 + "px" },
              <NumberFormat
                thousandSeparator={true} displayType="text"
                value={row.tensorE5B} 
              />
            )} 
            {celda("center",{width: 120 + "px"},
              <NumberFormat thousandSeparator={true}
                displayType="text" value={row.tensorE5BFin} 
              />
            )}   
            {celda("center",{width: 120 + "px"},
              <NumberFormat thousandSeparator={true}
                displayType="text" value={row.tensorE5BMotriz} 
              />
            )}  
             {celda("center",{width: 120 + "px"},
              <NumberFormat thousandSeparator={true}
                displayType="text" value={row.tensorE5BElonga} 
              />
            )} 
          </TableRow>
          {nvoLeyenda!==""?
            <TableRow>
              <TableCell colSpan={7}>
              <TableCell></TableCell>
              <TableCell align="left" padding={'none'} style={{color:'red'}}>
                  {nvoLeyenda} 
              </TableCell>
              </TableCell>
  
            </TableRow>
            :null}
          </>
        );
      }
      return auxFilas;
    } else {
      tablaSinResultados();
    }
  }

  const generarTabla =
    termSelect === 1 ? 
      generarTablaTerm1()
      : termSelect === 2 ? 
        generarTablaTerm2()
        : termSelect === 3 ? generarTablaTerm3():null;

  return termSelect === 1 || termSelect === 2 || termSelect === 3 ? (
    <Paper className={classes.root}>
      <TableContainer className={classes.alturaTabla} style={{ height: alturaTabla }} >
        <Table
          className={classes.table} stickyHeader
          size="small" aria-label="resultados"          
        >
          <TableHead>
            <TableRow>
              <TableCell
                key={8} padding={"none"} align="center"
                className={classes.tableCellHeader}
              >
            {findNumber('|74|') ?
              <Tooltip title={ listaCTensor.length===0?"":"Exportar a Excel" }>
                <Box  display="flex" >
                
                  <IconButton onClick={()=>exportarArch(listaExcel," Lista Carro Tensor")} 
                    disabled={ listaCTensor.length===0?true:false }>            
                      <SaveIcon style={{fontSize: 20, color :listaCTensor.length!==0?"white":""}}  color={listaCTensor.length===0?"inherit":""}   />            
                    </IconButton>  
                                    
                </Box>
              </Tooltip>
            : null}            
              </TableCell>
              {termSelect === 1
                ? columnasTerm1.map((row) => (
                    <TableCell
                      key={row.id} padding={"none"}
                      align="center" className={classes.tableCellHeader}
                    >
                      {row.titulo}
                    </TableCell>
                  ))
                : termSelect === 2 ?columnasTerm2.map((row) => (
                    <TableCell
                      key={row.id} padding={"none"} align="center"
                      className={classes.tableCellHeader}
                    >
                      {row.titulo}
                    </TableCell>
                  ))
                  : columnasTerm3.map((row) => (
                    <TableCell
                      key={row.id} padding={"none"} align="center"
                      className={classes.tableCellHeader}
                    >
                      {row.titulo}
                    </TableCell>
                  ))
                  }
            </TableRow>
          </TableHead>
          <TableBody>{generarTabla}</TableBody>
        </Table>
      </TableContainer>
      {modEditar}
    </Paper>
  ) : (
    <div></div>
  );
}
