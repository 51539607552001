import React,{useEffect,useState} from 'react';
import { useHistory } from "react-router-dom";
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import Cargando from '../layout/Cargando';
import moment from 'moment';
import axios from 'axios';
import qs from 'qs';
import Panel from './ParosFallasPanel'
import {makeStyles} from '@material-ui/core'
import {llamaApiContext,ErrorContext ,llamadaApiToken,ErrorGeneral} from '../funciones/ClienteAxios';
let colorBarra="#51ad32";//claro
let colorBarraHover="#73bd5b";// tenue
let colorBackBarra="#d6e6d1";//mas tenue
const estilos = makeStyles(theme=>({
    miroot:{
        
        scrollbarColor: colorBarra+" "+colorBarraHover,//"#51ad32 #c4e6f2",
        "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
          backgroundColor: colorBackBarra,
        },
        "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
          borderRadius: 8,
          backgroundColor: colorBarra,
          minHeight: 24,
          border: "3px solid "+colorBarra,
        },
        "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
          backgroundColor: colorBackBarra,
        },
        "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
          backgroundColor: colorBackBarra,
        },
        "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
          backgroundColor: colorBarraHover,
        },
        "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
          backgroundColor: colorBackBarra,
        },
    },    

}))
const ParosFllasinicio = () => {
    const misclases = estilos()
    let history = useHistory();
    const source = axios.CancelToken.source();     
    const {usuario,guardarUsuario}=React.useContext(AuthContext);
    const [totales, setTotales] = useState([]);    
    const [espera, setEspera] = useState(false);    
    const {generales,guardarGenerales}=React.useContext(GeneralesContext);      
    const [fInicio, setFinicio] = useState(moment(new Date()).format("YYYY/MM/DD "));
    const [fFinal, setFFinal] = useState(moment(new Date()).format("YYYY/MM/DD "));    
    const [buscar, setBuscar] = useState(true);
    const [Bucle, setBucle] = useState(0);        
    useEffect(()=>{                                          

		
	    veriSesion();	
        return ()=> {
            source.cancel();
        }			
	},[buscar]); 
    
    const veriSesion =  () => {            
        let auxTer=0;
        const usu= localStorage.getItem('UsuarioNom');
        let usuId= localStorage.getItem('UsuId');
        const nvl= localStorage.getItem('Tipo');
        const auxTerminal= localStorage.getItem('UsuTerminal')?parseInt(localStorage.getItem('UsuTerminal')):0;            
        const auxUbi= JSON.parse(localStorage.getItem('LisUbicOrd'));
        
        if (auxUbi === null ||auxUbi === undefined ) 
        {   llamarUbicacionOrd()    
        }                
        if(usu==null|| usuId===null)
        {   guardarUsuario([])				
            guardarGenerales([])    
            localStorage.clear();            	             
            history.push("/")	                	
        }
        else{
            if (usuario.length===0) 
            {
                guardarUsuario({...usuario,Nombre:usu,nivel:nvl,usrId:usuId});                                                      
            }
            if(generales.length==0)
            {
                guardarGenerales({...generales,Terminal:auxTerminal})   
                setBucle(auxTerminal)     
                auxTer=auxTerminal
            }
            else
            {   if(Bucle==0)
                {   auxTer=auxTerminal
                    setBucle(auxTerminal)     
                }
                else
                {   if(auxTerminal !=Bucle)
                    {   auxTer=Bucle
                    }    
                    else
                    {   setBucle(auxTerminal)     
                        auxTer=auxTerminal
                    }
                }                                       
            }
            guardarGenerales({...generales,Titulo:'Bitácora'})      
            llamaParoTotal(auxTer);       
            const auxUbi= localStorage.getItem('LisUbic');
            if (auxUbi===null) {
                llamaUbicacion()       
            }                
        }
    }
    
    const llamaUbicacion = () => {              
        let data = qs.stringify({                
            idDispositivo:'89y7gttvjhj98h'
        });
        let url = "/lugar-ubicacion-list";
        function respuesta(auxiliar) 
        {
            if (auxiliar ) 
            {   localStorage.setItem('LisUbic', JSON.stringify(auxiliar));   
            }               
        }      
        llamaApiContext(data, url,respuesta,ErrorContext,setEspera,history,source,guardarUsuario,guardarGenerales);            
    };  
    const llamarUbicacionOrd = () => {      
        let data = qs.stringify({
            tipo:'ORD',
            idDispositivo:'89y7gttvjhj98h'
        });
        let url = "/lugar-ubicacion-list-1_1";
        function respuestaListInterv(auxiliar) {
          if (auxiliar[0].Id!==-1) 
          {                
            localStorage.setItem('LisUbicOrd', JSON.stringify(auxiliar));                                    
          }                    
        }
  
        llamadaApiToken(data, url,respuestaListInterv,ErrorGeneral,history,source);
    };  

    const llamaParoTotal = (auxTer) => {     
        setTotales([])         
        let data = qs.stringify({
            terminal:auxTer ,
            fechaIni:fInicio+'00:00:00' ,
            fechaFin:fFinal+'23:59:59',    
            idDispositivo:'89y7gttvjhj98h'
        });
        let url = "/parofalla-total";
        function respuesta(auxiliar) 
        {
            if (auxiliar &&auxiliar[0].Id!= -1 ) 
            {   setTotales(auxiliar[0])                    
            }               
        } 

        llamaApiContext(data, url,respuesta,ErrorContext,setEspera,history,source,guardarUsuario,guardarGenerales);            
    }; 

    const contenido = totales.length !== 0 && !espera ?
                    <Panel totales={totales}  finicio={fInicio}  setFinicio={setFinicio}  
                        fFinal={fFinal} setFFinal={setFFinal} buscar={buscar} setBuscar={setBuscar}  
                        Bucle={Bucle} setBucle={setBucle}  usuarioContxt={usuario}  
                                                   
                    /> 
                    :<Cargando/>
    return (
        <div className={misclases.miroot} >
            {contenido}
        </div>
    );
}
 
export default ParosFllasinicio;