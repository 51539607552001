import React,{useEffect,useState,useContext} from 'react'
import axios from 'axios';
import qs from 'qs';
import { Box,MenuItem,TextField  ,FormControlLabel,FormControl,Input,
    IconButton,Tooltip,LinearProgress,InputLabel,Select as MaterialSelect,
    Checkbox,ListItemText,    
    } from '@material-ui/core'
import {AuthContext} from '../context/AuthContext'
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import {GeneralesContext} from '../context/GeneralesContext'
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import esLocale from "date-fns/locale/es";
import { DatePicker,MuiPickersUtilsProvider} from '@material-ui/pickers';
import {llamaApiCargaToken,llamadaApiToken, ErrorGeneral} from '../funciones/ClienteAxios';
import SearchIcon from '@material-ui/icons/Search';   
import {exportarArch} from '../funciones/ListaExcel'
import SaveIcon from '@material-ui/icons/Save';
import {toast} from 'react-toastify';
import TablaFiltro from './TablaFiltro';
import {findNumber} from "../funciones/FindNumber";
const arrEstatus = [
    {valor: 0, nombre: "Todo"},
    {valor: 1, nombre: "Registrada"},
    {valor: 2, nombre: "Finalizada"},
    {valor: 3, nombre: "Cancelada"},
]
const arrBusca = [
    {valor: "", nombre: "Sin Asignar"},
    {valor: 1, nombre: "Descripción"},
    {valor: 2, nombre: "Solución"},
    {valor: 3, nombre: "Refacción"},
]
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        //width: '20ch',
      }, 
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      formControl2: {
        margin: theme.spacing(1),        
        minWidth: 120,
        //maxWidth: 120,
      },
      formControl3: {
       // margin: theme.spacing(1),        
        //minWidth: 120,
        maxWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      Textnumero: {
        width: 5+"px",
      },
      formControlFull: {
        //margin: theme.spacing(1),
        //maxWidth: 120,
        width:5+"rem"
      },     
      alturaTabla:{
        minHeight: 280,
      },
    } 
  }));

const ReporteMttoExcel = () => {
    let history = useHistory();
    let numUbiOtro=234;
    const classes = useStyles(); 
    const source = axios.CancelToken.source();  
    const usuID = localStorage.getItem("UsuId");    
    const [datos,setDatos]=useState([])
    const [espera,setEspera]=useState(false)
    const [listaExcel, setListaExcel] = useState([]);
    const [auxContador, setAuxContador] = useState(0)
    
    const {usuario,guardarUsuario}=useContext(AuthContext);
    const {guardarGenerales}=useContext(GeneralesContext);  
    const [fechas,setFechas]=useState({auxFecha:new Date(),fechaFin:new Date()})    
    const [listUsu,setListUsu]=useState([])   
    const [listLugar,setListLugar]=useState([])   
    const [listPeriodo,setListPeriodo]=useState([])    
    const [listUbicacion, guardarListUbicacion] = useState([])
    const [ordFiltros,setOrdFiltros]=useState({Terminal:"", FchTipo:1, Estatus:0, Turno:"",
            OrdenId:"", Captura:"", Folio:"", Lugar:"", Ubica:"", Periodo:"",
            SearchTipo:"", Search:"", Evidencia:0, Tipo:1, Bucle:"", UbicDescr:"",
            Supervisa:false, SupervisaFolio:""});
    const {FchTipo, Estatus, Turno, OrdenId, Captura, Folio, Lugar, Ubica, 
            Periodo, SearchTipo,  Search, Evidencia, Tipo, Bucle, UbicDescr,
            Supervisa, SupervisaFolio} = ordFiltros                 
    const {auxFecha,fechaFin}=fechas
    const [auxUbicacionTipo, guardarAuxUbicacionTipo] = useState([])
    const [auxUbicacionSelecc, guardarAuxUbicacionSelecc] = useState([])
    const [ubicacionSeleccion, guardarUbicacionSeleccion] = useState([])
  useEffect(()=>{                 

    const veriSesion =  () => 
    {            
        const usu= localStorage.getItem('UsuarioNom');
        const nvl= localStorage.getItem('Tipo');            
        if(usu===null)
        {                                
          guardarUsuario([])						
          guardarGenerales([])     
          localStorage.clear();           
          history.push("/")	
        }
        else
        { 
            if (usuario.length === 0) 
            {
                guardarUsuario({Nombre:usu,nivel:nvl});                                      
            }
            guardarGenerales({Titulo:'Reporte Mtto Filtro'})   
            if (listUsu.length===0) 
            {
                llamaListUsu()         
            }  
            if (listLugar.length===0) 
            {
                llamaListLugar()     
            }  
            if (listPeriodo.length===0) 
            {
                llamaListPeriodo()  
            }
            
            if (Lugar!=='' &&Bucle!=='')  
            {
                llenarUbicaciones()
            }    
            if (datos.length===0) {
                datosOTs()
            }else{
              //  setDatos([])
            }
        }
      }
    veriSesion()
  
    return ()=> {
      source.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps	
  },[Lugar,Bucle])

  function llamaListUsu( )  {                      
    const data = qs.stringify({          
        usuario: usuID,
        idDispositivo: '987gyubjhkkjh'
    });
    const url = "/usuario-catalogo";

    function respuesta(aux) {
      if (aux[0].length !== 0) {
        setListUsu(aux);
      }
    }
    llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source)
};

  function llamaListLugar( )  {                      
    const data = qs.stringify({          
        tipo:'ORD',   
        idDispositivo: '987gyubjhkkjh'
      });
      const url = "/lugar-list-1_1";

    function respuesta(aux) {
      if (aux[0].length !== 0) {
        setListLugar(aux);
      }
    }            
    llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source)
  };

  function llamaListPeriodo( )  {                      
    const data = qs.stringify({          
      tipo: "ORD",
      idDispositivo: '987gyubjhkkjh'
    });
    const url = "/mtto-periodo";

    function respuesta(aux) {
      if (aux[0].length !== 0) {
        setListPeriodo(aux);
      }
    }            
    llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source)
  };

  const incrementarBuscar = e =>
  { e.preventDefault();    
    if ((SearchTipo===""&&Search==="" )|| (SearchTipo!==""&&Search!=="")) 
    {
      datosOTs()    
    }
    else{
      let mensaje=SearchTipo!==""?arrBusca[SearchTipo].nombre:"Buscar"
      toast.warning("Debe llenar el campo de "+mensaje, {
        position: "top-right", autoClose: 5000, hideProgressBar: false,
        closeOnClick: true, pauseOnHover: true, draggable: true,
        progress: undefined, toastId: `filtro-campo1`
      });
    }        
  };

  function llenarUbicaciones() {
    const auxUbi= JSON.parse(localStorage.getItem('LisUbicOrd'));      
    let auxArre=[];
    let auxArre2=[];
    auxArre2.push('TODO' )  
    auxUbi.forEach((ubica) => {
      if (ubica.LugarId==Lugar&& (ubica.Terminal.search(Bucle ) !== -1)) 
      {
        auxArre.push({ value : ubica.Id, label :ubica.Tipo })  
        auxArre2.push(ubica.Tipo )  
      }            
    })      
    if (Lugar==5) 
    {
      setOrdFiltros({
        ...ordFiltros,
        Ubicacion :numUbiOtro,
        UbicDescr :(UbicDescr.length!=0?UbicDescr:''),
      }) 
    }
    else
    {
      if (auxContador==1&&UbicDescr.length!=0) 
      {
        let arregloExtraido=[]
        let arregloExtraido2=[]
        let contadorComa=0
        // console.log(UbicDescr);
        for (let index = 0; index < UbicDescr.length; index++) 
        {
          if (UbicDescr.charAt(index)!=','&&UbicDescr.charAt(index)!=' ') 
          {
            contadorComa++;              
          }
          else
          {
            if (UbicDescr.charAt(index)==',') 
            {
              let auxNumero=""
              for (let auxIndice = contadorComa; auxIndice > 0; auxIndice--) 
              {                  
                auxNumero=auxNumero+UbicDescr.charAt(index-auxIndice)                  
              }                
              let auxFiltra1 = auxArre.filter(  (registro) => registro.label == auxNumero  )    
              arregloExtraido.push(auxFiltra1)                
              arregloExtraido2.push(auxNumero)  
              contadorComa=0
            }              
          }            
        }
        if (contadorComa>0) 
        {
          contadorComa++
          let auxNumero=""
          for (let auxIndice = contadorComa; auxIndice > 0; auxIndice--) 
          {                  
            if (UbicDescr.charAt(UbicDescr.length-auxIndice)!=' ') 
            {
              auxNumero=auxNumero+UbicDescr.charAt(UbicDescr.length-auxIndice)  
            }                                                  
          }            
          let auxFiltra1 = auxArre.filter(  (registro) => registro.label == auxNumero  )    
          arregloExtraido.push(auxFiltra1)            
          arregloExtraido2.push(auxNumero)  
          contadorComa=0
        }

        guardarUbicacionSeleccion(arregloExtraido)   
        if (arregloExtraido.length==auxArre.length) 
        {
          let tod=[]
          tod.push('TODO')
          arregloExtraido2.forEach((ubica) => {                              
            tod.push(ubica)                            
          })    
          
          guardarAuxUbicacionSelecc(tod);
        } 
        else 
        {
          guardarAuxUbicacionSelecc(arregloExtraido2);  
        }         
        setOrdFiltros({
          ...ordFiltros,            
          UbicDescr :UbicDescr+', ',
        })  
      } 
      else 
      {
        setOrdFiltros({
          ...ordFiltros,
          Ubicacion :0,
          UbicDescr :'',
        })           
        guardarUbicacionSeleccion([])      
        guardarAuxUbicacionSelecc([]);
      }
        
    }
    //setAuxContador(auxContador+1)
    guardarListUbicacion(auxArre)
    guardarAuxUbicacionTipo(auxArre2)
  }

  const seleccionarUbicacion = (e) => {
    let resultados=[];
    let auxArreglo=[];             
    if (e.target.value.length>1) 
    {
      let ubicNueva=filterArray(auxUbicacionSelecc,e.target.value)                       
      if (ubicNueva.length!==0) 
      { 
        if (ubicNueva=='TODO') 
        {          
          auxArreglo=[]                   
        }
        else
        {
          resultados= listUbicacion.filter(  (registro) => registro.label == ubicNueva  )
          if(ubicacionSeleccion.length>1) 
          { let arrFiltrado = ubicacionSeleccion.filter(  (registro)=>{                                        
              return(registro.value != resultados[0].value)})              
            auxArreglo=arrFiltrado;                                  
          } 
          else 
          {               
            auxArreglo.push({value:resultados[0].value ,label:resultados[0].label})
          } 
        }
      }
      else
      {
        if (e.target.value[e.target.value.length-1] =='TODO') 
        {          
          auxArreglo=listUbicacion           
        }
        else
        {
          resultados= listUbicacion.filter(  (registro) => registro.label == e.target.value[e.target.value.length-1]  )
          auxArreglo=ubicacionSeleccion            
          auxArreglo.push({value:resultados[0].value ,label:resultados[0].label})
        }
      }        
    }
    else
    { if (e.target.value[0] =='TODO') 
      {          
        auxArreglo=listUbicacion                 
      }
      else
      {
        resultados= listUbicacion.filter(  (registro) => registro.label == e.target.value  )                  
        if (resultados.length!==0) 
        {
          auxArreglo.push({value:resultados[0].value ,label:resultados[0].label})
        }else
        {
          auxArreglo= []
        }            
      }
    }    
    guardarUbicacionSeleccion(auxArreglo)        
    if (auxArreglo.length!==0) 
    { if (auxArreglo[0].length!==0) 
      {
        let nomUbiID='';
        auxArreglo.forEach((ubicacion)=>{
          nomUbiID=`${nomUbiID}${ubicacion.value}, `
        })
        let nomUbi='';
        if (e.target.value[e.target.value.length-1] =='TODO') 
        {          
          auxArreglo=listUbicacion    
          auxArreglo.forEach((ubicacion)=>{
            nomUbi=`${nomUbi}${ubicacion.label}, `
          })       
        }
        else
        {            
          e.target.value.forEach((ubicacion)=>{
            if (ubicacion!='TODO') 
            {
              nomUbi=`${nomUbi}${ubicacion}, `
            }              
          })            
        }
        setOrdFiltros({
          ...ordFiltros,           
          Ubicacion :0,
          UbicDescr: nomUbi    ,
        })  
      } 
      else 
      {
        setOrdFiltros({
          ...ordFiltros,
          Ubicacion :'',
          UbicDescr: ''    ,
        })          
      }        
    }
    else
    {
        setOrdFiltros({
        ...ordFiltros,
        Ubicacion :'',
        UbicDescr: ''    ,
      })  
    }      
    if (e.target.value[e.target.value.length-1] =='TODO') 
    { 
      if (auxArreglo.length!==0) 
      {
        guardarAuxUbicacionSelecc(auxUbicacionTipo)  
      } 
      else 
      {
        guardarAuxUbicacionSelecc([])  
      }                  
    }
    else
    {
      let ubicNueva=filterArray(auxUbicacionSelecc,e.target.value)                
      if (ubicNueva[0]=='TODO') 
      {
        guardarAuxUbicacionSelecc([])  
      } 
      else 
      {
        guardarAuxUbicacionSelecc(e.target.value);  
      }                 
    }            
  }
  
  async function datosOTs( )  {    
    setListaExcel([])
    setDatos([]);    
    const fInicio = moment(auxFecha).format("YYYY-MM-DD");
    const fecFinal = moment(fechaFin).format("YYYY-MM-DD");                   
    const data = qs.stringify({                      
        Usuario:usuID,
        Terminal:Bucle === "" ? 0: Bucle,
        Tipo:Tipo===1?1:Tipo,
        FchIni:fInicio + "T00:00:00",
        FchFin:fecFinal + "T23:59:59",
        FchTipo:FchTipo,
        Estatus:Estatus,
        Turno:Turno,
        OrdenId:OrdenId===""?0:OrdenId,
        Captura:Captura===""? 0:Captura,
        Folio:Folio,
        Lugar:Lugar===""?0:Lugar,
        Ubica:UbicDescr===""?"":UbicDescr.charAt(0,UbicDescr.length-3),
        Periodo:Periodo===""?0:Periodo,
        SearchTipo:SearchTipo===""?0:SearchTipo,
        Search:Search,
        Evidencia:Evidencia===""?0:Evidencia,
        Supervisa:Supervisa ? 1 : 0,
        SupervisaFolio:SupervisaFolio!==""?SupervisaFolio:0,
        idDispositivo: '987gyubjhkkjh'
    });
    const url = "/reporte/orden/actividad/dinamico";

    function respuesta(aux) {        
      let nvoArr=[],listExc=[]
      let colRow=""
      //let ordEsq=""
      if (aux[0].length !== 0) 
      { listExc.push(["Orden","Tipo","F.Orden","FolioSupervisa","Descripción","Terminal","FolioCierre","Lugar",
        "Estatus","Ref. Manual", "Periodo","FFinaliza","Captura","FCancel","CancelCausa",
        "Turno","Ubicación","RefTotal","UsrFinal",
        "Folio","Actvidad Descripción","Solución","Actv. RefMan	","Actv. FSoluc","Actv. FReg",
        "Actv. Estatus","Actv. Tipo", "Actv. Usu"
      ])
        aux[0].ordenes.forEach((ord)=>{
          // let fechOrd= moment(fechaOrd).format("YYYY-MM-DD");
          nvoArr.push({id:ord.id, fechaOrd:ord.fechaOrd, supervisaFolio:ord.supervisaFolio, descr:ord.descr, terminal:ord.terminal,
            folioCierre:ord.folioCierre , lugar:ord.lugar, tipo:ord.tipo, std:ord.finaliza, refmanual:ord.refmanual,
            fFinaliza:ord.fFinaliza, capturo:ord.capturo, fCancel:ord.fCancel, cancelCausa:ord.cancelCausa,
            turno:ord.turno, ubic: ord.ubicOtra !== "" ? ord.ubic : ord.ubicOtra,refTotal:ord.refaccionTotal, 
            usrFinal:ord.userfinaliza, 
            actId:"", folioCierreAct:"", descrAct:"", solucion:"",colRow:colRow,periodo:ord.periodo,
            actRefMan:"", actFSol:"", actFReg:"", actStd:"", actTipo: "",actUsu: ""
          })        
          /* listExc.push([ord.id,ord.tipo,ord.fechaOrd,ord.descr,ord.terminal,ord.folioCierre,ord.lugar,
            ord.std, ord.refmanual,ord.periodo, ord.fFinaliza, ord.capturo, ord.fCancel, ord.cancelCausa, 
            ord.turno, ord.ubicOtra !== "" ? ord.ubic : ord.ubicOtra, ord.refaccionTotal, ord.userfinaliza,
            "", "", "","","", "", "","","",])                    */
            listExc.push([ord.id,ord.tipo,  ord.fechaOrd, ord.supervisaFolio, ord.descr, ord.terminal,
              ord.folioCierre ,ord.lugar, ord.finaliza, ord.refmanual, ord.periodo,
              ord.fFinaliza, ord.capturo, ord.fCancel, ord.cancelCausa,
              ord.turno, (ord.ubicOtra !== "" ? ord.ubic : ord.ubicOtra), ord.refaccionTotal, 
              ord.userfinaliza, 
              "", "", "", "", "","", "", "",  "", ""
            ])
          if (ord.actividad[0].idAct!==0) 
          {
            ord.actividad.forEach((activ)=>{
              nvoArr.push({id:"", fechaOrd:"",descr:"", terminal:"", 
                lugar:"",tipo:"",std:"", refmanual:"",periodo:"", fFinaliza:"", capturo:"", 
                fCancel:"", cancelCausa:"", turno:"", ubic:"",refTotal:"", usrFinal:"",
                actId:activ.idAct, folioCierreAct:activ.folio,
                descrAct:activ.descr, solucion:activ.solucion,colRow:colRow,actRefMan:activ.referManual,
                actFSol:activ.fSolucion, actFReg:activ.fechaReg, actStd:activ.status, actTipo: activ.tipo,
                actUsu: activ.usuario
              })   
              listExc.push([ord.id, ord.tipo, ord.fechaOrd,ord.descr, ord.terminal,
                ord.folioCierre ,ord.lugar, ord.finaliza, ord.refmanual, ord.periodo,
                ord.fFinaliza, ord.capturo, ord.fCancel, ord.cancelCausa,
                ord.turno, (ord.ubicOtra !== "" ? ord.ubic : ord.ubicOtra), ord.refaccionTotal, 
                ord.userfinaliza, activ.folio, activ.descr, activ.solucion, activ.referManual,
                activ.fSolucion, activ.fechaReg, activ.status,  activ.tipo, activ.usuario
                
              ])
              /* listExc.push([ord.id, ord.tipo, ord.fechaOrd, ord.descr, ord.terminal, ord.folioCierre, ord.lugar,                   
                ord.std,ord.refmanual,ord.periodo, ord.fFinaliza, ord.capturo, ord.fCancel, ord.cancelCausa, 
                ord.turno, ord.ubicOtra !== "" ? ord.ubic : ord.ubicOtra, ord.refaccionTotal, ord.userfinaliza,
                activ.folio, activ.descr, activ.solucion, activ.referManual,
                activ.fSolucion, activ.fechaReg, activ.status,  activ.tipo, activ.usuario 
              ])  */
            })                        
          }                          
          colRow= ""===colRow?"#f3dcb8":""
        })
        setListaExcel(listExc)
        setDatos(nvoArr);
      }
    }
    llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source); 
  };

  const guardaUbicacionInput = e =>
  {  
      setOrdFiltros({
      ...ordFiltros,
      Ubicacion :numUbiOtro,
      UbicDescr :e.target.value    ,
    })
  } 



  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };   

    const filterArray = (arr1, arr2) => {
        const filtered = arr1.filter(el => {
            return arr2.indexOf(el) === -1;
        });
        return filtered;
    };

    const cambiarBucle = (event) => 
    {   setOrdFiltros({...ordFiltros, Bucle: event.target.value });
    };  

    const onChange = (e) => {
        setOrdFiltros({...ordFiltros, [e.target.name]: e.target.value })
    };

    const guardarInicio = auxF =>
    {
      setFechas({ ...fechas, auxFecha : auxF })
    }; 

    const guardarFin = auxF =>
    {
      setFechas({ ...fechas, fechaFin : auxF })
    };  

  const onChangeCheck = (event) => {
    setOrdFiltros({ ...ordFiltros, [event.target.name]: event.target.checked });
  };

  return (
    <div>
        <Box>
        <FormControl   size="small" 
            style={{ marginRight: 8 ,width:6+'rem'}} >
            <InputLabel htmlFor="age-native-simple">Terminal</InputLabel>
            <MaterialSelect
              value={Bucle} onChange={cambiarBucle}
                inputProps={{ name: 'Bucle', id: 'Bucle', }}
            >                
                <MenuItem value={""}>Sin asignar</MenuItem>
                <MenuItem value={1}>Bucle A</MenuItem>
                <MenuItem value={2}>Bucle B</MenuItem>        
                <MenuItem value={3}>Bucle C</MenuItem>                                
            </MaterialSelect>
        </FormControl>  
        <FormControl size="small"  style={{ marginRight: 8 ,width:8+'rem'}} >
            <InputLabel id="demo-simple-select-helper-label">Tipo</InputLabel>
            <MaterialSelect
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"  name="Tipo"
                value={Tipo} onChange={onChange}
            >                
                <MenuItem value={1}>Ord. Trabajo</MenuItem>
                <MenuItem value={2}>Actividades</MenuItem>                                
            </MaterialSelect>
           {/*  <FormHelperText>Some important helper text</FormHelperText> */}
        </FormControl>
        <FormControl size="small" style={{ marginRight: 8 ,width:8.5+'rem'}}  >
            <InputLabel id="simple-select-label">Tipo Fecha</InputLabel>
            <MaterialSelect
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper" name="FchTipo"
                value={FchTipo} onChange={onChange}                
            >
            <MenuItem value={1}>Fecha Registro</MenuItem>
            <MenuItem value={2}>Fecha Cierre</MenuItem>            
            </MaterialSelect>           
        </FormControl>
        <FormControl size="small" style={{ marginRight: 8 ,width:6+'rem'}}  >
            <InputLabel id="simple-select-label">Estatus</InputLabel>
            <MaterialSelect
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper" name="Estatus"
                value={Estatus} onChange={onChange}
            >                
             {arrEstatus.map((status) => {
                return (
                    <MenuItem value={status.valor} id={status.valor}>
                        {status.nombre}
                    </MenuItem>                    
                )
            })}           
            </MaterialSelect>        
        </FormControl>
        <FormControl size="small"style={{ marginRight: 8 ,width:6+'rem'}}   >
            <InputLabel id="simple-select-label">Turno</InputLabel>
            <MaterialSelect
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper" name="Turno"
                value={Turno} onChange={onChange}
            >
                <MenuItem value={"N"}>Nocturno</MenuItem>
                <MenuItem value={"D"}>Diurno</MenuItem>            
            </MaterialSelect>        
        </FormControl>
        <TextField id="standard-OrdenId" value={OrdenId} size="small"
            name="OrdenId" label="Orden" onChange={onChange} type="number"
            style={{marginRight:.7+'rem', width:6+'rem'}}
        />
        {   listLugar.length!==0 ? 
        <FormControl size="small"  style={{ marginRight: 8 ,width:7+'rem'}} >
            <InputLabel id="simple-select-label">Lugar</InputLabel>
            <MaterialSelect
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper" name="Lugar"
                value={Lugar} onChange={onChange}
            >
             {   listLugar.map((objeto)=>{
                return <MenuItem value={objeto.Id}>{objeto.Tipo}</MenuItem>                    
            })}           
            </MaterialSelect>        
        </FormControl>
         :null}
         {listUbicacion.length!==0 ?  
        ( Lugar!=5 ?  
        <FormControl className={classes.formControl2} style={{width:6+'rem',marginRight:.8+'rem'}} size="small">
          <InputLabel id="input-label-ubic">Ubicación</InputLabel>
          <MaterialSelect
            labelId="mutiple-checkbox-label-ubic"
            id="mutiple-checkbox-ubic" multiple
            value={auxUbicacionSelecc} onChange={seleccionarUbicacion}
            input={<Input />} MenuProps={MenuProps} 
            renderValue={(selected) => selected.join(', ')}            
          >
            {auxUbicacionTipo.map((ubic) =>
            {            
              return(
                <MenuItem key={ubic} value={ubic} >
                  <Checkbox checked={auxUbicacionSelecc.indexOf(ubic) > -1} />               
                  <ListItemText primary={ubic} />
                </MenuItem>
              )
            })}
          </MaterialSelect>
        </FormControl>:  
        <TextField id="outlinedUbicacion"           
          label="Ubicación"  size="small"
          value={UbicDescr} onChange={guardaUbicacionInput}  
          name="UbicDescr" style={{width:10+'rem'}}                                               
        />  
        )
        :null}     
        { listPeriodo.length!==0 ? 
        <FormControl size="small" style={{ marginRight: 8 ,width:6+'rem'}}  >
            <InputLabel id="simple-select-label">Periodo</InputLabel>
            <MaterialSelect
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper" name="Periodo"
                value={Periodo} onChange={onChange}
            >
            {   listPeriodo.map((objeto)=>{
                return <MenuItem value={objeto.Id}>{objeto.Tipo}</MenuItem>                    
            })}              
            </MaterialSelect>        
        </FormControl>
        :null}
        {listUsu.length!==0 ? 
        <FormControl size="small" style={{ marginRight: 8 ,width:12+'rem'}}  >
            <InputLabel id="simple-select-label">Captura</InputLabel>
            <MaterialSelect
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper" name="Captura"
                value={Captura} onChange={onChange}
            >
                <MenuItem value={""}>Sin Asignar</MenuItem> 
            {   listUsu.map((objeto)=>{
                return <MenuItem value={objeto.Id}>{objeto.Nombre}</MenuItem>                    
            })}
        
                
            </MaterialSelect>        
        </FormControl>
        :null}
         <br/>
        <Box mt={1}>
          <FormControl size="small" style={{ marginRight: 8 ,width:7+'rem'}}  >
              <InputLabel id="simple-select-label">Buscar por</InputLabel>
              <MaterialSelect
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper" name="SearchTipo"
                  value={SearchTipo} onChange={onChange}
              >
                  {arrBusca.map((status) => {
                      return (
                          <MenuItem value={status.valor} id={status.valor}>
                              {status.nombre}
                          </MenuItem>                    
                      )
                  })}                      
              </MaterialSelect>        
          </FormControl>
          { SearchTipo!==""? 
              <TextField id="standard-Search" value={Search} size="small"
                  name="Search" label={SearchTipo!==""?arrBusca[SearchTipo].nombre:"Buscar"} 
                  onChange={onChange} style={{marginRight:.7+'rem' ,width:12+'rem'}}
              />
          :null}
        
          <TextField id="standard-Folio" value={Folio} size="small"
              name="Folio" label="Folio de Cierre" onChange={onChange} 
              style={{marginRight:.7+'rem', width:8+'rem'}}
          />

          <FormControl size="small" style={{ marginRight: 8 ,width:6+'rem'}}   >
              <InputLabel id="simple-select-label">Evidencia</InputLabel>
              <MaterialSelect
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper" name="Evidencia"
                  value={Evidencia} onChange={onChange}
              >
              <MenuItem value={0}>No</MenuItem>
              <MenuItem value={1}>Si</MenuItem>            
              </MaterialSelect>        
          </FormControl>
          <FormControlLabel label="Supervisar" size="small"
            control={<Checkbox checked={Supervisa} name="Supervisa" size="small" onChange={onChangeCheck}/>}            
          />
          {Supervisa ?
            <TextField id="outlined-basic" label="Folio Supervisar" variant="outlined" size="small"
              name="SupervisaFolio" value={SupervisaFolio} onChange={onChange} style={{width:"9rem"}}/>
          : null
          }
          <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils} >   
              <Box component="span" pb={1}  >
                  <Tooltip title="Seleccionar Fecha Inicial" >
                  <Box  ml={1}  component="span"  >           
                      <DatePicker
                          disableFuture format="dd/MM/yyyy" size="small"
                          views={["year", "month", "date"]} label="Fecha Inicial"                       
                          minDate="2021-07-14" maxDate={new Date()}
                          value={auxFecha} onChange={guardarInicio}            
                          style={{width:7+'rem'}}
                          cancelLabel={"Cancelar"} okLabel="Seleccionar"       
                      />                      
                  </Box>    
                  </Tooltip>                                   
              </Box>  
              <Box component="span" pb={1}    >
                  <Tooltip title="Seleccionar Fecha Final" >
                  <Box  ml={1}  component="span"  >           
                      <DatePicker
                          disableFuture format="dd/MM/yyyy" size="small"
                          views={["year", "month", "date"]} label="Fecha Final"                       
                          minDate={auxFecha} maxDate={new Date()}
                          value={fechaFin} onChange={guardarFin}            
                          style={{width:7+'rem'}}
                          cancelLabel={"Cancelar"} okLabel="Seleccionar"       
                      />                      
                  </Box>    
                  </Tooltip>                                   
              </Box>                         
          </MuiPickersUtilsProvider>    
          <Box display="flex"   row>
            <Tooltip title="Buscar reporte" >  
              <Box width={{width:4+'rem'}} display="flex" >                 
                <IconButton color="inherit"  aria-label="add circle" 
                  component="span" onClick={incrementarBuscar} 
                >
                  <SearchIcon color="primary" style={{ fontSize: 40 }}  />
                </IconButton>  
              </Box>
            </Tooltip>
            <Tooltip title={ datos.length===0?"":"Exportar a Excel" }>
              <Box width={{width:4+'rem'}} display="flex" >
              {findNumber('|62|') ?
                <IconButton onClick={()=>exportarArch(listaExcel," Lista de Ordenes")} 
                  disabled={ datos.length===0?true:false }>            
                  <SaveIcon style={{fontSize: 40}}  color={datos.length===0?"inherit":"primary"}   />            
                </IconButton>  
              : null}                                
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>             
        
      <Box>
        {!espera? <TablaFiltro datos={datos}/>: <LinearProgress/>}
      </Box>
    </div>
  )
}

export default ReporteMttoExcel