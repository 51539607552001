import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {InputLabel,FormControl,Select as MaterialSelect  ,Box ,TextField,Typography,
  FormControlLabel,LinearProgress,Checkbox,RadioGroup,Radio,FormLabel} from '@material-ui/core';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import {ErrorGeneral, llamaApiCargaToken, llamadaApiToken} from "../funciones/ClienteAxios";
import Modal from '../Generales/ModalTam';
import Rating from '@material-ui/lab/Rating';
import {toast} from "react-toastify";
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '20ch',
      }, 
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      Textnumero: {
        width: 5+"px",
      },
      formControlFull: {
        //margin: theme.spacing(1),
        //minWidth: 120,
        width:5+"rem"
      },
      margin: {
        margin: theme.spacing(1),
      },
    },
  }));

const EditarActividad = ({modalAbierto,setModalAbierto,titulo,auxActividad,funcionActuliza}) => {
  let history = useHistory();
  const source = axios.CancelToken.source();  
  const classes = useStyles();
  const [espera,setEspera]=useState(false);
  const [mensaje, setMensaje] = useState('');
  const [tipoAdvertencia, setTipoAdvertencia] = useState('success');
  const [aviso, setAviso] = useState(false); 
  const [btnDesact, setBtnDesact] = useState(false); 
  const [nvActividad,guardarNvActividad]= useState({Orden:0,Tipo:0,Estado:1,Finaliza:0,Prioridad:0,
                                                          Descripcion:'',Solucion:'',ActividadId:0,RefManual:''});  
  const {Orden,Tipo,Estado,Finaliza,Prioridad,Descripcion,Solucion,ActividadId,RefManual}=nvActividad  
  const [listTipo, guardarListTipo] = useState([])
  const [listStatusActivid, guardarListStatusActivid] = useState([])
  //const [value, setValue] = React.useState(0);
  React.useEffect(()=>{           
    //console.log(auxActividad);
    const{ordenId,Id,descr,prioridad,solucion,tipoId,statusId,referManual}=auxActividad
    let auxFinaliza=solucion.length!=0?true:false
    guardarNvActividad({
      ...nvActividad,
      Orden:ordenId,ActividadId:Id,Descripcion:descr,Prioridad:prioridad,
      Solucion:solucion,Tipo:tipoId,Estado:statusId.toString(),Finaliza:auxFinaliza,
      RefManual:referManual
    })              
    
    const llenarListTipo = () => {
      let data = qs.stringify({
          idDispositivo: '987gyubjhkkjh'
      });
      let url = "/orden-actividad-tipo";

      function respuesta(aux) {
        if (aux[0].Id!=-1) 
        { 
          let auxArre=[];   
          aux.map((tipo) => {
            if (tipo.Id!=0) 
            {
              auxArre.push({ value : tipo.Id, label :tipo.Tipo })  
            }            
          })                    
          guardarListTipo(auxArre);                                      
        }         
      }
      llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);
    };  
    const llenarListStatActiv = () => {
      let data = qs.stringify({
          idDispositivo: '987gyubjhkkjh'
      });
      let url = "/orden-actividad-status";

      function respuesta(aux) {
        if (aux[0].Id!=-1) 
        { //console.log(aux);
          let auxArre=[];   
          aux.map((tipo) => {
            if (tipo.Id!=0) 
            {
              auxArre.push({ value : tipo.Id, label :tipo.Tipo })  
            }            
          })                    
          guardarListStatusActivid(aux);                                      
        }                         
      }
      llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);
    };  
    
  if (listTipo.length==0) 
  {
    llenarListTipo()
  }  
  if (listStatusActivid.length==0) 
  {
    llenarListStatActiv()
  }  
  
  return ()=> {
      source.cancel();
  }     
    
},[]);

  const registrarEditActividad=(auxEstatus)=>{
    const llenarNvActi = () => {
      let idusu= localStorage.getItem('UsuId'); 
      let NomUsu= localStorage.getItem('UsuarioNom');  
      let data = qs.stringify({
        ActividadId:ActividadId,                    
        Orden:Orden,
        Usuario:idusu,
        Tipo:Tipo,
        Status:auxEstatus,
        Prioridad:Prioridad,
        Descripcion:Descripcion,
        Solucion:Solucion,
        FchSolucion:'',
        ReferManual: RefManual,
        UsrFinaliza:Estado==="3"?NomUsu:"",
        idDispositivo:'8978yuhjnfrsdsd'
      });
      let url = "/orden-actividad-edit_1_1";
    
      function respuesta(aux) {
        if (aux.respuesta==1) 
        { //console.log(aux);
          let mensaje='Se edito correctamente la Actividad'
          setTipoAdvertencia("success")      
          setMensaje(mensaje);
          setAviso(true)    
          toast.success(mensaje, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });                
        funcionActuliza()
        setModalAbierto(false)
        }           
      }
      llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);
    };  
    llenarNvActi();       
  }

  const onChange = e =>
  { let expNopermitida = new RegExp('[#_:$!%-({})/*=?|]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[A-Za-z0-9]');
    if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && !expMenos.test(e.target.value) &&
        !expMas.test(e.target.value) ) || e.target.value===' ' || e.target.value==='') 
    {
    
      guardarNvActividad({
        ...nvActividad,
        [e.target.name] : e.target.value  
      })
      if (aviso) 
      {
        setMensaje('');
        setAviso(false);
      }
    }
  };

  const onChangeRef = e =>
  { let expNopermitida = new RegExp('[$!?]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[A-Za-z0-9#_:%-({})/*=|]');
    if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) ) 
        || e.target.value===' ' || e.target.value==='') 
    {    
      guardarNvActividad({
        ...nvActividad,
        [e.target.name] : e.target.value  
      })
      if (aviso) 
      {
        setMensaje('');
        setAviso(false);
      }
    }
  };   
  
  const guardTerminal = e =>
  {
    guardarNvActividad({
      ...nvActividad,
      [e.target.name] : e.target.value  
    })
  };

  const mostrarAlertas = (auxMensaje) => {
    setTipoAdvertencia("warning")
    let mensaje = auxMensaje
    setMensaje(mensaje);
    setAviso(true)
  }

  const validaDatos=(valor, mensaje)=>{
    let respuesta=false    
    if (valor!= null && valor!= '' && valor!= '0' && valor!== 0 ) {
      respuesta=true
     //console.log(valor);
    }
    else{      
      mostrarAlertas(mensaje);      
    }
    return respuesta
  }  
  function valNulos(auxMensaje, auxCampo) {
    let valida = false
    if (auxCampo !== '' && auxCampo !== '0' && auxCampo !== 0 && auxCampo !== null && auxCampo !== " ") {
        valida = true
    }
    if (!valida) {
        mostrarAlertas(auxMensaje)
    }
    return valida
  }

  const guardarDatos = () => 
  {
    if (validaDatos(Tipo,"Seleccionar Tipo")&& validaDatos(Prioridad, "Seleccionar Prioridad")
      && validaDatos(RefManual,"Llenar campo de Referencia Manual ") &&
      ( ((Descripcion.length>=5 && validaDatos(Descripcion, "Llenar campo de Descripción (mayor a 5 caracteres)") ))
        || (Descripcion.length<5 && validaDatos(0, "Llenar campo de Descripción (mayor a 5 caracteres)"))
      )
    ) 
    { if ( valNulos("Seleccionar Estatus",Estado)&&
        ( (Finaliza===1 &&valNulos("Ingrese una Solución", Solucion) )
        || Estado!=="3")
      ) 
      { setBtnDesact(true)
        registrarEditActividad(parseInt(Estado))                             
      }                                      
    }                                                         
  };
  
  const cambioActualiza = (event) => {
    guardarNvActividad({
      ...nvActividad,
        [event.target.name]: event.target.checked 
      });
  };
  const guardarPrioridad = (event) => {
    //console.log(event);
    if (event) {      
      guardarNvActividad({
        ...nvActividad,
        Prioridad : event  
      })
    }
    else
    {
      guardarNvActividad({
        ...nvActividad,
        Prioridad : 0
      })
      //console.log('sin guardar vacio');
    }
    
  };
  const guardaEstado = e =>
  {  
    guardarNvActividad({
      ...nvActividad,
      Estado : e.target.value  ,
      Finaliza: e.target.value==="3"?1:0,
    })  
    if (aviso) 
    {
      setMensaje('');
      setAviso(false);
    }      
  };
  return ( 
    <Modal
      titulo={titulo} modalAbierto={modalAbierto}
      setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
      mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
      aviso={aviso} setAviso={setAviso} espera={espera}
      setEspera={setEspera} tamanio={"sm"} btnDesact={btnDesact}
    >
      <form className={classes.root} noValidate autoComplete="off" fullWidth>                     
      {listStatusActivid.length!=0? 
        <FormControl component="fieldset" style={{width:30+'rem'}} size="small">              
          <FormLabel component="legend" style={{color:process.env.REACT_APP_COLOR,fontWeight:500}}>Estatus</FormLabel>      
          <RadioGroup   row   aria-label="position" //name="estatus"row
              defaultValue="top"   name="Estado" value={Estado} onChange={guardaEstado}
          >            
          {listStatusActivid.map((statActiv)=>{
            let auxColor="";
            if (statActiv.Id==0) 
            { auxColor='red'
            } 
            else 
            { if (statActiv.Id==1) 
              { auxColor='#F46239'
              } 
              else 
              {if (statActiv.Id==3) 
                { auxColor='#049b4f'
                }
              } 
            }            
           return(
            <span> 
              <FormControlLabel value={statActiv.Id.toString()} style={{color:auxColor}} 
                control={<Radio color="primary" size="small"/>} label={statActiv.Tipo}  
              size="small"/>                     
            </span>)
          })}                        
          </RadioGroup>                     
        </FormControl>     
      :null}    
     
       {listTipo.length!==0  ?  
        auxActividad.ordMultiple ===0?<Box display="flex" flexDirection="row">
          <Box mr={5}>  
            <FormControl className={classes.formControl} size="small" style={{width:12+'rem'}}>
              <InputLabel htmlFor="age-native-simple">Tipo</InputLabel>
              <MaterialSelect
                native value={Tipo} onChange={guardTerminal}
                inputProps={{ name: 'Tipo', id: 'Tipo', }}            
              >
                <option aria-label="None" value="" />      
                {listTipo.map((row) => (
                    <option value={row.value}>{row.label}</option>
                ))}   
              </MaterialSelect>
            </FormControl>     
            </Box>  
            <Box  borderColor="transparent">
            <span style={{color:process.env.REACT_APP_COLOR}}  >Prioridad</span>
            <Rating
              name="Prioridad"
              value={Prioridad} max={3}
              onChange={(event, newValue) => {
                guardarPrioridad(newValue);
              }}
              style={{color:process.env.REACT_APP_COLOR}}   
            />
          </Box>
        </Box> 
        :null
      :<Box width={100+"%"}><LinearProgress color="primary" /></Box>
      }   
      {auxActividad.ordMultiple ===0?
      <TextField id="outlinedReferen"           
        label="Referencia Manual"  size="small"
        value={RefManual} onChange={onChangeRef}  
        name="RefManual" style={{width:9+'rem'}}                                               
      />
      :null
      }               
      <br/>     
      <Box>
      <Typography component="p" variant="body2" color='primary' 
          style={{margin:0,marginLeft:.5+'rem'}} >
          Descripción
      </Typography>
      <FormControl component="fieldset" style={{width:30+'rem'}}>    
        <textarea id="Descripcion-TextArea" name="Descripcion" rows="5"
          cols="100"placeholder="Descripción" style={{width: 30+"rem",borderRadius:3+'px'}} 
          value={Descripcion} onChange={onChange} 
        />
      </FormControl>
      </Box>
      
      {/* <FormControlLabel
        control={<Checkbox checked={Finaliza} onChange={cambioActualiza} name="Finaliza" />}
        label="Finaliza"
      />      
      <br/> */}
      {Finaliza===1||Estado==="3"?
      <Box>
      <Typography component="p" variant="body2" color='primary' 
          style={{margin:0,marginLeft:.5+'rem'}} >
          Solución
      </Typography>       
        <textarea id="Solucion-TextArea" name="Solucion" rows="5"
          cols="100"placeholder="Solución" style={{width: 30+"rem",borderRadius:3+'px'}} 
          value={Solucion} onChange={onChange} 
        />
        </Box>
      :null}
      </form>
  </Modal>
  );
}
 
export default EditarActividad;