import React  from 'react';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import esLocale from "date-fns/locale/es";
import {TimePicker,MuiPickersUtilsProvider, } from '@material-ui/pickers';

const Relojes = ({nuevaFalla,guardarNuevaFalla}) => {
  //const [selectedDate, handleDateChange] = React.useState(new Date());
  const {Horario,Tiempo}=nuevaFalla

  function seleccionaHorario(hora) {
    guardarNuevaFalla({
      ...nuevaFalla,  Horario :hora  ,                        
    })
  }

  function seleccionTiempo(hora) {
    guardarNuevaFalla({
      ...nuevaFalla, Tiempo :hora  ,                        
    })
  }    

  return (
  <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
      
    <TimePicker //clearable
      ampm={false} format="HH:mm:ss"
      views={["hours", "minutes", "seconds"]} okLabel="Seleccionar"    
      label="Hora de Paro" value={Horario} cancelLabel={"Cancelar"}                        
      onChange={seleccionaHorario} style={{width:6+'rem'}}
    />
    <TimePicker ampm={false}
      openTo="hours" format="HH:mm:ss" 
      views={["hours", "minutes", "seconds"]} okLabel="Seleccionar"      
      label="Hora de Cierre" value={Tiempo} cancelLabel={"Cancelar"}
      onChange={seleccionTiempo} style={{width:6.5+'rem'}}
    />
    </MuiPickersUtilsProvider>
  );
}

export default Relojes
