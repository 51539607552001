import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {MenuItem, FormControl, Select, Box,
         InputLabel,Grid} from "@material-ui/core";
import ModalGeneral from "./Modal";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import {registrarConsumo} from '../funciones/RegistrarConsumo2'

import moment from "moment";
import ProgressLlamada from "./ProgressLlamada";
const useStyles = makeStyles((theme) => ({
  root: {
    margin: {
      margin: theme.spacing(1),
    },
  },
  campos:{
    width: '200px',
    marginBottom: '1em'
  },
  campoMedidor:{
    width: '420px',
    marginBottom: '1em'
  },
  fecha:{
    width: '200px',
    marginTop:'3px',
    marginBottom: '1em'
  }

}));


//modalAbierto es un state de tipo Bool
//titulo es un string con el titulo del modal
const NuevoConsumo = ({ modalAbierto, setModalAbierto, titulo,terminales, actualizarDatosTabla}) => {
  const classes = useStyles();
  const auxColor="#51ad32"
  const [mensaje, setMensaje] = useState("");
  const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
  const [aviso, setAviso] = useState(false);
  const [progress, setProgress] = useState(0);
  const [completados, setCompletados] = useState(0);
  const [registro, setRegistro] = useState({Medidor1:"",Medidor2:"",Medidor3:"",Medidor4:"",Medidor5:"",Medidor6:"",Medidor7:"",
                                            Consumo1: "",Consumo2: "",Consumo3: "",Consumo4: "",Consumo5: "",Consumo6: "",Consumo7: "",
                                            Costo1: "",Costo2: "",Costo3: "",Costo4: "",Costo5: "",Costo6: "",Costo7: "",
                                            Terminal: 0,Fecha:  moment.utc(new Date()).format()});

  const [cargando, setCargando] = useState(false);
  
  const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);
  let  {Medidor1,Medidor2,Medidor3,Medidor4,Medidor5,Medidor6,Medidor7,
        Consumo1,Consumo2,Consumo3,Consumo4,Consumo5,Consumo6,Consumo7,
        Costo1,Costo2,Costo3,Costo4,Costo5,Costo6,Costo7,Terminal,Fecha} = registro;
  const expPermitidaDecimal = /^(\d+\.?\d*|\.\d+)$/;
  const expPermitidaConsumo = /^\d+(\.\d{1,2})?$/;
  const expPermitidaCosto = /^\d+(\.\d{1,4})?$/;
  const expPermitidaAlfaNumerica = /^[A-Z\d]+$/;
  const expPermitidaMedidor = /^[A-Z]{1}\d{4}$/;
  useEffect(() => {
        /* setRegistro({
          ...setRegistro,
          Medidor1:"",Medidor2:"",Medidor3:"",Medidor4:"",Medidor5:"",Medidor6:"",Medidor7:"",
          Consumo1: "",Consumo2: "",Consumo3: "",Consumo4: "",Consumo5: "",Consumo6: "",Consumo7: "",
          Costo1: "",Costo2: "",Costo3: "",Costo4: "",Costo5: "",Costo6: "",Costo7: "", Fecha: new Date(),Terminal: 0}) */
     
  }, [ ]);

  const handleDateChange = (date) => {
    setRegistro({
      ...registro,
      Fecha: date,
    });
    console.log(date)
  };

  const onChangeNumerico = (e) => {
    let expNopermitida = new RegExp('[#A-Za-z]');
    if(expPermitidaDecimal.test(e.target.value)&&!expNopermitida.test(e.target.value)||e.target.value===' '||e.target.value===''){
      setRegistro({
        ...registro,
        [e.target.name]: e.target.value,
      });
    }
  }

  const onChangeMedidor = (e) => {
    //let expNopermitida = new RegExp('[#a-z]');
    if(e.target.value===' '||e.target.value===''||expPermitidaAlfaNumerica.test(e.target.value.toUpperCase())){
      setRegistro({
        ...registro,
        [e.target.name]: e.target.value.toUpperCase(),
      });
    }
  };

  const handleChangeTerminal = (event) => {
    if (event.target.value !== 0) {
      setRegistro({
        ...registro,
        Terminal: event.target.value,
      });
      setMensaje("");
    } else {
      setRegistro({
        ...registro,
        Terminal: event.target.value,
      });
      setTipoAdvertencia("error");
      setMensaje("Seleccione una Terminal Válida.");
      setAviso(true);
    }
  };

async function verificarCampos(){
  if(Terminal>0){
    if(verificarMedidor(Medidor1,"1")&&verificarConsumo(Consumo1,"1")&&verificarCosto(Costo1,"1")){
      if(verificarMedidor(Medidor2,"2")&&verificarConsumo(Consumo2,"2")&&verificarCosto(Costo2,"2")){
        if(verificarMedidor(Medidor3,"3")&&verificarConsumo(Consumo3,"3")&&verificarCosto(Costo3,"3")){
          if(verificarMedidor(Medidor4,"4")&&verificarConsumo(Consumo4,"4")&&verificarCosto(Costo4,"4")){
            if(verificarMedidor(Medidor5,"5")&&verificarConsumo(Consumo5,"5")&&verificarCosto(Costo5,"5")){
              if(verificarMedidor(Medidor6,"6")&&verificarConsumo(Consumo6,"6")&&verificarCosto(Costo6,"6")){
                if(verificarMedidor(Medidor7,"7")&&verificarConsumo(Consumo7,"7")&&verificarCosto(Costo7,"7")){
                  setMensaje("")
                  setCargando(true)
                  setBotonDeshabilitado(true)
                  let contador=0;
                  let val1 =  await registrarConsumo(Consumo1, Costo1,Medidor1,Terminal,Fecha)
                    if (val1){
                      //console.log('termino el 1')
                      contador++
                      setCompletados(contador)
                      setProgress(((contador)/7)*100)
                    }
                  let val2 =await registrarConsumo(Consumo2, Costo2,Medidor2,Terminal,Fecha)
                    if (val2){
                      //console.log('termino el 2')
                      contador++
                      setCompletados(contador)
                      setProgress(((contador)/7)*100)
                    }
                  let val3 = await registrarConsumo(Consumo3, Costo3,Medidor3,Terminal,Fecha)
                    if (val3){
                      //console.log('termino el 3')
                      contador++
                      setCompletados(contador)
                      setProgress(((contador)/7)*100)
                    }
                  let val4 = await registrarConsumo(Consumo4, Costo4,Medidor4,Terminal,Fecha)
                    if (val4){
                      //console.log('termino el 4')
                      contador++
                      setCompletados(contador)
                      setProgress(((contador)/7)*100)
                    }
                  let val5 =await registrarConsumo(Consumo5, Costo5,Medidor5,Terminal,Fecha)
                    if (val5){
                      //console.log('termino el 5')
                      contador++
                      setCompletados(contador)
                      setProgress(((contador)/7)*100)
                    }
                  let val6 = await registrarConsumo(Consumo6, Costo6,Medidor6,Terminal,Fecha)
                    if (val6){
                      //console.log('termino el 6')
                      contador++
                      setCompletados(contador)
                      setProgress(((contador)/7)*100)
                    }
                  let val7 =await registrarConsumo(Consumo7, Costo7,Medidor7,Terminal,Fecha)
                    if (val7){
                      //console.log('termino el 7')
                      contador++
                      setCompletados(contador)
                      setProgress(100)  
                    }                  
                  setTimeout(() => {
                    setBotonDeshabilitado(false)
                    if(val1&&val2&&val3&&val4&&val5&&val6&&val7){
                      setCargando(false)  
                      let mensaje = "Se registro correctamente la Nueva Lectura";
                      setTipoAdvertencia("success");
                      setMensaje(mensaje);
                      setAviso(true);
                      setRegistro({
                        ...registro,Terminal: 0,Fecha: new Date(),
                        Medidor1: "",Medidor2: "",Medidor3: "",Medidor4: "",Medidor5: "",Medidor6: "",Medidor7: "",
                        Consumo1: "",Consumo2: "",Consumo3: "",Consumo4: "",Consumo5: "",Consumo6: "",Consumo7: "",
                        Costo1: "",Costo2: "",Costo3: "",Costo4: "",Costo5: "",Costo6: "",Costo7: ""});
                        setProgress(0);
                        setCompletados(0);
                        actualizarDatosTabla();
                    }else{
                      let mensaje = "Ha ocurrido un error, inténtelo más tarde";
                      setTipoAdvertencia("warning");
                      setMensaje(mensaje);
                      setAviso(true);
                      }
                  }, 600);  
                }
              }
            }
          }
        }
      }
    }
  }else{
      setTipoAdvertencia("error");
      setMensaje("Seleccione una Terminal Válida.");
      setAviso(true);
  }
}


function verificarMedidor(medidor, numLinea){
  let validado=false;
  if(medidor&&medidor!=" "){
    if( /* expPermitidaMedidor.test(medidor) */  medidor.length>=6)
    {
      validado = true;
    }else{
      validado = false;
      setMensaje(`El Recibo ${numLinea} debe contener minimo 6 caracteres`);
      setAviso(true);
      setTipoAdvertencia("error");
    }
  }else{
    validado = false;
    //setMensaje(`Ingrese Medidor para la Linea ${numLinea}`);
    setMensaje(`Debe capturar el numero del Recibo ${numLinea} `);
    setAviso(true);
    setTipoAdvertencia("error");
  }
  
  return validado
}

function verificarConsumo(consumo, numLinea){
  let validado=false;
  if(consumo&&consumo!=" "){
    if(expPermitidaConsumo.test(consumo) && parseFloat(consumo)>0.00){
      validado = true;
    }else{
      validado = false;
      //setMensaje(`El Consumo de la Linea ${numLinea} debe tener máximo 2 decimales y ser mayor a 0`);
      setMensaje(`El Consumo del Recibo ${numLinea} debe tener máximo 2 decimales y ser mayor a 0`);
      setAviso(true);
      setTipoAdvertencia("error");
    }
  }else{
    validado = false;
    //setMensaje(`Ingrese Consumo para la Linea ${numLinea}`);
    setMensaje(`Debe capturar el Consumo para el Recibo ${numLinea}`);
    setAviso(true);
    setTipoAdvertencia("error");
  }
  return validado
}

function verificarCosto(costo, numLinea){
  let validado = false;
  if(costo&&costo!=" "){
    if(expPermitidaCosto.test(costo) && parseFloat(costo)>0.0000){
      validado = true;
    }else{
      validado = false;
      //setMensaje(`El Costo de la Linea ${numLinea} debe tener máximo 4 decimales y ser mayor a 0`);
      setMensaje(`El Costo del Recibo ${numLinea} debe tener máximo 4 decimales y ser mayor a 0`);
      setAviso(true);
      setTipoAdvertencia("error");
    }
  }else{
    validado = false;
    //setMensaje(`Ingrese Costo para la Linea ${numLinea}`);
    setMensaje(`Debe capturar el Costo del Recibo ${numLinea}`);
    setAviso(true);
    setTipoAdvertencia("error");
  }
  return validado;
}
async function verificarCampos2(){
  if(Terminal>0){

    setMensaje("")
    setCargando(true)
    setBotonDeshabilitado(true)
    let contador=0;
    //console.log('iniciando el 1')
    let val1 =  await registrarConsumo(Consumo1, Costo1,Medidor1,Terminal,Fecha)
    if (val1){    
      //console.log('termino el 1')
      contador++
      setCompletados(contador)
      setProgress(((contador)/7)*100)
    }
    //console.log('iniciando el 2')
    let val2 =await   registrarConsumo(Consumo2, Costo2,Medidor2,Terminal,Fecha)
    if (val2){
      //console.log('termino el 2')      
      contador++
      setCompletados(contador)
      setProgress(((contador)/7)*100)
    }
    //console.log('iniciando el 3')
    let val3 = await  registrarConsumo(Consumo3, Costo3,Medidor3,Terminal,Fecha)
    if (val3){
      //console.log('termino el 3')      
      contador++
      setCompletados(contador)
      setProgress(((contador)/7)*100)
    }
    //console.log('iniciando el 4')
    let val4 = await  registrarConsumo(Consumo4, Costo4,Medidor4,Terminal,Fecha)
    if (val4){
      //console.log('termino el 4')      
      contador++
      setCompletados(contador)
      setProgress(((contador)/7)*100)
    }
    //console.log('iniciando el 5')
    let val5 =await  registrarConsumo(Consumo5, Costo5,Medidor5,Terminal,Fecha)
    if (val5){
      //console.log('termino el 5')      
      contador++
      setCompletados(contador)
      setProgress(((contador)/7)*100)
    }
    //console.log('iniciando el 6')
    let val6 = await registrarConsumo(Consumo6, Costo6,Medidor6,Terminal,Fecha)
    if (val6){
      //console.log('termino el 6')      
      contador++
      setCompletados(contador)
      setProgress(((contador)/7)*100)
    }
    //console.log('iniciando el 7')
    let val7 =await  registrarConsumo(Consumo7, Costo7,Medidor7,Terminal,Fecha)
    if (val7){
      //console.log('termino el 7')      
      contador++
      setCompletados(contador)
      setProgress(((contador)/7)*100)
    }                                                      
    setBotonDeshabilitado(false)
    if(val1&&val2&&val3&&val4&&val5&&val6&&val7){
      setCargando(false)  
      let mensaje = "Se registró correctamente el registro";
      setTipoAdvertencia("success");
      setMensaje(mensaje);
      setAviso(true);
      setRegistro({
          ...registro,Terminal: 0,Fecha: new Date(),
          Medidor1: "",Medidor2: "",Medidor3: "",Medidor4: "",Medidor5: "",Medidor6: "",Medidor7: "",
          Consumo1: "",Consumo2: "",Consumo3: "",Consumo4: "",Consumo5: "",Consumo6: "",Consumo7: "",
          Costo1: "",Costo2: "",Costo3: "",Costo4: "",Costo5: "",Costo6: "",Costo7: ""});
        setProgress(0);
        setCompletados(0);
        actualizarDatosTabla();
    }else{
      let mensaje = "Ha ocurrido un error, inténtelo más tarde";
      setTipoAdvertencia("warning");
      setMensaje(mensaje);
      setAviso(true);
    }
                                                  
  }else{
      setTipoAdvertencia("error");
      setMensaje("Seleccione una Terminal Válida.");
      setAviso(true);
  }
}

  return (
    <ModalGeneral
      titulo={titulo} modalAbierto={modalAbierto}
      setModalAbierto={setModalAbierto}
      guardarDatos={verificarCampos}
      mensaje={mensaje} tamanio={'md'}
      tipoAdvertencia={tipoAdvertencia}
      aviso={aviso} setAviso={setAviso}
      botonDeshabilitado={botonDeshabilitado} cargando={cargando}      
    >
      <form className={classes.root} autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl className={classes.campos}
            >
              <InputLabel id="Terminal">Terminal</InputLabel>
              <Select
                label="Terminal" id="Terminal" name="Terminal"
                value={Terminal} onChange={handleChangeTerminal}
              >
                {terminales.map((terminal, index) => (
                  <MenuItem value={terminal.Id} key={terminal.Id}>
                    <em>{terminal.Descr}</em>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1}>
            <FormControl className={classes.fecha}>
              <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                <DatePicker
                  format="dd/MM/yyyy" views={["year", "month", "date"]}
                  label="Fecha Registro" size="small" name="Fecha"
                  minDate="2019-01-01" value={Fecha} onChange={handleDateChange}
                  cancelLabel={"Cancelar"} okLabel="Seleccionar" 
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
        </Grid>
        <Box display="flex" flexDirection="column" >
          {/* <span style={{paddingRight:2+'rem',width:6+'rem',color:auxColor,fontWeight:600}}>
              Linea 1
          </span>    */}     
          <Box display="flex" flexDirection="row" style={{width:33.5+'rem', marginBottom:.5+'rem'}}  >     
            <Box display="flex" flexDirection="row"  style={{width:11.5+'rem',marginRight:2+"rem"}}>
              <span style={{width:4.2+'rem',color:auxColor,fontWeight:600}}>
                {'Recibo 1'}
              </span>           
              <input type="text" id="Medidor1" value={Medidor1} 
                onChange={onChangeMedidor} name="Medidor1" style={{width:6+'rem',height:1.5+'rem',fontWeight:700}}/>
            </Box>
            <Box display="flex" flexDirection="row"  style={{width:12+'rem',marginRight:1.8+"rem"}} >
              <span style={{marginRight:.2+'rem',width:4.5+'rem',color:auxColor,fontWeight:600}}>
                  Consumo
              </span>           
              <input type="text" id="Consumo1" value={Consumo1} 
                onChange={onChangeNumerico} name="Consumo1" style={{width:6+'rem',height:1.5+'rem',fontWeight:700}}/>
            </Box>
            <Box display="flex" flexDirection="row"  style={{width:9.3+'rem'}}>
              <span style={{ paddingRight:.5+'rem',width:5+'rem',color:auxColor,fontWeight:600}}>
                  Costo
              </span>           
              <input type="text" id="Costo1" value={Costo1} 
                onChange={onChangeNumerico} name="Costo1" style={{width:6+'rem',height:1.5+'rem',fontWeight:700}}/>
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" >
          {/*  <span style={{paddingRight:2+'rem',width:6+'rem',color:auxColor,fontWeight:600}}>
              Linea 2
          </span>   */}      
          <Box display="flex" flexDirection="row" style={{width:33.5+'rem', marginBottom:.5+'rem'}}  >     
            <Box display="flex" flexDirection="row"  style={{width:11.5+'rem',marginRight:2+"rem"}}>
              <span style={{width:4.2+'rem',color:auxColor,fontWeight:600}}>
                {'Recibo 2'}
              </span>           
              <input type="text" id="Medidor2" value={Medidor2} 
                onChange={onChangeMedidor} name="Medidor2" style={{width:6+'rem',height:1.5+'rem',fontWeight:700}}/>
            </Box>
            <Box display="flex" flexDirection="row"  style={{width:12+'rem',marginRight:1.8+"rem"}} >
              <span style={{marginRight:.2+'rem',width:4.5+'rem',color:auxColor,fontWeight:600}}>
                  Consumo
              </span>           
              <input type="text" id="Consumo2" value={Consumo2} 
                onChange={onChangeNumerico} name="Consumo2" style={{width:6+'rem',height:1.5+'rem',fontWeight:700}}/>
            </Box>
            <Box display="flex" flexDirection="row"  style={{width:9.3+'rem'}}>
              <span style={{ paddingRight:.5+'rem',width:5+'rem',color:auxColor,fontWeight:600}}>
                  Costo
              </span>           
              <input type="text" id="Costo2" value={Costo2} 
                onChange={onChangeNumerico} name="Costo2" style={{width:6+'rem',height:1.5+'rem',fontWeight:700}}/>
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" >
            {/* <span style={{paddingRight:2+'rem',width:6+'rem',color:auxColor,fontWeight:600}}>
                Linea 3
            </span>   */}      
          <Box display="flex" flexDirection="row" style={{width:33.5+'rem', marginBottom:.5+'rem'}}  >     
            <Box display="flex" flexDirection="row"  style={{width:11.5+'rem',marginRight:2+"rem"}}>
              <span style={{width:4.2+'rem',color:auxColor,fontWeight:600}}>
                Recibo 3
              </span>           
              <input type="text" id="Medidor3" value={Medidor3} 
                onChange={onChangeMedidor} name="Medidor3" style={{width:6+'rem',height:1.5+'rem',fontWeight:700}}/>
              </Box>
              <Box display="flex" flexDirection="row"  style={{width:12+'rem',marginRight:1.8+"rem"}} >
              <span style={{marginRight:.2+'rem',width:4.5+'rem',color:auxColor,fontWeight:600}}>
                  Consumo
                </span>           
                <input type="text" id="Consumo3" value={Consumo3} 
                  onChange={onChangeNumerico} name="Consumo3" style={{width:6+'rem',height:1.5+'rem',fontWeight:700}}/>
              </Box>
              <Box display="flex" flexDirection="row"  style={{width:9.3+'rem'}}>
                <span style={{ paddingRight:.5+'rem',width:5+'rem',color:auxColor,fontWeight:600}}>
                  Costo
                </span>           
                <input type="text" id="Costo3" value={Costo3} 
                  onChange={onChangeNumerico} name="Costo3" style={{width:6+'rem',height:1.5+'rem',fontWeight:700}}/>
              </Box>
            </Box>
        </Box>
        <Box display="flex" flexDirection="column" >
            {/*  <span style={{paddingRight:2+'rem',width:6+'rem',color:auxColor,fontWeight:600}}>
                Linea 4
            </span>   */}      
          <Box display="flex" flexDirection="row" style={{width:33.5+'rem', marginBottom:.5+'rem'}}  >     
            <Box display="flex" flexDirection="row"  style={{width:11.5+'rem',marginRight:2+"rem"}}>
              <span style={{width:4.2+'rem',color:auxColor,fontWeight:600}}>
                Recibo 4
              </span>           
              <input type="text" id="Medidor4" value={Medidor4} 
                onChange={onChangeMedidor} name="Medidor4" style={{width:6+'rem',height:1.5+'rem',fontWeight:700}}/>
            </Box>
            <Box display="flex" flexDirection="row"  style={{width:12+'rem',marginRight:1.8+"rem"}} >
              <span style={{marginRight:.2+'rem',width:4.5+'rem',color:auxColor,fontWeight:600}}>
                  Consumo
              </span>           
              <input type="text" id="Consumo4" value={Consumo4} 
                onChange={onChangeNumerico} name="Consumo4" style={{width:6+'rem',height:1.5+'rem',fontWeight:700}}/>
            </Box>
            <Box display="flex" flexDirection="row"  style={{width:9.3+'rem'}}>
              <span style={{ paddingRight:.5+'rem',width:5+'rem',color:auxColor,fontWeight:600}}>
                  Costo
              </span>           
              <input type="text" id="Costo4" value={Costo4} 
                onChange={onChangeNumerico} name="Costo4" style={{width:6+'rem',height:1.5+'rem',fontWeight:700}}/>
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" >
            {/* <span style={{paddingRight:2+'rem',width:6+'rem',color:auxColor,fontWeight:600}}>
                Linea 5
            </span>     */}    
          <Box display="flex" flexDirection="row" style={{width:33.5+'rem', marginBottom:.5+'rem'}}  >     
            <Box display="flex" flexDirection="row"  style={{width:11.5+'rem',marginRight:2+"rem"}}>
              <span style={{width:4.2+'rem',color:auxColor,fontWeight:600}}>
                Recibo 5
              </span>           
              <input type="text" id="Medidor5" value={Medidor5} 
                onChange={onChangeMedidor} name="Medidor5" style={{width:6+'rem',height:1.5+'rem',fontWeight:700}}/>
            </Box>
            <Box display="flex" flexDirection="row"  style={{width:12+'rem',marginRight:1.8+"rem"}} >
              <span style={{marginRight:.2+'rem',width:4.5+'rem',color:auxColor,fontWeight:600}}>
                  Consumo
              </span>           
              <input type="text" id="Consumo5" value={Consumo5} 
                onChange={onChangeNumerico} name="Consumo5" style={{width:6+'rem',height:1.5+'rem',fontWeight:700}}/>
            </Box>
            <Box display="flex" flexDirection="row"  style={{width:9.3+'rem'}}>
              <span style={{ paddingRight:.5+'rem',width:5+'rem',color:auxColor,fontWeight:600}}>
                  Costo
              </span>           
              <input type="text" id="Costo5" value={Costo5} 
                onChange={onChangeNumerico} name="Costo5" style={{width:6+'rem',height:1.5+'rem',fontWeight:700}}/>
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" >
            {/*  <span style={{paddingRight:2+'rem',width:6+'rem',color:auxColor,fontWeight:600}}>
                Linea 6
            </span>   */}      
          <Box display="flex" flexDirection="row" style={{width:33.5+'rem', marginBottom:.5+'rem'}}  >     
            <Box display="flex" flexDirection="row"  style={{width:11.5+'rem',marginRight:2+"rem"}}>
              <span style={{width:4.2+'rem',color:auxColor,fontWeight:600}}>
                Recibo 6
              </span>           
              <input type="text" id="Medidor6" value={Medidor6} 
                onChange={onChangeMedidor} name="Medidor6" style={{width:6+'rem',height:1.5+'rem',fontWeight:700}}/>
            </Box>
            <Box display="flex" flexDirection="row"  style={{width:12+'rem',marginRight:1.8+"rem"}} >
              <span style={{marginRight:.2+'rem',width:4.5+'rem',color:auxColor,fontWeight:600}}>
                  Consumo
              </span>           
              <input type="text" id="Consumo6" value={Consumo6} 
                onChange={onChangeNumerico} name="Consumo6" style={{width:6+'rem',height:1.5+'rem',fontWeight:700}}/>
            </Box>
            <Box display="flex" flexDirection="row"  style={{width:9.3+'rem'}}>
              <span style={{ paddingRight:.5+'rem',width:5+'rem',color:auxColor,fontWeight:600}}>
                  Costo
              </span>           
              <input type="text" id="Costo6" value={Costo6} 
                onChange={onChangeNumerico} name="Costo6" style={{width:6+'rem',height:1.5+'rem',fontWeight:700}}/>
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" >
            {/*  <span style={{paddingRight:2+'rem',width:6+'rem',color:auxColor,fontWeight:600}}>
                Linea 7
            </span>  */}       
          <Box display="flex" flexDirection="row" style={{width:33.5+'rem', marginBottom:.5+'rem'}}  >     
            <Box display="flex" flexDirection="row"  style={{width:11.5+'rem',marginRight:2+"rem"}}>
              <span style={{width:4.2+'rem',color:auxColor,fontWeight:600}}>
                Recibo 7
              </span>           
              <input type="text" id="Medidor7" value={Medidor7} 
                onChange={onChangeMedidor} name="Medidor7" style={{width:6+'rem',height:1.5+'rem',fontWeight:700}}/>
            </Box>
            <Box display="flex" flexDirection="row"  style={{width:12+'rem',marginRight:1.8+"rem"}} >
              <span style={{marginRight:.2+'rem',width:4.5+'rem',color:auxColor,fontWeight:600}}>
                  Consumo
              </span>           
              <input type="text" id="Consumo7" value={Consumo7} 
                onChange={onChangeNumerico} name="Consumo7" style={{width:6+'rem',height:1.5+'rem',fontWeight:700}}/>
            </Box>
            <Box display="flex" flexDirection="row"  style={{width:9.3+'rem'}}>
              <span style={{ paddingRight:1+'rem',width:5+'rem',color:auxColor,fontWeight:600}}>
                  Costo
              </span>           
              <input type="text" id="Costo7" value={Costo7} 
                onChange={onChangeNumerico} name="Costo7" style={{width:6+'rem',height:1.5+'rem',fontWeight:700}}/>
            </Box>
          </Box>
        </Box>
      </form>
      {cargando && (<ProgressLlamada progress={progress} completados={completados}/>) }
    </ModalGeneral>
  );
};

export default NuevoConsumo;