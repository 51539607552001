import React from 'react';
import {Button,Dialog,DialogActions,DialogContent
  ,DialogContentText,DialogTitle,CircularProgress} from '@material-ui/core';
//import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Formulario from './EditarOrdenFormDummy'
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
//import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Alert from '@material-ui/lab/Alert';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'noWrap',
    alignItems: 'center',
  },
  wrapper: {
    //margin: theme.spacing(0),
    marginTop: 0,
    paddingTop:0,
    position: 'relative',
  },
 
  buttonGuardar:{color: green[500],},
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function EditarOrdenDummy(propiedades) {
  const [open, setOpen] = React.useState(false);
  const [aviso, setAviso] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const [mensaje, setMensaje] = React.useState('');
  const [tipoAdvertencia, setTipoAdvertencia] = React.useState('success');  
  //const [ubicacionSeleccion, guardarUbicacionSeleccion] = React.useState([])
  //const [auxUbicacionSelecc, guardarAuxUbicacionSelecc] = React.useState([])
  const [auxContador, setAuxContador] = React.useState(0)

  const [nuevaFalla,guardarNuevaFalla]= React.useState({ Usuario:'',Periodo:'',Lugar:'', Terminal:'',FchCausa:'',
                                              Turno:'N',Descripcion:'',Solucion:'',Causa:'',Finaliza:false,Usrfinaliza:'',
                                              Ubicacion:'',UbicDescr:'',SeccId:'',TipoId:'',Estado:''})    
  let history = useHistory();
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    guardarNuevaFalla({
      Usuario:'',Periodo:'',Lugar:'',Terminal:'',FchCausa:'',Turno:'N',
    Descripcion:'',Solucion:'',Causa:'',Finaliza:false,Usrfinaliza:'',
    Ubicacion:'',UbicDescr:'',SeccId:'',TipoId:'',Estado:''})
    setScroll(scrollType);
  };
  const abrirAviso = () => () => {
    setAviso(true);
    
  };
  const cerrarBorrar = () => {
    guardarNuevaFalla({
      Usuario:'',Periodo:'',Lugar:'',Terminal:'',FchCausa:'',Turno:'N',
    Descripcion:'',Solucion:'',Causa:'',Finaliza:false,Usrfinaliza:'',
    Ubicacion:'',UbicDescr:'',SeccId:'',TipoId:'',Estado:''})
    setOpen(false);
    propiedades.setModalAbierto(false)
    setAviso(false);
  };
  const handleClose = () => {
    setOpen(false);
    propiedades.setModalAbierto(false)
  };
  const handleCloseSN = () => {
    setAviso(false);
  };  
  const classes = useStyles();
  const [espera,setEspera]=React.useState(false);
  const {Usuario ,Periodo ,Lugar ,Terminal ,FchCausa ,Turno ,Descripcion ,
    Solucion ,Causa,Finaliza,Usrfinaliza,Ubicacion,UbicDescr,SeccId,TipoId,Estado} = nuevaFalla;
  
  
  
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (propiedades.modalAbierto) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    //console.log(propiedades.seleccionadoEdit);
    /*let hoy = new Date();     
    let fecha = moment(hoy);      
    setHoraActual(fecha);*/
    const {seleccionadoEdit}=propiedades

    //guardarAuxUbicacionSelecc()
    //guardarUbicacionSeleccion()
    let auxFinaliza=false
    if (seleccionadoEdit.Status==1) 
    {
      auxFinaliza=true
    }
    setAuxContador(1)
    guardarNuevaFalla({
      Usuario:'',Periodo:seleccionadoEdit.periId,Lugar:seleccionadoEdit.lugarId,Terminal:seleccionadoEdit.termiId,
      FchCausa:'',Turno:seleccionadoEdit.turno,Descripcion:seleccionadoEdit.descr,Solucion:seleccionadoEdit.solucion,Causa:seleccionadoEdit.causa,
      Finaliza:auxFinaliza,Usrfinaliza:seleccionadoEdit.Usrfinaliza,Ubicacion:seleccionadoEdit.ubicId,Estado:seleccionadoEdit.Status.toString(),
      UbicDescr:seleccionadoEdit.ubicDescr,SeccId:seleccionadoEdit.seccId,TipoId:seleccionadoEdit.tipoId})

  }, [propiedades.modalAbierto]);
 
  return (
  <div>         
    <Dialog
      open={propiedades.modalAbierto}
      onClose={handleClose}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth={true}
      maxWidth="md"
    >                
      <DialogTitle id="nuevo-reporte-titulo" style={{color:process.env.REACT_APP_COLOR}} align="center">{propiedades.titulo}</DialogTitle>              
      <DialogContent dividers={scroll === 'paper'}>        
        <Formulario nuevaFalla={nuevaFalla} guardarNuevaFalla={guardarNuevaFalla }                    
          auxContador={auxContador} setAuxContador={setAuxContador}
        />        
        <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
        >          
        </DialogContentText>
        { aviso&&tipoAdvertencia &&mensaje.length!=0 ? 
            <Alert severity={tipoAdvertencia}  onClose={handleCloseSN}>{mensaje}</Alert>:
              <span><br/></span>}
      </DialogContent>    
      <DialogActions>
        <Button onClick={cerrarBorrar} disabled={espera} color="secondary" variant="outlined">
          Cerrar
        </Button>
              
      </DialogActions>      
    </Dialog>     
  </div>
  );
}
