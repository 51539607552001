import React,{useEffect,useState} from 'react';
import { Box,Card  ,CardContent ,Typography,IconButton,TextField,
        FormControl,Select as MaterialSelect,InputLabel,Icon,Button      
        } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import TablaParo from './TablaParo';
import NumberFormat from 'react-number-format';
import Fechas from './Relojes2'
import LadoDerecho from './FallaLadoDerecho'
import Apertura from './EditarApertura'
import Cierre from './EditarCierre'
import Tooltip from '@material-ui/core/Tooltip';
import NvoParoFalla from './NvoParoFalla';
import NvoTurno from './NvoTurno';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import {llamadaApiToken,ErrorGeneral } from '../funciones/ClienteAxios';
import moment from 'moment';
import {findNumber} from '../funciones/FindNumber'
const useStyles = makeStyles((theme)=>({
    root: {
      minWidth: 275,
    },    
    title: {
      fontSize: 30,
      fontWeight:650,
    },
    title2: {
        fontSize: 22,
        fontWeight:650,
      },
    title4: {
    fontSize: 18,
    fontWeight:650,
    },
    title3: {
    fontSize: 16,
    fontWeight:650,
    },
    pos: {
      marginBottom: 12,
    },
    addButton: {
        marginRight: theme.spacing(2),       
      },
    altura2: {
        height: 68,
        marginTop:0,
        paddingTop:0,
    // width:275
    },
    altura1: {
        minHeight: 94,        
    },
    altura3: {
        minHeight: 150,        
    },
  }));
  const listTerminal=[{id:1 ,nom:"Bucle A"},{id:2 ,nom:"Bucle B"},{id:3 ,nom:"Bucle C"}]
  
const ParosFllasPanel = ({totales,finicio,fFinal,setFFinal,setFinicio,buscar, setBuscar,Bucle, setBucle,usuarioContxt,turnos}) => {    
    const nvlSNPermiso ='5'  
    const auxColor2="#6ebf52"
    const history = useHistory();             
    const classes = useStyles();  
    const idusu= localStorage.getItem('UsuId')? parseInt(localStorage.getItem('UsuId')):0;
    const source = axios.CancelToken.source();
    const [folio, setFolio] = useState({folioSelecc:''});    
    const [modalAbierto, setModalAbierto] = useState(false);
    const [modalAbierto2, setModalAbierto2] = useState(false);
    const [modalTurno, setModalTurno] = useState(false);
    const [modalNvoParo, setModalNvoParo] = useState(false);
    const [opcionApertura, setOpcionApertura] = useState(0);
    const [turnoBit, setTurnoBit] = useState([]);
    const [bitacoraTotales,setBitacoraTotales] = useState({HorarioApertura:'00:00:00',TiempoOpera:'00:00:00',Horometro:0.0,
                                                                Cabinas:0,Pinzas:0,Usuarios:0,Exentos:0,Incidencia:'',
                                                                HorarioFin:'00:00:00',Recorrido:0 ,Vueltas:0});
    const {HorarioApertura,TiempoOpera,Horometro,Cabinas,
            Pinzas,Usuarios,Exentos,Incidencia,HorarioFin,Recorrido,Vueltas} = bitacoraTotales                                                                    
    const {folioSelecc}=folio;    
    
    useEffect(() => {                
        setTurnoBit([])       
        llenarListTurno(moment(finicio).format("YYYY-MM-DD"))

        return () => {
            source.cancel();
        }

    }, [buscar])    
    
    const llenarListTurno = (fIni) => {      
        let data = qs.stringify({
            Usuario:idusu,
            Terminal:Bucle ,
            FIni:fIni+'T00:00:00' ,
            FFin:fIni+'T23:59:59', 
            idDispositivo:'89y7gttvjhj98h'
        });
        let url = "/bitacora-turno-fecha-terminal";
        function respuestaListTermin(aux) {
            if (aux[0].bitacoras) 
            {   setTurnoBit(aux[0])    
                //abrirModalTurno()
            }                       
        }            
        llamadaApiToken(data, url, respuestaListTermin,ErrorGeneral,history,source)
    };

    useEffect(()=>{ 
        if(totales.length!==0)
        {
            setBitacoraTotales({
                ...bitacoraTotales,
                TiempoOpera: totales.TiempoOpera,Horometro:totales.Horometro,Cabinas:totales.Cabina ,
                Pinzas:totales.Pinza,Usuarios:totales.UserCobro,Exentos:totales.UserExento,Incidencia:totales.incidencia,
                HorarioApertura:totales.HIni,HorarioFin:totales.HFin,Recorrido: totales.recorrido ,Vueltas:totales.vuelta
            })  
        }   
        else{
            setBitacoraTotales({...bitacoraTotales,Incidencia:""})
        }
        function checaVacios(valor){
            let respuesta=false
            if (valor===0 || valor==0.0 ||valor==="0.0" ||valor=="0.0" ||valor=="00:00:00") 
            {
                respuesta=true
            } 
            return respuesta
        }
        if (idusu !==1&&( checaVacios(totales.Horometro) || checaVacios(totales.Cabina) || checaVacios( totales.HIni)
           || checaVacios( totales.TiempoOpera)    && findNumber('|9|'))
        ) 
        {   setOpcionApertura(1)
            setModalAbierto(true)                        
        }
        /* else
        {            
            localStorage.setItem('UsuApertura',1);              
        }  */                                  
    },[totales]);    
    
    function recortaPorcentaje(auxiliar) 
    {   //console.log(auxiliar+ " "+auxiliar.toString().length);
        let valor= 0;
        if (auxiliar>10&&auxiliar<100) 
        {   if (auxiliar.toString().length>3) 
            {
                valor= auxiliar.toString().substring(0, 4)    
            }
            else
            {   valor= auxiliar.toString().substring(0, 2)    
            }
        }
        else
        {   if (auxiliar===100) 
            {   valor= auxiliar.toString().substring(0, 3)    
            }
            else
            {   if (auxiliar.toString().length>3)
                {   valor= auxiliar.toString().substring(0, 4)    
                }
                else
                {   valor= auxiliar.toString().substring(0, 1)    
                }    
            }        
        }
        
        return valor
    }

    const onChange = e =>
    {   let expNopermitida = new RegExp('[A-Za-z#._:$!%-({})/*=?|]');
        let expMenos = new RegExp('-');
        let expMas = new RegExp('[+]');     
        let expCadena = new RegExp('[0-9]');
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && !expMenos.test(e.target.value) &&
            !expMas.test(e.target.value) ) || e.target.value===' '|| e.target.value==='') 
        {
            setFolio({            
                [e.target.name] : e.target.value  
            })
        }     
    };

    const abrirModalTurno = () => 
    {   setModalTurno(true);
    };  

    const cambiarBucle = (event) => 
    {   setBucle(event.target.value);
    };  

    const incrementarBuscar = e =>
    { // e.preventDefault();    
        setBuscar(!buscar)        
    };
    function editarApertura() 
    {   setModalAbierto(true)
        setOpcionApertura(2)
    }

    function editarCierre() 
    {   setModalAbierto2(true)
    }

    const abrirNvoParo = () => 
    {   setModalNvoParo(true);
    };  

    const ordenFecha=(fecha)=>
    {   let ordenada=""
        ordenada=fecha.substring(8,10)+fecha.substring(4,8)+fecha.substring(0,4)
        return ordenada;
    }    
    
    const ordenBucle=(fecha)=>
    {   let ordenada=""
        ordenada=fecha.substring(8,10)+fecha.substring(4,8)+fecha.substring(0,4)
        return ordenada;
    }   

    const auxBuc=Bucle===1?"A":Bucle===2?"B":"C"
    const modal =modalAbierto   ? (
            usuarioContxt?.nivel !=undefined && usuarioContxt.nivel!== nvlSNPermiso ?
                (<Apertura titulo={`Apertura  ${ordenFecha(finicio) } Bucle ${auxBuc}`} modalAbierto={modalAbierto} setModalAbierto={setModalAbierto}
                    horaA={HorarioApertura} auxHorometro={Horometro} tiempoOp={TiempoOpera} bitacora={totales.bitacoraId}   
                    bitacoraTotales={bitacoraTotales} setBitacoraTotales={setBitacoraTotales} opcionApertura={opcionApertura}
                    auxCabinas={totales.Cabina}                                
                />)    
                : 
                null
            )
            :(null)

    const modal2 =modalAbierto2  ? ( 
        usuarioContxt?.nivel !=undefined && usuarioContxt.nivel!== nvlSNPermiso ? 
            (<Cierre titulo={`Cierre ${ordenFecha(finicio)} Bucle ${auxBuc}`} modalAbierto={modalAbierto2} setModalAbierto={setModalAbierto2}                
                bitacoraTotales={bitacoraTotales} setBitacoraTotales={setBitacoraTotales}   bitacora={totales.bitacoraId}
            />)
            :null
        )
        :(null)          
    
    const modNvoParo = modalNvoParo ? <NvoParoFalla modalAbierto={modalNvoParo} setModalAbierto={setModalNvoParo} 
                                        titulo="Nuevo Paro/Falla"  recargaTabla={incrementarBuscar} /> :null        
    const modNvoTurno = modalTurno && turnoBit.length!==0 ? <NvoTurno modalAbierto={modalTurno} setModalAbierto={setModalTurno} 
                                        titulo="Bitacora Turno"  recargaTabla={incrementarBuscar} turnoBit={turnoBit} /> :null                                                
    return (
        <React.Fragment>
            <Box display="flex" flexDirection="column" >
                <Box display="flex" flexDirection="row" >
                    <Box width="75%"  >                    
                        <Box mb={1} >
                            <Card  >
                                <CardContent style={{padding:0,marginTop:.8+'rem',marginBottom:.8+'rem'}}>
                                    <Box display="flex" flexDirection="row" justifyContent="center">
                                        <Box mr={1} mt={1}>
                                            <Typography className={classes.title4}  variant="h5" component="h2" 
                                                align="center"  color={'secondary'}> 
                                                
                                            { finicio.length!=0? ordenFecha(finicio):null}
                                            </Typography>                                    
                                            <Typography color="textSecondary" align="center" style={{fontSize:.95+'rem'}}>
                                                Fecha Inicio
                                            </Typography>
                                        </Box> 
                                        <Box mr={0} mt={1}>
                                            <Typography className={classes.title4}  variant="h5" component="h2" 
                                                align="center"  color={'secondary'}> 
                                                
                                            { fFinal.length!=0?ordenFecha(fFinal):null}
                                            </Typography>                                    
                                            <Typography color="textSecondary" align="center" style={{fontSize:.95+'rem'}}>
                                                Fecha Final
                                            </Typography>
                                        </Box>                                         
                                        <Box mr={1} mt={1} >
                                            <Typography className={classes.title4}  variant="h5" component="h2" 
                                                align="center"  color={'secondary'}> 
                                                
                                                {TiempoOpera.substring(0,5)}
                                            </Typography>                                    
                                            <Typography color="textSecondary" style={{fontSize:.95+'rem'}} align="center">
                                                Hora Operación
                                            </Typography>
                                        </Box>                                   
                                        <Box ml={1} mt={1}>
                                            <Typography className={classes.title4}  variant="h5" component="h1" 
                                                align="center"color={'secondary'}  >                                               
                                                <NumberFormat value= {Horometro}displayType={'text'}  
                                                    thousandSeparator={true}
                                                />
                                            </Typography>                                            
                                            <Typography  color="textSecondary" style={{fontSize:.95+'rem'}}  align="center" >
                                                Horometro
                                            </Typography>
                                        </Box>  
                                        <Box mx={1} mt={1}>
                                            <Typography className={classes.title4}  variant="h5" component="h2" 
                                                align="center"  color={'secondary'}> 
                                                <NumberFormat value= {Cabinas} displayType={'text'}  
                                                    thousandSeparator={true}/>
                                                { }
                                            </Typography>                                    
                                            <Typography color="textSecondary" style={{fontSize:.95+'rem'}} align="center">
                                                Cabina
                                            </Typography>
                                        </Box>                                                                                
                                        <Box mr={1} mt={1}>
                                            <Typography className={classes.title4}  variant="h5" component="h2" 
                                                align="center"  color={'secondary'} >                                                 
                                                <NumberFormat value= {Pinzas} displayType={'text'}  
                                                    thousandSeparator={true}/>
                                            </Typography>                                    
                                            <Typography color="textSecondary"style={{fontSize:.95+'rem'}}  align="center">
                                                Pinza
                                            </Typography>
                                        </Box>  
                                        <Box> 
                                            <Box ml={1}  display="flex" flexDirection="row" flexWrap="nowrap">
                                                <Typography className={classes.title4}  variant="h5" component="h2" 
                                                    align="center"  style={{color:auxColor2}}> 
                                                    <NumberFormat value= {Usuarios} displayType={'text'}  
                                                        thousandSeparator={true}/>
                                                    {}                                            
                                                </Typography>                                    
                                                <Box>
                                                    <Tooltip 
                                                        title={  usuarioContxt?.nivel !=undefined && usuarioContxt.nivel!== nvlSNPermiso 
                                                            ? "Asignar Hora Apertura":''}
                                                    >
                                                        <Icon onClick={() => findNumber('|9|') ? editarApertura() : ''}  >
                                                            <img src={"user_cobro2.svg"} height={23} width={23}/>
                                                        </Icon>
                                                    </Tooltip>
                                                </Box>
                                            </Box>
                                            <Box ml={1} >
                                                <Typography className={classes.title4}  variant="h5" component="h2" 
                                                    align="center"  style={{color:auxColor2}}> 
                                                    <NumberFormat value= {Exentos} displayType={'text'}  
                                                        thousandSeparator={true}/>
                                                    {}
                                                { " "}
                                                <span style={{paddingTop:'.02rem'}}>
                                                    <Tooltip 
                                                        title={  usuarioContxt?.nivel !=undefined && usuarioContxt.nivel!== nvlSNPermiso 
                                                            ? "Asignar Hora Cierre":''}
                                                    >
                                                    <Icon onClick={() => findNumber('|10|') ? editarCierre() : ''}>
                                                        <img src={"user_exento2.svg"} height={22} width={22}/>
                                                    </Icon>
                                                    </Tooltip>
                                                </span>
                                                </Typography>                                    
                                                
                                            </Box>                                         
                                        </Box>
                                        <Box> 
                                            <Box ml={1}  display="flex" flexDirection="row" flexWrap="nowrap">
                                                <Typography className={classes.title4}  variant="h5" component="h2" 
                                                    align="center"  style={{color:auxColor2}}> 
                                                    
                                                {Vueltas}                                            
                                                </Typography>                                    
                                                <Box ml={1} style={{paddingBottom:.55+'rem'}}>
                                                <Typography 
                                                    align="center"  color="textSecondary">                                                     
                                                    Vuelta
                                                </Typography>                                    
                                                </Box>
                                            </Box>
                                            <Box ml={1} mt={1} display="flex" flexDirection="row" flexWrap="nowrap">
                                                <Typography className={classes.title4}  variant="h5" component="h2" 
                                                    align="center"  style={{color:auxColor2}}> 
                                                    
                                                {Recorrido}                                                                                         
                                                </Typography>                                    
                                                <Box ml={1} style={{paddingTop:.0+'rem'}} >
                                                <Typography  align="center" color="textSecondary">                                                     
                                                    Km
                                                </Typography>                                    
                                                </Box>
                                            </Box>                                         
                                        </Box>
                                        
                                        <Box> 
                                            <Box ml={1}  display="flex" flexDirection="row" flexWrap="nowrap">
                                                <Tooltip 
                                                    title={  usuarioContxt?.nivel !=undefined && usuarioContxt.nivel!== nvlSNPermiso 
                                                        ? "Asignar Hora Apertura":''}
                                                >
                                                <Typography className={classes.title4}  variant="h5" component="h2" 
                                                    align="center"  style={{color:auxColor2}} onClick={() => findNumber('|9|') ? editarApertura() : ''} > 
                                                    
                                                    { HorarioApertura.substring(0,5)}
                                                </Typography>       
                                                </Tooltip>                             
                                                <Box ml={1} style={{paddingBottom:.58+'rem'}}>
                                                <Typography color="textSecondary"style={{fontSize:.95+'rem'}}  align="center">
                                                    H Inicio
                                                </Typography>
                                                </Box>
                                            </Box>
                                            <Box ml={1} mt={1} display="flex" flexDirection="row" flexWrap="nowrap">
                                                <Tooltip 
                                                    title= {  usuarioContxt?.nivel !=undefined && usuarioContxt.nivel!== nvlSNPermiso 
                                                        ? "Asignar Hora Cierre":''}>
                                                <Typography className={classes.title4}  variant="h5" component="h2" 
                                                    align="center"  style={{color:auxColor2}} onClick={() => findNumber('|10|') ? editarCierre() : ''} > 
                                                    
                                                    { HorarioFin.substring(0,5)}
                                                </Typography>                      
                                                </Tooltip>                                       
                                                <Box ml={1} style={{paddingTop:.15+'rem'}} >
                                                <Typography color="textSecondary" style={{fontSize:.95+'rem'}} align="center">
                                                    H Termino
                                                </Typography>
                                                </Box>
                                            </Box>                                         
                                        </Box>                                                 
                                    </Box>     
                                    { Incidencia!=="" ?
                                        <div style={{fontSize:1+'rem',marginLeft:1.5+'rem',fontWeight:400,marginRight:1+'rem'}}  >
                                            <span style={{color:auxColor2,fontWeight:700}}>Incidencia </span> {' '+ Incidencia}                                            
                                        </div>                                        
                                    :null} 
                                    {   turnoBit.length!==0 && turnoBit?.bitacoras.length!==0 ?
                                            <span>
                                                {turnoBit.bitacoras[0].Turno1!=="" ?
                                                <div style={{fontSize:1+'rem',marginLeft:1.5+'rem',fontWeight:400,marginRight:1+'rem'}}  >
                                                    <span style={{color:auxColor2,fontWeight:700}}>
                                                        Turno 1 </span> {' '+ turnoBit.bitacoras[0].Turno1}                                            
                                                </div>
                                                :null}
                                                {turnoBit.bitacoras[0].Turno2!==""?
                                                <div style={{fontSize:1+'rem',marginLeft:1.5+'rem',fontWeight:400,marginRight:1+'rem'}}  >
                                                    <span style={{color:auxColor2,fontWeight:700}}>
                                                        Turno 2 </span> {' '+ turnoBit.bitacoras[0].Turno2}                                            
                                                </div>
                                                :null}
                                        </span>                                   
                                    :null}
                                                                                                                               
                                </CardContent>     
                             
                            </Card>
                        </Box>
                        <Box  mb={.5}>
                        {findNumber('|11|') ?
                        <Card >
                   
                            <CardContent style={{padding:0,marginTop:.8+'rem',marginBottom:.6+'rem'}} >
                                <Box display="flex" flexDirection="row" justifyContent="center">
                                    <Box  >                                            
                                        { usuarioContxt?.nivel !=undefined && usuarioContxt.nivel!== nvlSNPermiso && findNumber('|6|') ? 
                                            //(<NuevoParoFalla titulo={"Nuevo Paro ó Falla"} />    )
                                            <IconButton color="inherit"  aria-label="add circle" 
                                                component="span" onClick={abrirNvoParo}
                                            >
                                                <AddCircleOutlineIcon color="primary" />
                                            </IconButton>
                                        : null                                              
                                        }                                             
                                    </Box>                                                     
                                    <Box >
                                    {findNumber('|7|') ?
                                        <IconButton color="inherit"  aria-label="add circle" 
                                            component="span" onClick={incrementarBuscar}
                                        >
                                            <SearchIcon color="primary" />
                                        </IconButton>                                                                                                                              
                                        : null}
                                    </Box>                                             
                                    <Box mr={2}>
                                        <FormControl className={classes.formControl} size="small" 
                                            style={{ marginRight: 8 }} disabled={findNumber('|7|') ? false : true} >
                                            <InputLabel htmlFor="age-native-simple">Terminal</InputLabel>
                                            <MaterialSelect
                                                native value={Bucle} onChange={cambiarBucle}
                                                inputProps={{ name: 'Bucle', id: 'Bucle', }}
                                            >
                                                <option aria-label="None" value="" />
                                                <option value={1}>Bucle A</option>
                                                <option value={2}>Bucle B</option>     
                                                <option value={3}>Bucle C</option>                                                                                        
                                            </MaterialSelect>
                                        </FormControl>                                                                
                                    </Box>                                        
                                    <Fechas   finicio={finicio} fFinal={fFinal}                                        
                                        setFinicio={setFinicio}  setFFinal={setFFinal}/>                                                                                                                         
                                    <Box mr={1} component="span">
                                        <TextField id="outlined-basic" name="folioSelecc"    
                                            style={{ width: 10+"rem" }}
                                            label="Folio" variant="outlined" size="small"
                                            value={folioSelecc} onChange={onChange}        
                                            disabled={findNumber('|7|') ? false : true}                                                                                 
                                        />                                                                                                                            
                                    </Box>   
                                    <Box alignContent={"center"}>
                                        <Button onClick={abrirModalTurno}variant="outlined" 
                                            color="primary" 
                                            disabled={turnoBit.length !== 0 && findNumber('|52|') ? false : true}
                                        > 
                                        Turno</Button >                                        
                                    </Box>  
                                </Box>                                                                                                        
                            </CardContent>                                
                        </Card>
                        : null}
                        </Box>
                        <Box ml={0.5} >
                            {   findNumber('|11|') ?  <TablaParo folioSelecc={folioSelecc} buscar={buscar}
                                finicio={finicio} fFinal={fFinal} Bucle={Bucle} recargaTabla={incrementarBuscar}                                        
                                usuarioContxt={usuarioContxt} nvlSNPermiso={nvlSNPermiso}
                            />  : null}                  
                        </Box>
                    </Box>              
                    
                    {totales.length !== 0? 
                        <LadoDerecho paroTotalHora={totales.paroTotalHora}  tParos={totales.paroRegistro}                        
                            enservicio={recortaPorcentaje(totales.totalEficiente) } 
                            paro ={recortaPorcentaje(totales.totalParo)}  />
                    :null}

                    {modal}
                    { modal2 }    
                    {modNvoParo}              
                    {modNvoTurno}                      
                </Box>                
            </Box> 
        </React.Fragment>
    );
}
 
export default ParosFllasPanel;