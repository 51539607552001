import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Box,Collapse,IconButton,Paper,Table,TableBody,TableCell,LinearProgress,
        TableContainer,TableHead,TableRow,} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import Editar from './EditOrden'
import EditarOrdenDummy from './EditarOrdenDummy'
import CreateIcon from '@material-ui/icons/Create';
import {ErrorGeneral, llamaApiCargaToken} from '../funciones/ClienteAxios';
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    //maxHeight: 550,
    maxHeight: window.innerHeight-170,
    minHeight: 280,
    minWidth:'100%',
  },
  container2: {
    maxHeight: 550,
    //maxHeight: window.innerHeight-170,
    //minHeight: 280,
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: "#51ad32"
    }
  },
  tableCell: {
    "$selected &": {
      color: "white"
    }
  },
  hover: {},
  selected: {}
});

export default function TablaOrden({setOrdenSeleccionada,orden,fechasSeleccionadas,buscar,setBuscar,turno,usuarioContxt,Bucle,estatus,funcionActuliza}) {
  const classes = useStyles();
  let history = useHistory();
  const [selectedID, setSelectedID] = useState(null);
  const [open, setOpen] = React.useState(false);     
  const [espera,setEspera]=useState(false);
  const [ordenSelecc, setOrdenSelecc] = React.useState(0);
  const [seleccionadoEdit, setSeleccionadoEdit] = React.useState([]);
  const [modalAbierto, setModalAbierto] = React.useState(false);

  const [datos, guardarDatos] = React.useState([])  
  const[sinDatos,guardarSinDatos]= React.useState(true)
  let nvlSNPermiso ='5'      
  React.useEffect(()=>{ 
    const source = axios.CancelToken.source();    
    let fInicio=moment(fechasSeleccionadas.inicio).format("YYYY-MM-DD")
    let fFinal=moment(fechasSeleccionadas.final).format("YYYY-MM-DD")  
    const llenaListTabla = () => {
      setOrdenSeleccionada([])
      let data = qs.stringify({
          terminal: Bucle,
          fechaIni: fInicio + 'T00:00:00',
          fechaFin: fFinal + 'T23:59:59',
          status: estatus,
          idDispositivo: '89y7gttvjhj98h'
      });
      let url = "/mtto-lista-intervalo-filtro";

      function respuestaListTabla(aux) {
          if (aux[0].Id !== -1) {
              guardarSinDatos(true)
              guardarDatos(aux)
          } else {
              guardarSinDatos(false)
          }
      }
      llamaApiCargaToken(data, url, respuestaListTabla, ErrorGeneral, setEspera, history, source);
    };
      
    llenaListTabla();      
    return ()=> {
      source.cancel();
    }                

},[buscar]);
const seleccionado=(auxId,auxOrden)=>{
  setSelectedID(auxId)
  //setOpen(false)
  setOrdenSeleccionada(auxOrden)
  //console.log(auxOrden);
}
const OrdenarFecha =(auxfecha)=>{
  let fecha = moment(auxfecha);      
  let modificada= fecha.format("DD/MM/YYYY");
  return modificada
}
function editarFall(auxFalla) {
  setSeleccionadoEdit(auxFalla)
 //console.log(auxFalla);
  setModalAbierto(true)
}
   
  function verMas(auxId) {    

    if (auxId === ordenSelecc) 
    {
      setOpen(!open)
    }
    else
    {
      setOpen(true)
    }    
    //console.log(open);
    setOrdenSelecc(auxId)
  }
  function tipoEstado(estado,idSelecc)
  { let auxEtiqueta=''
    let auxColor=''
    if (estado==0) 
    {
      auxEtiqueta='Registrado'
      auxColor='#F46239'
    }
    else
    {
      if (estado==1) 
      {
        auxEtiqueta='Solucionado'
        auxColor='#049b4f'
      }
      else
      {
        if (estado == -1) 
        {
          auxEtiqueta='Cancelado'
          auxColor='red'
        } 
      }
    }
    let renglon=<div></div>
    selectedID === idSelecc?
    renglon=<TableCell align="center" style={{ color:'white',fontWeight:700 ,width:100+'px'}} padding={'none'}>
              {auxEtiqueta}
            </TableCell>:
    renglon=<TableCell align="center" style={{ color:auxColor,fontWeight:700,width:100+'px'}} padding={'none'}>{auxEtiqueta}</TableCell>
    return renglon
  }
function Row(props) {
  const { row } = props;
  
  return (
    <React.Fragment>
      <TableRow onClick={ () => { seleccionado(row.Id,row); } }
        className={classes.tableRow} selected={ selectedID === row.Id}
        classes={{ hover: classes.hover, selected: classes.selected }}        
      >
        <TableCell align="center" padding={'none'} style={{ width:30+'px'}} >
          <IconButton color="primary"  aria-label="expand row" size="small" onClick={() => verMas(row.Id)}>
            { open && ordenSelecc=== row.Id ?( selectedID === row.Id?<KeyboardArrowUpIcon style={{color:'white'}} />:
                                                  <KeyboardArrowUpIcon  />) : 
              selectedID === row.Id ? <KeyboardArrowDownIcon style={{color:'white'}}  />:<KeyboardArrowDownIcon  />}            
          </IconButton>
        </TableCell>
        <TableCell align="center" padding={'none'} style={{ width:30+'px'}} >
          <IconButton   aria-label="add circle" size="small" onClick={() => editarFall(row)} component="span">
          {selectedID === row.Id ? <CreateIcon style={{color:'white'}} />:<CreateIcon color="primary"/>}
          </IconButton>                                                                                                                                                                          
        </TableCell>      
        <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ fontWeight:700,width:100+'px'}} >
          {row.Id}
        </TableCell>          
        <TableCell  className={classes.tableCell} align="left" padding={'none'} style={{ width:75+'px'}} >
            {moment.utc(row.fecha).local(true).format("DD/MM/YYYY")}
        </TableCell>        
        {tipoEstado(row.Status,row.Id)}     
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5} >
          <Collapse in={open && ordenSelecc=== row.Id} timeout="auto" unmountOnExit>
          <Box >                
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow color="primary">
                    <TableCell style={{ color:'#51ad32'}} padding={'none'} >Ubicación</TableCell>                       
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={row.Id}>
                    <TableCell align="left" padding={'none'} >
                      {row.ubicDescr }
                    </TableCell>                    
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
            
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
    );
  }
  function tablaCompleta(auxlista){
    //console.log("entrando sin preguntar");
    return(
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>            
              <TableCell align="center"></TableCell> 
              <TableCell align="center">{auxlista.length}</TableCell>            
              <TableCell align="center">Mtto </TableCell>            
              <TableCell align="left">Fecha</TableCell>
              <TableCell align="center">Estatus</TableCell>                    
            </TableRow>
          </TableHead>
          <TableBody>
            {auxlista.length!=0 ? (auxlista.map((row) => (
                <Row key={row.Id} row={row} />
            ))):null}
          </TableBody>
        </Table>
      </TableContainer>
   )
  }
  const tablaSinDatos=()=>{
   return(<TableContainer className={classes.container2}>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>            
          <TableCell align="center">Mtto </TableCell>            
          <TableCell align="left">Fecha</TableCell>
          <TableCell align="left">Estatus</TableCell>                                           
        </TableRow>
      </TableHead>
      <TableBody>
      <TableRow //className={classes.root}         
          className={classes.tableRow}
        >          
          <TableCell  className={classes.tableCell} align="center">Dia</TableCell>          
          <TableCell  className={classes.tableCell} align="left">Sin</TableCell>             
          <TableCell  className={classes.tableCell} align="left">Reportes</TableCell>   
                   
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
  ) 
  }
  const tablaSinResultados=()=>{
    return(<TableContainer className={classes.container2}>
     <Table stickyHeader aria-label="sticky table">
       <TableHead>
         <TableRow>            
            <TableCell align="center">Mtto </TableCell>            
            <TableCell align="left">Fecha</TableCell>
            <TableCell align="left">Estatus</TableCell>                    
         </TableRow>
       </TableHead>
       <TableBody>
       <TableRow //className={classes.root}         
           className={classes.tableRow}
         >          
           <TableCell  className={classes.tableCell} align="center">Mtto</TableCell>          
           <TableCell  className={classes.tableCell} align="left">Sin</TableCell>             
           <TableCell  className={classes.tableCell} align="left">coincidencias</TableCell>                  
         </TableRow>
       </TableBody>
     </Table>
   </TableContainer>
   ) 
   }
  function filtro()
  {        
    let info=[]      
    if (turno.length!=0) 
    { let auxCadena=turno.trim();       
      info=datos.filter((dat)=>dat.turno.toString().search(auxCadena.toUpperCase() ) !== -1)   
     // console.log(info);
    } 
    else 
    {
      if (orden.length!=0) 
      { let auxCadena=orden.trim();
        info=datos.filter((dat)=>dat.Id.toString().search(auxCadena) !== -1)           
        
      }
    }
    //let info=datos.filter((dat)=>dat.Id.toString().search(auxCadena.toUpperCase() ) !== -1)         
    let metodos=null
    if (info.length>0) {
      //console.log("mandandando la lista");
      metodos= tablaCompleta(info)
    } else {
      metodos=tablaSinResultados()
    }
    return metodos ;
  }
  const contenido2= turno!=''||  orden!=''  ? filtro() :tablaCompleta(datos) 
  const contenido = sinDatos&&datos.length!=0 ?contenido2:tablaSinDatos()
  const tabla = espera ?  (<Box><LinearProgress/> </Box>) :( contenido )
  const modal =seleccionadoEdit.length!=0 && modalAbierto  ? 
              (  usuarioContxt?.nivel !=undefined && usuarioContxt.nivel!== nvlSNPermiso ?
                (<Editar titulo={'Editar Mantenimiento '+seleccionadoEdit.Id} modalAbierto={modalAbierto} setModalAbierto={setModalAbierto}
                  seleccionadoEdit={seleccionadoEdit} setBuscar={setBuscar} buscar={buscar}      funcionActuliza={funcionActuliza}            
                />)
              
              :(<EditarOrdenDummy titulo={'Mantenimiento '+seleccionadoEdit.Id} modalAbierto={modalAbierto} setModalAbierto={setModalAbierto}
                 seleccionadoEdit={seleccionadoEdit} setBuscar={setBuscar} buscar={buscar}                 
                />)              
              )        
              :null
  return (
    <Paper className={classes.root}>
      {tabla}
      {modal}
    </Paper>
  );
}