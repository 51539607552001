import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {InputLabel,FormControl,Select as MaterialSelect  ,Box 
  ,RadioGroup,FormControlLabel,Radio,FormLabel
  ,LinearProgress} from '@material-ui/core';  
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Relojes from './RelojeDDrive'
import Modal from './Modal';
import moment from 'moment';
import {toast} from "react-toastify";
import {ErrorGeneral, llamaApiCargaToken, llamadaApiToken} from "../funciones/ClienteAxios";
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        //width: '20ch',
      }, 
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      Textnumero: {
        width: 5+"px",
      },
      formControlFull: {
        //margin: theme.spacing(1),
        //minWidth: 120,
        width:5+"rem"
      },
      alignContent:"center"
    },
  }));

const EditarDrive = ({modalAbierto,setModalAbierto,titulo,nvLectura,setNvLectura,fechDiaSel,seleccionadoEdit,funcionActualiza}) => {
  let history = useHistory();
  const auxColor="#51ad32"
  const idusu= localStorage.getItem('UsuId'); 
  const classes = useStyles();
  const [espera,setEspera]= useState(false);
  const [mensaje, setMensaje] = useState('');
  const [tipoAdvertencia, setTipoAdvertencia] = useState('success');
  const [aviso, setAviso] = useState(false);
  const [datosAfectacion, setDatosAfectacion] = useState([]);
  const [nvLecturaDriv,guardarNvLecturaDriv]= useState({DrivHorario:new Date(), DrivTemAmb:0.0, DrivVelSistem:0.0,
                                                        DrivTempRoda:0.0, DrivTempInv1:0.0, DrivTempInv2:0.0,
                                                        DrivTempInv3:0.0, DrivTempInv4:0.0, DrivDifPreP1P3:0.0,
                                                        DrivDifPreP2P3:0.0, DrivTempMaxDev:0.0, DrivTempMinDev:0.0,
                                                        Estado:'0', TerminalSel:0, DriveTipo:0, Corriente1:0.0,
                                                        Corriente2:0.0, Corriente3:0.0, Corriente4:0.0});  
  const {DrivHorario, DrivTemAmb, DrivVelSistem, DrivTempRoda, DrivTempInv1, DrivTempInv2,
        DrivTempInv3, DrivTempInv4, DrivDifPreP1P3, DrivDifPreP2P3, DrivTempMaxDev,
        Corriente1, Corriente2, Corriente3, Corriente4,
        DrivTempMinDev, Estado, DriveTipo}=nvLecturaDriv
  const [listLugarTerminal, guardarListLugarTerminal] = useState([])
  const [listTipo, guardarListTipo] = useState([])
  const source = axios.CancelToken.source();     
  React.useEffect(()=>{ 
    //let auxTerminal= localStorage.getItem('UsuTerminal');      
    
    const{  ValDifPreP1P3,ValDifPreP2P3,ValTemAmb,ValTempInv1,ValTempInv2,ValTempInv3
            ,ValTempInv4,ValTempMaxDev,ValTempMinDev,ValTempRoda,ValVelSistem,TermId,tpLectId,
            ValCorriente1, ValCorriente2, ValCorriente3, ValCorriente4}=seleccionadoEdit                             
    
    const llenarListEdoAfec = () => {
      const data = qs.stringify({
          idDispositivo: '987gyubjhkkjh'
      });
      const url = "/drive-edoafectacion";
  
      function respuesta(aux) {
        if (aux[0].Id != -1) 
        {  // guardarSinDatos(true)                                            
            setDatosAfectacion(aux)
            let auxEdoAfec = aux.filter(edoAfecta => edoAfecta.Tipo ==seleccionadoEdit.Tipo)
            
          guardarNvLecturaDriv({
          ...nvLecturaDriv,
          DrivHorario:moment.utc("2021-06-17T"+ seleccionadoEdit.Horario ).local(true), //'Thu Jun 17 2021 '+seleccionadoEdit.Horario+' GMT-0500' ,
          Estado:auxEdoAfec[0].Id.toString(),
          DrivTemAmb:ValTemAmb, DrivVelSistem:ValVelSistem, DrivTempRoda:ValTempRoda, DrivTempInv1:ValTempInv1, DrivTempInv2:ValTempInv2 ,
          DrivTempInv3:ValTempInv3, DrivTempInv4:ValTempInv4, DrivDifPreP1P3:ValDifPreP1P3, DrivDifPreP2P3:ValDifPreP2P3,
          DrivTempMaxDev:ValTempMaxDev, DrivTempMinDev:ValTempMinDev, TerminalSel:TermId, DriveTipo:tpLectId,
          Corriente1:ValCorriente1, Corriente2:ValCorriente2, Corriente3:ValCorriente3, Corriente4:ValCorriente4
        })    
        }else{
        // guardarSinDatos(false)
        }            
      }
      llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);
    };         
  
    if (listLugarTerminal.length==0) 
    {
      llenarListTerminal();  
    }
    if (listTipo.length==0) 
    {
      llenarListTipoDrive()
    }
    llenarListEdoAfec();
  
     return ()=> {
         source.cancel();
     }     
    
  },[]);

  const llenarListTipoDrive = () => {
    const data = qs.stringify({        
        idDispositivo: '987gyubjhkkjh'
    });
    const url = "/drive-tipo";

    function respuesta(aux) {
      if (aux[0].Id!=-1) 
      {//console.log(aux);
        let auxArre=[];   
        aux.map((tipo) => {
          if (tipo.Id!=0) 
          {
            auxArre.push({ value : tipo.Id, label :tipo.Tipo })  
          }            
        })                    
        guardarListTipo(auxArre);                                      
      }              
    }
    llamadaApiToken(data, url,respuesta,ErrorGeneral,history,source);
  };

  const llenarListTerminal = () => {
    const data = qs.stringify({
      usuario:idusu,
      idDispositivo: '987gyubjhkkjh'
    });
    const url = "/lugar-terminal-list";

    function respuesta(aux) {
      if (aux[0].Id!=-1) 
      {
        let auxArre=[];   
        aux.map((tipo) => {
          if (tipo.Id!=0) 
          {
            auxArre.push({ value : tipo.Id, label :tipo.Descr })  
          }            
        })                    
        guardarListLugarTerminal(auxArre);                                      
      }                 
    }
    llamadaApiToken(data, url,respuesta,ErrorGeneral,history,source);
  };

  const registrarNVLectura=(auxHorario)=>{     
    let mensaje=""
    setTipoAdvertencia("success")      
    setMensaje(mensaje);
    setAviso(false)          
    const llenarNvLect = () => {
      const idusu= localStorage.getItem('UsuId'); 
      //let NomUsu= localStorage.getItem('UsuarioNom');   
      const data = qs.stringify({
        llave:seleccionadoEdit.Id,
        EdoAfec:Estado,
        Usuario:idusu,
        Horario:auxHorario,
        TempAmbiente:DrivTemAmb,
        VelSist:DrivVelSistem,
        TempRoda:DrivTempRoda,
        TempInv1:DrivTempInv1,
        TempInv2:DrivTempInv2,
        TempInv3:DrivTempInv3,
        TempInv4:DrivTempInv4,
        DifPreP1P3:DrivDifPreP1P3,
        DifPreP2P3:DrivDifPreP2P3,
        TempMaxDev:DrivTempMaxDev,
        TempMinDev:DrivTempMinDev,
        Tipo:DriveTipo,
        Corriente1:Corriente1,
        Corriente2:Corriente2,
        Corriente3:Corriente3,
        Corriente4:Corriente4,
        idDispositivo:'frvdikmv67tujm'
      });
      let url = "/drive/v2/edit";
    
      function respuesta(aux) {
        if (aux.respuesta==1) 
        { //console.log(aux);
          let mensaje="Se registro correctamente la Lectura"
          setTipoAdvertencia("success")      
          setMensaje(mensaje);
          setAviso(true)      
          toast.success(mensaje, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }); 
          funcionActualiza()
          setModalAbierto(false)        
        } 
      }
      llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);
    };    
   
   llenarNvLect();    

  }

  const onChange = e =>
  { let expNopermitida = new RegExp('[#A-Za-z_:$!%-({})/*=?|]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[0-9]');
    if (expCadena.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&
        !expMas.test(e.target.value)||e.target.value===' '||e.target.value==='') 
    {
      guardarNvLecturaDriv({
        ...nvLecturaDriv,
        [e.target.name] : e.target.value  
      })
      if (aviso) 
      {
        setMensaje('');
        setAviso(false);
      }
    }
  };

  const onChange2 = e =>
  { let expNopermitida = new RegExp('[#_:$!%-({})/*=?|]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[A-Za-z0-9]');
    if (expCadena.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&
        !expMas.test(e.target.value)||e.target.value===' '||e.target.value==='') 
    {
      guardarNvLecturaDriv({
        ...nvLecturaDriv,
        [e.target.name] : e.target.value  
      })
      if (aviso) 
      {
        setMensaje('');
        setAviso(false);
      }
    }
  };
  
  function valNumero(auxNumero,auxCampo) {
    let valida=false
    //console.log(auxNumero);
    if (typeof parseInt(auxNumero) === 'number') 
    {
      if (parseInt(auxNumero) ===0) 
      {
        valida=true
      }  
      else{
        if (parseInt(auxNumero) >0) 
        {  
          valida=true
        } 
      }
    }
    if (!valida) {
      setTipoAdvertencia("warning")
      let mensaje="Llenar campo "+auxCampo            
      setMensaje(mensaje);
      setAviso(true)  
    }
    return valida
  }

  const guardTerminal = e =>
  {
    guardarNvLecturaDriv({
      ...nvLecturaDriv,
      [e.target.name] : e.target.value  
    })
  };

  const guardarDatos = () => 
  {
    if (DrivHorario!= '' &&DrivHorario!= '0' && DrivHorario!= null && moment(DrivHorario).format("HH:mm")!='00:00' ) 
    { if (DriveTipo != ''&&DriveTipo != '0') 
      {
        if (Estado != ''&&Estado != '0') 
        { if (valNumero(DrivTemAmb, "Temperatura Ambiente") &&valNumero(DrivVelSistem,"Referencia de Velocidad del Sistema" )  ) 
          {            
            if (valNumero( DrivTempRoda,"Temperatura de Rodamiento del Motor") && valNumero(DrivTempInv1,"Temperatura Inverter LeitDrive 1")   ) 
            {               
              if (valNumero(DrivTempInv2,"Temperatura Inverter LeitDrive 2") && valNumero(DrivTempInv3,"Temperatura Inverter LeitDrive 3")  ) 
              {              
                if (valNumero(DrivTempInv4,"Temperatura Inverter LeitDrive 4") && valNumero(Corriente1,"Corriente 1")    
                  && valNumero(Corriente2,"Corriente 2") && valNumero(Corriente3,"Corriente 3") 
                  && valNumero(Corriente4,"Corriente 4") && valNumero(DrivDifPreP1P3,"Dif. Presión P1-P3")   ) 
                {                
                  if (valNumero(DrivDifPreP2P3 ,"Dif. Presión P2-P3") && valNumero(DrivTempMaxDev,"Temperatura Máxima Instantánea Devanados Motor")   ) 
                  {                  
                    if (valNumero(DrivTempMinDev,"Temperatura Mínima Instantánea Devanados Motor")  ) 
                    { let fec = moment(DrivHorario);                                                                                               
                      registrarNVLectura(fec.format("HH:mm"))                       
                    }                                                                       
                  }                                             
                }                                                                                       
              }                                                      
            }           
          }         
        }   
        else
        { setTipoAdvertencia("warning")
          let mensaje="Seleccionar Estado de Afectación"            
          setMensaje(mensaje);
          setAviso(true)   
        }   
      } 
      else 
      { setTipoAdvertencia("warning")
        let mensaje="Seleccionar Tipo"            
        setMensaje(mensaje);
        setAviso(true)   
      }
    } 
    else 
    { setTipoAdvertencia("warning")
      let mensaje="Seleccionar Horario de Inicio"            
      setMensaje(mensaje);
      setAviso(true)   
    }                           
  };

  const guardaEstado = e =>
  {  
    guardarNvLecturaDriv({
      ...nvLecturaDriv,
      Estado : e.target.value  
    })  
    if (aviso) 
    {
      setMensaje('');
      setAviso(false);
    }      
  };

  return ( 
    <Modal
      titulo={titulo} modalAbierto={modalAbierto}
      setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
      mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
      aviso={aviso} setAviso={setAviso} espera={espera}
      setEspera={setEspera}
    >
      <form className={classes.root} noValidate autoComplete="off"   fullWidth>       
      <Relojes  nvLecturaDriv={nvLecturaDriv} HoraD={false} guardarNvLecturaDriv={guardarNvLecturaDriv}/>   
      {listTipo.length!=0 ?  
        <FormControl className={classes.formControl} size="small" style={{width:5.6+'rem'}}>
          <InputLabel htmlFor="age-native-simple">Tipo</InputLabel>
          <MaterialSelect
            native value={DriveTipo}
            onChange={guardTerminal}
            inputProps={{ name: 'DriveTipo', id: 'DriveTipo', }}
          >
            <option aria-label="None" value="" />      
            {listTipo.map((row) => (
                <option value={row.value}>{row.label}</option>
            ))}   
          </MaterialSelect>
        </FormControl>     
      :null}            
      {datosAfectacion.length!=0? 
        <FormControl component="fieldset" style={{width:30+'rem'}}>      
        
          <FormLabel component="legend" style={{color:auxColor,fontWeight:500}}>Estado Afectación</FormLabel>      
          <RadioGroup   row   aria-label="position" //name="estatus"row
            defaultValue="top"   name="Estado" value={Estado} onChange={guardaEstado}
          >            
            {datosAfectacion.map((nvAfectacion)=>{
            return( 
            <span> 
              <FormControlLabel value={nvAfectacion.Id.toString()} style={{color:auxColor}} 
                control={<Radio color="primary" />} label={nvAfectacion.Tipo} />    
              <br/>
            </span>)
          })}                        
          </RadioGroup>                     
        </FormControl>     
      :<LinearProgress color="primary" />}    
      { /*listLugarTerminal.length!=0 ?  
    <FormControl className={classes.formControl} size="small">
      <InputLabel htmlFor="age-native-simple">Terminal</InputLabel>
      <MaterialSelect
        native
        value={TerminalSel}
        onChange={guardTerminal}
        inputProps={{
            name: 'TerminalSel',
            id: 'TerminalSel',
        }}
      >
        <option aria-label="None" value="" />         
        {listLugarTerminal.map((row) => (
            <option value={row.value}>{row.label}</option>
          ))}   
      </MaterialSelect>
    </FormControl>     
    :null*/}   
      
      <Box  display="flex" flexDirection="row" style={{width:32+'rem'}}  >                
        <Box >
        
        <Box display="flex" flexDirection="row"  style={{width:31+'rem'}}>
          <span style={{paddingRight:2+'rem',width:26+'rem',color:auxColor,fontWeight:600}}>
              Temperatura Ambiente
          </span>           
          <input type="text" id="input-tempAmb" value={DrivTemAmb} 
            onChange={onChange} name="DrivTemAmb" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}/>
        </Box>
        <Box display="flex" flexDirection="row"  style={{width:31+'rem',paddingTop:.4+'rem'}}>
          <span style={{paddingRight:2+'rem',width:26+'rem',color:auxColor,fontWeight:600}}>
            Valor Referencia de Velocidad del Sistema
          </span>           
          <input type="text" id="input-velSistem" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}
          value={DrivVelSistem} onChange={onChange} name="DrivVelSistem" />
        </Box>
        <Box display="flex" flexDirection="row"  style={{width:31+'rem',paddingTop:.4+'rem'}}>
          <span style={{paddingRight:2+'rem',width:26+'rem',color:auxColor,fontWeight:600}}>
            Temperatura de Rodamiento del Motor
          </span>           
          <input type="text" id="input-DrivTempRoda" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}
          value={DrivTempRoda} onChange={onChange} name="DrivTempRoda" />
        </Box>
        <Box display="flex" flexDirection="row"  style={{width:31+'rem',paddingTop:.4+'rem'}}>
          <span style={{paddingRight:2+'rem',width:26+'rem',color:auxColor,fontWeight:600}}>
            Temperatura Inverter LeitDrive 1
          </span>           
          <input type="text" id="input-DrivTempInv1" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}
            value={DrivTempInv1} onChange={onChange} name="DrivTempInv1" />
        </Box>
        <Box display="flex" flexDirection="row"  style={{width:31+'rem',paddingTop:.4+'rem'}}>
          <span style={{paddingRight:2+'rem',width:26+'rem',color:auxColor,fontWeight:600}}>
            Temperatura Inverter LeitDrive 2
          </span>           
          <input type="text" id="input-DrivTempInv2" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}
            value={DrivTempInv2} onChange={onChange} name="DrivTempInv2"/>
        </Box>
        <Box display="flex" flexDirection="row"  style={{width:31+'rem',paddingTop:.4+'rem'}}>
          <span style={{paddingRight:2+'rem',width:26+'rem',color:auxColor,fontWeight:600}}>
            Temperatura Inverter LeitDrive 3
          </span>           
          <input type="text" id="input-DrivTempInv3" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}
            value={DrivTempInv3} onChange={onChange} name="DrivTempInv3"/>
        </Box>
        <Box display="flex" flexDirection="row"  style={{width:31+'rem',paddingTop:.4+'rem'}}>
          <span style={{paddingRight:2+'rem',width:26+'rem',color:auxColor,fontWeight:600}}>
            Temperatura Inverter LeitDrive 4
          </span>           
          <input type="text" id="input-DrivTempInv4" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}
            value={DrivTempInv4} onChange={onChange} name="DrivTempInv4"/>
        </Box>
        <Box display="flex" flexDirection="row"  style={{width:31+'rem',paddingTop:.4+'rem'}}>
          <span style={{paddingRight:2+'rem',width:26+'rem',color:auxColor,fontWeight:600}}>
            Corriente 1
          </span>           
          <input type="text" id="input-Corriente1" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}
            value={Corriente1} onChange={onChange} name="Corriente1"/>
        </Box>
        <Box display="flex" flexDirection="row"  style={{width:31+'rem',paddingTop:.4+'rem'}}>
          <span style={{paddingRight:2+'rem',width:26+'rem',color:auxColor,fontWeight:600}}>
            Corriente 2
          </span>           
          <input type="text" id="input-Corriente2" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}
            value={Corriente2} onChange={onChange} name="Corriente2"/>
        </Box>
        <Box display="flex" flexDirection="row"  style={{width:31+'rem',paddingTop:.4+'rem'}}>
          <span style={{paddingRight:2+'rem',width:26+'rem',color:auxColor,fontWeight:600}}>
            Corriente 3
          </span>           
          <input type="text" id="input-Corriente3" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}
            value={Corriente3} onChange={onChange} name="Corriente3"/>
        </Box>
        <Box display="flex" flexDirection="row"  style={{width:31+'rem',paddingTop:.4+'rem'}}>
          <span style={{paddingRight:2+'rem',width:26+'rem',color:auxColor,fontWeight:600}}>
            Corriente 4
          </span>           
          <input type="text" id="input-Corriente4" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}
            value={Corriente4} onChange={onChange} name="Corriente4"/>
        </Box>
        <Box display="flex" flexDirection="row"  style={{width:31+'rem',paddingTop:.4+'rem'}}>
          <span style={{paddingRight:2+'rem',width:26+'rem',color:auxColor,fontWeight:600}}>
            Dif. Presión P1-P3
          </span>           
          <input type="text" id="input-DrivDifPreP1P3" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}
            value={DrivDifPreP1P3} onChange={onChange} name="DrivDifPreP1P3" />
        </Box>
        <Box display="flex" flexDirection="row"  style={{width:31+'rem',paddingTop:.4+'rem'}}>
          <span style={{paddingRight:2+'rem',width:26+'rem',color:auxColor,fontWeight:600}}>
            Dif. Presión P2-P3
          </span>           
          <input type="text" id="input-DrivDifPreP2P3" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}
            value={DrivDifPreP2P3} onChange={onChange} name="DrivDifPreP2P3"  />
        </Box>
        <Box display="flex" flexDirection="row"  style={{width:31+'rem',paddingTop:.4+'rem'}}>
          <span style={{paddingRight:2+'rem',width:26+'rem',color:auxColor,fontWeight:600}}>
            Temperatura Máxima Instantánea Devanados Motor
          </span>           
          <input type="text" id="input-DrivTempMaxDev" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}
            value={DrivTempMaxDev} onChange={onChange} name="DrivTempMaxDev" />
        </Box>
        <Box display="flex" flexDirection="row"  style={{width:31+'rem',paddingTop:.4+'rem'}}>
          <span style={{paddingRight:2+'rem',width:26+'rem',color:auxColor,fontWeight:600}}>
            Temperatura Mínima Instantánea Devanados Motor
          </span>           
          <input type="text" id="input-DrivTempMinDev" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}
            value={DrivTempMinDev} onChange={onChange} name="DrivTempMinDev"  />
        </Box>

        </Box>
        </Box>
      </form>
    </Modal>
  );
}
 
export default EditarDrive;