import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {InputLabel,FormControl,Select as MaterialSelect  ,Box 
  ,FormControlLabel,LinearProgress,Typography,RadioGroup,Radio,FormLabel} from '@material-ui/core';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import {ErrorGeneral, llamaApiCargaToken, llamadaApiToken} from "../funciones/ClienteAxios";
import Modal from './Modal';
import Rating from '@material-ui/lab/Rating';
import {toast} from "react-toastify";
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '20ch',
      }, 
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      Textnumero: {
        width: 5+"px",
      },
      formControlFull: {
        //margin: theme.spacing(1),
        //minWidth: 120,
        width:5+"rem"
      },
      margin: {
        margin: theme.spacing(1),
      },
    },
  }));

const EditarActividad = ({modalAbierto,setModalAbierto,titulo,auxActividad,funcionActuliza}) => {
  let history = useHistory();
  const classes = useStyles();
  const source = axios.CancelToken.source();        
  const [espera,setEspera]= useState(false);
  const [mensaje, setMensaje] = useState('');
  const [tipoAdvertencia, setTipoAdvertencia] = useState('success');
  const [aviso, setAviso] = useState(false);  
  const [nvActividad,guardarNvActividad]= useState({Orden:0,Tipo:0,Estado:1,Finaliza:0,
                                                          Prioridad:0,Descripcion:'',Solucion:'',ActividadId:0});  
  const {Orden,Tipo,Estado,Finaliza,Prioridad,Descripcion,Solucion,ActividadId}=nvActividad  
  const [listTipo, guardarListTipo] = useState([])
  const [listStatusActivid, guardarListStatusActivid] = useState([])
  //const [value, setValue] = React.useState(0);
  React.useEffect(()=>{           
    //console.log(auxActividad);
    const{mttoId,Id,descr,prioridad,solucion,tipoId,statusId}=auxActividad
    let auxFinaliza=solucion.length!=0?true:false
    guardarNvActividad({
      ...nvActividad,
      Orden:mttoId,ActividadId:Id,Descripcion:descr,Prioridad:prioridad,
      Solucion:solucion,Tipo:tipoId,Estado:statusId.toString(),Finaliza:auxFinaliza
    })              
    const llenarListTipo = () => {
      let data = qs.stringify({
          idDispositivo: '987gyubjhkkjh'
      });
      let url = "/mtto-actividad-tipo";

      function respuesta(aux) {
        if (aux[0].Id!=-1) 
        {//console.log(aux);
          let auxArre=[];   
          aux.map((tipo) => {
            if (tipo.Id!== 0) 
            {
              auxArre.push({ value : tipo.Id, label :tipo.Tipo })  
            }            
          })                    
          guardarListTipo(auxArre);                                      
        }             
      }
      llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);
    };  
    const llenarListStatActiv = () => {
      let data = qs.stringify({
          idDispositivo: '987gyubjhkkjh'
      });
      let url = "/mtto-actividad-status";
      function respuesta(aux) {
        if (aux[0].Id!=-1) 
        { //console.log(aux);
          let auxArre=[];   
          aux.map((tipo) => {
            if (tipo.Id!==0) 
            {
              auxArre.push({ value : tipo.Id, label :tipo.Tipo })  
            }            
          })                    
          guardarListStatusActivid(aux);                                      
        }                        
      }
      llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);
    };  
        
  if (listTipo.length==0) 
  {
    llenarListTipo()
  }  
  if (listStatusActivid.length==0) 
  {
    llenarListStatActiv()
  }  
  
  return ()=> {
    source.cancel();
  }     
    
},[]);

  const registrarEditActividad=(auxEstatus)=>{   
    const llenarNvActi = () => {
      let idusu= localStorage.getItem('UsuId'); 
      let NomUsu= localStorage.getItem('UsuarioNom');  
      let data = qs.stringify({
          ActividadId:ActividadId,                    
          Mtto:Orden,
          Usuario:idusu,
          Tipo:Tipo,
          Status:auxEstatus,
          Prioridad:Prioridad,
          Descripcion:Descripcion,
          Solucion:Solucion,
          FchSolucion:' ',
          UsrFinaliza:Estado==="3"?NomUsu:"",
          idDispositivo:'8978yuhjnfrsdsd'
      });
      let url = "/mtto-actividad-edit-1-1";
    
      function respuesta(aux) {
        if (aux.respuesta==1) 
        { //console.log(aux);
          let mensaje='Se edito correctamente la Actividad'
          setTipoAdvertencia("success")      
          setMensaje(mensaje);
          setAviso(true)              
          toast.success(mensaje, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });                
          funcionActuliza()
          setModalAbierto(false)      
        }    
      }
      llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);
    };       
   
    llenarNvActi();    
  }

  const onChange = e =>
  { let expNopermitida = new RegExp('[#_:$!%-({})/*=?|]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[A-Za-z0-9]');
    if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && !expMenos.test(e.target.value) &&
        !expMas.test(e.target.value) ) || e.target.value===' ' || e.target.value==='') 
    {
    
      guardarNvActividad({
        ...nvActividad,
        [e.target.name] : e.target.value  
      })
      if (aviso) 
      {
        setMensaje('');
        setAviso(false);
      }
    }
  };

  const onChangeDesc = e =>
  {      
    guardarNvActividad({
      ...nvActividad,
      [e.target.name] : e.target.value  
    })
    if (aviso) 
    {
      setMensaje('');
      setAviso(false);
    }    
  };
  
  const mostrarAlertas = (auxMensaje) => {
    setTipoAdvertencia("warning")
    let mensaje = auxMensaje
    setMensaje(mensaje);
    setAviso(true)
  }
  function valNulos(auxMensaje, auxCampo) {
    let valida = false
    if (auxCampo !== '' && auxCampo !== '0' && auxCampo !== 0 && auxCampo !== null && auxCampo !== " ") {
        valida = true
    }
    if (!valida) {
        mostrarAlertas(auxMensaje)
    }
    return valida
  }
  const guardTerminal = e =>
  {
    guardarNvActividad({
      ...nvActividad,
      [e.target.name] : e.target.value  
    })
  };
  
  const guardarDatos = () => 
  {
    if (valNulos(Tipo,"Seleccionar Tipo")  && valNulos(Prioridad, "Seleccionar Prioridad")) 
    { if ( ((Descripcion.length>=5 && valNulos(Descripcion, "Llenar campo de Descripción (mayor a 5 caracteres)") ))
        || (Descripcion.length<5 && valNulos(0, "Llenar campo de Descripción (mayor a 5 caracteres)"))
      ) 
      { if ( valNulos("Seleccionar Estatus",Estado)&&
          ( (Finaliza===1 &&valNulos("Ingrese una Solución", Solucion) )
          || Estado!=="3")) 
        {    
          registrarEditActividad(parseInt(Estado))             
        }          
      }                    
    }                           
  };
  
  const cambioActualiza = (event) => {
    guardarNvActividad({
      ...nvActividad,
        [event.target.name]: event.target.checked 
      });
  };
  const guardarPrioridad = (event) => {
    //console.log(event);
    if (event) {      
      guardarNvActividad({
        ...nvActividad,
        Prioridad : event  
      })
    }
    else
    {
      guardarNvActividad({
        ...nvActividad,
        Prioridad : 0
      })
      //console.log('sin guardar vacio');
    }
    
  };
  const guardaEstado = e =>
  {  
    guardarNvActividad({
      ...nvActividad,
      Estado : e.target.value  ,
      Finaliza: e.target.value==="3"?1:0,
    })  
    if (aviso) 
    {
      setMensaje('');
      setAviso(false);
    }      
  };
  return ( 
    <Modal
      titulo={titulo} modalAbierto={modalAbierto}
      setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
      mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
      aviso={aviso} setAviso={setAviso}
      espera={espera} setEspera={setEspera}
    >
      <form className={classes.root} noValidate autoComplete="off" fullWidth>                     
      {listStatusActivid.length!=0? 
        <FormControl component="fieldset" style={{width:30+'rem'}} size="small">              
          <FormLabel component="legend" style={{color:'#51ad32',fontWeight:500}}>Estatus</FormLabel>      
          <RadioGroup   row   aria-label="position" //name="estatus"row
            defaultValue="top"   name="Estado" value={Estado} onChange={guardaEstado}
          >            
          {listStatusActivid.map((statActiv)=>{
            let auxColor="";
            if (statActiv.Id==0) 
            { auxColor='red'
            } 
            else 
            { if (statActiv.Id==1) 
              { auxColor='#F46239'
              } 
              else 
              {if (statActiv.Id==3) 
                { auxColor='#049b4f'
                }
              } 
            }
            //console.log(statActiv.Id);
           return(
            <span> 
              <FormControlLabel value={statActiv.Id.toString()} style={{color:auxColor}} 
                control={<Radio color="primary" size="small"/>} label={statActiv.Tipo}  
              size="small"/>                     
            </span>)
          })}                        
          </RadioGroup>                     
        </FormControl>     
      :null}    
     
       {listTipo.length!=0 ?
       auxActividad.ordMultiple ===0?  
       <Box display="flex" flexDirection="row">
        <Box mr={5}>  
        <FormControl className={classes.formControl} size="small" style={{width:12+'rem'}}>
          <InputLabel htmlFor="age-native-simple">Tipo</InputLabel>
          <MaterialSelect
            native value={Tipo} onChange={guardTerminal}
            inputProps={{ name: 'Tipo', id: 'Tipo', }}            
          >
            <option aria-label="None" value="" />      
            {listTipo.map((row) => (
                <option value={row.value}>{row.label}</option>
            ))}   
          </MaterialSelect>
        </FormControl>     
        </Box>  
        <Box  borderColor="transparent">
        <span style={{color:process.env.REACT_APP_COLOR_SEC}}  >Prioridad</span>
        <Rating
          name="Prioridad" value={Prioridad} max={3}
          onChange={(e, newValue) => { guardarPrioridad(newValue)}}
          style={{color:process.env.REACT_APP_COLOR}}   
        />
      </Box>
      </Box> 
      :null
      :<LinearProgress color="primary" />}          
      
      <br/>     
      <Box>
        <Typography component="p" variant="body2" color='primary' 
            style={{margin:0,marginLeft:.5+'rem'}} >
            Descripción
        </Typography>
        <FormControl component="fieldset" style={{width:30+'rem'}}>    
          <textarea id="Descripcion-TextArea" name="Descripcion" rows="5"
            cols="100" style={{width: 33.2+"rem",borderRadius:3+'px'}} 
            value={Descripcion} onChange={onChangeDesc} placeholder="Descripción"
          />
        </FormControl>
      </Box>
    {/*   <br/>
        <FormControlLabel
          control={<Checkbox checked={Finaliza} onChange={cambioActualiza} name="Finaliza" />}
          label="Finaliza"
        />
      
      <br/ >*/}
      {Finaliza ||Estado==="3" ?
        <Box>
          <Typography component="p" variant="body2" color='primary' 
            style={{margin:0,marginLeft:.5+'rem'}} >
              Solución
          </Typography> 
          <textarea id="Solucion-TextArea" name="Solucion" rows="5"
            cols="100"style={{width: 33.2+"rem",borderRadius:3+'px'}} 
            value={Solucion} onChange={onChangeDesc}  placeholder="Solución" 
          />
        </Box>
      :null}
      </form>
  </Modal>
  );
}
 
export default EditarActividad;