import React, { useState,useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {  IconButton, Table, TableBody, TableCell, 
        TableContainer, TableHead, TableRow,Checkbox ,Box  } from '@material-ui/core';
import {celda} from '../Generales/TablaGeneral';
import {useHistory} from "react-router-dom";
import CheckPerfil from './CheckPerfil';
import CheckPermisos from './CheckPermisos';
import {CheckCircle,CheckCircleOutline} from '@material-ui/icons/';
import {toast} from "react-toastify";
import qs from 'qs';
import axios from 'axios';
import {ErrorGeneral, llamaApiCargaToken,} from "../funciones/ClienteAxios";
import CircularProgress from '@material-ui/core/CircularProgress';
const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: window.innerHeight - 170,
        minHeight: 280,
        width: '25rem',
     
    },
    container2: {
        maxHeight: 550,
    },
    tableRow: {
        "&$selected, &$selected:hover": {
            backgroundColor: process.env.REACT_APP_COLOR
        }
    },
    tableCell: {
        "$selected &": {
            color: "white"
        }
    },
    hover: {},
    selected: {}
});

export default function TablaPerfilOperacion({  datos,tipo,usrId,llamaApi,tipoNom, tipoSelect, perfilId, listaOriginal,setListOriginal,setPerfil,setTipoSelect}) {    
    let nvlSNPermiso = '5'   
    let history = useHistory();
    const classes = useStyles();
    const [selectedID, setSelectedID] = useState(null);
    const [checked, setChecked] = useState(true);
    const [todo, setTodo] = useState(false);
    const [espera,setEspera]=useState(false);
    const [listTodo,setlistTodo]=useState([]);
    const source = axios.CancelToken.source();
    useEffect(() => {
        let bandera=true
        if (tipo===1) 
        {   let filtrado=datos.filter((aux)=>aux.permisoStatus === 0)
            if (filtrado.length!==0|| datos[0].Id<1) 
            {   bandera=false
            }
        }
        else
        {   let filtrado=datos.filter((aux)=>aux.PerfStatus === 0)
            if (filtrado.length!==0 || datos[0].Id<1) 
            {   bandera=false
            }
        }
        setlistTodo([])
        setTodo(bandera)
        return () => {
            source.cancel();
        }
    }, [datos])   

    const seleccionado = (auxId, auxOrden) => 
    {   setSelectedID(auxId)       
    }

    const handleChange = (event) => 
    {   setChecked(event.target.checked);
    };

    function Row(props) {
        const {row} = props;
        //console.log(row);
        return (        
        <TableRow onClick={() => { seleccionado(row.Id, row) }}
            className={classes.tableRow} selected={selectedID === row.Id}
            classes={{hover: classes.hover, selected: classes.selected}}
        >  
            {celda("left",{},
                  <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />,
                1, classes.tableCell
            )}
            {celda("left", { }, row.Nom, 5, classes.tableCell)}                    
        </TableRow>           
      
        );
    }

    const editPerfil = (nvoArreglo) => {
        let idusu = localStorage.getItem("UsuId");
        let data = qs.stringify({                
            usuario: idusu,      
            permisos:JSON.stringify(nvoArreglo),   
            idDispositivo: 'rjvikcmkcmsdkdcdsmc'
        });

        let url = '/usuario-perfil-edit';

        function response(auxiliar) {
            let mensaje =""
            if (auxiliar.respuesta == 1) 
            {   mensaje = `Se modificaron las operaciones de ${tipoNom}`                                                                                          
            }                                
            toast.success(mensaje, {
                toastId: `edit-user-ope${nvoArreglo[0].id}`,
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            llamaApi()
        }

     
         llamaApiCargaToken( data ,url, response, ErrorGeneral, setEspera, history, source);
    }

    const cambioTodo=(e)=>{
        e.preventDefault()
        setTodo(!todo)
        let arr=[]
        datos.forEach((element)=>{
            arr.push({id:element.PerfId,valor:!todo?1:0})
        })
        setlistTodo(arr)
        editPerfil(arr)        
    }
    
    function tablaCompleta(auxlista) {//usuario
        return (
        <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>                 
                    <TableCell align="center" padding='none'> </TableCell>    
                    <TableCell align="center" padding='none'>Permisos</TableCell>    
                </TableRow>
            </TableHead>
            <TableBody>
                {auxlista.length !== 0 ? 
                    (auxlista.map((row) => (
                        <CheckPermisos key={row.Id} registro={row}  usrId={usrId} 
                            llamaApi={llamaApi} //listaOriginal={listaOriginalPerm}
                        />                    
                    ))) 
                : null}
            </TableBody>
        </Table>
        </TableContainer>
        )
    }
    function tablaCompleta2(auxlista) {//perfil
        return (
        <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>                 
                    <TableCell align="center" padding='none'> 
                        <IconButton size="small" onClick={cambioTodo} component="span" 
                            disabled={auxlista[0].Id<1 ?true:false} aria-label="editar-check" 
                        >
                            { espera ?                             
                                <CircularProgress size={20}/>                             
                            :   (todo ? 
                                    <CheckCircle color='primary' />
                                :   <CheckCircleOutline  /> 
                                )
                            }
                        </IconButton>
                    </TableCell>    
                    <TableCell align="center" padding='none'>Operación</TableCell>    
                </TableRow>
            </TableHead>
            <TableBody>
                {auxlista.length !== 0 ? 
                    (auxlista.map((row) => (
                        <CheckPerfil key={row.Id} registro={row} selectedID={selectedID} 
                            actualizar={llamaApi} listTodo={listTodo}
                            tipoSelect={tipoSelect} perfilId={perfilId} setPerfil={setPerfil}
                            listaOriginal={listaOriginal}    setListOriginal={setListOriginal}
                            setTipoSelect={setTipoSelect}
                        />
                    /*   <Row key={row.Id} row={row}/> */
                    ))) 
                : null}
            </TableBody>
        </Table>
        </TableContainer>
        )
    }

    const tablaSinDatos = () => {
        return (
        <TableContainer className={classes.container2}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>            
                    <TableCell align="left">Operación</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow className={classes.tableRow}>
                    {celda("left", {}, "Sin Perfiles", 1, classes.tableCell)}                 
                </TableRow>
            </TableBody>
        </Table>
        </TableContainer>
        )
    }    
    const tabla=tipo===1?tablaCompleta(datos) :tablaCompleta2(datos) 
    const contenido = datos.length !== 0 ? tabla : tablaSinDatos()

    return (
    <span>
        {contenido}
    </span>
    );
}