import React,{useEffect,useContext,useState} from 'react'
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import { Box } from '@material-ui/core'
import Reporte from './Reporte'
import ReporteSN from './ReporteSNDatos'
import moment from 'moment';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Reloj from './Reloj';
import LinearProgress from '@material-ui/core/LinearProgress';
import {findNumber} from '../funciones/FindNumber'
import {llamadaApiToken,ErrorGeneral , llamaApiCargaToken } from '../funciones/ClienteAxios';
import Disponib from '../Disponi'
const RepoBitacora = () => {
  const history = useHistory();
  const usu= localStorage.getItem('UsuarioNom');
  const nvl= localStorage.getItem('Tipo');            
  const source = axios.CancelToken.source();  
  const [datos,setDatos]=useState([])
  const [espera,setEspera]=useState(false)
  const [espera2,setEspera2]=useState(false)
  const {usuario,guardarUsuario}=useContext(AuthContext);
  const {guardarGenerales}=useContext(GeneralesContext);  
  const [listDispo,setListDispo]=useState([])
  const [fechas,setFechas]=useState({auxFecha:new Date()})
  const {auxFecha}=fechas
  useEffect(()=>{               
   
    veriSesion()
    datosBitacora()
    datosDispo()
    return ()=> {
      source.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps	
  },[auxFecha])

  const veriSesion =  () => 
  {            
    if(usu===null)
    {                                
      guardarUsuario([])						
      guardarGenerales([])     
      localStorage.clear();           
      history.push("/")	
    }
    else
    { 
      if (usuario.length === 0) 
      {
        guardarUsuario({Nombre:usu,nivel:nvl});                                      
      }
      guardarGenerales({Titulo:'Reporte Bitacora'})                     
    }
  }

  const datosBitacora=()=>{
     
    const fInicio=moment(auxFecha).format("YYYY-MM-DD")                
    const data = qs.stringify({    
      fechaIni:fInicio+' 00:00:00' ,
      fechaFin:fInicio+' 23:59:59',                                  
      idDispositivo:'ifrekvjkxcdwdcwe'
    });
    const url = "/reporte-bitacora-rango-1-1";
    function respuesta(aux) {
      if (aux[0].length!==0) 
      {                
        setDatos(aux[0]);                                      
      }          
    }

    llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);
  }

  const datosDispo=()=>{
     
    const fInicio=moment(auxFecha).format("YYYY-MM-DD") 
    const fLim=new Date(moment(auxFecha).format("MM/DD/YYYY") )
    const fLimit=moment(auxFecha).format("MM")                
    const data = qs.stringify({    
      Fecha:fInicio+'T00:00:00' ,      
      idDispositivo:'ifrekvjkxcdwdcwe'
    });
    const url = "/reporte-bitacora-disponibilidad";
    function respuesta(aux) {
      if (aux[0].length!==0) 
      { //const filtro=aux.filter((disp)=>moment(disp.fecha).format("YYYY-MM-DD")===fInicio ||moment(disp.fecha).format("YYYY-MM-DD")===fAntes ) 
        const filtro=aux.filter((disp)=>new Date(moment.utc(disp.fecha).format("MM/DD/YYYY") )<= fLim &&  moment.utc(disp.fecha).format("MM")===fLimit)         
        setListDispo(filtro);                                      
      }          
    }

    llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera2,history,source);
  }

  function verificaReporte(auxJson){
    let auxValido=false;
    if (auxJson?.terminal !== undefined && auxJson.terminal[0].paros) {
      auxValido=true;
    //console.log('no esta vacio');
    } 
    else 
    {
      auxValido=false;
      //console.log('si esta vacio');
    }
    return auxValido;
  }

  return (
  <div>            
    <Box display="flex" flexDirection="row">
      <Box display="flex" width="25%" flexDirection="column">
        <Reloj fechas={fechas} setFechas ={setFechas } />
        {/* <span style={{backgroundColor:'red'}}>Dentro de la columna</span> */}
        {listDispo.length !== 0 ? <Disponib listDispo={listDispo} /> :null }
      </Box>
      <Box display="flex" width="75%" >
        { !espera ? 
          datos.length !== 0 ?  ( verificaReporte(datos) ?
            <Reporte urlReport={"reports/BitacoraReport230331.mrt"} informacion={datos}
              nombre={' Reporte Bitacora'} mostrarTB={findNumber('|54|')?true:false  }
            />
          :<span>{<ReporteSN urlReport={"reports/BitacoraReport230331.mrt"} informacion={datos}
              nombre={' Reporte Bitacora'} mostrarTB={findNumber('|54|')?true:false }
            />/*   */ } </span>
            ) :<Reporte urlReport={"reports/BitacoraReport230331.mrt"} informacion={datos}
            nombre={' Reporte Bitacora'} mostrarTB={findNumber('|54|')?true:false }
          />
          :
          <Box style={{width:'100%'}} pt={2}>
            <LinearProgress color="primary" /> 
          </Box>
        }                
      </Box>
    </Box>                        
  </div>
  )
}

export default RepoBitacora