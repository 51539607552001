import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Paper,Table,TableBody,TableCell,IconButton,
  TableContainer,TableHead,TableRow,
  LinearProgress,Box
} from '@material-ui/core';

import axios from 'axios';
import qs from 'qs';
import EditRefaccion from './EditarRefaccion'
import NvRefaccion from './NuevaRefaccion'
import { useHistory } from "react-router-dom";
//import CreateIcon from '@material-ui/icons/Create';
import {AuthContext} from '../context/AuthContext'
import AddIcon from '@material-ui/icons/Add';
import {ErrorGeneral, llamaApiCargaToken} from '../funciones/ClienteAxios';
import {findNumber} from "../funciones/FindNumber";
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 420,
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: "#51ad32"
    }
  },
  tableCell: {
    "$selected &": {
      color: "white"
    }
  },
  hover: {},
  selected: {}
});

export default function TablaRefacciones({orden}) {
  const classes = useStyles();
  let history = useHistory();
  const [selectedID, setSelectedID] = useState(null);
  const {usuario}=React.useContext(AuthContext);
  const [modalAbierto, setModalAbierto] = useState(false);
  const [modalAbierto2, setModalAbierto2] = useState(false);
  const[sinDatos,guardarSinDatos]= useState(false)
  const[actualiza,setActualiza]= useState(false)
  const [datos, guardarDatos] = useState([])
  const [selectRefacc, setSelectRefacc] = useState([])
  const [espera, setEspera] = useState(false)
  let nvlSNPermiso ='5' 
  React.useEffect(()=>{ 
    const source = axios.CancelToken.source();    
    const llenaListRef = () => {
      let data = qs.stringify({
          Mtto: orden.Id,
          idDispositivo: '89y7gttvjhj98h'
      });
      let url = "/mtto-refaccion-lista";

      function respuestaListAct(aux) {
        if ( aux[0].Id !== -1 ) 
        { guardarSinDatos(false)                 
          guardarDatos(aux)
        }
        else
        {
          guardarSinDatos(true)
        }     
      }

      llamaApiCargaToken(data, url, respuestaListAct, ErrorGeneral, setEspera, history, source);
    };
 
    llenaListRef();      
    return ()=> {
      source.cancel();
    }      
  },[orden,actualiza]);

const funcionActual=()=> {  
  setActualiza(!actualiza)
}

function nuevaActiv() {  
  setModalAbierto(true)
}
function editarRefac(auxFalla) 
{
  setSelectRefacc(auxFalla) 
  setModalAbierto2(true)
}

const rengNvReg= usuario?.nivel !=undefined && usuario.nivel!== nvlSNPermiso ?
  (<TableRow className={classes.tableRow}>           
    <TableCell  className={classes.tableCell} align="left" padding={'none'} 
      style={{  paddingLeft:10+'px'}} 
    >
      {findNumber('|31|') ?
        <IconButton   aria-label="agregar-actividad" size="small" 
          onClick={() => nuevaActiv()} component="span" 
        >
          <AddIcon color="primary"/>        
        </IconButton>   
      :null}     
    </TableCell>                
    <TableCell  className={classes.tableCell} align="center" padding={'none'} 
      style={{  paddingLeft:5+'px',fontWeight:700,color:'#51ad32'}}  >    
         <span >
         {findNumber('|31|') ? 'Nueva' : ""} 
        </span>
    </TableCell>            
    <TableCell  className={classes.tableCell} padding={'none'}
    style={{ paddingLeft:5+'px',fontWeight:500,color:'#51ad32'}}
    >   
      {findNumber('|31|') ? 'Refacción' : ""}      
    </TableCell>              
  </TableRow>)
  : (null)

  function Row(props) {
    const { row } = props;    
    return (
      <React.Fragment>
        <TableRow 
          onClick={() => { setSelectedID(row.Id); }}
          selected={selectedID === row.Id} className={classes.tableRow}
          classes={{ hover: classes.hover, selected: classes.selected }}          
        > 
          {/* <TableCell align="center" padding={'none'} style={{ width:30+'px'}} >
            <IconButton   aria-label="add circle" size="small" onClick={() => editarRefac(row)} 
              component="span"
            >
              { selectedID === row.Id ? 
                <CreateIcon style={{color:'white'}} />
                : <CreateIcon color="primary" />
              }
            </IconButton>                                                                                                                                                                          
          </TableCell>    */}          
          <TableCell  className={classes.tableCell} align="center" padding={'none'}
             style={{width:50+'px',  fontWeight:650,marginRight:5+'px'}}  >            
            {row.refId}
          </TableCell>
          <TableCell  className={classes.tableCell} align="center" padding={'none'}
            style={{width:70+'px',marginRight:5+'px'}} 
          >
            {row.cantidad}
          </TableCell>
          <TableCell  className={classes.tableCell} align="left" padding={'none'}>
            {row.refNom}
          </TableCell>                    
        </TableRow>      
      </React.Fragment>
    );
  }
  const tablaCompleta=()=>{
    return(
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>            
             {/*  <TableCell align="left" padding={'none'}></TableCell>   */}
              <TableCell align="left" padding={'none'}>#Parte </TableCell>            
              <TableCell align="center" padding={'none'}>Cantidad</TableCell>
              <TableCell align="left" padding={'none'}>Descripción</TableCell>                                    
            </TableRow>
          </TableHead>
          <TableBody>
            {rengNvReg}
            {datos.length!=0? (datos.map((row) => (
                <Row key={row.Id} row={row} />
            ))):null}
          </TableBody>
        </Table>
      </TableContainer>
   )
  }
  const tablaSinDatos=()=>{
   return(<TableContainer className={classes.container}>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>            
          <TableCell align="left" padding={'none'}>#Parte </TableCell>            
          <TableCell align="center" padding={'none'}>Cantidad</TableCell>    
          <TableCell align="left" padding={'none'}>Descripción</TableCell>                        
        </TableRow>
      </TableHead>
      <TableBody>
        {rengNvReg}
        <TableRow className={classes.tableRow} >          
          <TableCell  className={classes.tableCell} align="center" padding={'none'}
           style={{width:50+'px',marginRight:5+'px'}} 
          >
            Mtto 
          </TableCell>          
          <TableCell  className={classes.tableCell} align="center" padding={'none'}
           style={{width:70+'px',marginRight:5+'px'}} 
          >
            Sin
          </TableCell>             
          <TableCell  className={classes.tableCell} align="left"padding={'none'}>
            Datos
          </TableCell>                 
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
 ) 
  }
  const contenido = !sinDatos ?tablaCompleta() :tablaSinDatos()
  const cargando = !espera ? contenido : <Box my={2}><LinearProgress/></Box>
  const modal =modalAbierto  ? 
                                ( usuario?.nivel !=undefined && usuario.nivel!== nvlSNPermiso ?
                                  (<NvRefaccion titulo={'Nueva Refacción'} modalAbierto={modalAbierto} 
                                      setModalAbierto={setModalAbierto} auxOrdenId={orden.Id}
                                      funcionActual={funcionActual}
                                  />)
                                  :null
                                )
                                :null
  const modal2 =modalAbierto2  ? 
                                (usuario?.nivel !=undefined && usuario.nivel!== nvlSNPermiso ?
                                  ( <EditRefaccion titulo={'Editar Mtto '+orden.Id+' Refacción'} 
                                      modalAbierto={modalAbierto2} setModalAbierto={setModalAbierto2} 
                                      auxRefaccion={selectRefacc}
                                    />
                                  )
                                : ( <EditRefaccion titulo={'Mtto '+orden.Id+' Refacción '} 
                                      modalAbierto={modalAbierto2} setModalAbierto={setModalAbierto2} 
                                      auxRefaccion={selectRefacc}
                                    />
                                  )
                                )
                                :null                                  
  return (
    <Paper className={classes.root}>      
      {cargando}
      {modal}
      {modal2}
    </Paper>
  );
}
