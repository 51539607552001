import {useHistory} from "react-router-dom";
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import SearchIcon from '@material-ui/icons/Search';
import {makeStyles} from '@material-ui/core/styles';
import { Box, Card, CardContent, FormControl, FormControlLabel,
    Grid, IconButton, InputLabel, Radio, RadioGroup, Select as MaterialSelect,
    TextField, Tooltip, Typography, Checkbox } from '@material-ui/core'
import TablaOrden from './TablaOrden';
import RepoTab from './RepoTab';
import moment from 'moment';
import Relojes from './Relojes2'
import axios from 'axios';
import qs from 'qs';
import {ErrorGeneral, llamadaApiToken} from '../funciones/ClienteAxios';
import React, {useContext, useEffect, useState} from "react";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import NuevaOrden from "./NuevaOrden";
import {findNumber} from "../funciones/FindNumber";
const useStyles = makeStyles((theme) => ({
    title2: {
        fontSize: 18,
    },
    subTitle2: {
        fontSize: 12,
    },
    altura2: {
        minHeight: 70,
        marginTop: 0,
        paddingTop: 0,
    },
    altura3: {
        minHeight: 145,
        marginTop: 0,
        paddingTop: 0,
    },

}));
const arrEstatus = [
    {valor: -2, nombre: "Todo"},
    {valor: 0, nombre: "Registrada"},
    {valor: 1, nombre: "Finalizada"},
    {valor: -1, nombre: "Cancelada"},
]

const RepoInicio = () => {
    let history = useHistory();
    const classes = useStyles();
    let nvlSNPermiso = '5'
    const idusu = localStorage.getItem('UsuId');
    const source = axios.CancelToken.source();
    const {usuario, guardarUsuario} = useContext(AuthContext);
    const {guardarGenerales} = useContext(GeneralesContext);
    const [ordenSeleccionada, guardarOrdenSeleccionada] = useState([])
    const [ordenBusq, setOrdenBusq] = useState({orden: '', turno: ''});
    const [fechasSeleccionadas, setFechasSeleccionadas] = useState({inicio: new Date(), final: new Date()});
    const {orden, turno} = ordenBusq;
    const [estatus, setEstatus] = useState(-2);
    const [buscar, setBuscar] = useState(true);
    const [terminal, setTerminal] = useState(1);
    const [listLugarTerminal, setListLugarTerminal] = useState([])
    const [modalAbierto, setModalAbierto] = useState(false);
    const[filtro,setFiltro] = useState({supervisa:false})
    const{supervisa}=filtro

    useEffect(() => {                       

        const veriSesion = () => {
            const usu = localStorage.getItem('UsuarioNom');
            const nvl = localStorage.getItem('Tipo');
            if (usu === null) {
                guardarUsuario([])
                guardarGenerales([])
                localStorage.clear();
                history.push("/")
            } else {
                if (usuario.length === 0) {
                    guardarUsuario({Nombre: usu, nivel: nvl});
                }

                guardarGenerales({Titulo: 'Orden de Trabajo'})

                const auxUbi = JSON.parse(localStorage.getItem('LisUbicOrd'));
                if (auxUbi === null || auxUbi === undefined) {
                    llamarUbicacionOrd()
                }
                if (listLugarTerminal.length === 0) {
                    llenarListTerminal();
                }
            }
        }
        veriSesion();
        return () => {
            source.cancel();
        }
    }, []);

    const llenarListTerminal = () => {
        const data = qs.stringify({
            usuario: idusu,
            idDispositivo: '89y7gttvjhj98h'
        });
        const url = "/lugar-terminal-list";

        function respuestaListTermin(aux) {
            if (aux[0].Id !== -1) {
                let auxArre = [];
                aux.forEach((tipo) => {
                    if (tipo.Id !== 0) {
                        auxArre.push({value: tipo.Id, label: tipo.Descr})
                    }
                })
                let auxTerm = localStorage.getItem('UsuTerminal');
                setListLugarTerminal(auxArre);
                setTerminal(parseInt(auxTerm))
            }
        }

        llamadaApiToken(data, url, respuestaListTermin, ErrorGeneral, history, source)
    };    

    const llamarUbicacionOrd = () => {
        const data = qs.stringify({
            tipo: 'ORD',
            idDispositivo: '89y7gttvjhj98h'
        });
        const url = "/lugar-ubicacion-list-1_1";

        function respuestaListInterv(auxiliar) {
            if (auxiliar[0].Id !== -1) {
                localStorage.setItem('LisUbicOrd', JSON.stringify(auxiliar));
            }
        }

        llamadaApiToken(data, url, respuestaListInterv, ErrorGeneral, history, source);
    };

    const onChange = e => {
        let expNopermitida = new RegExp('[#._:$!%-({})/*=?|]');
        let expMenos = new RegExp('-');
        let expMas = new RegExp('[+]');
        let expCadena = new RegExp('[A-Za-z0-9]');
        if ((expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) &&
            !expMenos.test(e.target.value) && !expMas.test(e.target.value)) || e.target.value === ' ' || e.target.value === '') {
            setOrdenBusq({
                ...ordenBusq,
                [e.target.name]: e.target.value.trim()
            })
        }

    };

    const guardaTurno = e => {
        setOrdenBusq({
            ...ordenBusq,
            turno: e.target.value
        })
    };

    const incrementarBuscar = e => {
        setBuscar(!buscar)
    };

    const onChangeTerminal = (event) => {
        setTerminal(event.target.value);
    };

    const onChangeEstatus = (event) => {
        setEstatus(event.target.value);
    };

    function tipoEstado(estado) {
        let auxEtiqueta = ''
        let auxColor = ''
        if (estado === 0) {
            auxEtiqueta = 'Registrado'
            auxColor = '#F46239'
        } else {
            if (estado === 1) {
                auxEtiqueta = 'Solucionado'
                auxColor = '#049b4f'
            } else {
                if (estado === -1) {
                    auxEtiqueta = 'Cancelado'
                    auxColor = 'red'
                }
            }
        }
        let renglon = {color: auxColor, etiqueta: auxEtiqueta}
        return renglon
    }

    const Encabezado = ({espacio, estiloCampo, descripcion, campo}) => {
        return (
        <Box {...espacio} >
            <Typography className={classes.title2} variant="h5" color="secondary"
                component="h2" align="center" style={estiloCampo}
            >
                {campo}
            </Typography>
            <Typography className={classes.subTitle2} color="textSecondary" align="center">
                {descripcion}
            </Typography>
        </Box>
        )
    }

    const CampoRenglon = ({estiloBox, estiloTitulo, titulo, campo}) => {
        return (
            <Box  {...estiloBox} >
                <span style={estiloTitulo}>{titulo} </span>
                <Typography variant='body'> {campo}  </Typography>
            </Box>
        )
    }

    const costadoDerecho = (orden) => {
        let fecha = moment.utc(orden.fecha);
        let modificada = fecha.local(true).format("DD/MM/YYYY");
        let arrEstado = tipoEstado(orden.Status)
        const colorTitulo = process.env.REACT_APP_COLOR
        let fechFin = moment.utc(orden.ffinaliza)
        let fechFin2 = fechFin.local(true).format("DD/MM/YYYY HH:mm")
        return (
            <span>
        <Box pl={1} my={1}>
            <Card  >
                <CardContent>
                    <Box display="flex" flexDirection="row" justifyContent="center">
                        <Encabezado espacio={{}} estiloCampo={{fontWeight: '900'}} descripcion={"OT"} campo={orden.Id}/>
                        <Encabezado espacio={{ml: 1}} estiloCampo={{fontWeight: '900'}}
                            descripcion={"Periodo"} campo={orden.periNom}/>
                        {/* <Encabezado espacio={{ml: 1}} estiloCampo={{fontWeight: '900'}}
                                    descripcion={"Sección"} campo={orden.seccNom}/> */}
                        <Encabezado espacio={{ml: 1}} estiloCampo={{fontWeight: '900'}}
                                    descripcion={"Lugar"} campo={orden.lugarDescr}/>
                        <Encabezado espacio={{ml: 1}} estiloCampo={{fontWeight: '900'}}
                                    descripcion={"Fecha"} campo={modificada}/>
                        <Encabezado espacio={{ml: 1}} estiloCampo={{color: arrEstado.color, fontWeight: 700}}
                                    descripcion={"Estatus"} campo={arrEstado.etiqueta}/>
                        <Encabezado espacio={{ml: 1}} estiloCampo={{fontWeight: '900'}}
                                    descripcion={"Terminal"} campo={orden.termiDescr}/>
                        <Encabezado espacio={{ml: 1}} estiloCampo={{fontWeight: '900'}}
                                    descripcion={"Turno"} campo={orden.turno === 'D' ? 'Diurno' : 'Nocturno'}/>
                        <Encabezado espacio={{ml: 1}} estiloCampo={{fontWeight: '900'}}
                                    descripcion={"Tipo"} campo={orden.tipoNom}/>
                        {(orden.Status === 1 || orden.Status === -1) && (orden.Usrfinaliza !== "" && orden.Usrfinaliza !== " ") ?
                            <Encabezado espacio={{ml: 1}} estiloCampo={{fontWeight: '900'}}
                                        descripcion={"Finalizo"} campo={orden.Usrfinaliza}/>
                            : null
                        }                        
                    </Box>
                    {orden.refManual.length !== 0 && orden.refManual !== ' ' ?
                        <CampoRenglon estiloBox={{display: "flex"}} titulo="Ref. Manual " campo={orden.refManual}
                                      estiloTitulo={{color: colorTitulo, fontWeight: 700, paddingRight: 0.2 + 'rem'}}
                        />
                        : null}
                    {orden.ubicDescr.length !== 0 ?
                        <CampoRenglon estiloBox={{display: "flex"}} titulo="Ubicación " campo={orden.ubicDescr}
                                      estiloTitulo={{color: colorTitulo, fontWeight: 700, paddingRight: 0.2 + 'rem'}}
                        />
                        : null}
                    {orden.descr.length !== 0 ?
                        <CampoRenglon estiloBox={{display: "flex"}} titulo="Descripción " campo={orden.descr}
                                      estiloTitulo={{color: colorTitulo, fontWeight: 700, paddingRight: 0.2 + 'rem'}}
                        />
                        : null}
                    {orden.solucion.length !== 0 && orden.solucion !== ' ' ?
                        <CampoRenglon estiloBox={{display: "flex"}} titulo="Solución " campo={orden.solucion}
                                      estiloTitulo={{color: colorTitulo, fontWeight: 700, paddingRight: 0.2 + 'rem'}}
                        />
                        : null}
                    {orden.folioCierre && orden.folioCierre.length !== 0 && orden.folioCierre !== ' ' ?
                        <Box display={"flex"} flexDirection="row">
                            <CampoRenglon estiloBox={{display: "flex"}} titulo="Fecha Finaliza" campo={fechFin2}
                                          estiloTitulo={{
                                              color: colorTitulo,
                                              fontWeight: 700,
                                              paddingRight: 0.2 + 'rem'
                                          }}
                            />
                            <CampoRenglon estiloBox={{display: "flex", ml: 2}} titulo="Folio Cierre"
                                          campo={orden.folioCierre}
                                          estiloTitulo={{
                                              color: colorTitulo,
                                              fontWeight: 700,
                                              paddingRight: 0.2 + 'rem'
                                          }}
                            />
                        </Box>
                        : null}
                    {orden.Status === -1 ?
                        <CampoRenglon estiloBox={{display: "flex"}} titulo="Causa Cancelación "
                                      campo={moment.utc(orden.Fcancela).local(true).format("DD/MM/YYYY HH:mm") + ' ' + orden.causa}
                                      estiloTitulo={{color: 'red', fontWeight: 700, paddingRight: 0.2 + 'rem'}}
                        />
                        : null}
                </CardContent>                     
            </Card>
        </Box>                    
        <RepoTab orden={orden}/>                            
    </span>)
    }

    const funcionActuliza = () => {
        setBuscar(!buscar)
    }

    function handleAbrir() {
        setModalAbierto(true)
    }

    const onChangeCheck = (event) => {
        setFiltro({
          ...filtro,
            [event.target.name]: event.target.checked 
          });
    };

    const contenido = ordenSeleccionada.length !== 0 ? (costadoDerecho(ordenSeleccionada)) : (null)
    const principal = () => {
        return (
        <>
        <Grid container alignItems={'flex-start'} style={{marginTop: '.5rem', marginBottom: '.5rem'}}>
            <Grid item xs={12}>
                <Card className={classes.altura2}>
                    <CardContent style={{padding: '.5rem'}}>
                        <Grid container alignItems={'center'} justify={'space-around'}>
                            <Grid item>
                                {usuario?.nivel !== undefined && usuario.nivel !== nvlSNPermiso && findNumber('|1|') ?
                                    <Tooltip title="Nueva Orden">
                                        <IconButton size={"small"} color="primary" onClick={() => handleAbrir()}
                                            style={{paddingTop: '.6rem'}}>
                                            <AddCircleOutlineIcon/>
                                        </IconButton>
                                    </Tooltip>
                                :   <NuevaOrden titulo={"Nueva Orden"}
                                        funcionActuliza={funcionActuliza}/>
                                }
                            </Grid>
                            <Grid item>
                                <Tooltip title="Buscar">
                                <IconButton color="primary" size={"small"} onClick={incrementarBuscar}
                                    style={{paddingTop: '.6rem'}}>
                                    <SearchIcon/>
                                </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <FormControl component="fieldset" style={{paddingTop: '.6rem', paddingLeft: '.8rem'}}
                                    size="small">
                                    <RadioGroup row aria-label="position" size="small"
                                        defaultValue="top" name="turno" value={turno}
                                        onChange={guardaTurno}
                                    >
                                        <FormControlLabel value="D" label="T.Diurno"
                                            control={<Radio color="primary" size={"small"}
                                                style={{padding: 0}}/>}
                                        />
                                        <FormControlLabel value="N" label="T.Nocturno"
                                            control={<Radio color="primary" size={"small"}
                                                style={{padding: 0}}/>}
                                        />
                                        <FormControlLabel value="" label="Ambos"
                                            control={<Radio color="primary" size={"small"}
                                                style={{padding: 0}}/>}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item >
                                <FormControlLabel label="Supervisar" size="small"
                                    control={<Checkbox checked={supervisa} name="supervisa" size="small" onChange={onChangeCheck}/>}            
                                />
                            </Grid>
                            <Grid item>
                                {listLugarTerminal.length !== 0 ?
                                    <FormControl className={classes.formControl} size="small">
                                        <InputLabel htmlFor="terminal1-native-simple">Terminal</InputLabel>
                                        <MaterialSelect
                                            native value={terminal} onChange={onChangeTerminal}
                                            inputProps={{name: 'terminal', id: 'terminal'}}
                                        >
                                            <option aria-label="None" value=""/>
                                            {listLugarTerminal.map((row) => (
                                                <option value={row.value}>{row.label}</option>
                                            ))}
                                        </MaterialSelect>
                                    </FormControl>
                                : null}
                            </Grid>
                            <Grid item>
                                <FormControl component="fieldset" size="small">
                                    <InputLabel htmlFor="estatus1-native">Estatus</InputLabel>
                                    <MaterialSelect
                                        native value={estatus} onChange={onChangeEstatus}
                                        inputProps={{name: 'estatus', id: 'estatus'}}
                                    >
                                        {arrEstatus.map((status) =>                                            
                                            <option value={status.valor} id={status.valor}>
                                                {status.nombre}
                                            </option>                                            
                                        )}
                                    </MaterialSelect>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <Relojes fechasSeleccionadas={fechasSeleccionadas}
                                    setFechasSeleccionadas={setFechasSeleccionadas}/>
                            </Grid>
                            <Grid item>
                                <TextField id="outlined-basic" label="Folio" variant="outlined" size="small"
                                    name="orden" value={orden} onChange={onChange}/>
                            </Grid>
                    </Grid>
                </CardContent>
            </Card>
            </Grid>
        </Grid>

        <Grid container  alignItems={'flex-start'} >
            <Grid item xs={4}>
                <TablaOrden orden={orden} fechasSeleccionadas={fechasSeleccionadas}
                    guardarOrdenSeleccionada={guardarOrdenSeleccionada}
                    turno={turno} setOrdenBusq={setOrdenBusq} ordenBusq={ordenBusq}
                    setBuscar={setBuscar} buscar={buscar} usuarioContxt={usuario}
                    terminal={terminal} estatus={estatus} funcionActuliza={funcionActuliza}
                    supervisa={supervisa}
                />
            </Grid>
            <Grid item xs={8}>
                {contenido}
            </Grid>
        </Grid>
        </>
        )
    }

    const modal = modalAbierto ? <NuevaOrden modalAbierto={modalAbierto} setModalAbierto={setModalAbierto}
                                            titulo={"Nueva Orden"} funcionActuliza={funcionActuliza}
    /> : null

    return (
        <>
            {principal()}
            {modal}
        </>
    )
}

export default RepoInicio