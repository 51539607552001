import { useState } from "react";
import {
  Box,
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";
import { ErrorGeneral, llamaApiCargaToken } from "../funciones/ClienteAxios";
import qs from "qs";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  statusCard: {
    textAlign: "center",
    color: "white",
    marginRight: 5,
    height: "100%",
    paddingLeft: 0.5 + "rem",
    paddingRight: 0.5 + "rem",
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  input1: {
    height: 2,
  },
}));

const CardDetallePilona = ({
  element,
  index,
  setAviso,
  setMensaje,
  pilona,
  setUpdate,
  update,
  setEsperaModal,
  setActualiza,
}) => {
  const classes = useStyles();
  const [available, setAvailable] = useState(false);
  const [espera, setEspera] = useState(false);
  const [actividad, setActividad] = useState({
    Fecha: element.Fecha,
    Finaliza: element.Finaliza,
    Id: element.Id,
    LadoA: element.LadoA,
    LadoABloq: element.LadoABloq,
    LadoB: element.LadoB,
    LadoBBloq: element.LadoBBloq,
    MttoDetId: element.MttoDetId,
    TpPlanId: element.TpPlanId,
    TpPlanNom: element.TpPlanNom,
    UsrFinId: element.UsrFinId,
    UsrFinNom: element.UsrFinNom,
    UsrId: element.UsrId,
    UsrNom: element.UsrNom,
    statusId: element.statusId,
    statusNom: element.statusNom,
    observA: element.LadoAObserv,
    observB: element.LadoBObserv,
  });

  const {
    Fecha,
    Finaliza,
    Id,
    LadoA,
    LadoABloq,
    LadoB,
    LadoBBloq,
    MttoDetId,
    TpPlanId,
    TpPlanNom,
    UsrFinId,
    UsrFinNom,
    UsrId,
    UsrNom,
    statusId,
    statusNom,
    observA,
    observB,
  } = actividad;
  const historial = useHistory();
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let mensaje = "";

  const editarDetalleCabina = (auxStatusId) => {
    //setEspera(true)
    setEsperaModal(true);
    setAvailable(false);
    setActualiza(true);
    let data = qs.stringify({
      MttoId: pilona.MttoId,
      MttoDetId: actividad.MttoDetId,
      MttoDetPlanId: actividad.Id,
      Status: auxStatusId,
      Usuario: actividad.UsrId,
      LadoA: actividad.LadoA,
      LadoB: actividad.LadoB,
      LadoAObserv: observA,
      LadoBObserv: observB,
      idDispositivo: "987gyubjhkkjh",
    });
    let url = "/pilona-mtto-det-plan-editar-1-1";

    function respuesta(auxiliar) {
      if (auxiliar.respuesta === 1) {
        mensaje = "Se edito correctamente el registro ";
        setMensaje(mensaje + actividad.TpPlanNom);
        setAviso(true);
        toast.success(mensaje + actividad.TpPlanNom, {
          toastId: `det-edit-pil${actividad.Id}`,
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setEsperaModal(false);
      }
    }

    llamaApiCargaToken(
      data,
      url,
      respuesta,
      ErrorGeneral,
      setEspera,
      historial,
      source
    );
  };

  const handleA = (event) => {
    event.preventDefault();
    setAvailable(true);
    switch (event.target.value) {
      case "1":
        return setActividad({
          ...actividad,
          ...{ LadoA: 1 },
        });
      case "0":
        return setActividad({
          ...actividad,
          ...{ LadoA: 0 },
        });
    }
  };

  const handleB = (event) => {
    event.preventDefault();
    setAvailable(true);
    switch (event.target.value) {
      case "1":
        return setActividad({
          ...actividad,
          ...{ LadoB: 1 },
        });
      case "0":
        return setActividad({
          ...actividad,
          ...{ LadoB: 0 },
        });
    }
  };
  let status = 0;
  const cambiaStatus = () => {
    if (actividad.LadoA === 1 && actividad.LadoB === 1) {
      status = 3;
      return setActividad({
        ...actividad,
        ...{ statusId: 3 },
      });
    } else {
      status = 1;
      return setActividad({
        ...actividad,
        ...{ statusId: 1 },
      });
    }
  };

  const onChange = (e) => {
    setActividad({
      ...actividad,
      [e.target.name]: e.target.value,
    });
    setAvailable(true);
  };

  const guardaCambios = () => {
    let auxStatusId = 1;
    cambiaStatus();
    if (actividad.LadoA === 1 && actividad.LadoB === 1) {
      auxStatusId = 3;
      editarDetalleCabina(auxStatusId);
    } else {
      editarDetalleCabina(auxStatusId);
    }
  };
  const btnCancelar = () => {
    setAvailable(false);
    setActividad({
      ...actividad,
      Fecha: element.Fecha,
      Finaliza: element.Finaliza,
      LadoA: element.LadoA,
      LadoABloq: element.LadoABloq,
      LadoB: element.LadoB,
      LadoBBloq: element.LadoBBloq,
      TpPlanId: element.TpPlanId,
      TpPlanNom: element.TpPlanNom,
      statusId: element.statusId,
      statusNom: element.statusNom,
      observA: element.LadoAObserv,
      observB: element.LadoBObserv,
    });
  };

  const stdColor =
    statusId === 1 ? "#F46239" : statusId === 3 ? "#049b4f" : "red";

  return (
    <div key={index} style={{ overflowY: "auto" }} id={"cardDetPil" + index}>
      <Card className={classes.marginBottom} style={{ padding: 0 }}>
        <Grid
          container
          alignItems={"center"}
          justifyContent={"space-between"}
          style={{ padding: 0 }}
        >
          <Grid item xs={12}>
            {TpPlanNom}
          </Grid>
          <Grid item>
            <Card
              className={classes.statusCard}
              style={{
                backgroundColor: stdColor,
                alignItems: "center",
                display: "flex",
                padding: "0.625rem",
              }}
            >
              <Typography variant="h6" align={"center"}>
                {" "}
                {actividad.statusId === 1 ? "Pendiente" : "Finalizado"}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={3} id="datos-lados">
            <Grid container>
              <Grid item xs={12}>
                <FormControl component="fieldset" size="small">
                  <RadioGroup
                    row
                    aria-label="LadoA"
                    name="LadoA"
                    size={"small"}
                    defaultValue={LadoA.toString()}
                    onChange={handleA}
                  >
                    <FormControlLabel
                      size={"small"}
                      labelPlacement="start"
                      label="Lado A"
                      control={
                        <Radio
                          style={{
                            color: "transparent",
                            padding: 0,
                            paddingLeft: 3,
                          }}
                          size="small"
                        />
                      }
                    />
                    <FormControlLabel
                      value="1"
                      style={{ color: "#049b4f" }}
                      size={"small"}
                      labelPlacement="start"
                      label="OK"
                      control={
                        <Radio
                          style={{
                            color: "#0ac15d",
                            padding: 0,
                            paddingLeft: 3,
                          }}
                          size="small"
                        />
                      }
                    />
                    <FormControlLabel
                      value="0"
                      style={{ color: "#F46239", marginLeft: 20 }}
                      size={"small"}
                      labelPlacement="start"
                      label="PENDIENTE"
                      control={
                        <Radio
                          style={{
                            color: "#FF6439",
                            padding: 0,
                            paddingLeft: 3,
                          }}
                          size="small"
                        />
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset" size="small">
                  <RadioGroup
                    row
                    aria-label="LadoA"
                    name="LadoA"
                    size={"small"}
                    defaultValue={LadoB.toString()}
                    onChange={handleB}
                  >
                    <FormControlLabel
                      size={"small"}
                      labelPlacement="start"
                      label="Lado B"
                      control={
                        <Radio
                          style={{
                            color: "transparent",
                            padding: 0,
                            paddingLeft: 3,
                          }}
                          size="small"
                        />
                      }
                    />
                    <FormControlLabel
                      value="1"
                      style={{ color: "#049b4f" }}
                      size={"small"}
                      labelPlacement="start"
                      label="OK"
                      control={
                        <Radio
                          style={{
                            color: "#0ac15d",
                            padding: 0,
                            paddingLeft: 3,
                          }}
                          size="small"
                        />
                      }
                    />
                    <FormControlLabel
                      value="0"
                      style={{ color: "#F46239", marginLeft: 20 }}
                      size={"small"}
                      labelPlacement="start"
                      label="PENDIENTE"
                      control={
                        <Radio
                          style={{
                            color: "#FF6439",
                            padding: 0,
                            paddingLeft: 3,
                          }}
                          size="small"
                        />
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} id="inputs-observ2" style={{marginLeft:1.3+'rem'}}>
            <Grid container spacing={1} direction="column">
              <Grid item xs={12}>
                <label style={{ color: "#51ad32" }}>Observ. A</label>
              </Grid>
              <Grid item xs={12}>
                <label style={{ color: "#51ad32" }}>Observ. B</label>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5} id="inputs-observ">
            <Grid container spacing={1} direction="column">
              <Grid item xs={12}>
                <input
                  type="text"
                  name="observA"
                  value={observA}
                  onChange={onChange}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12}>
                <input
                  type="text"
                  name="observB"
                  value={observB}
                  onChange={onChange}
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Grid container>
              {espera === false ? (
                available ? (
                  <>
                    <Grid item>
                      <IconButton
                        size={"small"}
                        aria-label="delete"
                        onClick={(e) => btnCancelar()}
                      >
                        <CancelIcon size="small" color={"error"} />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton
                        size={"small"}
                        aria-label="update"
                        onClick={guardaCambios}
                      >
                        <CheckCircleIcon
                          size="small"
                          style={{ color: "#0ac15d" }}
                        />
                      </IconButton>
                    </Grid>
                  </>
                ) : null
              ) : (
                <Box mt={1} mr={1} ml={0.5}>
                  <CircularProgress size={20} />
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default CardDetallePilona;
