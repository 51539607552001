import {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    Box,
    Collapse,
    IconButton,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import {useHistory} from "react-router-dom";
import Rating from '@material-ui/lab/Rating';
import AddIcon from '@material-ui/icons/Add';
import NuevaActividad from './NuevaActividad';
import EditarActividad from './EditarActividad';
import EditarActividadDummy from './EditarActividadDummy';
import CreateIcon from '@material-ui/icons/Create';
import {AuthContext} from '../context/AuthContext'
import {celda} from '../Generales/TablaGeneral';
import {ErrorGeneral, llamaApiCargaToken} from '../funciones/ClienteAxios';
import {findNumber} from "../funciones/FindNumber";
const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 420,
    },
    tableRow: {
        "&$selected, &$selected:hover": {
            backgroundColor: process.env.REACT_APP_COLOR
        }
    },
    tableCell: {
        "$selected &": {
            color: "white"
        }
    },
    hover: {},
    selected: {}
});

export default function TablaActividades({orden}) {
    const classes = useStyles();
    const nvlSNPermiso = '5'
    const history = useHistory();
    const auxColor=process.env.REACT_APP_COLOR
    const [selectedID, setSelectedID] = useState(null);
    const [datos, guardarDatos] = useState([])
    const [sinDatos, guardarSinDatos] = useState(false)
    const [ordenSelecc, setOrdenSelecc] = useState(0);
    const [open, setOpen] = useState(false);
    const [modalAbierto, setModalAbierto] = useState(false);
    const [selectActividad, setSelectActividad] = useState([])
    const [modalAbierto2, setModalAbierto2] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const {usuario} = useContext(AuthContext);
    const [espera, setEspera] = useState(false)

    useEffect(() => {
        const source = axios.CancelToken.source();
        const llenaListActividad = () => {
            let data = qs.stringify({
                orden: orden.Id,
                idDispositivo: '89y7gttvjhj98h'
            });
            let url = "/orden-actividad-lista_1_1";

            function respuestaListAct(auxiliar) {
                if (auxiliar[0].Id !== -1) {
                    guardarSinDatos(false)
                    guardarDatos(auxiliar)
                } else {
                    guardarSinDatos(true)
                }
            }

            llamaApiCargaToken(data, url, respuestaListAct, ErrorGeneral, setEspera, history, source);
        };

        setSelectedID('')

        llenaListActividad()
        return () => {
            source.cancel();
        }
    }, [orden,actualizar]);

    const funcionActuliza=()=>{
        setActualizar(!actualizar)
    }

    const OrdenarFecha = (auxfecha) => {
        let modificada2 = moment.utc(auxfecha).local(true).format("DD/MM/YYYY HH:mm");
        return modificada2
    }

    function verMas(auxId) {
        if (auxId === ordenSelecc) {
            setOpen(!open)
        } else {
            setOpen(true)
        }
        setOrdenSelecc(auxId)
    }

    function nuevaActiv() {
        setModalAbierto(true)
    }

    function editarActiv(auxFalla) {
        setSelectActividad(auxFalla)
        setModalAbierto2(true)
    }

    function tipoEstado(estado, idSelecc) {
        let auxEtiqueta = ''
        let auxColor = ''
        if (estado === 1) {
            auxEtiqueta = 'Registrado'
            auxColor = '#F46239'
        } else {
            if (estado === 3) {
                auxEtiqueta = 'Solucionado'
                auxColor = '#049b4f'
            } else {
                if (estado === 0) {
                    auxEtiqueta = 'Cancelado'
                    auxColor = 'red'
                }
            }
        }
        let renglon = <div></div>
        selectedID === idSelecc ?
            renglon =
                <TableCell align="center" style={{color: 'white', fontWeight: 700, width: 100 + 'px'}} padding={'none'}>
                    {auxEtiqueta}
                </TableCell> :
            renglon = <TableCell align="center" style={{color: auxColor, fontWeight: 700, width: 100 + 'px'}}
                                 padding={'none'}>{auxEtiqueta}</TableCell>
        return renglon
    }

    function miniTabla(auxId, titulo, registro) {
        return (
            <Table size="small" aria-label="purchases">
                <TableHead>
                    <TableRow>
                        <TableCell align="left" padding={'none'}>{titulo}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow key={auxId}>
                        <TableCell className={classes.tableCell} align="left" padding={'none'}>
                            {registro}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        )
    }

    function Row(props) {
        const {row} = props;
        let estiloFolio = {
            color: selectedID === row.Id ? 'white' : '#0AC15D',
            fontWeight: '980',
            fontSize: 18,
            width: 10 + 'px'
        }
        let radioPrioridad = <Rating name="rating-prioridad" value={row.prioridad} size="small" max={3} readOnly
                                     style={{color: selectedID === row.Id ? 'white' : auxColor}}/>
        return (
            <>
                <TableRow
                    onClick={() => {
                        setSelectedID(row.Id);
                    }}
                    selected={selectedID === row.Id}
                    classes={{hover: classes.hover, selected: classes.selected}}
                    className={classes.tableRow}
                >
                    <TableCell align="center" padding={'none'} style={{width: 30 + 'px'}}>
                        <Tooltip title={open && ordenSelecc === row.Id ? 'Ver menos' : 'Ver más'}>
                            <IconButton color="primary" aria-label="expand row" size="small"
                                        onClick={() => verMas(row.Id)}>
                                {open && ordenSelecc === row.Id ? (selectedID === row.Id ?
                                        <KeyboardArrowUpIcon style={{color: 'white'}}/> : <KeyboardArrowUpIcon/>) :
                                    selectedID === row.Id ? <KeyboardArrowDownIcon style={{color: 'white'}}/> :
                                        <KeyboardArrowDownIcon/>}
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                    <TableCell align="center" padding={'none'} style={{width: 30 + 'px'}}>
                        <Tooltip title={'Editar'}>
                            <IconButton aria-label="add circle" size="small" onClick={() => editarActiv(row)}
                                        component="span">
                                {selectedID === row.Id ? <CreateIcon style={{color: 'white'}}/> :
                                    <CreateIcon color="primary"/>}
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                    {celda("center", estiloFolio, row.folio, 2, classes.tableCell)}
                    {celda("left", {
                        width: 125 + 'px',
                        paddingLeft: 5 + 'px'
                    }, OrdenarFecha(row.fecha), 3, classes.tableCell)}
                    {celda("center", {width: 75 + 'px'}, radioPrioridad, 4, classes.tableCell)}
                    {celda("left", {width: 145 + 'px'}, row.tipoNom, 4, classes.tableCell)}
                    {tipoEstado(row.statusId, row.Id)}
                    {celda("center", {}, row.referManual, 6, classes.tableCell)}
                </TableRow>
                <TableRow>
                    <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={8}>
                        <Collapse in={open && ordenSelecc === row.Id} timeout="auto" unmountOnExit>
                            <Box>
                                {row.solucion.length !== 0 ? miniTabla(row.Id, "Solución",
                                    <span>
                                        <span style={{ fontWeight:700, color:auxColor}} >{OrdenarFecha(row.fsolucion)}</span>
                                     {' ' + row.solucion} </span>)
                                : null}
                                {miniTabla(row.Id + 1, "Descripción", row.descr)}
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        );
    }

    const celdaVacia = <TableCell className={classes.tableCell} align="left" padding={'none'}></TableCell>
    const tablaCompleta = () => {

        return (<TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" padding={'none'}></TableCell>
                            <TableCell align="left" padding={'none'}></TableCell>
                            <TableCell align="center" padding={'none'}>Folio </TableCell>
                            <TableCell align="center" padding={'none'}>Fecha</TableCell>
                            <TableCell align="center" padding={'none'}>Prioridad</TableCell>
                            <TableCell align="left" padding={'none'}>Tipo</TableCell>
                            <TableCell align="center" padding={'none'}>Estatus</TableCell>
                            <TableCell align="center" padding={'none'}>Ref. Manual</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {usuario?.nivel !== undefined && usuario.nivel !== nvlSNPermiso ?
                            <TableRow className={classes.tableRow}>
                                <TableCell className={classes.tableCell} align="left" padding={'none'}
                                           style={{width: 30 + 'px'}}>
                                    {findNumber('|2|') ? 
                                        <Tooltip title={'Agregar actividad'}>
                                            <IconButton aria-label="agregar-actividad" size="small"
                                                onClick={() => nuevaActiv()} component="span">
                                                <AddIcon color="primary"/>
                                            </IconButton>
                                        </Tooltip>
                                    : null}
                                </TableCell>
                                <TableCell className={classes.tableCell} align="left" padding={'none'}
                                    style={{fontWeight: 700, color: auxColor}}>
                                    {findNumber('|2|') ?  'Nueva' : ""} 
                                </TableCell>
                                <TableCell className={classes.tableCell} align="center" padding={'none'} style={{
                                    paddingLeft: 5 + 'px',
                                    fontWeight: 700,
                                    color: auxColor
                                }}>{findNumber('|2|') ?  'Actividad' : ""}</TableCell>
                                {celdaVacia}
                                {celdaVacia}
                                {celdaVacia}
                                {celdaVacia}
                                {celdaVacia}
                                {celdaVacia}
                            </TableRow>
                            : null
                        }
                        {datos.length !== 0 ? (datos.map((row) => (
                            <Row key={row.Id} row={row}/>
                        ))) : null}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
    const tablaSinDatos = () => {
        return (<TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" padding={'none'}>Folio </TableCell>
                            <TableCell align="center" padding={'none'}>Fecha</TableCell>
                            <TableCell align="center" padding={'none'}>Prioridad</TableCell>
                            <TableCell align="left" padding={'none'}>Tipo</TableCell>
                            <TableCell align="left" padding={'none'}>Descripción</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {usuario?.nivel !== undefined && usuario.nivel !== nvlSNPermiso ?
                            <TableRow className={classes.tableRow}>
                                <TableCell className={classes.tableCell} align="center" padding={'none'}
                                    style={{width: 40 + 'px', paddingLeft: 10 + 'px'}}>
                                    {findNumber('|2|') ? 
                                        <IconButton aria-label="agregar-actividad" size="small" 
                                            onClick={() => nuevaActiv()} component="span">
                                            <AddIcon color="primary"/>
                                        </IconButton>
                                     : null}
                                </TableCell>
                                <TableCell className={classes.tableCell} align="center" padding={'none'}
                                           style={{
                                               width: 120 + 'px',
                                               paddingLeft: 5 + 'px',
                                               fontWeight: 700,
                                               color: auxColor
                                           }}>
                                      {findNumber('|2|') ?  'Nueva' : ""}
                                </TableCell>
                                <TableCell className={classes.tableCell} align="center" padding={'none'}
                                           style={{
                                               width: 30 + 'px',
                                               paddingLeft: 5 + 'px',
                                               fontWeight: 700,
                                               color: auxColor
                                           }}>
                                    {findNumber('|2|') ?  'Actividad' : ""}
                                </TableCell>
                                <TableCell className={classes.tableCell} align="left" padding={'none'}></TableCell>
                                <TableCell className={classes.tableCell} align="left" padding={'none'}></TableCell>
                            </TableRow>
                            : null
                        }
                        <TableRow className={classes.tableRow}>
                            <TableCell className={classes.tableCell} align="left" padding={'none'}
                                       style={{width: 30 + 'px'}}>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center" padding={'none'}
                                       style={{width: 120 + 'px', paddingLeft: 5 + 'px'}}>Orden </TableCell>
                            <TableCell className={classes.tableCell} align="center" padding={'none'}
                                       style={{width: 120 + 'px', paddingLeft: 5 + 'px'}}>Sin</TableCell>
                            <TableCell className={classes.tableCell} align="left"
                                       padding={'none'}>Actividades</TableCell>
                            <TableCell className={classes.tableCell} align="left" padding={'none'}></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
    const contenido = !sinDatos ? tablaCompleta() : tablaSinDatos()
    const cargando = !espera ? contenido : <Box my={2}><LinearProgress/></Box>
    const modal = modalAbierto ?
        (usuario?.nivel !== undefined && usuario.nivel !== nvlSNPermiso ?
                (<NuevaActividad titulo={'Nueva Actividad'} modalAbierto={modalAbierto}
                    setModalAbierto={setModalAbierto} auxOrdenId={orden.Id}
                    funcionActuliza={funcionActuliza}
                />)
                : null
        )
        : null
    const modal2 = modalAbierto2 ?
        (usuario?.nivel !== undefined && usuario.nivel !== nvlSNPermiso ?
                (
                    <EditarActividad titulo={'Editar Orden ' + orden.Id + ' Actividad ' + selectActividad.folio}
                        modalAbierto={modalAbierto2} funcionActuliza={funcionActuliza}
                        setModalAbierto={setModalAbierto2} auxActividad={selectActividad}
                    />)
                : (<EditarActividadDummy titulo={'Orden ' + orden.Id + ' Actividad ' + selectActividad.folio}
                        modalAbierto={modalAbierto2}
                        setModalAbierto={setModalAbierto2} auxActividad={selectActividad}
                />)
        )
        : null
    return (
        <Paper className={classes.root}>
            {cargando}
            {modal}
            {modal2}
        </Paper>
    );
}
