import React from 'react'
import {makeStyles,Hidden} from '@material-ui/core'
import Navbar from './Navbar'
import Cajon from './Cajon'
//import Cajita from './Cajita'
import {AuthContext} from '../context/AuthContext'
import CssBaseline from '@material-ui/core/CssBaseline';

const estilos = makeStyles(theme=>({
    miroot:{
        display:'flex',
    },
    eltoolbar:theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing(1), 
      },

}))
const Contenedor = (propiedades) => {
    const misclases = estilos()
    const [abrir,setAbrir]=React.useState(false);
    const {usuario}=React.useContext(AuthContext)

    const funcionAbrir=()=>
    {
      setAbrir(!abrir);
    }
    
    return (
      <div className={misclases.miroot}>
        <CssBaseline />
        {usuario.length!==0? <Navbar funcionAbrir={funcionAbrir} usuario={usuario}/>
          : null  
        }
        {usuario.length!==0?(
          <span>
            <Hidden mdDown>
              <Cajon 
                variant="permanent"   open={true}
                usuario={usuario}
              />    
            </Hidden>
            <Hidden lgUp>
              <Cajon funcionAbrir={funcionAbrir} 
                variant="temporary" open={abrir}
                onClose={funcionAbrir} usuario={usuario}
              />    
            </Hidden>
          </span>
        ):null}
        <div className={misclases.content}>
          <div className={misclases.eltoolbar}></div>
          {
            propiedades.children
          }              
        </div>          
      </div>
    )
}

export default Contenedor
