import React,{useState,useContext,useEffect} from "react";
import { AuthContext } from "../context/AuthContext";
import { GeneralesContext } from "../context/GeneralesContext";
import { Box,  IconButton, Tooltip, LinearProgress, } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Reporte from "./Reporte";
import ReporteSN from "./ReporteSNDatos";
import moment from "moment";
import axios from "axios";
import qs from "qs";
import { useHistory } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns"; 
import esLocale from "date-fns/locale/es";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {llamaApiCargaToken, ErrorGeneral} from '../funciones/ClienteAxios';
import {findNumber} from "../funciones/FindNumber";
const RepoCCTV = () => {
  const history = useHistory();
  const source = axios.CancelToken.source();
  const { usuario, guardarUsuario } = useContext(AuthContext);
  const { guardarGenerales } = useContext(GeneralesContext);
  const [datos, setDatos] = useState([]);
  const [espera, setEspera] = useState(false);
  const [buscar, setBuscar] = useState(true);
  const [fechas, setFechas] = useState({ auxFecha: new Date(), fechaFin: new Date(), });
  const { auxFecha, fechaFin } = fechas;

  useEffect(() => {
    
    async function datosBitacora(usuID) {
      let fInicio = moment(auxFecha).format("YYYY-MM-DD");
      let fecFinal = moment(fechaFin).format("YYYY-MM-DD");            
      let data = qs.stringify({
        fechaIni: fInicio + " 00:00:00",
        fechaFin: fecFinal + " 23:59:59",
        usuario: usuID,
        idDispositivo: '987gyubjhkkjh'
      });
      let url = "/reporte-cctv-rango";

      function respuesta(aux) {
        if (aux[0].length !== 0) {
          setDatos(aux[0]);
        }
      }
      llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);    
    }

    const veriSesion = () => {
      const usu = localStorage.getItem("UsuarioNom");
      const nvl = localStorage.getItem("Tipo");
      const usuID = localStorage.getItem("UsuId");
      if (usu == null || usuID == null) {
        guardarUsuario([]);
        guardarGenerales([]);
        localStorage.clear();
        history.push("/");
      } else {
        if (usuario.length === 0) {
          guardarUsuario({ Nombre: usu, nivel: nvl });
        }
        guardarGenerales({ Titulo: "Reporte CCTV" });
        datosBitacora(usuID);
      }
    };

    veriSesion();

    return () => {
      source.cancel();
    };
    //la siguiente linea puede generar errores o no en el useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buscar]);

  const guardarFin = (auxF) => {
    setFechas({
      ...fechas,
      fechaFin: auxF,
    });
  };

  const incrementarBuscar = e =>
  { 
    setBuscar(!buscar)        
  };

  function verificaReporte(auxJson) {
    let auxValido = false;
    if (auxJson.ordenes) {
      auxValido = true;
      console.log("no esta vacio");
    } else {
      auxValido = false;
      console.log("si esta vacio");
    }
    return auxValido;
  }

  const guardarInicio = (auxF) => {
    setFechas({
      ...fechas,
      auxFecha: auxF,
    });
  };

  return (
    <div>
      <Box display="flex" flexDirection="row">
        <Box display="flex" width="15%" flexDirection="column">                    
          <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
            <Box component="span" pb={1}>
              <Tooltip title="Seleccionar Fecha Inicial">
                <Box ml={1} component="span">
                  <DatePicker
                    disableFuture
                    format="dd/MM/yyyy" size="small"
                    views={["year", "month", "date"]}
                    label="Fecha Inicial" minDate="2021-07-14"
                    maxDate={new Date()} value={auxFecha}
                    onChange={guardarInicio} style={{ width: 7 + "rem" }}
                    cancelLabel={"Cancelar"} okLabel="Seleccionar"       
                  />
                </Box>
              </Tooltip>
            </Box>
            <Box component="span" pb={1}>
              <Tooltip title="Seleccionar Fecha Final">
                <Box ml={1} component="span">
                  <DatePicker
                    disableFuture
                    format="dd/MM/yyyy" size="small"
                    views={["year", "month", "date"]}
                    label="Fecha Final" minDate={auxFecha}
                    maxDate={new Date()} value={fechaFin}
                    onChange={guardarFin} style={{ width: 7 + "rem" }}
                    cancelLabel={"Cancelar"} okLabel="Seleccionar"       
                  />
                </Box>
              </Tooltip>
            </Box>
          </MuiPickersUtilsProvider>     
          <Tooltip title="Buscar reporte" >  
            <Box width={{width:4+'rem'}} display="flex" justifyContent="center">                 
            <IconButton color="inherit"  aria-label="add circle" 
              component="span" onClick={incrementarBuscar} 
            >
              <SearchIcon color="primary" style={{ fontSize: 40 }}  />
            </IconButton>  
            </Box>
          </Tooltip>     
        </Box>
        <Box display="flex" width="80%">
          {datos.length !== 0 && !espera ? (
            verificaReporte(datos) ? (
              <Reporte
                urlReport={"reports/Reporte_cctv_230213.mrt"}
                informacion={datos}
                nombre={" Reporte CCTV"}
                mostrarTB={findNumber('|64|') ?  true : false}
              />
            ) : (
              <ReporteSN
                urlReport={"reports/Reporte_cctv_230213.mrt"}
                informacion={datos}
                nombre={" Reporte CCTV"}
                mostrarTB={findNumber('|64|') ?  true : false}
              />
            )
          ) : (
            <Box style={{ width: "100%" }} pt={2}>
              <LinearProgress color="primary" />
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default RepoCCTV;
