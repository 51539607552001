import React from 'react';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import esLocale from "date-fns/locale/es";
import { DatePicker,MuiPickersUtilsProvider} from '@material-ui/pickers';
import { Box,Tooltip} from '@material-ui/core'
const Relojes2 = ({fechas,setFechas}) => {    
  const {auxFecha}=fechas  
  const guardarInicio = auxF =>
  {
    setFechas({
      ...fechas,
      auxFecha : auxF  
    })
  };  
  return (
  <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>    
    <Box component="span" pb={1}  >
      <Tooltip title="Seleccionar Fecha" >
        <Box  ml={1}  component="span"  >           
          <DatePicker
            disableFuture   format="dd/MM/yyyy"
            views={["year", "month", "date"]}
            label="Fecha" size="small"
            minDate="2021-07-14" maxDate={new Date()}
            value={auxFecha} onChange={guardarInicio}            
            style={{width:7+'rem'}} cancelLabel={"Cancelar"}                  
            okLabel="Seleccionar" 
          />                      
        </Box>    
      </Tooltip>                                   
    </Box>                         
  </MuiPickersUtilsProvider>
  );
}

export default Relojes2
