import React,{useState} from 'react';
import {FormControl,InputLabel,Select as MaterialSelect ,
  Box,Typography} from '@material-ui/core';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
//import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
//import Alert from '@material-ui/lab/Alert';
import {DateTimePicker, MuiPickersUtilsProvider, TimePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import esLocale from "date-fns/locale/es";
import ModalTam from '../Generales/ModalTam';
import {toast} from 'react-toastify';
import {llamadaApiToken,llamaApiCargaToken,ErrorGeneral } from '../funciones/ClienteAxios';
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'noWrap',
    //alignItems: 'center',
    flexDirection:"column"
  },
  wrapper: {
    //margin: theme.spacing(0),
    marginTop: 0,
    paddingTop:0,
    position: 'relative',
  },
 
  buttonGuardar:{color: green[500],},
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function ModalNvo({modalAbierto,setModalAbierto,titulo,listStatus ,listUbic,listTipo,actualizar}) {  
  let history = useHistory();
  const classes = useStyles();
  const source = axios.CancelToken.source();   
  const [aviso, setAviso] = useState(false);  
  const [mensaje, setMensaje] = useState('');
  const [tipoAdvertencia, setTipoAdvertencia] = useState('success');    
  const [nuevaFalla,guardarNuevaFalla]= useState({ Usuario:'', Terminal:'', Descripcion:'',
                                              Ubicacion:'',UbicDescr:'',TipoId:'',Status:0,Titulo:""})          
  
  const [espera,setEspera]=useState(false);
  const [fechas,setFechas]=useState({inicio:new Date() ,final:new Date()});
  const {Descripcion ,Solucion ,Ubicacion,UbicDescr,TipoId,Status,Titulo} = nuevaFalla;
  const {inicio,final}=fechas  

  const guardarInicio = auxfecha =>
  {
    setFechas({
      ...fechas,
      inicio : auxfecha  
    })
  };

  const registrarNvo=(UbicNom,auxFecha)=>{
    let idusu= localStorage.getItem('UsuId');
    const llenarNvoParo = () => {               
      let data = qs.stringify({                    
          Usuario:idusu,
          Fecha:auxFecha,
          Tipo:1,     
          UbicId:Ubicacion,
          UbicNom:UbicNom ? UbicNom:"",
          Status:1,
          Descr:Descripcion,
          Titulo:Titulo,
          idDispositivo:'iu87ygubhjjij',
      });   
      let url = "/cctv-bitacora-nuevo-1-2";
      function respuesta(aux) {
        if (aux.respuesta === 1) 
        {   let mensaje="Se registro correctamente "
          setTipoAdvertencia("success")      
          setMensaje(mensaje);
          setAviso(true)           
          //setRegistroExitoso(true) 
          toast.success(mensaje,{
            pauseOnFocusLoss: false,                    
            toastId: `nvo-cctv${aux.mensaje}`
          })
          actualizar()
          setModalAbierto(false)
        }       
      }    
      llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);
    }            
    llenarNvoParo();    
  }  

  const mostrarAlertas =(auxMensaje)=>{ 
    setTipoAdvertencia("warning")
    let mensaje=auxMensaje            
    setMensaje(mensaje);
    setAviso(true)   
  } 

  function valNulos(auxMensaje,auxCampo) 
  {    let valida=false    
    if ( auxCampo !== '' && auxCampo !== '0' && auxCampo !==0 
        && auxCampo !== null && auxCampo!==" " ) 
    {   valida=true              
    }
    if (!valida) 
    {   mostrarAlertas(auxMensaje)
    }
    return valida
  }

  const guardarParo = () => {
    let auxFecha=moment(inicio).format("YYYY-MM-DDTHH:mm:ss")
    //console.log(auxFecha);
    if (valNulos("Escribir Titulo",Titulo )//valNulos("Seleccionar Estatus",Status ) //&& valNulos("Seleccionar Tipo",TipoId ) 
      && valNulos("Seleccionar Ubicación",Ubicacion )  ) 
    {       
      if (Descripcion.length === 0  && valNulos("Escribir Descripción",'' ) || Descripcion.length >= 5      
      ) 
      { setAviso(false)
        //llamada(descUbicacion)   
        let auxUbiNom=listUbic.filter((ubic)=>ubic.value=== parseInt(Ubicacion))    
        
        //console.log(nuevaFalla,auxUbiNom[0].label);                                                                                         
        registrarNvo(auxUbiNom[0].label,auxFecha)
      }                                 
    }                           
  };
  
  const onChange = e =>
    {
      guardarNuevaFalla({
        ...nuevaFalla, [e.target.name] : e.target.value  
      })
    };

  const fecha=() =>{
  return  <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>      
                                                
       {/*  <TimePicker
          disableFuture          
          openTo="hours" format="HH:mm:ss" 
          views={["hours", "minutes", "seconds"]} 
          format="dd/MM/yyyy hh:mm:ss"
          views={["year", "month", "date"]}
          label="Hora" size="small"
          minDate="2017-03-14" maxDate={new Date()}
          value={inicio} onChange={guardarInicio}      
          style={{width:6.5+'rem'}}      
        /> */}
        <DateTimePicker
          label="Hora" size={"small"} ampm={false}
          value={inicio} style={{width:10+'rem'}}
          onChange={guardarInicio}
          //helperText="Hora"
          minDate="2017-03-14" maxDate={new Date()}
          format="dd/MM/yyyy HH:mm"
          cancelLabel={"Cancelar"} okLabel="Seleccionar"       
        />
                            
    </MuiPickersUtilsProvider>
  }
  return (
    <ModalTam  titulo={titulo} modalAbierto={modalAbierto} 
      setModalAbierto={setModalAbierto} guardarDatos={guardarParo} 
      mensaje={mensaje} tipoAdvertencia={tipoAdvertencia} 
      aviso={aviso} setAviso={setAviso} espera={espera} 
      setEspera={setEspera} tamanio="md" btnDesact={false}
    >    
    <form className={classes.root} noValidate autoComplete="off" fullWidth>     
   
    <Box mb={2}>
      {fecha()}
    { /* listStatus.length !== 0 ?
      <FormControl className={classes.formControl} size="small" >
        <InputLabel htmlFor="age-native-simple">Estatus</InputLabel>
        <MaterialSelect
          native value={Status} onChange={onChange}
          inputProps={{ name: 'Status', id: 'Status',}}
        >
          <option aria-label="None" value="" />                     
          {listStatus.map((row,index) => (
            <option value={row.value} key={index} >{row.label}</option>
          ))}   
        </MaterialSelect>
      </FormControl>     
    :null */}       
    { /* listTipo.length !== 0 ?
      <FormControl className={classes.formControl} size="small" style={{marginLeft:1+'rem'}} >
        <InputLabel htmlFor="age-native-simple">Tipo</InputLabel>
        <MaterialSelect
          native value={TipoId} onChange={onChange}
          inputProps={{ name: 'TipoId', id: 'TipoId',}}
        >
          <option aria-label="None" value="" />                     
          {listTipo.map((row,index) => (
            <option value={row.value} key={index} >{row.label}</option>
          ))}   
        </MaterialSelect>
      </FormControl>     
    :null */}             
    { listUbic.length !== 0 ?
      <FormControl className={classes.formControl} size="small" style={{marginLeft:1+'rem'}} >
        <InputLabel htmlFor="age-native-simple">Ubicación</InputLabel>
        <MaterialSelect
          native value={Ubicacion} onChange={onChange}
          inputProps={{ name: 'Ubicacion', id: 'Ubicacion',}}
        >
          <option aria-label="None" value="" />                     
          {listUbic.map((row,index) => (
            <option value={row.value} key={index} >{row.label}</option>
          ))}   
        </MaterialSelect>
      </FormControl>     
    :null}     
    
    </Box>                          
    <Box>
      <Typography component="p" variant="body2" color='primary' 
        style={{margin:0,marginLeft:.5+'rem'}} 
      >
        Titulo
      </Typography>   
      <textarea id="Titulo-input" name="Titulo" rows="3" placeholder="Titulo"
        cols="100" style={{width: 24+"rem",borderRadius:3+'px'}} 
        value={Titulo} onChange={onChange} maxlength={500}
      />
    </Box>
  
    <Box>
      <Typography component="p" variant="body2" color='primary' 
        style={{margin:0,marginLeft:.5+'rem'}} >
          Descripción
      </Typography>   
      <textarea id="descripcion-input" name="Descripcion" rows="5"
        cols="100" placeholder="Descripción" style={{width: 24+"rem",borderRadius:3+'px'}} 
        value={Descripcion} onChange={onChange} maxlength={2000}
      />
    </Box>
  </form>
  </ModalTam>
  );
}
