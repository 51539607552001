import React, { useEffect, useRef, useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Box,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select as MaterialSelect,
  TextField,
} from "@material-ui/core";
import Modal from "../Generales/ModalTam";
import qs from "qs";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { toast } from "react-toastify";
import {
  ErrorGeneral,
  llamaApiCargaToken,
  llamadaApiToken,
} from "../funciones/ClienteAxios";
import axios from "axios";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "noWrap",
    alignItems: "center",
  },
  wrapper: {
    marginTop: 0,
    paddingTop: 0,
    position: "relative",
  },

  buttonGuardar: { color: green[500] },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function EditarOrden({
  setModalAbierto,
  titulo,
  modalAbierto,
  seleccionadoEdit,
  funcionActuliza,
}) {
  const classes = useStyles();

  const source = axios.CancelToken.source();
  let idusu = localStorage.getItem("UsuId");
  let history = useHistory();
  let NomUsu = localStorage.getItem("UsuarioNom");
  let numUbiOtro = 234;

  const descriptionElementRef = useRef(null);  
  const [aviso, setAviso] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
  const [btnDesact, setBtnDesact] = useState(false);
  const [listPeriodo, setListPeriodo] = useState([]);
  const [listLugar, setListLugar] = useState([]);
  const [listLugarTerminal, setListLugarTerminal] = useState([]);
  const [listTipo, setListParoTipo] = useState([]);
  const [ubicacionSeleccion, guardarUbicacionSeleccion] = useState([]);
  const [auxUbicacionSelecc, guardarAuxUbicacionSelecc] = useState([]);
  const [listUbicacion, guardarListUbicacion] = useState([]);
  const [auxUbicacionTipo, guardarAuxUbicacionTipo] = useState([]);
  const [listSeccion, guardarListSeccion] = useState([]);
  const [espera, setEspera] = useState(false);
  const [listEspecialidad,setListEspecialidad] = useState([])
  const [listEvidencia,setListEvidencia] = useState([])
  const [auxContador,setAuxContador] = useState(1)
  const [nuevaFalla, guardarNuevaFalla] = useState({
    Usuario: "",//NomUsu,
    Periodo: seleccionadoEdit.periId,
    Lugar: seleccionadoEdit.lugarId,
    Terminal: seleccionadoEdit.termiId,
    FchCausa: "",
    Turno: seleccionadoEdit.turno,
    Descripcion: seleccionadoEdit.descr,
    Solucion: seleccionadoEdit.solucion,
    Causa: seleccionadoEdit.causa,
    Finaliza: 0,//seleccionadoEdit.finalizaId,
    Usrfinaliza:"",// seleccionadoEdit.Usrfinaliza,
    Ubicacion: seleccionadoEdit.ubicId,
    Estado: seleccionadoEdit.Status.toString(),
    UbicDescr: seleccionadoEdit.ubicDescr,
    SeccId: seleccionadoEdit.seccId,
    TipoId: seleccionadoEdit.tipoId,
    OrdenId: seleccionadoEdit.Id,
    EspecialId:seleccionadoEdit.espId,
  });
  const {
    Lugar,
    Terminal,
    Turno,
    Estado,
    Causa,
    Descripcion,
    Solucion,
    Finaliza,
    Ubicacion,
    UbicDescr,
    SeccId,
    TipoId,
    Periodo,
    EspecialId,
  } = nuevaFalla;

  useEffect(() => {
    const llamaListPeriodo = () => {
      let data = qs.stringify({
        tipo: "MTTO",
        idDispositivo: "987gyubjhkkjh",
      });
      let url = "/mtto-periodo";

      function respuesta(aux) {
        if (aux[0].length !== 0) {
          setListPeriodo(aux);
        }
      }

      llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
    };

    if (listPeriodo.length === 0) {
      llamaListPeriodo();
    }

    const llenarListLugar = () => {
      let data = qs.stringify({
        tipo:'MTTO',
        idDispositivo: "89y7gttvjhj98h",
      });
      let url = "/lugar-list-1_1";

      function respuesta(aux) {
        if (aux[0].Id !== -1) {
          let auxArre = [];
          aux.map((tipo) => {
            if (tipo.Id != 0) {
              auxArre.push({ value: tipo.Id, label: tipo.Tipo });
            }
          });
          setListLugar(auxArre);
        }
      }

      llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
    };

    if (listLugar.length === 0) {
      llenarListLugar();
    }

    const llenarListTerminal = () => {
      let data = qs.stringify({
        usuario: idusu,
        idDispositivo: "89y7gttvjhj98h",
      });
      let url = "/lugar-terminal-list";

      function respuesta(aux) {
        if (aux[0].Id !== -1) {
          let auxArre = [];
          aux.map((tipo) => {
            if (tipo.Id != 0) {
              auxArre.push({ value: tipo.Id, label: tipo.Descr });
            }
          });
          setListLugarTerminal(auxArre);
        }
      }

      llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
    };

    if (listLugarTerminal.length === 0) {
      llenarListTerminal();
    }

    const llenarListParo = () => {
      let data = qs.stringify({
        idDispositivo: "89y7gttvjhj98h",
      });
      let url = "/mtto-actividad-tipo";

      function respuesta(aux) {
        if (aux[0].Id !== -1) {
          let auxArre = [];
          aux.map((tipo) => {
            auxArre.push({ value: tipo.Id, label: tipo.Tipo });
          });
          setListParoTipo(auxArre);
        }
      }

      llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
    };

    if (listTipo.length === 0) {
      llenarListParo();
    }
    const llenarListEspecial = () => {
        let data = qs.stringify({          
          idDispositivo: "89y7gttvjhj98h",
        });
        let url = "/mtto-especialidad-list";
  
        function respuesta(aux) {
          if (aux[0].Id !== -1) {
            let auxArre = [];
            aux.map((tipo) => {
              if (tipo.Id != 0) {
                auxArre.push({ value: tipo.Id, label: tipo.Tipo });
              }
            });
            setListEspecialidad(auxArre);
          }
        }  
        llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
    };

    function llenarUbicaciones() {
      const auxUbi = JSON.parse(localStorage.getItem('LisUbicMtto'));
      let auxArre = [];
      let auxArre2 = [];
      auxArre2.push('TODO')           
      auxUbi.forEach((ubica) => {
          if (ubica.LugarId === parseInt(Lugar) && (ubica.Terminal.search(Terminal) !== -1)) {
              auxArre.push({value: ubica.Id, label: ubica.Tipo})
              auxArre2.push(ubica.Tipo)
          }
      })
      if (Lugar === 5 || Lugar === "5") {
          
         guardarNuevaFalla({
              ...nuevaFalla,
              Ubicacion: numUbiOtro,
              UbicDescr: (UbicDescr.length !== 0 ? UbicDescr : ''),
          })  
      } 
      else 
      {   //let auxUId=seleccionadoEdit.ubicId
          
          let auxUDesc= seleccionadoEdit.ubicDescr                
          if (auxContador===1&&auxUDesc.length!==0) 
          {    
              let arregloExtraido=[]
              let arregloExtraido2=[]
              let contadorComa=0
              // console.log(auxUDesc);
              for (let index = 0; index < auxUDesc.length; index++) 
              {
                  if (auxUDesc.charAt(index)!=','&&auxUDesc.charAt(index)!=' ') 
                  {
                  contadorComa++;              
                  }
                  else
                  {
                  if (auxUDesc.charAt(index)==',') 
                  {
                      let auxNumero=""
                      for (let auxIndice = contadorComa; auxIndice > 0; auxIndice--) 
                      {                  
                      auxNumero=auxNumero+auxUDesc.charAt(index-auxIndice)                  
                      }                
                      let auxFiltra1 = auxArre.filter(  (registro) => registro.label == auxNumero  )    
                      arregloExtraido.push(auxFiltra1)                
                      arregloExtraido2.push(auxNumero)  
                      contadorComa=0
                  }              
                  }            
              }
              if (contadorComa>0) 
              {
                  contadorComa++
                  let auxNumero=""
                  for (let auxIndice = contadorComa; auxIndice > 0; auxIndice--) 
                  {                  
                  if (auxUDesc.charAt(auxUDesc.length-auxIndice)!=' ') 
                  {
                      auxNumero=auxNumero+auxUDesc.charAt(auxUDesc.length-auxIndice)  
                  }                                                  
                  }            
                  let auxFiltra1 = auxArre.filter(  (registro) => registro.label == auxNumero  )    
                  arregloExtraido.push(auxFiltra1)            
                  arregloExtraido2.push(auxNumero)  
                  contadorComa=0
              }             
              guardarUbicacionSeleccion(arregloExtraido)   
              if (arregloExtraido.length==auxArre.length) 
              {
                  let tod=[]
                  tod.push('TODO')
                  arregloExtraido2.forEach((ubica) => {                              
                  tod.push(ubica)                            
                  })    
                  
                  guardarAuxUbicacionSelecc(tod);
              } 
              else 
              {
                  guardarAuxUbicacionSelecc(arregloExtraido2);  
              }                         
              guardarNuevaFalla({
                  ...nuevaFalla,            
                  UbicDescr :auxUDesc+', ',
              })   
          } 
          else 
          {
               guardarNuevaFalla({
                  ...nuevaFalla,
                  Ubicacion :0,
                  UbicDescr :'',
              })           
              guardarUbicacionSeleccion([])      
              guardarAuxUbicacionSelecc([]);
          }
      }
      setAuxContador(auxContador+1)
      //guardarUbicacionSeleccion([])
      //guardarAuxUbicacionSelecc([]);
      guardarListUbicacion(auxArre)
      guardarAuxUbicacionTipo(auxArre2)
  }

    if (Lugar !== "" &&Lugar !== 0 && Terminal !== 0&&Terminal !== "") {
      llenarUbicaciones();
    }

    async function llenarListParoAla() {
      let data = qs.stringify({
        idDispositivo: "8978yuhjnfrsdsd",
      });
      let url = "/mtto-seccion-lista";

      function respuesta(aux) {
        if (aux[0].Id !== -1) {
          let auxArre = [];
          aux.forEach((tipo) => {
            if (tipo.Id !== 0) {
              auxArre.push({ value: tipo.Id, label: tipo.Tipo });
            }
          });
          guardarListSeccion(auxArre);
        }
      }

      llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
    }
    
    const llenarListEvidencia = () => {
        let data = qs.stringify({
            llave:seleccionadoEdit.Id,
            idDispositivo: "89y7gttvjhj98h",
        });
        let url = "/mtto-evidencia-list_1_1";
  
        function respuesta(aux) {
          if (aux[0].Id !== -1) {            
            setListEvidencia(aux);
          }
        }
  
        llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
    };

    if (listSeccion.length === 0) {
      llenarListParoAla();
    }
    if (listEspecialidad.length===0) 
    { llenarListEspecial() 
    }   
    if (listEvidencia.length===0) 
    { llenarListEvidencia() 
    } 
    return () => {
      source.cancel();
    };
  }, [Terminal, Lugar]);

  function llamada(auxSolucion, auxUbic) {
    const guardaNuevaFalla = () => {
      setBtnDesact(true);
      setEspera(true);
      let data = qs.stringify({
        MttoId: seleccionadoEdit.Id,
        Usuario: idusu,
        Periodo: 1,//Periodo,
        Terminal: Terminal,
        Lugar: Lugar,
        Turno: Turno,
        Estatus: Estado,
        Finaliza: Finaliza=== 1 ? 1 : (Estado === "1" ? 1 : 0),
        Fechafinaliza: " ",
        Descripcion: Descripcion,
        Solucion: auxSolucion,
        Causa: Causa,
        Fchcausa: " ",
        Usrfinaliza: Finaliza=== 1 ? NomUsu:(Estado === "1" ?  seleccionadoEdit.Usrfinaliza : ""),
        UbicId: Ubicacion,
        UbicDescr: Ubicacion == 0 ? auxUbic : UbicDescr,
        SeccId: SeccId,
        TipoId: TipoId,
        EspecialId:EspecialId,
        idDispositivo: "9y87tgfvjbhj",
      });
      let url = "/mtto-edit";

      function response(auxiliar) {
        if (auxiliar.respuesta == 1) {
          let mensaje = "Se edito el Mtto correctamente";
          setTipoAdvertencia("success");
          setMensaje(mensaje);
          setAviso(false);
          toast.success(mensaje, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });                    
          setBtnDesact(false);
          setModalAbierto(false);        
          funcionActuliza();
        }
      }

      llamaApiCargaToken(
        data,
        url,
        response,
        ErrorGeneral,
        setEspera,
        history,
        source
      );
    };
    guardaNuevaFalla();
  }

  const mostrarAlertas = (auxMensaje) => {
    setTipoAdvertencia("warning");
    let mensaje = auxMensaje;
    setMensaje(mensaje);
    setAviso(true);
  };

  function valNulos(auxMensaje, auxCampo) {
    let valida = false;
    if ( auxCampo !== "" && auxCampo !== "0" && auxCampo !== 0 &&
         auxCampo !== null && auxCampo !== " "
        )
    {
      valida = true;
    }
    if (!valida) 
    {   mostrarAlertas(auxMensaje);
    }
    return valida;
  }

  const guardarDatos = () => {
    if ((Estado=== "1"&&Finaliza===1 && listEvidencia.length>0 )|| Estado!=="1")  
    {
        if (
            valNulos("Seleccionar Terminal", Terminal) &&
            valNulos("Seleccionar Tipo", TipoId) &&
           // valNulos("Seleccionar Periodo", Periodo) &&
            valNulos("Seleccionar Lugar", Lugar) &&
            valNulos("Seleccionar Ubicación", UbicDescr) && 
            valNulos("Seleccionar Sección.",SeccId)&&           
            valNulos("Seleccionar Especialidad.",EspecialId)&&
            valNulos("Ingrese una Descripcion", Descripcion) &&
            (Finaliza===1 &&valNulos("Ingrese una Solución", Solucion) || Estado!=="1")
          ) 
        {            
            llamada(Solucion,  UbicDescr.substring(0, UbicDescr.length - 2));
        }    
    } 
    else 
    {
        mostrarAlertas("Debe subir una foto de Evidencia para cerrar el Mantenimiento.")        
    }
    
  };

  const guardaTurno = (e) => {
    guardarNuevaFalla({
      ...nuevaFalla,
      Turno: e.target.value,
    });
  };

  const onChange = (e) => {
    guardarNuevaFalla({
      ...nuevaFalla,
      [e.target.name]: e.target.value,
    });
  };

  const handleDescripcion = (e) => {
    e.preventDefault();
    guardarNuevaFalla({ ...nuevaFalla, Descripcion: e.target.value });
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const filterArray = (arr1, arr2) => {
    const filtered = arr1.filter((el) => {
      return arr2.indexOf(el) === -1;
    });
    return filtered;
  };

  const seleccionarUbicacion = (e) => {
    let resultados = [];
    let auxArreglo = [];

    if (e.target.value.length > 1) {
      let ubicNueva = filterArray(auxUbicacionSelecc, e.target.value);

      if (ubicNueva.length !== 0) {
        if (ubicNueva == "TODO") {
          auxArreglo = [];
        } else {
          resultados = listUbicacion.filter(
            (registro) => registro.label == ubicNueva
          );
          if (ubicacionSeleccion.length > 1) {
            let arrFiltrado = ubicacionSeleccion.filter((registro) => {
              return registro.value !== resultados[0].value;
            });
            auxArreglo = arrFiltrado;
          } else {
            auxArreglo.push({
              value: resultados[0].value,
              label: resultados[0].label,
            });
          }
        }
      } else {
        if (e.target.value[e.target.value.length - 1] === "TODO") {
          auxArreglo = listUbicacion;
        } else {
          resultados = listUbicacion.filter(
            (registro) =>
              registro.label === e.target.value[e.target.value.length - 1]
          );
          auxArreglo = ubicacionSeleccion;
          auxArreglo.push({
            value: resultados[0].value,
            label: resultados[0].label,
          });
        }
      }
    } else {
      if (e.target.value[0] === "TODO") {
        auxArreglo = listUbicacion;
      } else {
        resultados = listUbicacion.filter(
          (registro) => registro.label === e.target.value[0]
        );
        if (resultados.length !== 0) {
          auxArreglo.push({
            value: resultados[0].value,
            label: resultados[0].label,
          });
        } else {
          auxArreglo = [];
        }
      }
    }

    guardarUbicacionSeleccion(auxArreglo);

    if (auxArreglo.length !== 0) {
      if (auxArreglo[0].length != 0) {
        let nomUbiID = "";
        auxArreglo.forEach((ubicacion) => {
          nomUbiID = nomUbiID + ubicacion.value + ",";
        });
        let nomUbi = "";
        if (e.target.value[e.target.value.length - 1] === "TODO") {
          auxArreglo = listUbicacion;
          auxArreglo.forEach((ubicacion) => {
            nomUbi = `${nomUbi}${ubicacion.label}, `;
          });
        } else {
          e.target.value.forEach((ubicacion) => {
            if (ubicacion !== "TODO") {
              nomUbi = `${nomUbi} ${ubicacion}, `;
            }
          });
        }
        guardarNuevaFalla({
          ...nuevaFalla,
          Ubicacion: 0,
          UbicDescr: nomUbi,
        });
      } else {
        guardarNuevaFalla({
          ...nuevaFalla,
          Ubicacion: "",
          UbicDescr: "",
        });
      }
    } else {
      guardarNuevaFalla({
        ...nuevaFalla,
        Ubicacion: "",
        UbicDescr: "",
      });
    }

    if (e.target.value[e.target.value.length - 1] === "TODO") {
      //console.log(e.target.value[e.target.value.length-1]);
      if (auxArreglo.length != 0) {
        guardarAuxUbicacionSelecc(auxUbicacionTipo);
      } else {
        guardarAuxUbicacionSelecc([]);
      }
    } else {
      let ubicNueva = filterArray(auxUbicacionSelecc, e.target.value);
      if (ubicNueva[0] == "TODO") {
        guardarAuxUbicacionSelecc([]);
      } else {
        guardarAuxUbicacionSelecc(e.target.value);
      }
    }
  };

  const guardaUbicacionInput = (e) => {
    guardarNuevaFalla({
      ...nuevaFalla,
      Ubicacion: numUbiOtro,
      UbicDescr: e.target.value,
    });
  };

  const guardaEstado = (e) => {
    guardarNuevaFalla({
      ...nuevaFalla,
      Estado: e.target.value,
      Finaliza: e.target.value==="1"?1:0,
    });
  };

  return (
    <Modal
        titulo={titulo} modalAbierto={modalAbierto}
        setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
        mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
        aviso={aviso} setAviso={setAviso} espera={espera}
        setEspera={setEspera} tamanio={"md"} btnDesact={btnDesact}
    >
      <form className={classes.root} noValidate autoComplete="off" fullWidth>
        <Box>
          <Box mr={2}>
            <FormControl component="fieldset" style={{ width: 30 + "rem" }}>
              <RadioGroup
                    row aria-label="position"
                    defaultValue="top" name="Estado"
                    value={Estado} onChange={guardaEstado}
              >
                <FormControlLabel
                    value="0" label="Registrado"
                    style={{ color: "#F46239" }}
                    control={<Radio color="primary" />}                  
                />
                <FormControlLabel
                    value="1" label="Finalizado"
                    style={{ color: "#049b4f" }}
                    control={<Radio color="primary" />}                  
                />
                <FormControlLabel
                    value="-1" label="Cancelado"
                    style={{ color: "red" }}
                    control={<Radio color="primary" />}                  
                />
              </RadioGroup>
            </FormControl>
            </Box>
            <Box mr={2}>
                <FormControl component="fieldset">
                <FormLabel component="legend">Turno</FormLabel>
                <RadioGroup
                    row aria-label="position"
                    defaultValue="top"
                    name="Turno" value={Turno}
                    onChange={guardaTurno}
                >
                    <FormControlLabel
                        value="N" label="T.Nocturno"
                        control={<Radio color="primary" />}                    
                    />
                    <FormControlLabel
                        value="D" label="T.Diurno"
                        control={<Radio color="primary" />}                    
                    />
                </RadioGroup>
                </FormControl>
            </Box>                      
                {Estado === "-1" ? (
                 <Box mb={1} mt={1}>
                    <TextField
                    size="small"
                    id="causa-cancelacion-input"
                    label="Causa de Cancelación"
                    defaultValue=""
                    fullwidth
                    style={{ width: "100%" }}
                    variant="outlined"
                    value={Causa}
                    onChange={onChange}
                    name="Causa"
                    />
                 </Box>
                ) : null}
           
            <Box display="flex" row mb={2}>  
            <Box mr={2}>
                {listLugarTerminal.length !== 0 ? (
                <FormControl size="small" style={{width:7.5+'rem'}}>
                    <InputLabel htmlFor="age-native-simple">Terminal</InputLabel>
                    <MaterialSelect
                        native value={Terminal} onChange={onChange}
                        inputProps={{ name: "Terminal", id: "Terminal", }}
                    >
                    <option aria-label="None" value="" />
                    {listLugarTerminal.map((row) => (
                        <option value={row.value}>{row.label}</option>
                    ))}
                    </MaterialSelect>
                </FormControl>
                ) : null}
            </Box>
            <Box mr={2}>
                {listTipo.length !== 0 ? (
                <FormControl size="small" style={{width:11.5+'rem'}}>
                    <InputLabel htmlFor="Periodo">Tipo</InputLabel>
                    <MaterialSelect
                        native value={TipoId} onChange={onChange}
                        inputProps={{ name: "TipoId", id: "TipoId", }}
                    >
                    <option aria-label="None" value="" />
                    {listTipo.map((row) => (
                        <option value={row.value}>{row.label}</option>
                    ))}
                    </MaterialSelect>
                </FormControl>
                ) : null}
            </Box>
            <Box mr={2}>
                {listLugar.length !== 0 ? (
                <FormControl size="small" style={{width:5.5+'rem'}}>
                    <InputLabel htmlFor="age-native-simple">Lugar</InputLabel>
                    <MaterialSelect native value={Lugar} onChange={onChange}
                        inputProps={{ name: "Lugar", id: "Lugar", }}
                    >
                    <option aria-label="None" value="" />
                    {listLugar.map((row) => (
                        <option value={row.value}>{row.label}</option>
                    ))}
                    </MaterialSelect>
                </FormControl>
                ) : null}
            </Box>
            
            <Box mr={2}>
                {listUbicacion.length !== 0 ? (
                Lugar !== 5 || Lugar !== "5" ? (
                    <FormControl className={classes.formControl2}
                        style={{ width: 8 + "rem" }} size="small"
                    >
                    <InputLabel id="input-label-ubic">Ubicación</InputLabel>
                    <MaterialSelect
                        labelId="mutiple-checkbox-label-ubic"
                        id="mutiple-checkbox-ubic" multiple
                        value={auxUbicacionSelecc}
                        onChange={seleccionarUbicacion}
                        input={<Input />} MenuProps={MenuProps}
                        renderValue={(selected) => selected.join(", ")}
                    >
                        {auxUbicacionTipo.map((ubic) => {
                        return (
                          <MenuItem key={ubic} value={ubic}>
                          <Checkbox
                            checked={auxUbicacionSelecc.indexOf(ubic) > -1}
                          />
                          <ListItemText primary={ubic} />
                          </MenuItem>
                        );
                        })}
                    </MaterialSelect>
                    </FormControl>
                ) : (
                    <TextField
                    id="outlinedUbicacion"
                    label="Ubicación"
                    size="small"
                    value={UbicDescr}
                    onChange={guardaUbicacionInput}
                    name="UbicDescr"
                    style={{ width: 10 + "rem" }}
                    />
                )
                ) : null}
            </Box>
            <Box mr={2}>
                {listSeccion.length !== 0 ? (
                <FormControl
                    className={classes.formControl} size="small"
                    style={{ width: 4.8 + "rem" }}
                >
                    <InputLabel htmlFor="Seccion-Input">Sección</InputLabel>
                    <MaterialSelect native value={SeccId} onChange={onChange}
                        inputProps={{ name: "SeccId", id: "SeccId", }}
                    >
                    <option aria-label="None" value="" />
                    {listSeccion.map((row) => (
                        <option value={row.value}>{row.label}</option>
                    ))}
                    </MaterialSelect>
                </FormControl>
                ) : null}
            </Box>
            </Box>
            <Box display="flex" row mb={2}>  
        {/*     <Box mr={2}>
                {listPeriodo.length !== 0 ? (
                <FormControl size="small">
                    <InputLabel htmlFor="age-native-simple">Periodo</InputLabel>
                    <MaterialSelect
                        native value={Periodo} onChange={onChange}
                        inputProps={{ name: "Periodo", id: "Periodo" }}
                    >
                        <option aria-label="None" value="" />
                        {listPeriodo.map((row, index) => (
                            <option key={index} value={row.Id}>
                                {row.Tipo}
                            </option>
                        ))}
                    </MaterialSelect>
                </FormControl>
                ) : null}
            </Box> */}                                    
            {listEspecialidad.length!==0 ?
                <Box >
                    <FormControl style={{width:10+'rem'}} size="small" >
                    <InputLabel htmlFor="EspecialId">Especialidad</InputLabel>
                    <MaterialSelect
                        native value={EspecialId} onChange={onChange}
                        inputProps={{ name: 'EspecialId', id: 'EspecialId', }}
                    >
                        <option aria-label="None" value="" />                     
                        {listEspecialidad.map((row) => (
                            <option value={row.value}>{row.label}</option>
                        ))}   
                    </MaterialSelect>
                </FormControl>     
                </Box>
            :null}    
            </Box>    
            <Box >            
            <TextField
              style={{ width: "100%" }} variant="outlined"
              id="Descripcion" label="Descripción"
              multiline rows={4} value={Descripcion}
              onChange={handleDescripcion} name="Descripcion"
            />
            {Finaliza===1 ||Estado==="1" ?
              <TextField style={{ width: "100%" ,marginTop:1+'rem'}}
                id="outlined-sol" name="Solucion" size="small"
                label="Solución"  variant="outlined"
                value={Solucion} onChange={onChange}             
                multiline rows={4}
              />
            :null}
          </Box>
        </Box>
      </form>
    </Modal>
  );
}
