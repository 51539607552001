import React,{useState,useEffect} from 'react'
import { Button,Box, LinearProgress,Menu,
    MenuItem ,Tooltip,IconButton} from '@material-ui/core';
import Modal from "../Generales/ModalInformativo";
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import axios from 'axios';
import AndroidIcon from '@material-ui/icons/Android';
import { useHistory } from "react-router-dom";
import qs from 'qs';
import {llamaApiCargaToken,llamadaApiToken,ErrorGeneral } from '../funciones/ClienteAxios';
const MenuAndroid = ({}) => {
    let history = useHistory();
    const nvl= localStorage.getItem('Tipo')?parseInt(localStorage.getItem('Tipo')):0;      
    const source = axios.CancelToken.source();
    const Dispvo =localStorage.getItem('Dispvo') ;    
    const auxDispV =Dispvo && Dispvo.length >= 12 ? Dispvo:'w8rf51v21dsd2cs'   
    const [cargando, setCargando] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [listApk, setListApk] = useState([]);
    useEffect(() => {
      
        getLista()
        return () => {
            source.cancel();
        }
    }, [])
    
    const getLista = () => {  
        const data = qs.stringify({            
            idDispositivo: auxDispV,
        });
        const url = "/sistema-apk-disponible";
        function respuesta(aux) {
          if (aux) 
          {  //console.log(aux);
            setListApk(aux);    
          }else{
            setListApk([])
          }    
        }
        llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setCargando,history,source);
      };    

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onDownload = (response) => {
                

        // create "a" HTML element with href to file & click
        let a = document.createElement('a');
        a.href = response;
        a.download = "lucy"+".apk";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);   
        //URL.revokeObjectURL(response);   
        setAnchorEl(null);
    };

    return (
    <div >
         <Tooltip title={"Aplicacion Android"} >        
            <IconButton color="inherit" onClick={handleClick} 
               aria-label="menu"  size='small' >
                    <AndroidIcon />
            </IconButton>  
        </Tooltip>

        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            {listApk.length !==0 ? 
                listApk.map((elemento,index)=>
                {return(elemento.Tipo==="Suite" && nvl !==7 && nvl !== 8 )
                    || (elemento.Tipo==="Estructura" && nvl >=7 || nvl === 1)
                    ? 
                    <MenuItem key={index} onClick={()=>onDownload(elemento.Link)}>
                        {`${elemento.Tipo==="Estructura" ?"Observador":elemento.Tipo} ${elemento.Version}`}                        
                    </MenuItem>
                    :null    
                }
                
                )                
            :  null                 
            }
            {/*     <a href={elemento.Link} id={`referencia-apk${index}`}>
                        <MenuItem >{elemento.Tipo}</MenuItem>
                    </a> */}
        </Menu>
    </div>
    )

}

export default MenuAndroid