import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormControlLabel, Box, MenuItem, FormControl, Select, 
         InputLabel, Radio, RadioGroup, FormLabel ,Grid} from "@material-ui/core";
import ModalGeneral from "./Modal";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { useHistory } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {
      margin: {
        margin: theme.spacing(1),
      },
    },
    medidor:{
      width:'100px',
      marginBottom:'1em'
    },
    campos:{
      width: '200px',
      marginBottom: '1em'
    },
    camposLectura:{
      width: '150px',
      marginBottom: '1em'
    },
    fecha:{
      width: '200px',
      marginTop:'3px',
      marginBottom: '1em'
    }
  
  }));

const FormEditar = ({ modalAbierto, setModalAbierto, titulo, energia,terminales, actualizarDatosTabla}) => {
    const classes = useStyles();
    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [aviso, setAviso] = useState(false);
    const [editActivo, setEditActivo] = useState("activo");
    const [registro, setRegistro] = useState({
                                                  Id: energia.Id,
                                                  Consumo: energia.Consumo,
                                                  Costo: energia.PrecioPromedio,
                                                  Terminal: energia.TerminalId,
                                                  Fecha: moment().format(energia.Fecha),
                                                  Medidor: energia.NumMedidor,
                                                  Activo: 1
                                                  });
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const [cargando, setCargando] = useState(false);
    const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);
    const { Consumo, Costo, Terminal, Fecha, Medidor, Activo } = registro;
    const expPermitidaDecimal = /^(\d+\.?\d*|\.\d+)$/;
    const expPermitidaConsumo = /^\d+(\.\d{1,2})?$/;
    const expPermitidaCosto = /^\d+(\.\d{1,4})?$/;
    const expPermitidaAlfaNumerica = /^[A-Z\d]+$/;
    const expPermitidaMedidor = /^[A-Z]{1}\d{4}$/;
    const historial = useHistory();
    const handleDateChange = (date) => {
        setRegistro({
            ...registro,
            Fecha: date,
        });
    };
    
    const onChangeConsumo = (e) => {
      let expNopermitida = new RegExp('[#A-Za-z]');
      if(expPermitidaDecimal.test(e.target.value)&&!expNopermitida.test(e.target.value)||e.target.value===' '||e.target.value===''){
        
        setRegistro({
          ...registro,
          Consumo: e.target.value,
        });
      }
    }
    const onChangeCosto = (e) => {
      let expNopermitida = new RegExp('[#A-Za-z]');
      if(expPermitidaDecimal.test(e.target.value)&&!expNopermitida.test(e.target.value)||e.target.value===' '||e.target.value===''){
        setRegistro({
          ...registro,
          Costo: e.target.value,
        });
      }
    };

    const onChangeMedidor = (e) => {
      let expNopermitida = new RegExp('[#a-z]');
      if(expPermitidaAlfaNumerica.test(e.target.value.toUpperCase()) ||e.target.value===' '||e.target.value===''){
        setRegistro({
          ...registro,
          Medidor: e.target.value.toUpperCase(),
        });
      }
    };

      const handleChangeTerminal = (event) => {
        if (event.target.value !== 0) {
          setRegistro({
            ...registro,
            Terminal: event.target.value,
          });
          setMensaje("");
        } else {
          setRegistro({
            ...registro,
            Terminal: event.target.value,
          });
          setTipoAdvertencia("error");
          setMensaje("Seleccione una Terminal Válida.");
          setAviso(true);
        }
      };
    
      const onChangeActivo = (event) => {
        event.preventDefault();
        setEditActivo(event.target.value);
        switch(event.target.value){
          case "activo":
            setRegistro({
              ...registro,
              Activo: 1,
            });
          break;
          case "inactivo":
            setRegistro({
              ...registro,
              Activo: 0,
            });
          break;
        }
      };

    function verificarCampos(){
        if(Terminal>0){
          if(verificarMedidor(Medidor)&&verificarConsumo(Consumo)&&verificarCosto(Costo)){
              actualizarConsumo();
          }
        }else{
          setTipoAdvertencia("error");
          setMensaje("Seleccione una Terminal Válida.");
          setAviso(true);
      } 
    }

function verificarMedidor(medidor){
  let validado=false;
  if(medidor&&medidor!=" "){
    if(medidor.length>=6){
      validado = true;
    }else{
      validado = false;
      setMensaje(`El Recibo debe contener minimo 6 caracteres`);
      setAviso(true);
      setTipoAdvertencia("error");
    }
  }else{
    validado = false;
    setMensaje(`Debe capturar el numero de Recibo `);
    setAviso(true);
    setTipoAdvertencia("error");
  }
  
  return validado
}

function verificarConsumo(consumo){
  let validado=false;
  if(consumo&&consumo!=" "){
    if(expPermitidaConsumo.test(consumo) && parseFloat(consumo)>0.00){
      validado = true;
    }else{
      validado = false;
      setMensaje(`El Consumo debe tener máximo 2 decimales y ser mayor a 0`);
      setAviso(true);
      setTipoAdvertencia("error");
    }
  }else{
    validado = false;
    setMensaje(`Debe capturar el Consumo `);
    setAviso(true);
    setTipoAdvertencia("error");
  }
  return validado
}

function verificarCosto(costo){
  let validado = false;
  if(costo&&costo!=" "){
    if(expPermitidaCosto.test(costo) && parseFloat(costo)>0.0000){
      validado = true;
    }else{
      validado = false;
      setMensaje(`El Costo debe tener máximo 4 decimales y ser mayor a 0`);
      setAviso(true);
      setTipoAdvertencia("error");
    }
  }else{
    validado = false;
    setMensaje(`Debe capturar el Costo`);
    setAviso(true);
    setTipoAdvertencia("error");
  }
  return validado;
}

  async function actualizarConsumo() {
    setCargando(true);
    setBotonDeshabilitado(true);
    let auxU = process.env.REACT_APP_LINK + `/energia-editar_1_1`;
    let token = localStorage.getItem("token20");

    let FechaSinFormato = moment(registro.Fecha).format();
    let Fecha = FechaSinFormato.slice(0,-6);
    let dataInfo = qs.stringify({
      EnergiaId: registro.Id,
      Terminal: Terminal,
      Fecha: Fecha,
      Consumo: Consumo,
      Precio: Costo,
      Medidor: Medidor,
      idDispositivo: "EBRDVDVDVFFD",
      Status: Activo,
    });
    let config = {
      url: auxU,
      method: "POST",
      data: dataInfo,
      headers: {
        "access-token": token,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      cancelToken: source.token,
    };
    let aux = {};
    await axios(config)
      .then(async (response) => {
        aux = response.data;
        if (aux.respuesta===1) {
          let mensaje = "Se editó correctamente la lectura";
          setTipoAdvertencia("success");
          setMensaje(mensaje);
          setAviso(true);
          actualizarDatosTabla();
        } else {
          let mensaje = "Ha ocurrido un error, inténtelo más tarde";
          setTipoAdvertencia("warning");
          setMensaje(mensaje);
          setAviso(true);
        }
      })
      .catch(function (error) {
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        {
          let mensaje = "Datos Incorrectos";
          setTipoAdvertencia("warning");
          setMensaje(mensaje);
          setAviso(true);
        }else
        {
          if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
              ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
          {
            console.log("Token inválido");
            localStorage.clear();                      
            historial.push("/");                              
          } 
        }
      });
      setBotonDeshabilitado(false);
      setCargando(false);
  }

  return (
    <ModalGeneral
      titulo={titulo} modalAbierto={modalAbierto}
      setModalAbierto={setModalAbierto} guardarDatos={verificarCampos}
      mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
      aviso={aviso} setAviso={setAviso} tamanio={"md"}
      botonDeshabilitado={botonDeshabilitado}
      cargando={cargando}
    >
      <form className={classes.root} autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl className={classes.campos}>
              <InputLabel id="Terminal">Terminal</InputLabel>
              <Select
                label="Terminal" id="Terminal" name="Terminal" value={Terminal} onChange={handleChangeTerminal}
              >
                {terminales.map((terminal, index) => (
                  <MenuItem value={terminal.Id} key={terminal.Id}>
                    <em>{terminal.Descr}</em>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1}>
            <FormControl className={classes.fecha}>
              <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                <DatePicker format="dd/MM/yyyy"
                  label="Fecha" size="small" name ="Fecha"
                  value={Fecha} onChange={handleDateChange}
                  minDate="2016-12-31"
                  cancelLabel={"Cancelar"} okLabel="Seleccionar" 
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item>
            <FormControl className={classes.campos}>
              <FormLabel>Activo</FormLabel>
              <RadioGroup aria-label="activo" name="Activo"
                defaultValue={editActivo} onChange={onChangeActivo}                
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControlLabel value="activo" control={<Radio />} label="Activo" />
                  </Grid>
                  <Grid item xs={1}>
                    <FormControlLabel value="inactivo" control={<Radio />} label="Inactivo" />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Box display="flex" flexDirection="row" style={{width:28+'rem', marginBottom:.5+'rem'}}  >     
          <Box display="flex" flexDirection="row"  style={{width:8+'rem',marginRight:1+"rem"}}>
            <span style={{width:3+'rem',color:'#51ad32',fontWeight:600,marginRight:.8+'rem'}}>
              {'Recibo'}
            </span>           
            <input type="text" id="Medidor" value={Medidor} 
              onChange={onChangeMedidor} name="Medidor" style={{width:4+'rem',height:1.5+'rem',fontWeight:700}}/>
          </Box>
          <Box display="flex" flexDirection="row"  style={{width:10+'rem',marginRight:1.2+"rem"}}>
            <span style={{width:4.2+'rem',color:'#51ad32',fontWeight:600,marginRight:.5+'rem'}}>
                Consumo
            </span>           
            <input type="text" id="Consumo" value={Consumo} 
              onChange={onChangeConsumo} name="Consumo" style={{width:6+'rem',height:1.5+'rem',fontWeight:700}}/>
          </Box>
          <Box display="flex" flexDirection="row"  style={{width:10+'rem'}}>
            <span style={{width:2.5+'rem',color:'#51ad32',fontWeight:600,marginRight:.5+'rem'}}>
                Costo
            </span>           
            <input type="text" id="Costo" value={Costo} 
              onChange={onChangeCosto} name="Costo" style={{width:5+'rem',height:1.5+'rem',fontWeight:700}}/>
          </Box>
        </Box>
      </form>
    </ModalGeneral>
  );
};
    
export default FormEditar;