import React, { useState,useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {  Box, TableRow,Checkbox   } from '@material-ui/core';
import {celda} from '../Generales/TablaGeneral';
import {useHistory} from "react-router-dom";
import {ErrorGeneral, llamaApiCargaToken} from "../funciones/ClienteAxios";
import {toast} from "react-toastify";
import qs from 'qs';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: window.innerHeight - 170,
        minHeight: 280,
        width: '15rem',
     
    },
    container2: {
        maxHeight: 550,
    },
    tableRow: {
        "&$selected, &$selected:hover": {
            backgroundColor: process.env.REACT_APP_COLOR
        }
    },
    tableCell: {
        "$selected &": {
            color: "white"
        }
    },
    hover: {},
    selected: {}
});

export default function CheckPerfil({registro,actualizar,listTodo,listaOriginal,tipoSelect,perfilId,setTipoSelect,setPerfil  }) {
    const classes = useStyles();
    let history = useHistory();
    const [selectedID, setSelectedID] = useState(null);
    const [operacion, setOperacion] = useState({Opera:0,status:false}); 
    const [espera,setEspera]=useState(false);
    const {Opera,status}=operacion;    
    const source = axios.CancelToken.source();
    useEffect(() => {                
 
       if (listTodo.length!==0) 
       {
            
            let filtrado =listTodo.filter((elemnt)=>elemnt.id===registro.PerfId)
            if (filtrado.length!==0) 
            {
                setOperacion({Opera:filtrado[0].id,status:filtrado[0].valor===1?true:false})          
            }    
            else{
                setOperacion({Opera:registro.PerfId,status:registro.PerfStatus===1?true:false})          
            }
       }
       else
       {
            setOperacion({Opera:registro.PerfId,status:registro.PerfStatus===1?true:false})          
       }
        
    }, [registro,listTodo])
    
    const seleccionado = (auxId, auxOrden) => {
        setSelectedID(auxId)       
    }

    const editar = (cambio) => {

        const editPermiso = () => {
            let idusu = localStorage.getItem("UsuId");
            let auxTokenId=cambio?1:0
            let data = qs.stringify({                
                usuario: idusu,      
                permisos:JSON.stringify([{id:Opera,valor:cambio?1:0}] ),   
                idDispositivo: 'rjvikcmkcmsdkdcdsmc'
            });

            let url = '/usuario-perfil-edit';

            function response(auxiliar) {
                let mensaje =""
                if (auxiliar.respuesta == 1) 
                {   mensaje = `Se modifico operación ${registro.Nom}`                                                                                                          
                }                                
                toast.success(mensaje, {
                    toastId: `edit-user-ope${Opera+auxTokenId}`,
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

               let filtrado1=listaOriginal.filter((perfil)=>perfil.Id === perfilId)
               let modificado=[] ,tipoMod=[]
               filtrado1[0].tipo.forEach(element => {
       
                   let nvoTipo=[]
       
                   if (element.Id===tipoSelect.Id) 
                   {   
                       let nvoOpera=[]
                       element.operaciones.forEach((operacion)=>
                       {
                           if (operacion.PerfId===parseInt(Opera)) 
                           {
                               nvoOpera.push({...operacion,PerfStatus:cambio?1:0})                               
                           }
                           else
                           {   nvoOpera.push({...operacion})                        
                           }
                       })
                       nvoTipo.push({...element,operaciones: nvoOpera})    
                       tipoMod={...element,operaciones: nvoOpera}
                   }
                   else
                   {
                       nvoTipo.push({...element})
                   }
                   modificado.push(nvoTipo[0])
                });
                let aux={ ...filtrado1[0],tipo:modificado}                
                setTipoSelect(tipoMod)
                let posicion = listaOriginal.findIndex((element) => element.Id === filtrado1[0].Id)
                listaOriginal.splice(posicion, 1,aux)                                                      
                setPerfil(aux)                
            }

    
            llamaApiCargaToken( data ,url, response, ErrorGeneral, setEspera, history, source);
        }

        editPermiso()
    }
    const handleChange = (event) => {        
        event.preventDefault();    
        setOperacion({...operacion,status :event.target.checked});
        editar(event.target.checked)
      };

    function Row(props) {
        const {row} = props;
        
        return (        
        <TableRow //onClick={() => { seleccionado(row.Id, row) }}
            className={classes.tableRow} selected={selectedID === row.Id}
            classes={{hover: classes.hover, selected: classes.selected}}
        >  
            {celda("center",{},
                
                espera ? 
                    <Box style={{padding:.2+'rem'}}>
                        <CircularProgress size={28}/> 
                    </Box>
                :   <Checkbox disabled={row.Id <1}
                        checked={status}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />                                
                ,
                1, classes.tableCell
            )}
            {celda("left", { }, `${row.Nom} ${row.Id} ` , 5, classes.tableCell)}                    
        </TableRow>           
      
        );
    }


    return (
  
        <Row row={registro}/>
  
    );
}