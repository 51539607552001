import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
    Paper, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, IconButton, Card, Box, LinearProgress
} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import EditarRefacciones from "./EditarRefacciones";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "block", width: '750px',
    }, tableRow: {
        backgroundColor: theme.palette.primary.main,
    }, alturaTabla: {
        minHeight: 280,
    }, tableCellHeader: {
        witdh: 'auto', backgroundColor: theme.palette.primary.main, color: 'white'
    }, tableCellSeleccionada: {
        color: 'white',
    },
}));

const columnas = [
    {
    id: 2, titulo: "Estatus"
}, {
    id: 3, titulo: "Folio"
}, {
    id: 4, titulo: "Alias"
}, {
    id: 5, titulo: "Descripción"
},];

export default function TablaRefacciones({
                                             listaRefacciones,
                                             usuarioContext,
                                             actualiza,
                                             setActualiza,
                                             refaccionId
                                         }) {

    const classes = useStyles();

    const [idBotonEditar, setIdBotonEditar] = useState(-1);
    const [modalEditarAbierto, setModalEditarAbierto] = useState(false);
    const [modalTitulo, setModalTitulo] = useState("");
    const [refaccion, setRefaccion] = useState(null);
    const [alturaTabla, setAlturaTabla] = useState(window.innerHeight < 1300 ? window.innerHeight - 145 : window.innerHeight - 80)
    const [espera, setEspera] = useState(false);
    const [sinDatos, guardarSinDatos] = useState(true)

    let nvlSNPermiso = '5'

    useEffect(() => {
        window.addEventListener('resize', function () {
            if (window.innerHeight < 1300) {
                setAlturaTabla(window.innerHeight - 145)
            } else {
                setAlturaTabla(window.innerHeight - 80)
            }
        });
    }, []);

    const modalEditarRefaccion = modalEditarAbierto ? (usuarioContext?.nivel != undefined && usuarioContext.nivel !== nvlSNPermiso ?
        <EditarRefacciones modalAbierto={modalEditarAbierto} setModalAbierto={setModalEditarAbierto}
                           listaRefacciones={listaRefacciones}
                           titulo={modalTitulo} refaccion={refaccion}
                           actualiza={actualiza} setActualiza={setActualiza}
        /> : null) : null;

    function abrirModalEdit(row) {
        setModalEditarAbierto(!modalEditarAbierto);
        setModalTitulo("Editar Refacción" + row.Alias);
        setRefaccion(row);
    }

    function handleClickEditar(row) {
        if (row.Id !== idBotonEditar) {
            setIdBotonEditar(row.Id);
        }
    }

    const tablaSinDatos = () => {
        return (<TableContainer>
                <Table className={classes.table} size="small" aria-label="resultados" stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columnas.map((row) => (<TableCell key={row.id} padding={"none"} align="center"
                                                               className={classes.tableCellHeader}>
                                {row.titulo}
                            </TableCell>))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="center" padding={"none"} style={{width: 95}}>Sin</TableCell>
                            <TableCell align="center" padding={"none"} style={{width: 95}}>datos</TableCell>
                            <TableCell align="center" padding={"none"} style={{width: 95}}>para</TableCell>
                            <TableCell align="center" padding={"none"} style={{width: 95}}>mostrar...</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const tablaSinResultados = () => {
        return (
            <TableContainer>
                <Table className={classes.table} size="small" aria-label="resultados" stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columnas.map((row) => (<TableCell key={row.id} padding={"none"} align="center"
                                                               className={classes.tableCellHeader}>
                                {row.titulo}
                            </TableCell>))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="center" padding={"none"} style={{width: 95}}>Sin</TableCell>
                            <TableCell align="center" padding={"none"} style={{width: 95}}>coincidencias...</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    function tablaCompleta(auxlista) {
        return (
            <TableContainer className={classes.alturaTabla} style={{height: alturaTabla}}>
                <Table className={classes.table} size="small" aria-label="resultados" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell padding={"none"} align="center"
                                       className={classes.tableCellHeader}></TableCell>
                            {columnas.map((row) => (
                                <TableCell key={row.id} padding={"none"} align="center"
                                           className={classes.tableCellHeader}>
                                    {row.titulo}
                                </TableCell>))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {auxlista.map((row, index) => (<TableRow
                            key={index} onClick={() => handleClickEditar(row)}
                            className={row.Id === idBotonEditar ? classes.tableRow : ""}
                        >
                            <TableCell align="center" padding={"none"}
                                       className={classes.TableCell}>
                                <IconButton size="small"
                                            component={"span"}
                                            onClick={row.Id === idBotonEditar ? () => abrirModalEdit(row) : null}>
                                    {row.Id === idBotonEditar ? <EditIcon style={{color: "white"}}/> : null}
                                </IconButton>
                            </TableCell>
                            <TableCell align="center" padding={"none"} style={{width: 75 + "px"}}
                                       className={row.Id === idBotonEditar ? classes.tableCellSeleccionada : null}>
                                <Card style={row.Status === 1 ? {
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'green',
                                    color: '#FFFFFF',
                                } : {width: '100%', height: '100%', backgroundColor: 'red', color: '#FFFFFF'}}>
                                    <b>{row.Status === 1 ? 'Activo' : 'Inactivo'}</b>
                                </Card>
                            </TableCell>
                            <TableCell align="center" padding={"none"}
                                       className={row.Id === idBotonEditar ? classes.tableCellSeleccionada : null}>
                                {row.Folio}
                            </TableCell>
                            <TableCell align="center" padding={"none"}
                                       className={row.Id === idBotonEditar ? classes.tableCellSeleccionada : null}>
                                {row.Alias}
                            </TableCell>
                            <TableCell align="left" padding={"none"}
                                       className={row.Id === idBotonEditar ? classes.tableCellSeleccionada : null}>
                                {row.Descr}
                            </TableCell>
                        </TableRow>))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    function filtro() {
        let info = []

        if (refaccionId.length != 0) {
            let auxCadena = refaccionId.trim();
            info = listaRefacciones.filter((dat) => dat.Descr.toString().search(auxCadena) !== -1)
        }

        let metodos
        if (info.length > 0) {
            metodos = tablaCompleta(info)
        } else {
            metodos = tablaSinResultados()
        }
        return metodos;
    }

    const contenido2 = refaccionId != '' ? filtro() : tablaCompleta(listaRefacciones)
    const contenido = sinDatos && listaRefacciones.length != 0 ? contenido2 : tablaSinDatos()
    const tabla = espera ? (<Box mt={2}><LinearProgress/> </Box>) : (contenido)

    return (<Paper className={classes.root}>
        {tabla}
        {modalEditarRefaccion}
    </Paper>)
}

