import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {InputLabel,FormControl,Select as MaterialSelect  ,Box 
  ,TextareaAutosize ,TextField,RadioGroup,FormControlLabel,Radio
  } from '@material-ui/core';
  import {toast} from "react-toastify";
//import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Relojes from './Relojes3'
import Modal from './Modal';
import moment from 'moment';
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '20ch',
      }, 
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      Textnumero: {
        width: 5+"px",
      },
      formControlFull: {
        //margin: theme.spacing(1),
        //minWidth: 120,
        width:5+"rem"
      },
    },
  }));

const EditarApertura = ({modalAbierto,setModalAbierto,titulo,horaA,auxHorometro,tiempoOp,bitacora,bitacoraTotales,setBitacoraTotales,opcionApertura,auxCabinas}) => {
  let history = useHistory();
  const classes = useStyles();
  const [espera,setEspera]=React.useState(false);
  const [btnCancelar,setbtnCancelar]=React.useState(true);
  const [mensaje, setMensaje] = React.useState('');
  const [tipoAdvertencia, setTipoAdvertencia] = React.useState('success');
  const [aviso, setAviso] = React.useState(false);
  const [horaApertura,guardarHoraApertura]= React.useState({Horario:'',Tiempo:'',Horometro:0.0, cabina:0});
  const {Horario,Tiempo,Horometro,cabina}=horaApertura
  React.useEffect(()=>{ 
    /* const auxApert =localStorage.getItem('UsuApertura');      
     */
    if (opcionApertura==2 ) {
      setbtnCancelar(false)
    }
    guardarHoraApertura({
      ...horaApertura,
      Horario: 'Thu Jun 17 2021 ' + horaA + ' GMT-0500 (hora de verano central)' ,
      Tiempo: 'Thu Jun 17 2021 ' + tiempoOp + ' GMT-0500 (hora de verano central)',
      Horometro: auxHorometro , cabina: auxCabinas,
    })   
              
          
    
  },[]);

  const registrarApertura=(auxFInicio,auxOpera)=>{
    const source = axios.CancelToken.source();     
    
    async function llenarListParo()  {       
      setEspera(true)     
      let auxU=process.env.REACT_APP_LINK +`/bitacora-editar-apertura_1_1`;         
      let token =localStorage.getItem('token20') ;         
      let idusu= localStorage.getItem('UsuId'); 
      let dataInfo = qs.stringify({                  
        'bitacora':bitacora,
        'usuario':idusu,
        'operacion':auxOpera,
        'fechaInicio':auxFInicio,
        'Horometro':Horometro,
        'cabina':cabina,
        'idDispositivo':'987t67fyvgh98uh'
          });
      let config = {
          url:auxU ,
          method: 'POST',
          headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
          data: dataInfo,
          cancelToken: source.token
      };
      let aux ={};           
      await axios(config)
      .then(function (response) {                 
        aux=  response.data;   
        //console.log(aux);                              
        if (aux.respuesta==1) 
        {                                                                 
          console.log(aux);
          let mensaje="Se registro correctamente la Apertura"
          toast.success(mensaje, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setModalAbierto(false)
          setTipoAdvertencia("success")      
          setMensaje(mensaje);
          setAviso(true)                       
          setBitacoraTotales({
            ...bitacoraTotales ,
            HorarioApertura:auxFInicio,
            TiempoOpera:auxOpera,
            Horometro:Horometro,
            Cabinas:cabina
          })  
          localStorage.setItem('UsuApertura',1);      
          setbtnCancelar(false)
        }                                            
      })
      .catch(function (error) {                  
        console.log(`Error: ${error}`);        
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        {
          console.log('datos incorrectos' );  
        }else
        {
          if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
              ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
          {
            console.log('loguearse de nuevo');
            localStorage.clear();                      
            history.push("/");                              
          } 
        }
      });
      setEspera(false)      
      
    };
    llenarListParo();    
    return ()=> {
        source.cancel();
    }

  }
  const onChange = e =>
  { let expNopermitida = new RegExp('[#A-Za-z_:$!%-({})/*=?|]');
    let expMenos = new RegExp('-');
    let expMas = new RegExp('[+]');     
    let expCadena = new RegExp('[0-9]');
    if (expCadena.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&
        !expMas.test(e.target.value)||e.target.value===' '||e.target.value==='') 
    {
      guardarHoraApertura({
        ...horaApertura,
        [e.target.name] : e.target.value  
      })
    }
  };
  
  const validaDatos=(valor, mensaje)=>{
    let respuesta=false    
    if (valor!= '' &&valor!= '0' &&valor!== 0 && valor!="00:00") {
      respuesta=true
     //console.log(valor);
    }
    else{
      setTipoAdvertencia("warning")    
      setMensaje(mensaje);
      setAviso(true)     
    }
    return respuesta
  }
  const guardarDatos = () => 
  {//console.log( Horario);  
    if (validaDatos(moment(Tiempo).format("HH:mm"),"Seleccionar Tiempo de operación")) 
    { if (validaDatos(moment(Horario).format("HH:mm"),"Seleccionar Horario de Inicio") ) 
      { if (validaDatos(cabina,"Escribir Cabinas") && validaDatos(Horometro,"Escribir Horometro")) 
        { 
          let fec = moment(Horario);
          let fec2 = moment(Tiempo);            
          //console.log( fec.format("HH:mm"));
          //console.log( fec2.format("HH:mm")); 
         // console.log( Horometro);          
          registrarApertura(fec.format("HH:mm"),fec2.format("HH:mm"))                       
        }         
      }         
    }                   
  };
  
  return ( 
  <Modal
    titulo={titulo} modalAbierto={modalAbierto}
    setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
    mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
    aviso={aviso} setAviso={setAviso} espera={espera} btnCancelar={btnCancelar}
  >
    <form className={classes.root} noValidate autoComplete="off" fullWidth>                    
      
      <Relojes horaApertura={horaApertura}  guardarHoraApertura={guardarHoraApertura}/> 
      <TextField id="input-cabina" label="Cabinas" size="small" type="number"
            value={cabina} onChange={onChange} name="cabina" style={{width:4+'rem'}}
        />  
      <TextField id="modal-apertura" label="Horómetro" size="small" type="number"
          value={Horometro} onChange={onChange} name="Horometro"
      />        
      
    </form>
  </Modal>
  );
}
 
export default EditarApertura;