import {useState,useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField} from "@material-ui/core";
import ModalGeneral from "./Modal";
import {useHistory} from "react-router-dom";
import axios from "axios";
import qs from "qs";
import moment from "moment";
import 'react-toastify/dist/ReactToastify.css';
import {toast} from 'react-toastify';
import {ErrorGeneral, llamaApiCargaToken} from "../funciones/ClienteAxios";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: {
            margin: theme.spacing(1),
        },
    }, medidor: {
        width: '100px', marginBottom: '1em'
    }, campos: {
        width: '150px', marginBottom: '1em'
    }, camposLectura: {
        width: '150px', marginBottom: '1em'
    }, fecha: {
        width: '200px', marginTop: '3px', marginBottom: '1em'
    }

}));

const EditarCarroTensor = ({modalAbierto, setModalAbierto, titulo, energia, setListaCTensor, listaCTensor}) => {
        const classes = useStyles();
        const idusu= localStorage.getItem('UsuId');                     
        const [mensaje, setMensaje] = useState("");
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const [cargando, setCargando] = useState(false);
        const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);        
        const expPermitidaDecimal = /^(\d+\.?\d*|\.\d+)$/;
        const exPermitidaNumeros = /^\d+(\.\d{1,2})?$/;
        const historial = useHistory();

        
        const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
        const [aviso, setAviso] = useState(false);
        const [editActivo, setEditActivo] = useState("activo");
        const [registro, setRegistro] = useState({
            bitacora: energia.Id,
            usuario: energia.UsrId,
            LecturaE1: energia.tensorE1,            
            LecturaE5C: energia.tensorE5C,  
            LecturaE5B: energia.tensorE5B,            
            Fecha: moment().format(energia.fBitacora),
            FechaAlta: moment().format(energia.fAlta),
            Activo: 0,
            Terminal: energia.termId,
            posFinE1:0,
            posFinE5C:0,
            posFinE5B:0,
            posCarroE1:0,
            posCarroE5C:0,
            posCarroE5B:0,
        });
        //let FechaSinFormato = moment(registro.Fecha).format();
        const FechaSinFormato=moment().format(energia.fBitacora)
        const { bitacora, usuario, LecturaE1,  LecturaE5C, LecturaE5B,
            Fecha, FechaAlta, Activo, Terminal, posFinE1,
            posFinE5C, posFinE5B, posCarroE1,
            posCarroE5C, posCarroE5B } = registro;

        useEffect(() => {
          
            setRegistro({...registro,
                bitacora: energia.Id,
                usuario: energia.UsrId,
                LecturaE1: energia.tensorE1,            
                LecturaE5C: energia.tensorE5C,  
                LecturaE5B: energia.tensorE5B,            
                Fecha: moment().format(energia.fBitacora),
                FechaAlta: moment().format(energia.fAlta),
                Activo: 0,
                Terminal: energia.termId,
                posFinE1:energia.tensorE1Fin, posFinE5C:energia.tensorE5CFin,
                posFinE5B:energia.tensorE5BFin, posCarroE1:energia.tensorE1Motriz,
                posCarroE5C:energia.tensorE5CMotriz, posCarroE5B:energia.tensorE5BMotriz,
            });
          
        }, [energia])
        
    

        //console.log('REG',registro)
        //console.log('EREGBE',energia)

        const editarCarro = () => {
            setCargando(true)
            setBotonDeshabilitado(true);                   
            const data = qs.stringify({
                bitacora: bitacora,
                usuario: idusu,                
                LecturaE1:parseFloat( LecturaE1).toFixed(2),  
                LecturaE1Fin:parseFloat(posFinE1).toFixed(2),
                LecturaE1Motriz:parseFloat(posCarroE1).toFixed(2),
                LecturaE5C: parseFloat(LecturaE5C).toFixed(2),
                LecturaE5CFin:parseFloat(posFinE5C).toFixed(2),
                LecturaE5CMotriz:parseFloat(posCarroE5C).toFixed(2),
                LecturaE5B:parseFloat(LecturaE5B).toFixed(2),
                LecturaE5BFin:parseFloat(posFinE5B).toFixed(2),
                LecturaE5BMotriz:parseFloat(posCarroE5B).toFixed(2),
                LecturaE7:0.0,
                LecturaE7Fin:0.0,
                LecturaE7Motriz:0.0,
                idDispositivo: "EBRDVDVDVFFD",                
            });

            const url = "/bitacora/ctensor/edit";

            function respuesta(auxiliar) {
                let mensaje =""
                if (auxiliar.respuesta === 1) {
                    mensaje = "Se editó correctamente la lectura ";
                    setTipoAdvertencia("success");
                    setMensaje(mensaje);
                    setAviso(true)
                    setModalAbierto(false)
                    toast.success( mensaje + moment(FechaSinFormato).format("DD/MM/YYYY"), {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    const posicion = listaCTensor.findIndex((element) => element.Id === registro.bitacora)
                    const auxElog=obtenElogacion()
                    const arrMod={...energia, ...auxElog, UsrId:idusu, tensorE1: LecturaE1,                        
                            tensorE5C: LecturaE5C,tensorE5B:  LecturaE5B,       
                            tensorE1Fin:posFinE1, tensorE1Motriz:posCarroE1, tensorE5BFin:posFinE5B, 
                            tensorE5BMotriz:posCarroE5B, tensorE5CFin:posFinE5C, tensorE5CMotriz:posCarroE5C,                                
                        }
                    listaCTensor.splice(posicion, 1, arrMod)
                    let nvoArr=listaCTensor
                    //console.log(listaCTensor);
                    setListaCTensor([])
                    setTimeout(() => {
                        setListaCTensor(nvoArr)
                      }, 100);  
                    
                } 
                else 
                {
                    mensaje = "Ha ocurrido un error, inténtelo más tarde ";
                    setTipoAdvertencia("warning");
                    setMensaje(mensaje);
                    setAviso(true);
                    toast.error(mensaje + moment(FechaSinFormato).format("DD/MM/YYYY"), {
                        position: "top-right", autoClose: 5000,
                        hideProgressBar: false, closeOnClick: true,
                        pauseOnHover: true, draggable: true, progress: undefined,
                    });
                }
            }

            llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, historial, source);
        }

    const obtenElogacion=()=>{
        let auxArr=[]
        if (Terminal === 1 ) {
            auxArr={tensorE1Elonga:(( parseFloat(posFinE1)+ parseFloat(posCarroE1) - parseFloat(LecturaE1))*2).toFixed(2) }
        } 
        else 
        {
            if (Terminal === 2) 
            {
                auxArr={tensorE5CElonga:(( parseFloat(posFinE5C)+ parseFloat(posCarroE5C) - parseFloat(LecturaE5C))*2).toFixed(2) }
            }    
            else{
                auxArr={tensorE5BElonga:(( parseFloat(posFinE5B)+parseFloat(posCarroE5B) - parseFloat(LecturaE5B))*2).toFixed(2) }
            }
        }        
        
        return auxArr
    }

    const validaNum=( aux,campo)=>{
        const valor= parseFloat(aux)
        let valida=false
        let mensj=""
        if (aux!=="") {
            if ( valor>=0 && valor<=999.99) {
                valida=true    
            }
            else{
                mensj=`Campo ${campo} debe ser menor a 999.99`                            
            }            
        }
        else{
            mensj=`Debe escribir el campo ${campo}.`                        
        }
        
        if (mensj!=="") {            
            setTipoAdvertencia("error");
            setMensaje(mensj);
            setAviso(true);
        }
        return valida
    }

    const validar2=()=>{
        if (Terminal === 1) {
            if (validaNum(LecturaE1,"E1 Inicial")&& validaNum(posFinE1,"E1 Final")&& validaNum(posCarroE1,"Carro")) {
                editarCarro()
            }
        }
        else{
            if (Terminal === 2) {
                if (validaNum(LecturaE5C,"E5C Inicial")&& validaNum(posFinE5C,"E5C Final")&& validaNum(posCarroE5C,"Carro")) {
                    editarCarro()
                }
            }
            else{
                if (Terminal === 3) {
                    if (validaNum(LecturaE5B,"E5B Inicial")&& validaNum(posFinE5B,"E5B Final")&& validaNum(posCarroE5B,"Carro")) {
                        editarCarro()
                    }
                }                
            }   
        }
    }


    const validar = () => {
        if ((Terminal === 1 &&  LecturaE1 !== "" &&  posFinE1 !== ""&&  posCarroE1 !== "" ) 
            || (Terminal === 2 &&  LecturaE5C !== "" &&  posFinE5C !== ""&&  posCarroE5C !== "") 
            || (Terminal === 3 &&  LecturaE5B !== "" &&  posFinE5B !== ""&&  posCarroE5B !== "") ) 
        {           
        
            editarCarro()
        } 
        else 
        {   const mensj=`Debe escribir el campo Estación.`
            /*  toast.error(mensj, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }); */
            setTipoAdvertencia("error");
            setMensaje(mensj);
            return setAviso(true);
        }
    }

        const onChangeEstacion1 = (e) => {
            let expNopermitida = new RegExp('[#A-Za-z]');
            if ((expPermitidaDecimal.test(e.target.value) && !expNopermitida.test(e.target.value) )
                || e.target.value === ' ' || e.target.value === '') 
            {
                setRegistro({ ...registro, [e.target.name]: e.target.value , });
            }
        }

        const onChangeEstacion5C = (e) => {
            let expNopermitida = new RegExp('[#A-Za-z]');
            if ((expPermitidaDecimal.test(e.target.value) && !expNopermitida.test(e.target.value) )
            || e.target.value === ' ' || e.target.value === '') 
            {
                setRegistro({ ...registro, [e.target.name]: e.target.value, });
            }
        }
        
        const onChangeEstacion5B = (e) => {
            let expNopermitida = new RegExp('[#A-Za-z]');
            if ((expPermitidaDecimal.test(e.target.value) && !expNopermitida.test(e.target.value) )
            || e.target.value === ' ' || e.target.value === '') 
            {
                setRegistro({ ...registro, [e.target.name]: e.target.value, });
            }
        }

    function verificarEstacion(estacion, nombre) {
        let validado = false;
        if (estacion && estacion != " ") {
            if (expPermitidaDecimal.test(estacion)) {
                if ((estacion >= 0.25 && estacion <= 8.00)) {
                    validado = true;
                } else {
                    validado = false;
                    setMensaje(`La Estación ${nombre} debe ser mayor o igual a 0.25 y menor o igual a 8.00`);
                    setAviso(true);
                    setTipoAdvertencia("error");
                }
            } else {
                validado = false;
                setMensaje(`La Estación ${nombre} debe tener máximo 2 decimales`);
                setAviso(true);
                setTipoAdvertencia("error");
            }
        } else {
            validado = false;
            setMensaje(`Ingrese Estación ${nombre}`);
            setAviso(true);
            setTipoAdvertencia("error");
        }

        return validado
    }

    const limitCaracter=6
    return (
    <ModalGeneral
        titulo={titulo} modalAbierto={modalAbierto}
        setModalAbierto={setModalAbierto} guardarDatos={validar2}
        mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
        aviso={aviso} setAviso={setAviso}  cargando={cargando}
        tamanio={"md"} botonDeshabilitado={botonDeshabilitado}            
    >
        <form className={classes.root} autoComplete="off">
        <div style={{marginBottom:"1rem"}}></div>
        {   Terminal === 1 ? 
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <FormControl className={classes.campos}>
                        <TextField id="LecturaE1"
                            label="E1 Inicial" variant="outlined"
                            size="small" value={LecturaE1} autoFocus={true}
                            inputProps= {{ maxLength: limitCaracter }} 
                            onChange={onChangeEstacion1} name="LecturaE1"
                        />
                    </FormControl>
                </Grid>     
                <Grid item xs={4}>
                    <FormControl className={classes.campos}>
                        <TextField id="PosFinE1"
                            label="E1 Final" variant="outlined"
                            size="small" value={posFinE1} autoFocus={true}
                            inputProps= {{ maxLength: limitCaracter }} 
                            onChange={onChangeEstacion1} name="posFinE1"
                        />
                    </FormControl>
                </Grid>      
                <Grid item xs={4}>
                    <FormControl className={classes.campos}>
                        <TextField id="PosCarroE1"
                            label="Carro" variant="outlined"
                            size="small" value={posCarroE1} autoFocus={true}
                            inputProps= {{ maxLength: limitCaracter }} 
                            onChange={onChangeEstacion1} name="posCarroE1"
                        />
                    </FormControl>
                </Grid>             
            </Grid> 
        :   ( Terminal === 2 ?
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <FormControl className={classes.campos}>
                        <TextField id="LecturaE5C"
                            label="5C Inicial " variant="outlined"
                            size="small" value={LecturaE5C} autoFocus={true}
                            inputProps= {{ maxLength: limitCaracter }} 
                            onChange={onChangeEstacion5C} name="LecturaE5C"
                        />
                    </FormControl>
                </Grid>      
                <Grid item xs={4}>
                    <FormControl className={classes.campos}>
                        <TextField id="txtposFinE5C"
                            label="5C Final " variant="outlined"
                            size="small" value={posFinE5C} autoFocus={true}
                            inputProps= {{ maxLength:limitCaracter }} 
                            onChange={onChangeEstacion5C} name="posFinE5C"
                        />
                    </FormControl>
                </Grid>      
                <Grid item xs={4}>
                    <FormControl className={classes.campos}>
                        <TextField id="txtPosCarroE5C"
                            label="Carro" variant="outlined"
                            inputProps= {{ maxLength:limitCaracter }} 
                            size="small" value={posCarroE5C} autoFocus={true}
                            onChange={onChangeEstacion5C} name="posCarroE5C"
                        />
                    </FormControl>
                </Grid>                    
            </Grid>
            :   <Grid container spacing={2} >
                    <Grid item xs={4}>
                        <FormControl className={classes.campos}>
                            <TextField id="LecturaE5B"
                                label="5B Inicial " variant="outlined"
                                size="small" value={LecturaE5B} autoFocus={true}
                                inputProps= {{ maxLength: limitCaracter}} 
                                onChange={onChangeEstacion5B} name="LecturaE5B"
                            />
                        </FormControl> 
                    </Grid>   
                    <Grid item xs={4}>
                        <FormControl className={classes.campos}>
                            <TextField id="txtposFinE5B"
                                label="5B Final" variant="outlined"
                                size="small" value={posFinE5B} autoFocus={true}
                                inputProps= {{ maxLength:limitCaracter }} 
                                onChange={onChangeEstacion5B} name="posFinE5B"
                            />
                        </FormControl>
                    </Grid>      
                    <Grid item xs={4}>
                        <FormControl className={classes.campos}>
                            <TextField id="txtPosCarroE5B"
                                label="Carro" variant="outlined"
                                size="small" value={posCarroE5B} autoFocus={true}
                                inputProps= {{ maxLength:limitCaracter }} 
                                onChange={onChangeEstacion5B} name="posCarroE5B"
                            />
                        </FormControl>
                    </Grid>     
                                    
                </Grid>
            )
        }              
        </form>
    </ModalGeneral>);
    }
;

export default EditarCarroTensor;