import React,{useState,useEffect} from 'react'
import ModalTam from '../Generales/ModalTamCerrar'
import { FormControl, InputLabel, Box,   
     TextField, Select,Grid
} from '@material-ui/core';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import {toast} from "react-toastify";
import {ErrorGeneral , llamaApiCargaToken } from '../funciones/ClienteAxios';
import moment from "moment/moment";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import DateFnsUtils from "@date-io/date-fns";
const NewEditDetalle = ({modalAbierto,setModalAbierto,auxMonitoreo,llenaListTabla,orden,listTipo, listUbic}) => {
  const history = useHistory();
  const [espera,setEspera]= useState(false);    
  const [btnCancelar,setbtnCancelar]= useState(false);
  const [aviso, setAviso] = useState(false);    
  const [mensaje, setMensaje] = useState('');
  const idusu= localStorage.getItem('UsuId');
  const Dispvo = localStorage.getItem("Dispvo");
  const auxDispV = Dispvo.length >= 12 ? Dispvo : "451224452121";
  const source = axios.CancelToken.source();
  const [tipoAdvertencia, setTipoAdvertencia] = useState('success');                   
  const [monitoreo, setMonitoreo] = useState({llave:0, Ffin:new Date(),
      Fini:new Date(), Status:1, Titulo:"", Descr:"",Tipo:0,Ubic:0});          
  const{llave,Ffin,Fini,Status,Titulo,Descr,Tipo,Ubic}=monitoreo

  useEffect(()=>{
    if(auxMonitoreo.length!==0)
    {
      setMonitoreo({...monitoreo,
        llave:auxMonitoreo.Id, 
        Fini:moment.utc(auxMonitoreo.FHorario).local(true),Titulo:auxMonitoreo.Titulo,
        Descr:auxMonitoreo.Descr,Tipo:auxMonitoreo.TipoId,
        Ubic:auxMonitoreo.UbicId
      })
    }
  },[auxMonitoreo])
    
  const onChange=(e)=>{        
    setMonitoreo({...monitoreo,[e.target.name]:e.target.value})
  }

  const registrar=(auxHora)=>{                       
    const data = qs.stringify({          
        Bitacora:orden.Id,          
        Llave:llave,
        Usuario:idusu,      
        Tipo:Tipo,        
        Ubicacion:Ubic,
        Horario:auxHora,          
        Status:Status,
        Titulo:Titulo,
        Descr:Descr,              
        idDispositivo:auxDispV,
    });   
    const url = "/cctv-monitoreo-det-new";
    function respuesta(aux) 
    {
      if (aux.respuesta === 1) 
      { const mensaje="Se edito correctamente "
        setTipoAdvertencia("success")      
        setMensaje(mensaje);
        setAviso(true)           
        //setRegistroExitoso(true) 
        llenaListTabla()
        setModalAbierto(false)
        toast.success(mensaje,{
          pauseOnFocusLoss: false,                    
          toastId: `nvo-cctv${aux.mensaje}`
        })
      }       
    }    
    llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);
  } 

  function valNulos(auxMensaje, auxCampo) {
    let valida = false
    if (auxCampo !== '' && auxCampo !== '0'&& auxCampo !== "0" && auxCampo !== 0 && auxCampo !== null && auxCampo !== " ") {
        valida = true
    }
    if (!valida) {
        mostrarAlertas(auxMensaje)
    }
    return valida
  }
  const mostrarAlertas = (auxMensaje) => {
    setTipoAdvertencia("warning")
    let mensaje = auxMensaje
    setMensaje(mensaje);
    setAviso(true)
  }
  const guardarDatos=()=>{      
    if (valNulos("Seleccionar Tipo", Tipo)  &&valNulos("Seleccionar Ubicación", Ubic)  
    && (valNulos("Escribir Actividad/Titulo", Titulo) && (Titulo.length>=3|| valNulos("Escribir Actividad/Titulo", "")))
    ) 
    { 
      registrar(moment(Fini).format("YYYY-MM-DDTHH:mm:ss"))  
    }
    
  }
  
  const onChangeFech1 = e => {
    //console.log(e);, 
    setMonitoreo({
      ...monitoreo,
      Fini: e
    })
  };

 
  return (
  <ModalTam titulo={llave===0 ?"Nuevo Evento":`Editar Evento ${llave}`} 
    modalAbierto={modalAbierto} setModalAbierto={setModalAbierto} 
    guardarDatos={guardarDatos} tipoAdvertencia={tipoAdvertencia} 
    aviso={aviso} setAviso={setAviso}  espera={espera} 
    btnCancelar={btnCancelar} tamanio="md"  mensaje={mensaje}
  >
    <Box display="flex" flexDirection="column" width={"27rem"}>
      <Box mb={2} mt={1}>  
        <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
          <Box mr={1} mb={2} component="span" >
            <DateTimePicker
              label="Horario" size={"small"} ampm={false}
              style={{width: 10 + 'rem',marginRight:"1rem"}}
              onChange={onChangeFech1} name="Fini"
              minDate="2017-03-14" maxDate={new Date()}
              format="dd/MM/yyyy HH:mm" value={Fini}               
              cancelLabel={"Cancelar"} okLabel="Seleccionar"                                                         
            />
          {/*  <DateTimePicker  
              label="Fecha Final" size={"small"} ampm={false}
              style={{width: 10 + 'rem'}}
              onChange={onChangeFech2} name="Ffin"
              minDate={Fini} maxDate={new Date()}
              format="dd/MM/yyyy HH:mm" value={Ffin}                                                                
            /> */}
          </Box>
        </MuiPickersUtilsProvider>          
        {listTipo.length !== 0 ? 
          <FormControl size="small">
            <InputLabel htmlFor="Tipo-inp-simp">Tipo</InputLabel>
            <Select
              native value={Tipo} onChange={onChange}
              inputProps={{ name: 'Tipo', id: 'Tipo', }}
            >
              <option aria-label="None" value=""/>
              {listTipo.map((row) => 
                <option value={row.Id}>{row.Descr}</option>
              )}
            </Select>
          </FormControl>
        : null}        
        {listUbic.length !== 0 ? 
          <FormControl size="small" style={{marginLeft:"1rem"}}>
            <InputLabel htmlFor="Ubic-inp-simp">Ubicación</InputLabel>
            <Select
              native value={Ubic} onChange={onChange}
              inputProps={{ name: 'Ubic', id: 'Ubic',}}
            >
              <option aria-label="None" value=""/>
              {listUbic.map((row) => 
                <option value={row.Id}>{row.Descr}</option>
              )}
            </Select>
          </FormControl>
        : null}
      </Box>
      <TextField id="monitTitulo" variant="outlined"
        label="Actividad/Título" size="small" value={Titulo}
        onChange={onChange} name="Titulo"
        style={{marginBottom:'1rem'}}  
      />
      
      <TextField id="monitDescr" name="Descr"  
        label="Descripción" size="small" value={Descr}
        multiline rows={4}   variant="outlined"
        onChange={onChange} 
      />
      {/* llave!==0 ? 
        <FormControlLabel label="Activo"
          control={
            <Checkbox name="checkedB" color="primary"
              checked={Status} onChange={handleChange}              
            />
          }        
        />
      :null */}
    </Box>        
  </ModalTam>
  )
}

export default NewEditDetalle