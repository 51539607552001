import React,{useState,useContext} from 'react'
import { useHistory } from "react-router-dom";
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
//import qs from 'qs';

import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import { Box,Card  ,CardContent ,Typography,IconButton,TextField,Tooltip,
    Radio ,RadioGroup,FormControlLabel,FormControl,InputLabel,Select as MaterialSelect } from '@material-ui/core'
import TablaOrden from './TablaOrden';
import RepoTab from './RepoTab';
//import qs from 'qs';
import moment from 'moment';
import Relojes from './Relojes2'
import NuevoMantenimiento from './NvaOrden'
import axios from 'axios';
import qs from 'qs';
import {ErrorGeneral, llamadaApiToken} from '../funciones/ClienteAxios';
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import {findNumber} from "../funciones/FindNumber";
const useStyles = makeStyles((theme)=>({
    root: {
      minWidth: 275,
    },    
    title: {
      fontSize: 28,
    },
    title2: {
        fontSize: 18,
      },
    subTitle2: {
        fontSize: 12,
      },
    pos: {
      marginBottom: 12,
    },
    addButton: {
        marginRight: theme.spacing(1),       
      },
      altura2: {
        height: 70,
        //width:'100%',
        marginTop:0,
        paddingTop:0,
       // width:275
      },
      altura3: {
        height: 145,
        marginTop:0,
        paddingTop:0,
       // width:275
      },
      altura1: {
        height: 90,        
      },
  }));
  const arrEstatus = [
    {valor: -2, nombre: "Todo"},
    {valor: 0, nombre: "Registrada"},
    {valor: 1, nombre: "Finalizada"},
    {valor: -1, nombre: "Cancelada"},
]

const EdificioInicio = () => {
    let history = useHistory();
    const classes = useStyles();
    let nvlSNPermiso ='5'  
    const {usuario,guardarUsuario}= useContext(AuthContext);
    const {guardarGenerales}= useContext(GeneralesContext);  
    const [tablaSeccionada,guardarTablaSeleccionada]= useState(0)
    const [estatus, setEstatus] = useState(-2);
    const [ordenSeleccionada,setOrdenSeleccionada]= useState([])
    const [ordenBusq, setOrdenBusq] =  useState({orden:'',turno:''});
    const [fechasSeleccionadas, setFechasSeleccionadas] = useState({inicio:new Date(),final:new Date()});
    const {orden,turno}=ordenBusq;
    const [buscar, setBuscar] = useState(true);
    const [espera, setEspera] = React.useState(false);   
    const [Bucle, setBucle] = useState(0);    
    const [listLugarTerminal, setListLugarTerminal] = useState([])
    const [modalAbierto, setModalAbierto] = useState(false);

    React.useEffect(()=>{ 
        const source = axios.CancelToken.source();     
        let idusu = localStorage.getItem('UsuId');     
        const llamarUbicacionOrd = () => {
            let data = qs.stringify({
                tipo: 'MTTO',
                idDispositivo: '89y7gttvjhj98h'
            });
            let url = "/lugar-ubicacion-list-1_1";

            function respuestaListInterv(auxiliar) {
                if (auxiliar[0].Id !== -1) {
                    localStorage.setItem('LisUbicMtto', JSON.stringify(auxiliar));
                }
            }

            llamadaApiToken(data, url, respuestaListInterv, ErrorGeneral, history, source);
        };
        const llenarListTerminal = () => {
            let data = qs.stringify({
                usuario: idusu,
                idDispositivo: '89y7gttvjhj98h'
            });
            let url = "/lugar-terminal-list";

            function respuestaListTermin(aux) {
                if (aux[0].Id !== -1) {
                    let auxArre = [];
                    aux.forEach((tipo) => {
                        if (tipo.Id !== 0) {
                            auxArre.push({value: tipo.Id, label: tipo.Descr})
                        }
                    })                    
                    setListLugarTerminal(auxArre);                    
                }
            }

            llamadaApiToken(data, url, respuestaListTermin, ErrorGeneral, history, source)
        };

		const veriSesion =  () => {            
            const usu= localStorage.getItem('UsuarioNom');
            const nvl= localStorage.getItem('Tipo');         
            const auxTerminal= localStorage.getItem('UsuTerminal');   
            if(usu==null)
            {                                
                guardarUsuario([])						
                guardarGenerales([])     
                localStorage.clear();           
                history.push("/")	
            }
            else{               
                setBucle(auxTerminal)  
                if (usuario.length==0) 
                {
                    guardarUsuario({Nombre:usu,nivel:nvl});                                      
                }
                guardarGenerales({Titulo:'Mantenimiento Edificio'})      
                let listUbi =localStorage.getItem('LisUbicMtto') ; 
                if (listUbi===null||listUbi===undefined) {
                    llamarUbicacionOrd()    
                    
                }     
                llenarListTerminal()
                
            }
        }
	    veriSesion();	
    
    },[]);

    const onChange = e =>
    {   let expNopermitida = new RegExp('[#._:$!%-({})/*=?|]');
        let expMenos = new RegExp('-');
        let expMas = new RegExp('[+]');     
        let expCadena = new RegExp('[A-Za-z0-9]');
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) &&
            !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
            ||e.target.value===' '||e.target.value==='') 
        {
            setOrdenBusq({
                ...ordenBusq,
                [e.target.name] : e.target.value.trim()  
            })
        }        
    };

    const guardaTurno = e =>
    {   
        setOrdenBusq({
            ...ordenBusq,
            turno : e.target.value  
        })            
    };

    const incrementarBuscar = e =>
    {     
        setBuscar(!buscar)    
    };

    const onChangeEstatus = (event) => {
        setEstatus(event.target.value);
    };

    function tipoEstado(estado) {
        let auxEtiqueta = ''
        let auxColor = ''
        if (estado === 0) {
            auxEtiqueta = 'Registrado'
            auxColor = '#F46239'
        } else {
            if (estado === 1) {
                auxEtiqueta = 'Solucionado'
                auxColor = '#049b4f'
            } else {
                if (estado === -1) {
                    auxEtiqueta = 'Cancelado'
                    auxColor = 'red'
                }
            }
        }
        let renglon = {color: auxColor, etiqueta: auxEtiqueta}
        return renglon
    }

    const cambiarBucle = (event) => {        
        setBucle(event.target.value);
    };  

    const Encabezado = ({espacio, estiloCampo, descripcion, campo}) => {
    return (
        <Box {...espacio} >
            <Typography className={classes.title2} variant="h5" color="secondary"
                component="h2" align="center" style={estiloCampo}
            >
                {campo}
            </Typography>
            <Typography className={classes.subTitle2} color="textSecondary" align="center">
                {descripcion}
            </Typography>
        </Box>
    )
    }

    const CampoRenglon = ({estiloBox, estiloTitulo, titulo, campo}) => {
        return (
            <Box  {...estiloBox} >
                <span style={estiloTitulo}>{titulo} </span>
                <Typography variant='body'> {campo}  </Typography>
            </Box>
        )
    }

    function handleAbrir() {
        setModalAbierto(true)
    }

const costadoDerecho =(mtto)=>{
    let fecha = moment.utc(mtto.fecha);          
    let modificada= fecha.local(true).format("DD/MM/YYYY");
    let arrEstado = tipoEstado(mtto.Status)
    const colorTitulo = process.env.REACT_APP_COLOR
    let fechFin = moment.utc(mtto.ffinaliza)
    let fechFin2 = fechFin.local(true).format("DD/MM/YYYY HH:mm")
    console.log(mtto);
    return(
    <span>
        <Box pl={1} my={1} >
            <Card  id="Card-det-edificio" >
            <CardContent >
                <Box display="flex" flexDirection="row" justifyContent="center">                        
                    <Encabezado espacio={{}} estiloCampo={{fontWeight: '900'}} descripcion={"MTTO"} campo={mtto.Id}/>                                                  
                    {/* <Encabezado espacio={{ml: 1}} estiloCampo={{fontWeight: '900'}}
                        descripcion={"Periodo"} campo={mtto.periNom}/> */}
                    <Encabezado espacio={{ml: 1}} estiloCampo={{fontWeight: '900'}}
                        descripcion={"Lugar"} campo={mtto.lugarDescr}/>
                    <Encabezado espacio={{ml: 1}} estiloCampo={{fontWeight: '900'}}
                        descripcion={"Fecha"} campo={modificada}/>
                    <Encabezado espacio={{ml: 1}} estiloCampo={{color: arrEstado.color, fontWeight: 700}}
                        descripcion={"Estatus"} campo={arrEstado.etiqueta}/>                        
                    <Encabezado espacio={{ml: 1}} estiloCampo={{fontWeight: '900'}}
                        descripcion={"Terminal"} campo={mtto.termiDescr}/>
                    <Encabezado espacio={{ml: 1}} estiloCampo={{fontWeight: '900'}}
                        descripcion={"Turno"} campo={mtto.turno === 'D' ? 'Diurno' : 'Nocturno'}/>
                    <Encabezado espacio={{ml: 1}} estiloCampo={{fontWeight: '900'}}
                        descripcion={"Tipo"} campo={mtto.tipoNom}/>                                                
                    {(mtto.Status === 1 || mtto.Status === -1) && (mtto.Usrfinaliza !== "" && mtto.Usrfinaliza !== " ") ?
                        <Encabezado espacio={{ml: 1}} estiloCampo={{fontWeight: '900'}}
                            descripcion={"Finalizo"} campo={mtto.Usrfinaliza}/>
                        : null
                    } 
                </Box>                                                                                                        
                {/* mtto.refManual.length !== 0 && mtto.refManual !== ' ' ?
                    <CampoRenglon estiloBox={{display: "flex"}} titulo="Ref. Manual " campo={mtto.refManual}
                        estiloTitulo={{color: colorTitulo, fontWeight: 700, paddingRight: 0.2 + 'rem'}}
                    />
                    : null */}
                
                {mtto.ubicDescr.length !== 0 ?
                    <CampoRenglon estiloBox={{display: "flex"}} titulo="Ubicación " campo={mtto.ubicDescr}
                        estiloTitulo={{color: colorTitulo, fontWeight: 700, paddingRight: 0.2 + 'rem'}}
                    />
                    : null}                       
                {mtto.descr.length !== 0 ?
                    <CampoRenglon estiloBox={{display: "flex"}} titulo="Descripción " campo={mtto.descr}
                        estiloTitulo={{color: colorTitulo, fontWeight: 700, paddingRight: 0.2 + 'rem'}}
                    />
                    : null}     
                {mtto.solucion.length !== 0 && mtto.solucion !== ' ' ?
                    <CampoRenglon estiloBox={{display: "flex"}} titulo="Solución " campo={mtto.solucion}
                        estiloTitulo={{color: colorTitulo, fontWeight: 700, paddingRight: 0.2 + 'rem'}}
                    />
                : null} 
                {mtto?.finaliza === "Finalizada" ?
                    <Box display={"flex"} flexDirection="row">
                        <CampoRenglon estiloBox={{display: "flex"}} titulo="Fecha Finaliza" campo={fechFin2}
                            estiloTitulo={{color: colorTitulo,fontWeight: 700,paddingRight: 0.2 + 'rem'}}
                        />                       
                    </Box>
                    : null}
                {mtto.Status === -1 ?
                    <CampoRenglon estiloBox={{display: "flex"}} titulo="Causa Cancelación "
                        campo={moment.utc(mtto.Fcancela).local(true).format("DD/MM/YYYY HH:mm") + ' ' + mtto.causa}
                        estiloTitulo={{color: 'red', fontWeight: 700, paddingRight: 0.2 + 'rem'}}
                    />
                    : null}                  
            </CardContent>                     
            </Card>
        </Box>                    
        <RepoTab guardarTablaSeleccionada={guardarTablaSeleccionada} orden={mtto}/>                            
    </span>)
}
const funcionActuliza = () => {
    setBuscar(!buscar)
}
const contenido = ordenSeleccionada.length!== 0 ?(costadoDerecho(ordenSeleccionada)):(null)
const principal=()=>{
    //console.log(auxTotal);
    return(
        <Box display="flex" flexDirection="column" >
            <Box style={{ padding:0,margin:0}} >
                <Card className={classes.altura2}  >
                    <CardContent >
                        <Box display="flex" flexDirection="row" >
                            <Box pr={1}  >                                            
                                { usuario?.nivel !=undefined && usuario.nivel!== nvlSNPermiso && findNumber('|29|') ?
                                    <Tooltip title="Nuevo Mantenimiento">
                                        <IconButton size="small" color="primary" onClick={() => handleAbrir()}
                                            style={{paddingTop: '.6rem'}}
                                        >
                                            <AddCircleOutlineIcon/>
                                        </IconButton>
                                    </Tooltip>                                
                                    :   null
                                }
                            </Box>                                                                  
                            <Box pr={1} >
                                <IconButton color="primary"  aria-label="add circle" size="small"
                                    component="span" onClick={incrementarBuscar} style={{marginTop:.7+'rem'}}
                                >
                                    <SearchIcon/>
                                </IconButton>                                                                                                                              
                            </Box>
                            <Box style={{ marginBottom:5+'px' }} mr={1}>
                                {listLugarTerminal.length !== 0 ?
                                <FormControl className={classes.formControl} style={{width:7.5+'rem'}} size="small">
                                    <InputLabel htmlFor="Bucle-native-simple">Terminal</InputLabel>
                                    <MaterialSelect 
                                        native value={Bucle} onChange={cambiarBucle}
                                        inputProps={{name: 'Bucle', id: 'Bucle1'}}
                                    >                                        
                                        {listLugarTerminal.map((row) =>{ 
                                        return(<option value={row.value}>{row.label}</option>)
                                        })}
                                    </MaterialSelect>
                                </FormControl>
                                : null}    
                            </Box>
                            <Box>
                                <FormControl component="fieldset" size="small">
                                <InputLabel htmlFor="estatus1-native">Estatus</InputLabel>
                                <MaterialSelect
                                    native value={estatus} onChange={onChangeEstatus}
                                    inputProps={{name: 'estatus', id: 'estatus'}}
                                >
                                    {arrEstatus.map((status) => {
                                        return (
                                            <option value={status.valor} id={status.valor}>
                                                {status.nombre}
                                            </option>
                                        )

                                    })}
                                </MaterialSelect>
                            </FormControl>
                            </Box>
                            <Box >                                                                
                                <Box mr={1} component="span" width={1/4}>
                                    <FormControl component="fieldset" >                                        
                                        <RadioGroup row aria-label="turno" style={{marginTop:.2+'rem'}}
                                            defaultValue="top" name="turno" value={turno} onChange={guardaTurno}
                                        >
                                            <FormControlLabel value="D" size="small" control={<Radio color="primary" size={"small"}/>} label="T.Diurno" />
                                            <FormControlLabel value="N" size="small" control={<Radio color="primary" size={"small"}/>} label="T.Nocturno" />
                                            <FormControlLabel value="" size="small" control={<Radio color="primary" size={"small"}/>} label="Ambos" />                                   
                                        </RadioGroup>                     
                                    </FormControl>
                                </Box>                                
                                <Box   component="span" >                                
                                    <TextField id="outlined-basic" label="Folio" variant="outlined" 
                                        size="small" style={({width:8+"rem",marginBottom: 8, height:1+'rem'})} 
                                        name="orden" value={orden} onChange={onChange}                                                     
                                    />                                                                                                              
                                    <Relojes fechasSeleccionadas={fechasSeleccionadas} 
                                        setFechasSeleccionadas={setFechasSeleccionadas} />
                            </Box>                                                                                               
                            </Box>                                                        
                        </Box>                                                                                                        
                    </CardContent>                                
                </Card>
            </Box>  
            <Box  display="flex" flexDirection="row">                
                <Box width="29%"  >                                                        
                    <Box p={1} >
                       {Bucle!==0 ? 
                        <TablaOrden orden={orden} fechasSeleccionadas={fechasSeleccionadas} 
                            setOrdenSeleccionada={setOrdenSeleccionada} 
                            turno={turno} setOrdenBusq={setOrdenBusq} ordenBusq={ordenBusq}
                            setBuscar={setBuscar} buscar={buscar} usuarioContxt={usuario}  
                            Bucle={Bucle} estatus={estatus} funcionActuliza={funcionActuliza}
                        />:null }                  
                    </Box>
                </Box>                    
                <Box width="71%"   >                        
                    {contenido}
                </Box> 
            </Box>                
        </Box>      
    )
}
//const contenido = totales.length!=0 &&espera?principal(totales) :<Cargando/>
const modal = modalAbierto ? <NuevoMantenimiento modalAbierto={modalAbierto} setModalAbierto={setModalAbierto}
                                titulo={"Nuevo Mantenimiento"} funcionActuliza={funcionActuliza} /> 
                            : null
    return (
        <div>
            {principal()}
            {modal}
        </div>
    )
}

export default EdificioInicio
