import React,{useState,useEffect} from 'react';
import { makeStyles,Grid, Box, Typography,Card} from '@material-ui/core'
const useStyles = makeStyles((theme) => ({
    img:{
        width:'40px',
        height:'40px',
    },
    imgSeleccionada:{
        width:'40px',
        height:'40px',
        backgroundColor:'#424242'
    },
    contenedorimg:{
        width:'40px',
        height:'40px',  
        position: "relative",
        textAlign: "center",
    },
    contenedorimgSeleccionado:{
        width:'40px',
        height:'40px',  
        position: "relative",
        textAlign: "center",
        backgroundColor:'#424242'
    },
    textoCentrado:{
        position: "absolute",
        color:theme.palette.primary.main,
        top: "48%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    fila:{
        padding:1
    },
    contenedor:{
        overflowX:'auto',
        /*borderRadius: '6px',
        border: '2px solid #0EA7E0',
        overflowX:'scroll'*/
      }
  }));

export default function CabinaContainerSelec({cabinasTotales,cabSelecc,setCabinaSelec,arrCabinas,arrCabSelect,setArrCabSelect}){
    const classes = useStyles();
    const [filas,setFilas] =useState(0)
    const [restantes, setRestantes] = useState(0)
    const [numCabinas, setNumCabinas] =useState(0);
    let altuTamMax=825
    let anchoTamMax=1535
    const [alturaContenedor, setAlturaContenedor] = useState (window.innerHeight<altuTamMax ? window.innerHeight-310: window.innerHeight - 530)
    const [cabinaObjLista, setCabinaObjLista] = useState([])
    //const [cabinaSeleccionada, setCabinaSeleccionada] = useState({});
    const [anchoContenedor, setAnchoContenedor] = useState(window.innerWidth<anchoTamMax ? window.innerWidth-600 : window.innerWidth-600)
    const [indice,setIndice] = useState(-1);
    const [contador,setContador] = useState(0);
    const[todoCab,setTodoCab]= useState(false);
    useEffect(()=>{
        let auxCabinas = Math.trunc(((alturaContenedor)/55))
        setNumCabinas(auxCabinas)
        let auxTotalCab =0
        let auxArreCab=[]
        auxArreCab.push({Id:0})
        arrCabinas.map((cab)=>{
         /*    if (auxArreCab.length===0) 
            {   auxArreCab.push({Id:cab.Id})
            } */
            let aux=auxArreCab.filter((cabina)=>cabina.Id === cab.cabinaId)
            if (aux.length===0) 
            {
                //auxArreCab.push({Id:cab.cabinaId,status:cab.stId})
                auxArreCab.push({Id:cab.Id,Descr:cab.Descr})
            }
        })
        //console.log(auxArreCab);
        const auxfilas = Math.trunc((auxArreCab.length/auxCabinas))
        
        setFilas(auxfilas)
       // console.log(auxArreCab)
        if(auxArreCab.length%auxCabinas!=0){
            const resta = auxArreCab.length - auxfilas * auxCabinas
            setRestantes(resta)
        }
        setCabinaObjLista(auxArreCab)
        setCabinaSelec([])
 
        window.addEventListener('resize', function() {
            if(window.innerHeight<altuTamMax)
            { setAlturaContenedor(window.innerHeight-310)
            }
            else
            { setAlturaContenedor(window.innerHeight - 530)
            }

            if(window.innerWidth<anchoTamMax)
            {   setAnchoContenedor(window.innerWidth-680)
            }
            else
            {   setAnchoContenedor(window.innerWidth-600)
            }
        });
        
    },[arrCabinas])
    
    function seleccionarCabina(seleccionado){       
       let arrSelecc=arrCabinas.filter((cab)=>cab.cabinaId === seleccionado.Id)     
       
        if(indice!== seleccionado.Id)
        {   setIndice(seleccionado.Id)
        }
        if (arrSelecc.length>0) 
        {   setCabinaSelec(arrSelecc)
        }               
    }

    function seleccionarCabina2(seleccionado){
        //console.log(seleccionado);
    
        let arrSelecc=cabinaObjLista.filter((cab)=>cab.Id === seleccionado.Id)//filtra el arreglo de todas las cabinas para obtener  la nva cabina seleccionada
        //console.log(arrSelecc);
        if (arrSelecc.length > 0) // si no obtiene nada no existe la cabina
        {   let auxArr =arrCabSelect //igualamos el arreglo seleccionado en una nueva variable para hacerle push
           // console.log(auxArr.length);
            if (arrCabSelect.length>0) //primero sabemos si el arreglo de las cabinas ya seleccionadas tiene alguna agregada o no
            {
                let aux=arrCabSelect.filter((cab)=>cab.Id === seleccionado.Id)//filtramos si la cabina seleccionada ya esta en el arreglo
                let nvo=[]
                
                if (aux.length>0) // si ya existe  filtramos el arreglo sin agregar la seleccionada
                {
                    nvo=arrCabSelect.filter((cab)=>cab.Id !== seleccionado.Id)                     
                    setArrCabSelect(nvo)
                    setContador(contador+1)
                }
                else// si no existe agregamos la nueva cabina
                {
                    auxArr.push(arrSelecc[0])
                    setArrCabSelect(auxArr)
                    setContador(contador+1)
                }        
            } 
            else// si no tiene agregamos la cabina seleccionado al arreglo vacio 
            {   console.log("saltando el metodo");
                setArrCabSelect(arrSelecc)
                setContador(contador+1)
            }  
        }
 
    }
    
    function seleccionTodo() {
      
        if (arrCabSelect.length === (cabinaObjLista.length-1) ) 
        {
            setArrCabSelect([])    
            setTodoCab(false)
        }
        else
        {   let auxFiltrado=cabinaObjLista.filter((cab)=>cab.Id !== 0)
            setArrCabSelect(auxFiltrado)
            setTodoCab(true)
        }
    }

    function colorCabSelect(seleccionada) {
        let respuesta=null        
        respuesta=arrCabSelect.filter((cab)=>cab.Id === seleccionada.Id)
        if (respuesta.length > 0) {
            console.log(respuesta);
        }        
        let aux=respuesta.length > 0 ? true:false

        return (aux)
    }

    function generarCabinas(){
        let arr=[];
        let id=0;
        //console.log(cabinaObjLista.length);
        for(let i = 0; i<filas;i++){
            let arrCab= []
            for(let cabinas=0;cabinas<numCabinas;cabinas++){
                let cabina=cabinaObjLista[id];
                let srcImg="";                
                srcImg="TELEFERICO_blanco.svg"
                if(cabina.Id === 0){
                    let cabTodas = <div className={todoCab ? (classes.contenedorimgSeleccionado) : (classes.contenedorimg) }
                                        onClick={()=>{seleccionTodo(cabina)}}
                                    >
                                    <img className={classes.img} src={srcImg}/>
                                        <div className={classes.textoCentrado}>
                                        <strong  style={{fontSize:'12px'}}>Todo</strong>
                                        </div>
                                    </div>
                    arrCab.push(cabTodas)
                }else{
                        let auxCab=arrCabSelect.length ?
                            (<div //className={ cabina.Id === indice ? classes.contenedorimgSeleccionado :classes.contenedorimg} 
                                onClick={()=>{seleccionarCabina2(cabina)}}
                                className={ arrCabSelect.indexOf(cabina) > -1  ? (classes.contenedorimgSeleccionado) : (classes.contenedorimg) }
                            >
                                <img className={classes.img} src={srcImg}/>
                                <div className={classes.textoCentrado}>
                                    <strong>{cabina.Descr}</strong>
                                </div>
                            </div>)
                        :( <div  onClick={()=>{seleccionarCabina2(cabina)}}
                                className={ classes.contenedorimg }
                            >
                                <img className={classes.img} src={srcImg}/>
                                <div className={classes.textoCentrado}>
                                    <strong>{cabina.Descr}</strong>
                                </div>
                            </div>
                        )
                        arrCab.push(auxCab)    
                }
                id++;
            }
            let grid = <Box flexDirection={"column"} display="flex" className={classes.fila}>
                            {arrCab}
                        </Box>
            arr.push(grid);
        }
            if(restantes>0){
                let arrCab= []
                for(let cabinas=0;cabinas<restantes;cabinas++){
                    if(id<cabinaObjLista.length){
                        let cabina=cabinaObjLista[id];
                        let srcImg="";                
                        srcImg="TELEFERICO_blanco.svg"
        
                        arrCab.push(<div //className={ cabina.Id === indice ? classes.contenedorimgSeleccionado :classes.contenedorimg} 
                                        className={ arrCabSelect.indexOf(cabina) > -1  ? (classes.contenedorimgSeleccionado) : (classes.contenedorimg) }
                                        onClick={()=>{seleccionarCabina2(cabina)}}
                                    >
                                        <img className={classes.img} src={srcImg}/>
                                        <div className={classes.textoCentrado}>
                                            <strong>{cabina.Descr}</strong>
                                        </div>
                                    </div>)    
                        id++;
                    }
                }
                if(arrCab.length>0){
                    let grid =  <Box flexDirection={"column"} display="flex" className={classes.fila}>
                                    {arrCab}
                                </Box>
                    arr.push(grid);
                }
            }
        return arr;
    }

    const cabMostrar = cabinaObjLista.length!=0 ?  generarCabinas() : null;  
    return(
        <Box flexDirection={"row"} display="flex" className={classes.contenedor} 
            style={{maxHeight:alturaContenedor, maxWidth:anchoContenedor,paddingRight:.5+'rem'}}
        >
            {cabMostrar}
        </Box>
    )
}