import {ErrorGeneral, llamaApiCargaToken} from "../funciones/ClienteAxios";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    useMediaQuery
} from "@material-ui/core";
import qs from "qs";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import React, {useState,useEffect} from "react";
import {green} from "@material-ui/core/colors";
import {toast} from "react-toastify";

const useStyles = makeStyles((theme) => ({
    buttonGuardar: {color: green[500],},
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

const ModalDesactivar = ({
                             abrirModal,
                             setAbrirModal,
                             cargando,
                             setCargando,
                             imageId,
                             imageSrc,
                             idusu,
                             galeria,
                             history,
                             source
                         }) => {

    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [wait, setWait] = useState(false)
   /*  useEffect(() => {
        let posicion = galeria.findIndex((element) => element.Id === parseInt(imageId))
        //console.log(imageId,posicion,galeria);        
    }, []) */
    
    const desactivarImagen = () => {
        let data = qs.stringify({
            Llave: imageId, Usuario: idusu, Status: 0, idDispositivo: '987gyubjhkkjh'
        });
        let url = "/orden-evidencia-desactiva-edit";

        function respuesta(response) {
           if(response.respuesta === 1){
               setAbrirModal(false)
               toast.success('La evidencia fue removida correctamente.', {
                   position: "top-right",
                   autoClose: 5000,
                   hideProgressBar: false,
                   closeOnClick: true,
                   pauseOnHover: true,
                   draggable: true,
                   progress: undefined,
               });
               let posicion = galeria.findIndex((element) => element.Id === parseInt(imageId))
               galeria.splice(posicion, 1)
           }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    }

    const handleClose = () => {
        setAbrirModal(false)
    };

    const handleDelete = () => {
        setWait(true)
        setCargando(true)
        desactivarImagen()
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={abrirModal}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle style={{color: '#51ad32'}} id="mod2-titulo"
                         align="center">{'¿Desea eliminar esta evidencia?'}</DialogTitle>
            <DialogContent style={{padding: 0, overflowY: 'hidden'}}>
                <img src={imageSrc} style={{width: '100%'}} alt={'Imagen a Eliminar'}/>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    Cancelar
                </Button>
                <Button
                    variant="outlined" className={classes.buttonGuardar}
                    disabled={wait ? true : cargando} onClick={handleDelete} autoFocus
                >
                    Aceptar
                </Button>
                {cargando && <CircularProgress size={24} className={classes.buttonProgress}/>}
            </DialogActions>
        </Dialog>
    );
};

export default ModalDesactivar;
